import { Button } from './button/Button';

export interface IPaginationProps {
  hasPrevious: boolean;
  previous: () => void;
  hasNext: boolean;
  next: () => void;
  isFetching: boolean;
  page?: number;
  totalPages?: number;
}

export const Pagination: React.FC<IPaginationProps> = (props) => {
  const { hasPrevious, previous, hasNext, next, isFetching, page, totalPages } = props;

  if (!hasNext && !hasPrevious) {
    return null;
  }

  return (
    <div className="flex justify-between">
      <div>
        <Button isDisabled={isFetching || !hasPrevious} onTrigger={previous} isLoading={isFetching}>
          Previous
        </Button>
      </div>
      {!!(page != null && totalPages != null) && (
        <div className="flex items-center justify-center">
          Page {page + 1} of {totalPages}
        </div>
      )}
      <div>
        <Button isDisabled={isFetching || !hasNext} onTrigger={next} isLoading={isFetching} variant="primary">
          Next
        </Button>
      </div>
    </div>
  );
};
