import React from 'react';

type MicrosoftIconProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const MicrosoftIcon = ({ className = '', width = 21, height = 21 }: MicrosoftIconProps) => (
  <div className={`w-[${width}px] h-[${height}px] relative overflow-hidden ${className}`}>
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="9" height="9" fill="#F25022" />
      <rect x="1" y="11" width="9" height="9" fill="#00A4EF" />
      <rect x="11" y="1" width="9" height="9" fill="#7FBA00" />
      <rect x="11" y="11" width="9" height="9" fill="#FFB900" />
    </svg>
  </div>
);

export default MicrosoftIcon;
