import toast from 'react-hot-toast';

import { DialogContent, DialogRoot } from '@/components/dialog/Dialog';
import { RiskIndicationDot } from './RiskIndicator';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { updateRisk } from '../models';

export interface IUpdateRiskScoreDialogProps {
  riskId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const UpdateRiskScoreDialog: React.FC<IUpdateRiskScoreDialogProps> = (props) => {
  const { riskId, isOpen, setIsOpen } = props;
  const { workspace } = useWorkspace();

  const handleRiskChange = async (newScore: number) => {
    try {
      await updateRisk({
        workspaceId: workspace.id,
        riskId,
        data: { overallScoreOverwrite: newScore },
      });
      toast.success('Risk score has been updated');
      setIsOpen(false);
    } catch (err) {
      toast.error('Failed to update risk score');
    }
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Update Risk Score</h1>

        <div className="grid gap-2">
          <div
            className="flex gap-2 items-center hover:bg-green-100 text-green-800 px-2 py-1 rounded cursor-pointer"
            onClick={() => {
              handleRiskChange(20);
            }}
          >
            <RiskIndicationDot score={20} hasBeenScored={true} />
            Low
          </div>
          <div
            className="flex gap-2 items-center hover:bg-yellow-100 text-yellow-800 px-2 py-1 rounded cursor-pointer"
            onClick={() => {
              handleRiskChange(60);
            }}
          >
            <RiskIndicationDot score={60} hasBeenScored={true} />
            Medium
          </div>
          <div
            className="flex gap-2 items-center hover:bg-red-2 text-red-9 px-2 py-1 rounded cursor-pointer"
            onClick={() => {
              handleRiskChange(80);
            }}
          >
            <RiskIndicationDot score={80} hasBeenScored={true} />
            High
          </div>
          <div
            className="flex gap-2 items-center hover:bg-red-2 text-red-9 px-2 py-1 rounded cursor-pointer"
            onClick={() => {
              handleRiskChange(100);
            }}
          >
            <RiskIndicationDot score={100} hasBeenScored={true} />
            Urgent
          </div>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
