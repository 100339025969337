import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  X as XIcon,
  Check as CheckIcon,
  Warning as WarningIcon,
  Trash as TrashIcon,
  Robot as RobotIcon,
  Hand as HandIcon,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';

import { Breadcrumb } from '@/components/Breadcrumb';
import { StartAnalysisFilePicker } from './FilePicker';
import { TreeNode } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { Button } from '@/components/button/Button';
import { SimpleSelect } from '@/components/select/SimpleSelect';
import type {
  BodyType as CreateAnalysisPayload,
  ResponseType as CreateAnalysisResponse,
} from '@/app/workspacePresetRun/endpoints/CreateAnalysisEndpoint';
import { useTeam } from '@/app/team/context/TeamContext';
import { fetchEndpointData } from '@/utils/fetch.client';
import { DocumentIndexingStatus, LanguageEnum } from '@/app/workspaceDocument/enums';
import { useAuth } from '@/contexts/auth-context';
import { nullthrows } from '@/utils/invariant';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { captureException } from '@sentry/react';
import { getDisplayError } from '@/utils/get-display-error';
import { Input } from '@/components/input/Input';
import classNames from '@/utils/classnames';
import { Spinner } from '@/components/Spinner';
import { InfoDialog } from '@/components/dialog/InfoDialog';
import { Filepath } from '@/components/Filepath';
import { Tag } from '@/components/Tag';
import { CardButton } from '@/components/card/CardOld';

const DOC_ALERT_TRESHOLD = 50;

interface ISelectDocumentsStepProps {
  analysisId: string;
  selectedFiles: TreeNode[];
  onSelectionChange: (files: TreeNode[]) => void;
}

export const SelectDocumentsStep: React.FC<ISelectDocumentsStepProps> = (props) => {
  const { analysisId, selectedFiles, onSelectionChange: _onSelectionChange } = props;
  const { tree } = useWorkspace();

  const handleSelectionChange = useCallback(
    (files: TreeNode[]) => {
      const fileIds = new Set();
      const uniqueFiles: TreeNode[] = [];
      for (const file of files) {
        if (fileIds.has(file.id)) {
          continue;
        }
        uniqueFiles.push(file);
      }
      return _onSelectionChange(uniqueFiles);
    },
    [_onSelectionChange],
  );

  return (
    <div>
      <div className="mb-4">Select the documents to perform the analysis on.</div>

      <div>
        <StartAnalysisFilePicker selectedFiles={selectedFiles} onSelectionChange={handleSelectionChange} />
      </div>

      {selectedFiles.length > 0 && (
        <>
          <div className="font-medium mb-2 mt-4 flex gap-1 items-center">
            <div>Selected files</div>
            {selectedFiles.length > DOC_ALERT_TRESHOLD && <WarningIcon className="w-4 h-4" />}
            <div>{`(${selectedFiles.length})`}</div>
            <InfoDialog title="Hint: Document count">
              <div>
                Document analysis always run over all selected documents, the more documents you select, the more
                expensive the analysis becomes. Make sure to always select the most minimal subset of data to ensure
                quick and efficient analysis and to prevent excessive costs.
              </div>
            </InfoDialog>
          </div>
          <div className="flex flex-col gap-1">
            {selectedFiles.map((v, idx) => {
              const node = tree.getNode(v.id);
              let status: 'invalid' | 'processed' | 'processing' = 'invalid';
              if (node?.document) {
                if (node.document.indexingStatus === DocumentIndexingStatus.Indexed) {
                  if (node.document.processingStatus === 'processed') {
                    status = 'processed';
                  } else {
                    status = 'processing';
                  }
                } else if (node.document.indexingStatus === DocumentIndexingStatus.Invalid) {
                  status = 'invalid';
                } else {
                  status = 'processing';
                }
              }

              return (
                <div
                  className={classNames('flex justify-between border-gray-200 py-1', {
                    'border-b': idx !== selectedFiles.length - 1,
                  })}
                  key={v.id}
                >
                  <Filepath>{v.getFullpath()}</Filepath>
                  <div className="flex items-center gap-2">
                    <div>
                      {status === 'invalid' ? (
                        <XIcon className="w-6 h-6 text-danger-color-dark" />
                      ) : status === 'processed' ? (
                        <CheckIcon className="w-6 h-6 text-green-600" />
                      ) : (
                        <Spinner size={6} />
                      )}
                    </div>
                    <div>
                      <Button
                        size={6}
                        shape="square"
                        onTrigger={() => {
                          handleSelectionChange(selectedFiles.filter((f) => f.id !== v.id));
                        }}
                      >
                        <TrashIcon className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

type Step = 'select-files' | 'select-party' | 'select-preset' | 'select-auto';

interface IStartAnalysisFlowProps {
  presetType: 'one-by-one' | 'cross-document';
  presets: Array<{
    id: string;
    name: string;
  }>;
}

interface IPartyCount {
  count: number;
  name: string;
}

export const StartAnalysisFlow: React.FC<IStartAnalysisFlowProps> = (props) => {
  const { presetType, presets } = props;
  const { me } = useAuth();
  const { team } = useTeam();
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const { tree, treeKey } = useWorkspace();

  const presetItems = useMemo(() => {
    return presets.map((p) => {
      return {
        key: p.id,
        name: p.name,
      };
    });
  }, [presets]);

  const [analysisId] = useState<string>(() => {
    return `${me.name.replace(/\s+/, '-')}-${dayjs().format('DD-MMM-YYYY_HH-mm')}`.toLowerCase();
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<Step>('select-files');
  const [targetParty, setTargetParty] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<TreeNode[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<{ key: string; name: string } | null>(null);
  const [areFilesReady, setAreFilesReady] = useState(false);

  let title = 'Analyse documents one by one';
  switch (currentStep) {
    case 'select-files':
      title = 'Select documents to analyse';
      break;
    case 'select-party':
      title = 'Select party';
      break;
    case 'select-auto':
      title = 'Manual or automatic?';
      break;
    case 'select-preset':
      title = 'Select a preset';
      break;
  }

  const createAnalysis = async (opts: { isAutomatic: boolean }) => {
    const { isAutomatic } = opts;

    setIsLoading(true);
    try {
      const trimmedTargetParty = targetParty.trim();

      if (!selectedPreset && !isAutomatic) {
        throw new Error('No preset selected');
      }

      const payload: CreateAnalysisPayload = {
        presetId: selectedPreset?.key,
        workspaceId: workspace.id,
        documentIds: selectedFiles.map((v) => v.id),
        targetParty: trimmedTargetParty,
        language: workspace.language as LanguageEnum,
        presetType,
        isAutomatic,
      };
      const response = await fetchEndpointData<CreateAnalysisResponse>(`/api/v1/workspace/analysis/create`, {
        method: 'POST',
        body: payload,
      });
      navigate(`/app/t/${team.id}/workspace/${workspace.id}/analysis/${response.analysis.id}`);
      toast.success('Analysis has been created');
    } catch (err) {
      captureException(err);
      toast.error('Could not create analysis: ' + getDisplayError(err));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!selectedFiles.length) {
      setAreFilesReady(false);
      return;
    }

    for (const file of selectedFiles) {
      const node = tree.getNode(file.id);

      if (
        !node ||
        !node.document ||
        node.document.indexingStatus !== DocumentIndexingStatus.Indexed ||
        node.document.processingStatus !== 'processed'
      ) {
        setAreFilesReady(false);
        return;
      }
    }

    setAreFilesReady(true);
  }, [selectedFiles, treeKey]);

  const parties: Array<IPartyCount> = useMemo(() => {
    const partyMap = new Map<string, IPartyCount>();
    const addParty = (party: string) => {
      const normalizedParty = party.toLowerCase().replace(/\s+/g, '');
      if (!normalizedParty) {
        return;
      }

      let count = (partyMap.get(normalizedParty)?.count ?? 0) + 1;
      partyMap.set(normalizedParty, {
        count,
        name: party,
      });
    };
    for (const file of selectedFiles) {
      const node = tree.getNode(file.id);
      if (!node || !node.document) {
        continue;
      }
      if (node.document.parties) {
        for (const party of node.document.parties) {
          addParty(party.name);

          if (party.companyName) {
            addParty(party.companyName);
          }

          for (const role of party.roles) {
            addParty(role);
          }
        }
      }
    }
    const list = [...partyMap.values()].sort((a, b) => b.count - a.count);
    return list.slice(0, 5);
  }, [selectedFiles, treeKey]);

  return (
    <div>
      <div className="mb-2 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />
      </div>

      {currentStep === 'select-files' && (
        <div>
          <SelectDocumentsStep
            analysisId={analysisId}
            selectedFiles={selectedFiles}
            onSelectionChange={setSelectedFiles}
          />

          <div className="flex justify-end mt-4">
            <Button
              isDisabled={!selectedFiles.length || !areFilesReady}
              isLoading={selectedFiles.length > 0 && !areFilesReady}
              onTrigger={() => setCurrentStep('select-party')}
              variant="primary"
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {currentStep === 'select-party' && (
        <div>
          <div className="mb-4">
            <div className="mb-4">Specify the party to focus on for risk identification.</div>

            <div className="mb-4">
              <div className="label-text mb-2">Represented party</div>
              <Input type="text" value={targetParty} onChange={setTargetParty} />
            </div>

            {parties.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {parties.map((v) => {
                  return (
                    <Tag
                      color="blue"
                      onClick={() => {
                        setTargetParty(v.name);
                      }}
                    >
                      {`${v.name} (${v.count})`}
                    </Tag>
                  );
                })}
              </div>
            )}
          </div>

          <div className="flex justify-between">
            <Button onTrigger={() => setCurrentStep('select-files')}>Previous</Button>

            <Button onTrigger={() => setCurrentStep('select-auto')} variant="primary">
              Next
            </Button>
          </div>
        </div>
      )}

      {currentStep === 'select-auto' && (
        <div>
          <div className="grid grid-cols-2 gap-4 my-4">
            <CardButton
              title="Manual"
              onClick={() => {
                if (isLoading) {
                  return;
                }

                setCurrentStep('select-preset');
              }}
              icon={<HandIcon className="w-12 h-12" />}
              centered={true}
              content={<div className="max-w-md">Select one of your presets to run over these documents.</div>}
            />
            <CardButton
              title="Automatic (experimental)"
              onClick={() => {
                if (isLoading) {
                  return;
                }

                createAnalysis({ isAutomatic: true });
              }}
              icon={<RobotIcon className="w-12 h-12" />}
              centered={true}
              content={
                <div className="max-w-md">
                  Based on your documents and our internal database of questions we automatically detect the most
                  relevant questions and come up with a questionaire.
                </div>
              }
            />
          </div>

          <div className="flex justify-between">
            <Button onTrigger={() => setCurrentStep('select-party')}>Previous</Button>
          </div>
        </div>
      )}

      {currentStep === 'select-preset' && (
        <div>
          <div className="mb-4">
            <div className="mb-4">
              Select the preset to run for this analysis and the output language of the analysis.
            </div>

            <div className="mb-4">
              <div className="label-text mb-2">Preset</div>
              <SimpleSelect items={presetItems} selectedItem={selectedPreset} onSelect={setSelectedPreset} />
            </div>
          </div>

          <div className="flex justify-between">
            <Button onTrigger={() => setCurrentStep('select-auto')}>Previous</Button>

            <Button
              isDisabled={!selectedPreset}
              isLoading={isLoading}
              onTrigger={() => createAnalysis({ isAutomatic: false })}
            >
              Create analysis
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
