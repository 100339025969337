import React, { useCallback } from 'react';
import toast from 'react-hot-toast';
import mime from 'mime';

import { useTeam } from '@/app/team/context/TeamContext';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { FullPageDropzone, IProcessedFileSystemEntry } from '@/components/file/FullPageDropzone';

export function isSkippedFilename(filename: string) {
  // Hidden files (.) and temporary files created by MS Office (~$)
  return filename.startsWith('.') || filename.startsWith('~$');
}

export interface IFullPageDropUploadProps {
  folderId: string | null;
  folderPrefix?: string | null;
  allowedTypes: string[];
}

export const FullPageDropUpload: React.FC<IFullPageDropUploadProps> = (props) => {
  const { folderId, folderPrefix, allowedTypes } = props;
  const { team } = useTeam();
  const { syncState } = useWorkspace();

  const handleDrop = useCallback(
    (droppedItems: IProcessedFileSystemEntry[]) => {
      const allowedTypesSet = new Set(allowedTypes);

      const uploadItem = (item: IProcessedFileSystemEntry, parts: string[]) => {
        if (isSkippedFilename(item.name)) {
          return;
        }

        if (item.type === 'file') {
          const file = item?.file;
          const fileType = file.type || mime.getType(file.name) || 'application/octet-stream';
          if (!allowedTypesSet.has(fileType)) {
            toast.error(`File type ${fileType} is not allowed, skipping file ${[...parts, file.name].join('/')}`);
            return;
          }

          const mbSize = file.size / 1024 / 1024;
          if (mbSize > team.uploadLimit) {
            toast.error(`File size ${mbSize}MB exceeds the upload limit ${team.uploadLimit}MB`);
            return;
          }

          const prefix = [...(folderPrefix ? folderPrefix.split('/') : []), ...parts].filter(Boolean).join('/');
          syncState.startUpload({
            folderId,
            file,
            fileType,
            folderPrefix: prefix,
          });
        } else {
          for (const child of item.children) {
            if (isSkippedFilename(child.name)) {
              continue;
            }

            uploadItem(child, [...parts, item.name]);
          }
        }
      };

      for (const droppedItem of droppedItems) {
        if (droppedItem) {
          uploadItem(droppedItem, []);
        }
      }
    },
    [folderId, allowedTypes.sort().join(',')],
  );

  return <FullPageDropzone onDrop={handleDrop} text="Drop Files to upload" />;
};
