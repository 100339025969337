import { Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { captureException } from '@sentry/react';

import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { getDisplayError } from '@/utils/get-display-error';
import { InputField } from '@/components/input/InputField';
import { Button } from '@/components/button/Button';
import { LanguageSelectField } from '@/components/LanguageSelect';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { nullthrows } from '@/utils/invariant';
import { AddonSelectionField } from '../../team/components/AddonSelectionField';
import { InputDialog } from '@/components/dialog/InputDialog';
import { DataField } from '@/components/DataField';
import { SpinnerBlock } from '@/components/Spinner';
import type { ResponseType as InternalTeamResponseType } from '../endpoints/InternalTeamEndpoint';
import type {
  ResponseType as ScheduleTeamDeletionResponseType,
  BodyType as ScheduleTeamDeletionBodyType,
} from '../endpoints/InternalTeamScheduleForDeletionEndpoint';
import type {
  ResponseType as CancelTeamDeletionResponseType,
  BodyType as CancelTeamDeletionBodyType,
} from '../endpoints/InternalTeamCancelScheduledDeletionEndpoint';
import type {
  ResponseType as InternalUpdateTeamResponseType,
  BodyType as InternalUpdateTeamBodyType,
} from '../endpoints/InternalUpdateTeamEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { formatDate } from '@/utils/date';

const updateTeamSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
  supportTeamUrl: Yup.string().url('Invalid URL').nullable(),
});

export const InternalTeamPage = () => {
  const { internalTeamId: _teamId } = useParams<{ internalTeamId: string }>();
  const teamId = nullthrows(_teamId, 'Team ID is required');

  const { data, isLoading, mutate } = useSWR<InternalTeamResponseType>(
    `/api/v1/internal/team/get/${teamId}`,
    fetchEndpointData,
  );

  if (!data?.team && isLoading) {
    return <SpinnerBlock message="Loading team data..." />;
  }

  const team = nullthrows(data?.team, 'Team not found');
  const initialValues = {
    name: team.name,
    language: team.language,
    enabledAddons: team.enabledAddons,
    uploadLimit: `${team.uploadLimit}`,
    fileSizeLimit: `${team.fileSizeLimit}`,
    lowCreditsWarningTreshold: `${team.lowCreditsWarningTreshold}`,
    supportTeamUrl: team.teamsChatUrl ?? '',
  };

  const hasPendingDeletion = team.scheduledForDeletion;
  const deletionDate = team.scheduledDeletionDate && formatDate(team.scheduledDeletionDate);

  return (
    <div className="page-content">
      <PageHeader title="INTERNAL Team Settings" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Teams',
              to: '..',
            },
            {
              name: 'Team',
            },
          ]}
        />

        <div className="flex gap-2">
          <InputDialog
            triggerText="Add Credits"
            title="Add Credits"
            description="Enter a new name for the folder"
            labelText="Credits"
            submitText="Add Credits"
            initialValue="0"
            onSubmit={async (value) => {
              try {
                const parsedAmount = parseInt(value, 10);
                if (isNaN(parsedAmount) || parsedAmount <= 0) {
                  throw new Error('Invalid amount');
                }

                await fetchEndpointData(`/api/v1/internal/team/add-credits`, {
                  method: 'POST',
                  body: {
                    teamId: team.id,
                    amount: parsedAmount,
                  },
                });
                mutate();
                toast.success('Credits have been added to the team');
              } catch (err) {
                captureException(err);
                toast.error('Could not add credits to the team: ' + getDisplayError(err));
              }
            }}
          />

          {/* Conditionally render either Schedule or Cancel deletion button based on scheduledForDeletion */}
          {!hasPendingDeletion ? (
            <ConfirmDialog
              triggerText="Schedule for Deletion"
              title={`Schedule Team "${team.name}" for Deletion`}
              submitText="Schedule Deletion"
              triggerVariant="destructive"
              submitVariant="destructive"
              description={
                <div>
                  Are you sure you want to schedule the team <span className="font-medium">"{team.name}"</span> for
                  deletion? The team will be automatically deleted after 7 days.
                </div>
              }
              onSubmit={async () => {
                try {
                  const payload: ScheduleTeamDeletionBodyType = {
                    teamId: team.id,
                  };
                  await fetchEndpointData<ScheduleTeamDeletionResponseType>(`/api/v1/internal/team/schedule-deletion`, {
                    method: 'POST',
                    body: payload,
                  });
                  await mutate();
                  toast.success('Team has been scheduled for deletion in 7 days');
                } catch (err) {
                  captureException(err);
                  toast.error('Could not schedule team for deletion: ' + getDisplayError(err));
                }
              }}
            />
          ) : (
            <ConfirmDialog
              triggerText="Cancel Scheduled Deletion"
              title={`Cancel Scheduled Deletion`}
              submitText="Cancel Deletion"
              triggerVariant="outline"
              submitVariant="primary"
              description={
                <div>
                  This team is currently scheduled to be deleted {deletionDate}. Do you want to cancel the scheduled
                  deletion?
                </div>
              }
              onSubmit={async () => {
                try {
                  const payload: CancelTeamDeletionBodyType = {
                    teamId: team.id,
                  };
                  await fetchEndpointData<CancelTeamDeletionResponseType>(`/api/v1/internal/team/cancel-deletion`, {
                    method: 'POST',
                    body: payload,
                  });
                  await mutate(); // Refresh the data to update status
                  toast.success('Scheduled deletion has been cancelled');
                } catch (err) {
                  captureException(err);
                  toast.error('Could not cancel scheduled deletion: ' + getDisplayError(err));
                }
              }}
            />
          )}
        </div>
      </div>

      <div className="card">
        {hasPendingDeletion && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
            <h3 className="text-red-700 font-medium">Team Scheduled for Deletion</h3>
            <p className="text-red-600">This team is scheduled to be deleted on {deletionDate}.</p>
          </div>
        )}

        <div className="mb-4">
          <DataField title="Remaining credits">{team.remainingCredits}</DataField>
          {hasPendingDeletion && (
            <DataField title="Deletion status">
              <span className="text-red-600 font-medium">Scheduled for deletion</span>
            </DataField>
          )}
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={updateTeamSchema}
          onSubmit={async (values) => {
            try {
              const payload: InternalUpdateTeamBodyType = {
                teamId: team.id,
                data: {
                  ...values,
                  uploadLimit: parseInt(values.uploadLimit, 10),
                  fileSizeLimit: parseInt(values.fileSizeLimit, 10),
                  lowCreditsWarningTreshold: parseInt(values.lowCreditsWarningTreshold, 10),
                },
              };
              await fetchEndpointData<InternalUpdateTeamResponseType>(`/api/v1/team/internal-update`, {
                method: 'POST',
                body: payload,
              });
              mutate();
              toast.success('Team updated');
            } catch (err: any) {
              captureException(err);
              toast.error('Could not update team: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Name" type="text" name="name" />

              <LanguageSelectField labelText="Language" name="language" />

              <InputField labelText="Low on credits treshold" type="number" name="lowCreditsWarningTreshold" />

              <InputField labelText="Upload size limit" type="number" name="uploadLimit" />

              <InputField labelText="File size limit" type="number" name="fileSizeLimit" />

              <InputField labelText="Support Team URL" type="text" name="supportTeamUrl" />

              <AddonSelectionField labelText="Enabled Addons" name="enabledAddons" />

              <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                Update Team
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
