import { LinkProps } from 'react-router-dom';
import { MouseEvent } from 'react';

import { BaseButton, BaseButtonProps, BaseLinkButton } from './BaseButton';

export interface ButtonProps {
  onTrigger?: (evt: MouseEvent) => void;
  type?: BaseButtonProps['type'];
  size?: BaseButtonProps['size'];
  shape?: BaseButtonProps['shape'];
  variant?: BaseButtonProps['variant'];
  width?: BaseButtonProps['width'];
  children?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  title?: string;
  tabIndex?: number;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    onTrigger,
    size,
    shape,
    type = 'button',
    variant = 'default',
    width,
    children,
    isDisabled,
    isLoading,
    iconLeft,
    iconRight,
    title,
    tabIndex,
  } = props;

  return (
    <BaseButton
      size={size}
      shape={shape}
      width={width}
      title={title}
      onClick={onTrigger}
      type={type}
      disabled={isDisabled}
      variant={variant}
      isLoading={isLoading}
      iconLeft={iconLeft}
      iconRight={iconRight}
      tabIndex={tabIndex}
    >
      {children}
    </BaseButton>
  );
};

export interface LinkButtonProps {
  onTrigger?: (evt: MouseEvent) => void;
  shape?: BaseButtonProps['shape'];
  variant?: BaseButtonProps['variant'];
  width?: BaseButtonProps['width'];
  children?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactElement;
  to: LinkProps['to'];
  isExternal?: boolean;
  target?: string;
  title?: string;
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const {
    onTrigger,
    shape,
    variant = 'default',
    width,
    children,
    to,
    iconLeft,
    iconRight,
    isExternal,
    target,
    title,
    isDisabled,
  } = props;

  return (
    <BaseLinkButton
      onClick={onTrigger}
      to={to}
      shape={shape}
      variant={variant}
      width={width}
      iconLeft={iconLeft}
      iconRight={iconRight}
      isExternal={isExternal}
      target={target}
      title={title}
      isDisabled={isDisabled}
    >
      {children}
    </BaseLinkButton>
  );
};
