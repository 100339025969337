import React, { useMemo } from 'react';
import { Question as QuestionIcon } from '@phosphor-icons/react';

import { GroupedAnswers } from './group-answers';
import { AnswerText } from './AnswerText';
import { Tooltip } from '@/components/tooltip/Tooltip';
import classNames from '@/utils/classnames';
import { Expandable, useExpandableState, ExpandAllButton } from '@/components/expandable/Expandable';

export interface IDisplayPerQuestionProps {
  answers: GroupedAnswers;
  showDetailed?: boolean;
  showTranslation?: boolean;
  openRef: string | null;
  onOpenRef: (answerId: string) => void;
  onOpenDebugDialog: (answerId: string) => void;
}

interface QuestionWithCategory {
  id: string;
  name: string;
  question: string;
  idx: number;
  category?: string;
}

export const DisplayAnswersByQuestion: React.FC<IDisplayPerQuestionProps> = (props) => {
  const { answers, showDetailed, showTranslation, openRef, onOpenRef, onOpenDebugDialog } = props;

  // Process questions and determine if all lack categories
  const { categorizedQuestions, sortedCategories, allQuestionsUncategorized } = useMemo(() => {
    const categorizedMap = new Map<string, QuestionWithCategory[]>();
    let hasCategory = false;

    [...answers.questions.values()].forEach((question) => {
      const questionWithCategory = question as QuestionWithCategory;
      if (questionWithCategory.category) {
        hasCategory = true;
      }

      const category = questionWithCategory.category || 'Uncategorized';

      if (!categorizedMap.has(category)) {
        categorizedMap.set(category, []);
      }
      categorizedMap.get(category)?.push(questionWithCategory);
    });

    return {
      categorizedQuestions: categorizedMap,
      sortedCategories: [...categorizedMap.keys()].sort(),
      allQuestionsUncategorized: !hasCategory,
    };
  }, [answers.questions]);

  // Get all question IDs for expand/collapse functionality
  const allQuestionIds = useMemo(() => [...answers.questions.keys()], [answers.questions]);

  // Use expandable state hooks
  const {
    expandedIds: expandedQuestions,
    isExpanded: isQuestionExpanded,
    toggleExpanded: toggleQuestion,
    toggleAll: toggleAllQuestions,
  } = useExpandableState(allQuestionIds);

  const {
    expandedIds: expandedCategories,
    isExpanded: isCategoryExpanded,
    toggleExpanded: toggleCategory,
    toggleAll: toggleAllCategories,
  } = useExpandableState(sortedCategories);

  // Function to toggle all questions in a category
  const toggleAllQuestionsInCategory = (category: string) => {
    const questionsInCategory = categorizedQuestions.get(category) || [];
    const questionIds = questionsInCategory.map((q) => q.id);

    if (questionIds.every((id) => expandedQuestions.has(id))) {
      const newExpandedQuestions = new Set(expandedQuestions);
      questionIds.forEach((id) => {
        // eslint-disable-next-line drizzle/enforce-delete-with-where
        newExpandedQuestions.delete(id);
      });
      toggleAllQuestions([...newExpandedQuestions]);
    } else {
      // Expand all questions in this category
      const newExpandedQuestions = new Set(expandedQuestions);
      questionIds.forEach((id) => newExpandedQuestions.add(id));
      toggleAllQuestions([...newExpandedQuestions]);
    }
  };

  // Render unstructured questions without categories
  if (allQuestionsUncategorized) {
    const uncategorizedQuestions = categorizedQuestions.get('Uncategorized') || [];

    return (
      <div className="flex flex-col gap-4">
        {allQuestionIds.length > 0 && (
          <div className="flex justify-end mb-2">
            <ExpandAllButton
              allIds={allQuestionIds}
              expandedIds={expandedQuestions}
              onToggle={toggleAllQuestions}
              label="Expand All Questions"
              collapsedLabel="Collapse All Questions"
            />
          </div>
        )}

        {uncategorizedQuestions
          .sort((a, b) => a.idx - b.idx)
          .map((question) => {
            const questionAnswers = answers.groupedPerQuestion.get(question.id) ?? [];

            return (
              <Expandable
                title={question.name}
                isExpanded={isQuestionExpanded(question.id)}
                onExpandToggle={() => toggleQuestion(question.id)}
                className="border border-gray-200 rounded-md overflow-hidden mb-3"
                headerClassName="font-medium gap-1 flex items-center p-2 bg-gray-50 cursor-pointer hover:bg-gray-100 border-b border-gray-200"
                rightElement={
                  <Tooltip text={question.question}>
                    <QuestionIcon className="w-4 h-4" />
                  </Tooltip>
                }
              >
                {questionAnswers.map((answer) => {
                  const doc = answers.documents.get(answer.documentId);
                  const isOpen = openRef === answer.documentAnswerId;

                  return (
                    <div
                      className={classNames('grid grid-cols-3', {
                        'text-gray-900': !openRef || isOpen,
                        'text-gray-600': !!openRef && !isOpen,
                      })}
                      key={answer.documentAnswerId}
                    >
                      <div className="border-b border-r border-gray-200 pr-2 py-1 text-gray-800 pl-2">
                        {doc?.name ?? '...'}
                      </div>
                      <div className="border-b border-gray-200 col-span-2 pl-2 py-1">
                        <AnswerText
                          answer={answer}
                          showDetailed={showDetailed}
                          showTranslation={showTranslation}
                          onOpenRef={onOpenRef}
                          onOpenDebugDialog={onOpenDebugDialog}
                        />
                      </div>
                    </div>
                  );
                })}
              </Expandable>
            );
          })}
      </div>
    );
  }

  // Render with categorization
  return (
    <div className="flex flex-col gap-4">
      {sortedCategories.length > 0 && (
        <div className="flex justify-end mb-2">
          <ExpandAllButton
            allIds={sortedCategories}
            expandedIds={expandedCategories}
            onToggle={toggleAllCategories}
            label="Expand All Categories"
            collapsedLabel="Collapse All Categories"
            className="px-3 py-1 bg-gray-100 hover:bg-gray-200 rounded-md text-sm font-medium flex items-center gap-1 mr-2"
          />
        </div>
      )}

      {sortedCategories.map((category) => {
        const questionsInCategory = categorizedQuestions.get(category) || [];
        const categoryQuestionIds = questionsInCategory.map((q) => q.id);

        return (
          <Expandable
            title={category}
            isExpanded={isCategoryExpanded(category)}
            onExpandToggle={() => toggleCategory(category)}
            itemCount={questionsInCategory.length}
            itemLabel="questions"
            className="border border-gray-200 rounded-md overflow-hidden"
            headerClassName="font-semibold gap-1 flex items-center p-3 cursor-pointer hover:bg-gray-200"
            contentClassName="pl-4 pr-4 pt-2 pb-2"
            caretSize={5}
            caretClassName="text-gray-600"
            withHeaderBg={true}
          >
            <div className="flex justify-end mb-2">
              <ExpandAllButton
                allIds={categoryQuestionIds}
                expandedIds={expandedQuestions}
                onToggle={() => toggleAllQuestionsInCategory(category)}
                label="Expand All Questions"
                collapsedLabel="Collapse All Questions"
              />
            </div>

            {questionsInCategory
              .sort((a, b) => a.idx - b.idx)
              .map((question) => {
                const questionAnswers = answers.groupedPerQuestion.get(question.id) ?? [];

                return (
                  <Expandable
                    title={question.name}
                    isExpanded={isQuestionExpanded(question.id)}
                    onExpandToggle={() => toggleQuestion(question.id)}
                    className="border border-gray-200 rounded-md overflow-hidden mb-3"
                    headerClassName="font-medium gap-1 flex items-center p-2 bg-gray-50 cursor-pointer hover:bg-gray-100 border-b border-gray-200"
                    rightElement={
                      <Tooltip text={question.question}>
                        <QuestionIcon className="w-4 h-4" />
                      </Tooltip>
                    }
                  >
                    {questionAnswers.map((answer) => {
                      const doc = answers.documents.get(answer.documentId);
                      const isOpen = openRef === answer.documentAnswerId;

                      return (
                        <div
                          className={classNames('grid grid-cols-3', {
                            'text-gray-900': !openRef || isOpen,
                            'text-gray-600': !!openRef && !isOpen,
                          })}
                          key={answer.documentAnswerId}
                        >
                          <div className="border-b border-r border-gray-200 pr-2 py-1 text-gray-800 pl-2">
                            {doc?.name ?? '...'}
                          </div>
                          <div className="border-b border-gray-200 col-span-2 pl-2 py-1">
                            <AnswerText
                              answer={answer}
                              showDetailed={showDetailed}
                              showTranslation={showTranslation}
                              onOpenRef={onOpenRef}
                              onOpenDebugDialog={onOpenDebugDialog}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Expandable>
                );
              })}
          </Expandable>
        );
      })}
    </div>
  );
};
