import { useField } from 'formik';

import { JURISDICTION_NAMES } from '../constants.client';
import { ISimpleSelectItem, SimpleSelect } from '../../../components/select/SimpleSelect';
import { InputWrapper } from '../../../components/InputWrapper';

export const JURISDICTION_OPTIONS: ISimpleSelectItem[] = Object.entries(JURISDICTION_NAMES)
  .sort((a, b) => {
    return a[1].localeCompare(b[1]);
  })
  .map((v) => {
    return {
      key: v[0],
      name: v[1],
    };
  });

export interface IJurisdictionSelectFieldProps {
  name: string;
  labelText: string;
  helperText?: string;
  isDisabled?: boolean;
}

export const JurisdictionSelectField: React.FC<IJurisdictionSelectFieldProps> = (props) => {
  const { labelText, helperText, isDisabled, name } = props;
  const [field, meta, helpers] = useField({ name });

  return (
    <InputWrapper
      labelText={labelText}
      invalidText={meta.touched ? meta.error : undefined}
      helperText={helperText}
      noLabel
    >
      <SimpleSelect
        items={JURISDICTION_OPTIONS}
        selectedItem={JURISDICTION_OPTIONS.find((v) => v.key === field.value) ?? null}
        onSelect={(val) => {
          helpers.setValue(val?.key ?? null);
        }}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
      />
    </InputWrapper>
  );
};
