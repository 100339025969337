import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from '@/utils/classnames';
import useSWR from 'swr';

import { IChatMessage } from '../useChat';
import { ChatMessageContent } from '../chat/ChatMessage';
import { ChatMessageActions } from '../chat/ChatMessageActions';
import { REF_RE, getReferenceIdsFromMatch } from '../../../workspaceDocument/constants';
import { formatDate } from '../../../../utils/date';
import { fetchEndpointData } from '../../../../utils/fetch.client';
import { ResponseType as ChatMessagePartsResponseType } from '../../endpoints/WorkspaceChatMessagePartsEndpoint';
import { ChatReferencePreviewDialog } from './ChatReferencePreviewDialog';
import { BaseButton } from '../../../../components/button/BaseButton';

export interface IBaseReferenceButtonProps {
  isDirect: boolean;
  refId?: string;
  onTrigger: () => void;
  children: React.ReactNode;
  title?: string;
}

const BaseReferenceButton: React.FC<IBaseReferenceButtonProps> = (props) => {
  const { onTrigger, children, refId, isDirect, title } = props;

  return (
    <button
      type="button"
      title={title}
      className={classNames(
        'inline-flex items-center justify-center font-medium select-none cursor-pointer rounded-full text-xs w-5 h-5 mr-1 mb-1',
        {
          'bg-blue-100 text-blue-700 hover:bg-blue-200': isDirect,
          'bg-neutral-100 text-neutral-600 hover:bg-neutral-200': !isDirect,
        },
      )}
      onClick={(evt) => {
        evt.preventDefault();
        onTrigger();
      }}
    >
      {children}
    </button>
  );
};

export interface IReferenceButtonProps {
  isDirect: boolean;
  setOpenRef: (newRef: string | null) => void;
  contextEntry: IChatMessage['references'][0] & { key: string };
  doc: { name: string; date: Date };
}

const ReferenceButton: React.FC<IReferenceButtonProps> = (props) => {
  const { setOpenRef, contextEntry, doc, isDirect } = props;

  const refNumber = contextEntry.refId;

  const handleTrigger = () => {
    setOpenRef(contextEntry.key);
  };

  return (
    <BaseReferenceButton
      isDirect={isDirect}
      refId={String(refNumber)}
      onTrigger={() => {
        setOpenRef(contextEntry.key);
      }}
      title={`${doc.name}, ${formatDate(doc.date)}`}
    >
      {refNumber}
    </BaseReferenceButton>
  );
};

const ReferenceListItem: React.FC<{
  contextEntry: IChatMessage['references'][0] & { key: string };
  doc: { name: string; date: Date };
  isDirect: boolean;
  setOpenRef: (newRef: string | null) => void;
}> = ({ contextEntry, doc, isDirect, setOpenRef }) => {
  const handleOpenRef = () => setOpenRef(contextEntry.key);

  return (
    <div
      key={contextEntry.key}
      className="flex items-center mb-1 p-1 rounded hover:bg-neutral-100 cursor-pointer"
      onClick={handleOpenRef}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') handleOpenRef();
      }}
    >
      <span
        className={classNames('inline-flex items-center justify-center text-xs w-5 h-5 mr-1 select-none', {
          'font-medium text-neutral-700': isDirect,
          'font-normal text-neutral-400': !isDirect,
        })}
      >
        {contextEntry.refId}.
      </span>
      <span
        className={classNames('ml-2 text-sm truncate', {
          'font-medium text-neutral-700': isDirect,
          'font-normal text-neutral-400': !isDirect,
        })}
        title={`${doc.name}, ${formatDate(doc.date)}`}
      >
        {doc.name}, {formatDate(doc.date)}
      </span>
    </div>
  );
};

export interface IWorkspaceChatMessageComponentProps {
  msg: IChatMessage;
  openRef: string | null;
  setOpenRef: (newRef: string | null) => void;
}

export const WorkspaceChatMessageComponent: React.FC<IWorkspaceChatMessageComponentProps> = (props) => {
  const { msg, openRef, setOpenRef } = props;
  const [showAllRefs, setShowAllRefs] = useState(false);
  const { data: msgPartsData, mutate } = useSWR<ChatMessagePartsResponseType>(
    `/api/v1/workspace/chat/message-parts/${msg.messageId}`,
    fetchEndpointData,
  );

  useEffect(() => {
    mutate();
  }, [msg.messagePartsHash, mutate]);

  const isUser = msg.userId !== 'system';

  // Memoize references with sorting and key generation
  const sortedReferences = useMemo(() => {
    return msg.references
      .sort((a, b) => {
        return b.matches.filter((m) => m.isRelevant).length - a.matches.filter((m) => m.isRelevant).length;
      })
      .map((ref) => ({
        ...ref,
        key: ref.refId != null ? `${msg.messageId}#${ref.refId}` : `${msg.messageId}#${ref.documentId}`,
      }));
  }, [msg.references, msg.messageId]);

  // Memoize content processing
  const processedContent = useMemo(() => {
    return msg.content.replaceAll(REF_RE, (substring) => {
      const matches = Array.from(substring.matchAll(REF_RE));
      if (matches.length > 0) {
        const refIds = getReferenceIdsFromMatch(matches[0]!);
        return `<ref#${refIds.join(',')}>`;
      } else {
        return '';
      }
    });
  }, [msg.content]);

  // Memoize used references calculation
  const usedReferences = useMemo(() => {
    return new Set<number>(
      [...msg.content.matchAll(REF_RE)]
        .map((match) => {
          return getReferenceIdsFromMatch(match)
            .map(Number)
            .filter((v) => !isNaN(v));
        })
        .flat(),
    );
  }, [msg.content]);

  // Memoize direct and indirect reference filtering together
  const { directReferences, indirectReferences } = useMemo(() => {
    const direct: typeof sortedReferences = [];
    const indirect: typeof sortedReferences = [];
    sortedReferences.forEach((ref) => {
      if (ref.refId != null) {
        if (usedReferences.has(ref.refId)) {
          direct.push(ref);
        } else {
          indirect.push(ref);
        }
      }
    });
    return { directReferences: direct, indirectReferences: indirect };
  }, [sortedReferences, usedReferences]);

  // Memoize references to display based on showAllRefs state
  const referencesToDisplay = useMemo(() => {
    if (!showAllRefs) {
      return directReferences.sort((a, b) => (a.refId ?? 0) - (b.refId ?? 0));
    }
    return [...directReferences, ...indirectReferences].sort((a, b) => (a.refId ?? 0) - (b.refId ?? 0));
  }, [showAllRefs, directReferences, indirectReferences]);

  const toggleShowAllReferences = useCallback(() => {
    setShowAllRefs((prev) => !prev);
  }, []);

  const messageParts = useMemo(() => {
    return msgPartsData?.parts ?? [];
  }, [msgPartsData]);

  // Memoize reference count for button text
  const referenceCount = useMemo(() => {
    return directReferences.length + indirectReferences.length;
  }, [directReferences.length, indirectReferences.length]);

  // Memoize button visibility
  const shouldShowViewAllButton = useMemo(() => {
    return indirectReferences.length > 0;
  }, [indirectReferences.length]);

  return (
    <div key={msg.messageId} className={classNames('flex flex-col', { 'items-end': isUser, 'items-start': !isUser })}>
      {/* User Query Bubble */}
      {isUser && (
        <div className="mb-1 px-4 py-2 bg-neutral-100 rounded-lg max-w-[85%]">
          <div className="font-inter text-neutral-900 whitespace-pre-line">{processedContent}</div>
        </div>
      )}

      {/* System Response Bubble */}
      {!isUser && (
        <div className={classNames('p-4 bg-white rounded-lg max-w-[85%]')}>
          <ChatMessageContent
            message={{
              ...msg,
              content: processedContent, // Use processed content instead of mutating the original
            }}
            references={msg.references}
            documents={msg.documents}
            openRef={(refId) => {
              setOpenRef(`${msg.messageId}#${refId}`);
            }}
          />
        </div>
      )}

      <div>
        {sortedReferences.map((ref) => {
          const doc = msg.documents[ref.documentId]!;
          return (
            <ChatReferencePreviewDialog
              key={ref.key}
              messageParts={messageParts}
              document={doc}
              reference={ref}
              isOpen={openRef === ref.key}
              onOpenChange={(newOpen) => {
                if (newOpen === true) {
                  setOpenRef(ref.key);
                } else if (openRef === ref.key) {
                  setOpenRef(null);
                }
              }}
            />
          );
        })}
      </div>

      {sortedReferences.length > 0 && (
        <div className="pl-4">
          {referencesToDisplay.map((contextEntry) => {
            const doc = msg.documents[contextEntry.documentId]!;
            const isDirect = usedReferences.has(contextEntry.refId!);
            return (
              <ReferenceListItem
                key={contextEntry.key}
                contextEntry={contextEntry}
                doc={doc}
                isDirect={isDirect}
                setOpenRef={setOpenRef}
              />
            );
          })}

          {shouldShowViewAllButton && (
            <BaseButton type="button" onClick={toggleShowAllReferences} variant="default" className="mt-4">
              {showAllRefs ? `Hide other sources` : `Show all ${referenceCount} sources`}
            </BaseButton>
          )}
        </div>
      )}

      {/* Action Buttons */}
      {!isUser && (
        <div className="mt-2 flex justify-end">
          <ChatMessageActions messageId={msg.messageId} messageContent={processedContent} />
        </div>
      )}
    </div>
  );
};
