import React from 'react';
import { BookBookmark as BookMarkIcon, Bug as BugIcon } from '@phosphor-icons/react';

import { IAnswerData } from './group-answers';
import { AnswerLoadingBar } from '../AnswerLoadingBar';
import { useAppContext } from '@/contexts/app-context';

export interface IAnswerTextProps {
  answer: IAnswerData;
  showDetailed?: boolean;
  showTranslation?: boolean;
  onOpenRef: (documentAnswerId: string) => void;
  onOpenDebugDialog: (documentAnswerId: string) => void;
}

export const AnswerText: React.FC<IAnswerTextProps> = (props) => {
  const { answer, showDetailed, showTranslation, onOpenRef, onOpenDebugDialog } = props;
  const { enableDebugMode } = useAppContext();

  if (answer.isLoading) {
    return <AnswerLoadingBar seed={answer.documentAnswerId} />;
  }

  return (
    <div className="flex justify-between">
      <div>{showDetailed ? answer.full : showTranslation ? answer.minifiedTranslated : answer.minified}</div>
      <div className="flex gap-2">
        {enableDebugMode && (
          <div
            className="mt-1 hover:text-link-color cursor-pointer"
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();

              onOpenDebugDialog(answer.documentAnswerId);
            }}
          >
            <BugIcon className="w-4 h-4" />
          </div>
        )}
        <div
          className="mt-1 hover:text-link-color cursor-pointer pr-2"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();

            onOpenRef(answer.documentAnswerId);
          }}
        >
          <BookMarkIcon className="w-4 h-4" />
        </div>
      </div>
    </div>
  );
};
