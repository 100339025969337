import classNames from '@/utils/classnames';
import { Link } from 'react-router-dom';

export interface ICardProps {
  title: string;
  content: React.ReactElement;
}

export const Card: React.FC<ICardProps> = (props) => {
  return (
    <div className="card h-full w-full">
      <div className="card-heading">{props.title}</div>
      <div>{props.content}</div>
    </div>
  );
};

export interface ICardButtonProps {
  onClick: () => void;
  title: string;
  icon?: React.ReactElement;
  content?: React.ReactElement;
  centered?: boolean;
}

export const CardButton: React.FC<ICardButtonProps> = (props) => {
  const { onClick, icon, title, content, centered } = props;

  return (
    <div onClick={onClick} className="card py-4" data-clickable="true">
      {icon && (
        <div
          className={classNames('card-icon mb-4', {
            'flex justify-center': centered,
          })}
        >
          {icon}
        </div>
      )}
      <div
        className={classNames('card-heading', {
          'mb-2': !!content,
          'text-center': centered,
        })}
      >
        {title}
      </div>
      <div
        className={classNames({
          'text-center flex justify-center': centered,
        })}
      >
        {content}
      </div>
    </div>
  );
};

export interface ICardLinkProps {
  to: string;
  title: string;
  icon?: React.ReactElement;
  content?: React.ReactElement;
  centered?: boolean;
}

export const CardLink: React.FC<ICardLinkProps> = (props) => {
  const { to, icon, title, content, centered } = props;

  return (
    <Link to={to} className="card py-4" data-clickable="true">
      {icon && (
        <div
          className={classNames('card-icon mb-4', {
            'flex justify-center': centered,
          })}
        >
          {icon}
        </div>
      )}
      <div
        className={classNames('card-heading', {
          'mb-2': !!content,
          'text-center': centered,
        })}
      >
        {title}
      </div>
      <div
        className={classNames({
          'text-center flex justify-center': centered,
        })}
      >
        {content}
      </div>
    </Link>
  );
};
