// !!! Used cross client side and server side !!!

export enum TeamAddons {
  RiskAnalysis = 'risk-analysis',
  LlmChunking = 'llm-chunking',
}

export function hasAddonEnabled(addons: string[], addon: TeamAddons): boolean {
  return addons.includes(addon) ?? false;
}

const VALID_ADDONS_SET = new Set<string>(Object.values(TeamAddons));
export function verifyAddons(addons: string[]): string[] {
  return addons.filter((a) => VALID_ADDONS_SET.has(a));
}
