import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';

import { ITableHeader, Table } from '../../../components/table/Table';
import { useTeam } from '@/app/team/context/TeamContext';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { LinkButton } from '../../../components/button/Button';
import { formatDateTime } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { Pagination } from '../../../components/Pagination';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as QuestionsPresetsResponseType } from '../endpoints/QuestionsPresetsEndpoint';
import { ImportButton } from '@/components/ImportButton';
import { presetExportSchema, PresetExportSchemaType } from './UpdatePresetPage';
import { getDisplayError } from '@/utils/get-display-error';
import type {
  BodyType as CreateQuestionsPresetPayload,
  ResponseType as CreateQuestionsPresetResponseType,
} from '../endpoints/CreateQuestionsPresetEndpoint';
import { useAuth } from '@/contexts/auth-context';

const PRESETS_TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Name',
  },
  {
    id: 'type',
    name: 'Type',
  },
  {
    id: 'createdAt',
    name: 'Created at',
  },
];

export const PresetsPage = () => {
  const { team } = useTeam();
  const { me } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [refreshKey, setRefreshKey] = useState(0);
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const searchParams = new URLSearchParams();
      searchParams.set('teamId', team.id);
      searchParams.set('page', variables.page.toString());
      searchParams.set('take', variables.take.toString());
      const result = await fetchEndpointData<QuestionsPresetsResponseType>(
        `/api/v1/questions-preset/list?${searchParams.toString()}`,
      );
      return {
        data: result.docs ?? [],
        pages: result.pagination.pages,
      };
    },
    [team.id],
  );
  const pagination = usePagination({
    pageSize: 40,
    page: +(searchParams.get('page') ?? 0),
    fetcher: pageFetcher,
    refreshToken: refreshKey,
  });

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('page', pagination.variables.page.toString(10));
      return prev;
    });
  }, [pagination.variables.page]);

  const presets = pagination.data ?? [];
  const title = 'Presets';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />

        <div className="flex gap-2">
          {me.isSuperUser && (
            <ImportButton
              accept="application/json"
              onFileUpload={async (files) => {
                try {
                  if (files.length > 0) {
                    const firstFile = files[0]!;
                    const text = await firstFile.text();
                    const json = presetExportSchema.parse(JSON.parse(text)) as PresetExportSchemaType;

                    const payload: CreateQuestionsPresetPayload = {
                      teamId: team.id,
                      data: {
                        name: json.name,
                        type: json.type,
                        questions: json.questions.map((v, idx) => {
                          return {
                            ...v,
                            index: idx,
                            category: v.category ?? '',
                            additionalContext: v.additionalContext,
                            answerInstructions: v.answerInstructions,
                          };
                        }),
                      },
                    };
                    await fetchEndpointData<CreateQuestionsPresetResponseType>('/api/v1/questions-preset/create', {
                      method: 'POST',
                      body: payload,
                    });

                    setRefreshKey((prev) => prev + 1);

                    toast.success('Preset imported succesfully');
                  }
                } catch (err) {
                  captureException(err);
                  toast.error(`Could not import preset: ${getDisplayError(err)}`);
                }
              }}
            >
              Import Preset
            </ImportButton>
          )}
          <LinkButton to="new" variant="primary">
            Create preset
          </LinkButton>
        </div>
      </div>

      {!presets.length && pagination.isFetching ? (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      ) : !presets.length ? (
        <div className="text-center py-12">
          <p className="text-gray-600 text-lg mb-4">No presets found</p>
          <p className="text-gray-500">Click the "Create preset" button above to create your first preset</p>
        </div>
      ) : (
        <Table
          idKey="id"
          headers={PRESETS_TABLE_HEADERS}
          data={presets}
          mapData={(entry) => {
            return [
              <Link to={entry.id} className="w-full hover:text-link-color">
                {entry.name}
              </Link>,
              entry.type,
              formatDateTime(entry.createdAt),
            ];
          }}
        />
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          page={pagination.variables.page}
          totalPages={pagination.pages}
        />
      </div>
    </div>
  );
};
