import React, { memo, useMemo } from 'react';

// Types
export type AvatarId = string;

interface GradientAvatarProps {
  id: AvatarId;
  className?: string;
}

// Utility functions
const seededRandom = (seed: string): number => {
  let hash = 0;
  for (let i = 0; i < seed.length; i++) {
    hash = (hash << 5) - hash + seed.charCodeAt(i);
    hash = hash & hash;
  }

  const x = Math.sin(hash) * 10000;
  return x - Math.floor(x);
};

const generateHSLColors = (id: string): [string, string] => {
  const random = seededRandom(id);
  const baseHue = Math.floor(random * 360);
  const color1 = `hsl(${baseHue}, 70%, 65%)`;
  const color2 = `hsl(${(baseHue + 60) % 360}, 70%, 65%)`;

  return [color1, color2];
};

export const GradientAvatar = memo<GradientAvatarProps>(({ id, className }) => {
  const [color1, color2] = useMemo(() => generateHSLColors(id), [id]);

  return (
    <div
      className={`w-9 h-9 rounded-full ${className || ''}`}
      style={{
        background: `linear-gradient(135deg, ${color1}, ${color2})`,
      }}
    />
  );
});

GradientAvatar.displayName = 'GradientAvatar';
