import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import toast from 'react-hot-toast';

import { SpinnerBlock } from '../../../components/Spinner';
import Logo from '../../../components/Logo';
import GoogleIcon from '../../../components/icons/GoogleIcon';
import MicrosoftIcon from '../../../components/icons/MicrosoftIcon';
import { Button } from '../../../components/button/Button';
import type { ResponseType as MeResponse } from '../../user/endpoints/MeEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useSWR<MeResponse>('/api/v1/user/me', fetchEndpointData, {
    shouldRetryOnError: false,
    errorRetryCount: 0,
  });
  const redirectUri = window.location.origin;

  useEffect(() => {
    if (data?.me) {
      navigate('/app/t', { replace: true });
    }
  }, [data?.me, navigate]);

  if (isLoading) {
    return <SpinnerBlock message="Loading..." className="h-screen" />;
  }

  const handleGoogleLogin = () => {
    const fullRedirectUri = `/api/auth/google/redirect?redirectUri=${encodeURIComponent(redirectUri)}`;
    window.location.href = fullRedirectUri;
  };

  const handleMicrosoftLogin = () => {
    const fullRedirectUri = `/api/auth/microsoft/redirect?redirectUri=${encodeURIComponent(redirectUri)}`;
    window.location.href = fullRedirectUri;
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="w-[360px] flex flex-col items-center gap-8">
        <Logo width={28} height={24} />

        <h1 className="text-center text-2xl font-bold text-[#262626] leading-9">Login</h1>

        <div className="w-full flex flex-col gap-2">
          <Button onTrigger={handleGoogleLogin} iconLeft={<GoogleIcon />}>
            Continue with Google
          </Button>

          <Button onTrigger={handleMicrosoftLogin} iconLeft={<MicrosoftIcon />}>
            Continue with Microsoft
          </Button>
        </div>
      </div>

      <footer className="absolute bottom-8 flex justify-center items-center gap-8">
        <a
          href="https://docs.google.com/document/d/1VfBoGVguSoj5-r8NOcq72qZdGD0yoRx5E04drLrPgiM/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Terms and conditions
        </a>
        <a
          href="https://docs.google.com/document/d/1yZ_9gworSTk6LNkXqVTYscd_9HoSYaL0g5w9HEpdTh4/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Acceptable Use Policy
        </a>
        <a
          href="https://docs.google.com/document/d/1T33zx7ocKqyOQfEk_VS2I2o-y_qd3Y54lHKPK7SYvcg/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};
