import React from 'react';
import { Question as QuestionIcon } from '@phosphor-icons/react';

import { Tooltip } from '@/components/tooltip/Tooltip';
import classNames from '@/utils/classnames';
import { GroupedAnswers } from './group-answers';
import { AnswerText } from './AnswerText';
import { Expandable, useExpandableState, ExpandAllButton } from '@/components/expandable/Expandable';

export interface IDisplayPerDocumentProps {
  answers: GroupedAnswers;
  showDetailed?: boolean;
  showTranslation?: boolean;
  openRef: string | null;
  onOpenRef: (answerId: string) => void;
  onOpenDebugDialog: (answerId: string) => void;
}

export const DisplayAnswersByDocument: React.FC<IDisplayPerDocumentProps> = (props) => {
  const { answers, showDetailed, showTranslation, openRef, onOpenRef, onOpenDebugDialog } = props;

  const {
    expandedIds: expandedDocuments,
    isExpanded: isDocumentExpanded,
    toggleExpanded: toggleDocument,
    toggleAll: toggleAllDocuments,
  } = useExpandableState([...answers.documents.keys()]);

  return (
    <div className="flex flex-col gap-4">
      {answers.documents.size > 0 && (
        <div className="flex justify-end mb-2 gap-2">
          <ExpandAllButton
            allIds={[...answers.documents.keys()]}
            expandedIds={expandedDocuments}
            onToggle={toggleAllDocuments}
            label="Expand All Documents"
            collapsedLabel="Collapse All Documents"
          />
        </div>
      )}

      {[...answers.documents.values()].map((doc) => {
        const categorizedAnswers = answers.groupedPerDocument.get(doc.id);
        if (!categorizedAnswers) return null;

        return (
          <Expandable
            title={doc.name}
            isExpanded={isDocumentExpanded(doc.id)}
            onExpandToggle={() => toggleDocument(doc.id)}
            itemCount={categorizedAnswers.length}
            itemLabel="answers"
            headerClassName="font-semibold gap-1 flex items-center p-3 bg-gray-100 cursor-pointer hover:bg-gray-200 border-b border-gray-200"
            caretSize={5}
            caretClassName="text-gray-600"
          >
            {categorizedAnswers.map((item) => {
              const question = answers.questions.get(item.questionId);
              const isOpen = openRef === item.documentAnswerId;

              return (
                <div
                  className={classNames('mb-3 border border-gray-200 rounded-md overflow-hidden', {
                    'text-gray-900': !openRef || isOpen,
                    'text-gray-600': !!openRef && !isOpen,
                  })}
                >
                  <div className="font-semibold flex items-center justify-between p-3 bg-gray-50 border-b border-gray-200">
                    <div>{question?.name ?? '...'}</div>
                    <Tooltip text={question?.question ?? ''}>
                      <QuestionIcon className="w-4 h-4" />
                    </Tooltip>
                  </div>
                  <div className="p-3">
                    <AnswerText
                      answer={item}
                      showDetailed={showDetailed}
                      showTranslation={showTranslation}
                      onOpenRef={onOpenRef}
                      onOpenDebugDialog={onOpenDebugDialog}
                    />
                  </div>
                </div>
              );
            })}
          </Expandable>
        );
      })}
    </div>
  );
};
