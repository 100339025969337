import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import Logo from '../../../components/Logo';
import { Button } from '../../../components/button/Button';
import type { BodyType as LoginUsingTokenBody } from '../magic-auth/LoginUsingTokenEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { getDisplayError } from '@/utils/get-display-error';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  token: Yup.string().min(1, 'Token is required').required('Token is required'),
});

export const ValidateLoginTokenPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const handleVerifyToken = async (
    values: { email: string; token: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      const payload: LoginUsingTokenBody = {
        email: values.email,
        token: values.token,
      };
      await fetchEndpointData('/api/v1/auth/login-using-token', {
        method: 'POST',
        body: payload,
      });
      navigate('/');
    } catch (err) {
      console.error('Token verification error:', err);
      toast.error(`Verification failed: ${getDisplayError(err)}`);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="w-[360px] flex flex-col items-center gap-8">
        <Logo width={28} height={24} />

        <h1 className="text-center text-2xl font-bold text-[#262626] leading-9">Verify Login Code</h1>

        <Formik
          initialValues={{
            email: email || '',
            token: '',
          }}
          validationSchema={loginSchema}
          onSubmit={handleVerifyToken}
        >
          {({ values, handleChange, handleSubmit, isSubmitting, errors, touched }) => (
            <form onSubmit={handleSubmit} className="w-full flex flex-col gap-8">
              <div className="w-full flex flex-col gap-1">
                <div className="flex items-start gap-1">
                  <label className="text-[#525760] text-sm font-semibold leading-[22px]">Email</label>
                </div>
                <div className="w-full px-3 py-1.5 bg-white rounded-lg border border-[#C0C4CC] flex items-center">
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="name@work-email.com"
                    className="w-full border-none outline-none text-base bg-transparent placeholder:text-[#A3A3A3] placeholder:font-[450] leading-[25.6px]"
                  />
                </div>
                {errors.email && touched.email && <div className="text-red-500 text-xs mt-1">{errors.email}</div>}
              </div>

              <div className="w-full flex flex-col gap-1">
                <div className="flex items-start gap-1">
                  <label className="text-[#525760] text-sm font-semibold leading-[22px]">Login Code</label>
                </div>
                <div className="w-full px-3 py-1.5 bg-white rounded-lg border border-[#C0C4CC] flex items-center">
                  <input
                    type="text"
                    name="token"
                    value={values.token}
                    onChange={handleChange}
                    placeholder="Enter your login code"
                    className="w-full border-none outline-none text-base bg-transparent placeholder:text-[#A3A3A3] placeholder:font-[450] leading-[25.6px]"
                  />
                </div>
                {errors.token && touched.token && <div className="text-red-500 text-xs mt-1">{errors.token}</div>}
              </div>

              <div className="w-full">
                <div className="w-full">
                  <Button type="submit" isDisabled={isSubmitting} isLoading={isSubmitting} variant="primary" size={12}>
                    {isSubmitting ? 'Verifying...' : 'Verify login code'}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

      <footer className="absolute bottom-8 flex justify-center items-center gap-8">
        <a
          href="https://docs.google.com/document/d/1VfBoGVguSoj5-r8NOcq72qZdGD0yoRx5E04drLrPgiM/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Terms and conditions
        </a>
        <a
          href="https://docs.google.com/document/d/1yZ_9gworSTk6LNkXqVTYscd_9HoSYaL0g5w9HEpdTh4/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Acceptable Use Policy
        </a>
        <a
          href="https://docs.google.com/document/d/1T33zx7ocKqyOQfEk_VS2I2o-y_qd3Y54lHKPK7SYvcg/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};
