import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { CaretLeft as CaretLeftIcon, CaretRight as CaretRightIcon } from '@phosphor-icons/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from 'recharts';
import useSWR from 'swr';

import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { useTeam } from '@/app/team/context/TeamContext';
import { formatDate } from '@/utils/date';
import { ITableHeader, Table } from '@/components/table/Table';
import { Button } from '@/components/button/Button';
import { SpinnerBlock } from '@/components/Spinner';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { DailyUsageResponseType } from '../endpoints/DailyTeamUsageEndpoint';
import type { MonthlyWorkspaceResponseType } from '../endpoints/MonthlyWorkspaceUsageEndpoint';
import type { AllTimeWorkspaceResponseType } from '../endpoints/AllTimeWorkspaceUsageEndpoint';
import { generateContrastingColors } from '@/utils/colors/get-colors';

enum UsageTab {
  DAILY = 'daily',
  MONTHLY = 'monthly',
  ALL_TIME = 'all-time',
}

// Define tab configuration type
interface TabConfig {
  id: UsageTab;
  label: string;
  requiresDateNavigation: boolean;
}

const TABS: TabConfig[] = [
  {
    id: UsageTab.DAILY,
    label: 'Daily Teams Usage',
    requiresDateNavigation: true,
  },
  {
    id: UsageTab.MONTHLY,
    label: 'Monthly Workspace Usage',
    requiresDateNavigation: true,
  },
  {
    id: UsageTab.ALL_TIME,
    label: 'All Time Workspace Usage',
    requiresDateNavigation: false,
  },
];

const USAGE_TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'day',
    name: 'Day',
  },
  {
    id: 'type',
    name: 'Type',
  },
  {
    id: 'count',
    name: 'Count',
  },
  {
    id: 'username',
    name: 'User',
  },
];

const WORKSPACE_USAGE_TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'workspaceName',
    name: 'Workspace',
  },
  {
    id: 'count',
    name: 'Count',
  },
  {
    id: 'percentage',
    name: 'Percentage',
  },
];

const MAX_WORKSPACE_TO_DISPLAY = 10;

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-dark-200 px-2 py-1 rounded text-white">
        <p>{`${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

interface DailyUsageViewProps {
  month: dayjs.Dayjs;
  data: DailyUsageResponseType | undefined;
  isLoading: boolean;
}

const DailyUsageView: React.FC<DailyUsageViewProps> = ({ month, data, isLoading }) => {
  const usage = useMemo(() => {
    return (
      data?.usage.map((item, index) => ({
        ...item,
        id: index,
      })) ?? []
    );
  }, [data]);

  const totalUsage = usage.reduce((acc, entry) => acc + entry.count, 0);

  const groupedByDay = useMemo(() => {
    const map = new Map<string, number>();
    usage.forEach((entry) => {
      const key = formatDate(entry.day);
      const value = map.get(key) ?? 0;
      map.set(key, value + entry.count);
    });
    return Array.from(map.entries()).map(([day, count]) => ({ day, count }));
  }, [usage]);

  const usageRows = useMemo(() => {
    return [
      ...usage.map((v) => {
        return {
          ...v,
          day: formatDate(v.day),
        };
      }),
      {
        id: 'total',
        day: 'Total Usage',
        count: totalUsage,
        type: '',
        username: '',
      },
    ];
  }, [totalUsage, usage]);

  if (!data?.usage && isLoading) {
    return <SpinnerBlock message="Loading daily usage..." />;
  }

  return (
    <div>
      <div className="mt-4 mb-4">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={groupedByDay}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" tick={{ fill: '#3D3D3D' }} />
            <YAxis tick={{ fill: '#3D3D3D' }} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="count" fill="#5a7ee8" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <Table
        idKey="id"
        headers={USAGE_TABLE_HEADERS}
        data={usageRows}
        mapData={(entry) => {
          return [entry.day, entry.type, entry.count, entry.username];
        }}
      />
    </div>
  );
};

interface WorkspaceUsageViewProps {
  data: MonthlyWorkspaceResponseType | AllTimeWorkspaceResponseType | undefined;
  isLoading: boolean;
  isAllTime?: boolean;
}

const WorkspaceUsageView: React.FC<WorkspaceUsageViewProps> = ({ data, isLoading, isAllTime = false }) => {
  const workspaceUsage = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.workspaceUsage.map((item) => ({
      ...item,
      id: item.workspaceId,
    }));
  }, [data]);

  const totalWorkspaceUsage = useMemo(() => {
    return workspaceUsage.reduce((acc, entry) => acc + entry.count, 0);
  }, [workspaceUsage]);

  const workspaceUsageRows = useMemo(() => {
    if (workspaceUsage.length === 0) return [];

    const sortedWorkspaces = [...workspaceUsage].sort((a, b) => b.count - a.count);

    return [
      ...sortedWorkspaces.map((v) => {
        return {
          ...v,
          percentage: totalWorkspaceUsage > 0 ? `${((v.count / totalWorkspaceUsage) * 100).toFixed(1)}%` : '0%',
        };
      }),
      {
        id: 'total',
        workspaceId: '',
        workspaceName: 'Total Usage',
        count: totalWorkspaceUsage,
        percentage: '100%',
      },
    ];
  }, [workspaceUsage, totalWorkspaceUsage]);

  const pieChartData = useMemo(() => {
    if (workspaceUsage.length === 0) return [];

    const sortedWorkspaces = [...workspaceUsage].sort((a, b) => b.count - a.count);
    const colors = generateContrastingColors(sortedWorkspaces.length, {
      saturation: 30,
      lightness: 55,
    });

    if (sortedWorkspaces.length <= MAX_WORKSPACE_TO_DISPLAY) {
      return sortedWorkspaces.map((ws, index) => ({
        name: ws.workspaceName,
        value: ws.count,
        fill: colors[index],
      }));
    }

    const topWorkspaces = sortedWorkspaces.slice(0, MAX_WORKSPACE_TO_DISPLAY);
    const otherWorkspaces = sortedWorkspaces.slice(MAX_WORKSPACE_TO_DISPLAY);
    const otherCount = otherWorkspaces.reduce((sum, ws) => sum + ws.count, 0);

    return [
      ...topWorkspaces.map((ws, index) => ({
        name: ws.workspaceName,
        value: ws.count,
        fill: colors[index],
      })),
      {
        name: 'Others',
        value: otherCount,
        fill: '#d3d3d3',
      },
    ];
  }, [workspaceUsage]);

  if (isLoading) {
    return <SpinnerBlock message="Loading workspace usage..." />;
  }

  if (workspaceUsage.length === 0) {
    return <div className="p-4 text-center text-gray-500">No workspace usage data available for this period.</div>;
  }

  return (
    <div className="mt-4 mb-4">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <h4 className="text-lg font-medium mb-2">Distribution Chart</h4>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) =>
                  percent > 0.05
                    ? `${name.length > 15 ? name.substring(0, 15) + '...' : name} ${(percent * 100).toFixed(0)}%`
                    : null
                }
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value: number, name) => [
                  `${value} (${((value / totalWorkspaceUsage) * 100).toFixed(1)}%)`,
                  name,
                ]}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="w-full md:w-1/2">
          <h4 className="text-lg font-medium mb-2">Workspace Details</h4>
          <Table
            idKey="id"
            headers={WORKSPACE_USAGE_TABLE_HEADERS}
            data={workspaceUsageRows}
            mapData={(entry) => {
              return [entry.workspaceName, entry.count, entry.percentage];
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const UsagePage: React.FC = () => {
  const { team, refreshTeam } = useTeam();
  const [month, setMonth] = useState(dayjs());
  const [activeTab, setActiveTab] = useState<UsageTab>(UsageTab.DAILY);

  // Get date parameters for the current month
  const startDate = month.startOf('month').toISOString();
  const endDate = month.endOf('month').toISOString();

  const { data: dailyData, isLoading: isDailyLoading } = useSWR<DailyUsageResponseType>(
    `/api/v1/usage/daily?teamId=${team.id}&startDate=${startDate}&endDate=${endDate}`,
    fetchEndpointData,
  );

  const { data: monthlyWorkspaceData, isLoading: isMonthlyWorkspaceLoading } = useSWR<MonthlyWorkspaceResponseType>(
    `/api/v1/usage/monthly-workspace?teamId=${team.id}&startDate=${startDate}&endDate=${endDate}`,
    fetchEndpointData,
  );

  const { data: allTimeWorkspaceData, isLoading: isAllTimeWorkspaceLoading } = useSWR<AllTimeWorkspaceResponseType>(
    `/api/v1/usage/all-time-workspace?teamId=${team.id}`,
    fetchEndpointData,
  );

  useEffect(() => {
    refreshTeam();
  }, [refreshTeam]);

  const remainingCredits = team.remainingCredits ?? 0;

  const showDateNavigation = TABS.find((tab) => tab.id === activeTab)?.requiresDateNavigation ?? false;

  const tabClasses = 'py-2 px-4 font-medium';
  const activeTabClasses = 'text-blue-600 border-b-2 border-blue-600';
  const inactiveTabClasses = 'text-gray-500 hover:text-gray-700';

  return (
    <div className="page-content">
      <PageHeader title="Team Usage" />
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Team Usage',
            },
          ]}
        />
        <div className="h-10 min-w-[160px] flex items-center justify-end">
          {showDateNavigation ? (
            <div className="flex items-center gap-4">
              <Button
                onTrigger={() => {
                  setMonth((v) => v.subtract(1, 'month'));
                }}
              >
                <CaretLeftIcon />
              </Button>
              <div>{month.format('MMMM YYYY')}</div>
              <Button
                onTrigger={() => {
                  setMonth((v) => v.add(1, 'month'));
                }}
                isDisabled={month.startOf('month').isSame(dayjs().startOf('month'))}
              >
                <CaretRightIcon />
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="border-b border-gray-200 mb-4">
        <div className="flex space-x-4">
          {TABS.map((tab) => (
            <button
              key={tab.id}
              className={`${tabClasses} ${activeTab === tab.id ? activeTabClasses : inactiveTabClasses}`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      {activeTab === UsageTab.DAILY && <DailyUsageView month={month} data={dailyData} isLoading={isDailyLoading} />}
      {activeTab === UsageTab.MONTHLY && (
        <WorkspaceUsageView data={monthlyWorkspaceData} isLoading={isMonthlyWorkspaceLoading} />
      )}
      {activeTab === UsageTab.ALL_TIME && (
        <WorkspaceUsageView data={allTimeWorkspaceData} isLoading={isAllTimeWorkspaceLoading} isAllTime={true} />
      )}
      <div className="font-medium my-4">{`Remaining credits: ${remainingCredits}`}</div>
    </div>
  );
};
