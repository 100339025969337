import { createId } from '@paralleldrive/cuid2';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';

import { Throttle } from '@/utils/Throttle';
import { getClient } from '../../../contexts/websocket-context';
import { Emitter } from '../../../utils/emitter';
import { DisposableStore } from '../../../utils/disposable';
import type { ResponseType as UnreadNotificationsCountResponse } from '../endpoints/UnreadNotificationsCountEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';

export class NotificationState {
  private websocket = getClient();
  private subscriptionDisposable = new DisposableStore();

  private notificationEmitter = new Emitter<{
    id: string;
    title: string;
    description: string;
    url: string;
  }>();
  public onNotification = this.notificationEmitter.event;

  notificationsHash = Date.now().toString(16);
  private notificationsEmitter = new Emitter<string>();
  public onNotificationsChange = this.notificationsEmitter.event;

  notificationsCount = 0;
  private notificationsCountEmitter = new Emitter<number>();
  public onNotificationsCountChange = this.notificationsCountEmitter.event;

  private updateReadCount = new Throttle(() => {
    return this._updateReadCount();
  }, 500);

  constructor(public readonly userId: string) {
    this.websocket.onConnect(() => {
      this.subscribe().catch((err) => {
        captureException(err);
        toast.error(err.message);
      });
    });

    this.subscribe().catch((err) => {
      captureException(err);
      toast.error(err.message);
    });
  }

  private updateNotificationHash() {
    this.notificationsHash = Date.now().toString(16);
    this.notificationsEmitter.fire(this.notificationsHash);
  }

  private updateReconnectHash(): void {
    this.updateNotificationHash();
  }

  private cleanupSubscription() {
    this.subscriptionDisposable.dispose();
    this.subscriptionDisposable = new DisposableStore();
  }

  private async _updateReadCount(): Promise<void> {
    const result = await fetchEndpointData<UnreadNotificationsCountResponse>('/api/v1/notification/unread-count');
    this.notificationsCount = result.count;
    this.notificationsCountEmitter.fire(this.notificationsCount);
  }

  private setupSubscription(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const msgRef = createId();
      let isResolved = false;

      this.subscriptionDisposable.add(
        this.websocket.onMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved && message.method === 'notification/subscribe-ack') {
              resolve();
              this.updateReconnectHash();
              isResolved = true;
            }

            switch (message.method) {
              case 'notification/new': {
                this.notificationsCount = this.notificationsCount + 1;
                this.notificationsCountEmitter.fire(this.notificationsCount);
                this.updateReadCount.execute();
                this.updateNotificationHash();
                this.notificationEmitter.fire({
                  id: message.data.notification.id,
                  title: message.data.notification.title,
                  description: message.data.notification.description,
                  url: message.data.notification.url,
                });
                break;
              }
              case 'notification/delete': {
                this.updateReadCount.execute();
                this.updateNotificationHash();
                break;
              }
              case 'notification/read': {
                this.updateReadCount.execute();
                this.updateNotificationHash();
                break;
              }
              case 'notification/all-read': {
                this.updateReadCount.execute();
                this.updateNotificationHash();
                break;
              }
            }
          }
        }),
      );
      this.subscriptionDisposable.add(
        this.websocket.onErrorMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved) {
              reject(new Error(message.error.message));
              isResolved = true;
            }
            this.cleanupSubscription();
          }
        }),
      );
      this.websocket.send({
        ref: msgRef,
        method: 'notification/subscribe',
        data: {},
      });
    });
  }

  private async subscribe(): Promise<void> {
    this.updateReadCount.execute();
    this.cleanupSubscription();
    await this.setupSubscription();
  }
}

let _notificationState: NotificationState | null = null;
export function getNotificationState(userId: string): NotificationState {
  if (!_notificationState || _notificationState.userId !== userId) {
    _notificationState = new NotificationState(userId);
  }
  return _notificationState;
}
