import React from 'react';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { InternalCategorySelectField } from '../components/InternalCategorySelect';

interface ICategoryDeleteDialogValues {
  newCategoryId: number | null;
}

interface ICategoryDeleteDialogProps {
  isOpen?: boolean;
  setIsOpen?: (newIsOpen: boolean) => void;
  onSubmit: (value: ICategoryDeleteDialogValues) => Promise<void>;
  excludedCategoryIds?: number[];
  categoryName: string;
  initialValues?: {
    newCategoryId: number | null;
  };
}

export const CategoryDeleteDialog: React.FC<ICategoryDeleteDialogProps> = (props) => {
  const { isOpen, categoryName, setIsOpen, excludedCategoryIds, initialValues, onSubmit } = props;

  return (
    <FormDialog
      open={isOpen}
      setOpen={setIsOpen}
      showTrigger={false}
      triggerText=""
      title={`Delete category ${categoryName}`}
      submitText="Delete"
      onSubmit={onSubmit}
      initialValues={
        initialValues ?? {
          newCategoryId: null,
        }
      }
    >
      <InternalCategorySelectField
        labelText="Replace category with"
        name="newCategoryId"
        excludedCategoryIds={excludedCategoryIds}
      />
    </FormDialog>
  );
};
