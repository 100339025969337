import React, { useId } from 'react';

import classNames from '@/utils/classnames';
import { Tooltip } from './tooltip/Tooltip';

export interface IMultiToggleOption {
  value: string;
  icon: React.ReactNode;
  title: string;
  isDisabled?: boolean;
}

export interface IMultiToggleProps {
  options: IMultiToggleOption[];
  value: string;
  onChange: (value: string) => void;
}

export const MultiToggle: React.FC<IMultiToggleProps> = (props) => {
  const { options, value, onChange } = props;
  const id = useId();

  return (
    <div className="flex gap-1 bg-gray-200 p-1 rounded">
      {options.map((v) => {
        return (
          <div key={`multi-toggle-${id}-${v.value}`}>
            <Tooltip text={v.title}>
              <div
                className={classNames('p-2 rounded', {
                  'bg-white': value === v.value,
                  'hover:bg-gray-100': !v.isDisabled && value !== v.value,
                  'cursor-pointer': !v.isDisabled,
                })}
                onClick={() => {
                  if (v.isDisabled) {
                    return;
                  }

                  onChange(v.value);
                }}
              >
                {v.icon}
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};
