import { useReducer } from 'react';

import { ICategoryNode } from './useCategories';

export interface ICategoryTreeState {
  categoryTree: ICategoryNode[];
  openCategories: Set<string>;
}

export type CategoryTreeAction =
  | {
      type: 'open';
      categoryId: string;
    }
  | {
      type: 'set_category_tree';
      categoryTree: ICategoryNode[];
    }
  | {
      type: 'close';
      categoryId: string;
    }
  | {
      type: 'toggle';
      categoryId: string;
    }
  | {
      type: 'toggle_all';
    };

function categoryTreeReducer(prevState: ICategoryTreeState, action: CategoryTreeAction): ICategoryTreeState {
  switch (action.type) {
    case 'open': {
      const openCategories = new Set(prevState.openCategories);
      openCategories.add(action.categoryId);
      return {
        ...prevState,
        openCategories,
      };
    }
    case 'close': {
      const openCategories = new Set(prevState.openCategories);
      // eslint-disable-next-line drizzle/enforce-delete-with-where
      openCategories.delete(action.categoryId);
      return {
        ...prevState,
        openCategories,
      };
    }
    case 'toggle': {
      const openCategories = new Set(prevState.openCategories);
      if (openCategories.has(action.categoryId)) {
        // eslint-disable-next-line drizzle/enforce-delete-with-where
        openCategories.delete(action.categoryId);
      } else {
        openCategories.add(action.categoryId);
      }
      return {
        ...prevState,
        openCategories,
      };
    }
    case 'toggle_all':
      if (prevState.openCategories.size === 0) {
        const allCategoryIds = new Set<string>();
        const traverse = (category: ICategoryNode) => {
          if (allCategoryIds.has(category.id)) {
            return;
          }

          allCategoryIds.add(category.id);
          category.children.forEach(traverse);
        };
        prevState.categoryTree.forEach(traverse);

        return {
          ...prevState,
          openCategories: allCategoryIds,
        };
      } else {
        return {
          ...prevState,
          openCategories: new Set(),
        };
      }
    case 'set_category_tree':
      return {
        ...prevState,
        categoryTree: action.categoryTree,
      };
    default:
      return prevState;
  }
}

export function useCategoryTreeReducer(categoryTree: ICategoryNode[]) {
  return useReducer(categoryTreeReducer, {
    categoryTree,
    openCategories: new Set<string>(),
  });
}
