import {
  Gear as SettingsIcon,
  File as FileIcon,
  Scan as ScanTextIcon,
  Chat as MessageSquareIcon,
  List as LogsIcon,
  Sliders as SlidersHorizontalIcon,
  Tag as TagIcon,
  Graph as GraphIcon,
  Play as PlayIcon,
  SquaresFour as SquaresFourIcon,
  Warning as WarningIcon,
  ArrowLeft as ArrowLeftIcon,
} from '@phosphor-icons/react';
import { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../contexts/auth-context';
import { useTeam } from '@/app/team/context/TeamContext';
import { TeamDashboardTemplate } from './TeamDashboardTemplate';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { BaseButton } from '../button/BaseButton';
import { useNavigate } from 'react-router-dom';
import { TeamPermissionEnum, WorkspacePermissionEnum } from '@/app/auth/enum';
import { nullthrows } from '@/utils/invariant';
import { Tooltip } from '../tooltip/Tooltip';
import { Spinner, SpinnerBlock } from '../Spinner';
import { TeamAddons } from '@/app/team/enums';
import { WorkspaceType } from '@/app/workspace/enums';
import { LinkButton } from '../button/Button';
import { useWorkspaceCategories } from '@/app/workspaceCategory/contexts/WorkspaceCategoriesContext';
import { Delay } from '../Delay';
import { type SidebarNavButtonProps } from '../button/SidebarNavButton';

export interface IDashboardProps {
  children: React.ReactNode;
}

export const WorkspaceDashboard: React.FC<IDashboardProps> = (props) => {
  const { children } = props;
  const { me, myTeams } = useAuth();
  const { team: selectedTeam } = useTeam();
  const { workspace, isSyncingTree, tree, treeKey } = useWorkspace();
  const { isLoading: isLoadingCategories } = useWorkspaceCategories();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const currentTeam = nullthrows(
    myTeams.find((t) => t.team.id === selectedTeam?.id),
    'Team not found',
  );

  const sidebarTopButtons = useMemo(() => {
    const buttons: SidebarNavButtonProps[] = [];

    if (workspace.type === WorkspaceType.DueDiligence || workspace.type === WorkspaceType.Playground) {
      buttons.push({
        icon: <PlayIcon className="button-icon" />,
        title: 'Start analysis',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/analysis/start`,
      });
    }

    buttons.push({
      icon: <FileIcon className="button-icon" />,
      title: 'Documents',
      to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/documents`,
    });

    if (workspace.type === WorkspaceType.DueDiligence && selectedTeam.enabledAddons.includes(TeamAddons.RiskAnalysis)) {
      buttons.push({
        icon: <WarningIcon className="button-icon" />,
        title: 'Risks',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/risks`,
      });
    }

    if (workspace.type === WorkspaceType.DueDiligence || workspace.type === WorkspaceType.Playground) {
      buttons.push({
        icon: <ScanTextIcon className="button-icon" />,
        title: 'Analysis results',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/analysis`,
      });
    }

    if (workspace.permissions.includes(WorkspacePermissionEnum.Chat)) {
      buttons.push({
        icon: <MessageSquareIcon className="button-icon" />,
        title: 'Chat',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/chat`,
      });
    }

    return buttons;
  }, [workspace, me.isSuperUser]);

  const sidebarBottomButtons = useMemo(() => {
    const buttons: SidebarNavButtonProps[] = [
      {
        icon: <GraphIcon className="button-icon" />,
        title: 'Insights',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/insights`,
      },
    ];

    if (
      (workspace.type === WorkspaceType.DueDiligence || workspace.type === WorkspaceType.Playground) &&
      currentTeam.permissions.includes(TeamPermissionEnum.ManagePresets)
    ) {
      buttons.push({
        icon: <SlidersHorizontalIcon className="button-icon" />,
        title: 'Presets',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/questions-preset`,
      });
    }

    if (workspace.permissions.includes(WorkspacePermissionEnum.ManageCategories)) {
      buttons.push({
        icon: <TagIcon className="button-icon" />,
        title: 'Categories',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/categories`,
      });
    }

    if (workspace.permissions.includes(WorkspacePermissionEnum.ReadAuditLogs)) {
      buttons.push({
        icon: <LogsIcon className="button-icon" />,
        title: 'Audit Logs',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/audit-logs`,
      });
    }

    if (workspace.permissions.includes(WorkspacePermissionEnum.ManageMembers)) {
      buttons.push({
        icon: <SettingsIcon className="button-icon" />,
        title: 'Settings',
        to: `/app/t/${selectedTeam.id}/workspace/${workspace.id}/settings`,
      });
    }

    return buttons;
  }, [workspace, me.isSuperUser]);

  useEffect(() => {
    if (!isSyncingTree && !isLoadingCategories) {
      setIsLoading(false);
    }
  }, [isSyncingTree, isLoadingCategories]);

  if (isLoading) {
    return (
      <Delay
        ms={250}
        after={
          <div className="p-4 h-screen flex flex-col">
            <div className="flex">
              <LinkButton to=".." iconLeft={<ArrowLeftIcon className="button-icon" />} variant="ghost">
                Back to workspaces
              </LinkButton>
            </div>

            <div className="flex-1">
              <SpinnerBlock message={isLoadingCategories ? 'Loading categories...' : 'Loading file tree...'} />
            </div>
          </div>
        }
      />
    );
  }

  return (
    <>
      <TeamDashboardTemplate
        breadcrumbButtons={[
          <BaseButton variant="ghost" onClick={() => navigate(`/app/t/${selectedTeam.id}/workspace/${workspace.id}`)}>
            <div className="flex gap-2 items-center">
              <div>
                <SquaresFourIcon className="w-4 h-4" />
              </div>
              <div>{`Workspace: ${workspace.name}`}</div>
            </div>
          </BaseButton>,
        ]}
        sidebarTopButtons={sidebarTopButtons}
        sidebarBottomButtons={sidebarBottomButtons}
        statusIndicatorOverwrite={
          isSyncingTree ? (
            <Tooltip text={`Syncing workspace...`}>
              <div>
                <Spinner size={4} />
              </div>
            </Tooltip>
          ) : undefined
        }
        notificationsLink={`/app/t/${selectedTeam.id}/workspace/${workspace.id}/notifications`}
      >
        {children}
      </TeamDashboardTemplate>
    </>
  );
};
