import { DOCX_STYLES } from '@/utils/docx/write-docx';
import dayjs from 'dayjs';
import * as docx from 'docx';

import { GroupedAnswers } from './group-answers';

export function downloadDocAnswersAsDocx(groupedAnswers: GroupedAnswers, documentIds: string[]) {
  const children: any[] = [];

  children.push(
    new docx.Paragraph({
      heading: docx.HeadingLevel.HEADING_1,
      text: 'Run Export - ' + dayjs().format('DD-MM-YYYY HH:mm'),
    }),
    new docx.Paragraph({
      text: '',
    }),
  );

  const documents = [...groupedAnswers.documents.values()].filter((doc) => documentIds.includes(doc.id));

  for (const doc of documents) {
    const _answers = groupedAnswers.groupedPerDocument.get(doc.id);
    if (!_answers) {
      continue;
    }

    const answers = _answers.sort((a, b) => a.idx - b.idx);
    const table = new docx.Table({
      rows: answers.map((answer) => {
        const question = groupedAnswers.questions.get(answer.questionId)?.name ?? '-';

        return new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  children: [
                    new docx.TextRun({
                      text: question,
                      bold: true,
                    }),
                  ],
                }),
              ],
              width: { size: 35, type: docx.WidthType.PERCENTAGE },
              margins: {
                top: 64,
                bottom: 64,
                left: 64,
                right: 64,
              },
            }),
            new docx.TableCell({
              children: answer.minified.split('\n').map((v) => {
                return new docx.Paragraph({ text: v });
              }),
              width: { size: 65, type: docx.WidthType.PERCENTAGE },
              margins: {
                top: 64,
                bottom: 64,
                left: 64,
                right: 64,
              },
            }),
          ],
        });
      }),
      width: { size: 100, type: docx.WidthType.PERCENTAGE },
      columnWidths: [35, 65],
      layout: docx.TableLayoutType.FIXED,
    });

    children.push(
      new docx.Paragraph({
        heading: docx.HeadingLevel.HEADING_2,
        text: doc.name,
      }),
      table,
      new docx.Paragraph({
        text: '',
      }),
    );
  }

  const doc = new docx.Document({
    creator: 'Jurimesh',
    description: 'Analysis export',
    styles: DOCX_STYLES,
    sections: [
      {
        children,
      },
    ],
  });

  docx.Packer.toBlob(doc).then((blob) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `doc-analysis-results-${dayjs().format('DD-MM-YYYY-HH-mm')}.docx`;
    link.click();
  });
}
