import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { X } from '@phosphor-icons/react';

import { Button } from '@/components/button/Button';
import { WorkspacePermissionsSelector } from '@/app/workspaceUser/components/WorkspacePermissionsSelector';
import { TeamUserSelectField } from '@/app/teamUser/components/TeamUserSelector';
import { CheckboxField } from '@/components/checkbox/CheckboxField';
import { WorkspaceUser } from '../create/UsersPage';
import { WorkspacePermissionEnum } from '@/app/auth/enum';

interface TeamUser {
  id: string;
  name: string;
}

export interface UserFormValues {
  assignedUser: TeamUser | null;
  permissions: string[];
  isOwner: boolean;
}

interface UserFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: UserFormValues) => void;
  initialValues?: UserFormValues | null;
  mode?: 'add' | 'edit';
  existingUsers?: WorkspaceUser[];
}

interface ValidationErrors {
  assignedUser?: string;
  [key: string]: string | undefined;
}

const addTeamUserSchema = Yup.object().shape({
  assignedUser: Yup.object()
    .shape({
      id: Yup.string().required('User is required'),
      name: Yup.string().required(),
    })
    .nullable()
    .required('User is required'),
  permissions: Yup.array(Yup.string()).required('Required'),
  isOwner: Yup.boolean().required('Required'),
});

export const UserFormModal: React.FC<UserFormModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialValues = null,
  mode = 'add',
  existingUsers = [],
}) => {
  if (!isOpen) return null;

  const defaultValues: UserFormValues = {
    assignedUser: null,
    permissions: [],
    isOwner: false,
  };

  const formInitialValues = initialValues || defaultValues;

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const validateForm = (values: UserFormValues): ValidationErrors => {
    const errors: ValidationErrors = {};

    if (mode === 'add' && values.assignedUser) {
      const isDuplicate = existingUsers.some((user) => user.id === values.assignedUser?.id);
      if (isDuplicate) {
        errors.assignedUser = 'This user is already added to the workspace';
      }
    }

    return errors;
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]"
      onClick={handleBackdropClick}
      style={{
        backdropFilter: 'blur(2px)',
        isolation: 'isolate',
      }}
    >
      <div
        className="bg-white rounded-lg shadow-xl w-full max-w-xl p-6 relative"
        style={{
          transform: 'translateZ(0)',
          boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.2)',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" type="button">
          <X className="w-5 h-5" />
        </button>

        <h2 className="text-xl font-semibold mb-4">{mode === 'add' ? 'Add User' : 'Edit User'}</h2>

        <Formik
          initialValues={formInitialValues}
          validationSchema={addTeamUserSchema}
          validate={validateForm}
          onSubmit={(values: UserFormValues, { resetForm }: FormikHelpers<UserFormValues>) => {
            onSubmit(values);
            resetForm();
            onClose();
          }}
          enableReinitialize
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              <TeamUserSelectField name="assignedUser" labelText="User" />

              {/* Display the duplicate user error */}
              {errors.assignedUser && touched.assignedUser && (
                <div className="text-red-500 text-sm mt-1 mb-2">{errors.assignedUser}</div>
              )}

              <div className="heading-two mb-2">Permissions</div>
              <div className="mb-4">
                <WorkspacePermissionsSelector
                  value={values.permissions as WorkspacePermissionEnum[]}
                  onChange={(v: string[]) => setFieldValue('permissions', v)}
                />
              </div>

              <CheckboxField labelText="Is Owner" name="isOwner" />

              <div className="flex justify-end gap-2 mt-6">
                <Button type="button" variant="default" onTrigger={onClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  {mode === 'add' ? 'Add User' : 'Save Changes'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
