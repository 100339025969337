import useSWR from 'swr';
import { Link } from 'react-router-dom';

import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as RiskfulClausesResponseType } from '../endpoints/ListRiskfulClausesEndpoint';
import { Spinner } from '@/components/Spinner';

export const BackofficeRiskfulClausesPage = () => {
  const { data, isLoading, error } = useSWR<RiskfulClausesResponseType>(
    '/api/v1/internal/risks/riskful-clauses/list',
    fetchEndpointData,
    {
      shouldRetryOnError: false,
      errorRetryCount: 0,
    },
  );

  const documentTypes = data?.docs ?? [];
  return (
    <div className="page-content">
      <PageHeader title="INTERNAL Risks" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Risks',
              to: '..',
            },
            {
              name: 'Riskful clauses',
            },
          ]}
        />
      </div>

      {error && <div>Error: {error.message}</div>}

      {isLoading && <Spinner size={8} />}

      <div className="grid gap-1">
        {documentTypes.map((v) => {
          return (
            <Link
              to={v.documentCategory}
              className="card-no-padding px-2 py-1 w-full"
              key={v.documentCategory}
              data-clickable="true"
            >
              {v.documentCategory}
            </Link>
          );
        })}
      </div>
    </div>
  );
};
