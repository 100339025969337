/**
 * Utility functions for color generation and manipulation
 */

/**
 * Configuration options for color generation
 */
export interface ColorGenerationOptions {
  /** Color saturation (0-100), default 65 */
  saturation?: number;
  /** Color lightness (0-100), default 55 */
  lightness?: number;
  /** Starting hue value (0-360), default 0 */
  startHue?: number;
}

/**
 * Generates an array of visually distinct colors
 * Uses an HSL color model to ensure good contrast between colors
 *
 * @param {number} count - Number of colors to generate
 * @param {ColorGenerationOptions} options - Configuration options
 * @returns {string[]} Array of hex color codes
 */
export const generateContrastingColors = (count: number, options: ColorGenerationOptions = {}): string[] => {
  const { saturation = 65, lightness = 55, startHue = 0 } = options;

  const colors: string[] = [];
  const goldenRatioConjugate = 0.618033988749895; // Golden ratio conjugate for even color distribution

  let hue = startHue;

  while (colors.length < count) {
    // Use golden ratio conjugate to get well-distributed hues
    hue = (hue + goldenRatioConjugate) % 1;

    const h = Math.floor(hue * 360);
    const s = saturation;
    const l = lightness;

    colors.push(hslToHex(h, s, l));
  }

  return colors;
};

/**
 * Converts HSL color values to hex color code
 *
 * @param {number} h - Hue (0-360)
 * @param {number} s - Saturation (0-100)
 * @param {number} l - Lightness (0-100)
 * @returns {string} Hex color code
 */
export const hslToHex = (h: number, s: number, l: number): string => {
  h /= 360;
  s /= 100;
  l /= 100;

  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number): number => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  const toHex = (x: number): string => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};
