import { createId } from '@paralleldrive/cuid2';
import { createContext, useContext, useState } from 'react';

// Create a context for category template list updates
interface CategoryTemplateListContextValue {
  refreshToken: string;
  refreshCategoryTemplates: () => void;
}

const CategoryTemplateListContext = createContext<CategoryTemplateListContextValue>({
  refreshToken: '',
  refreshCategoryTemplates: () => {},
});

/**
 * Provider component that manages category template list refresh state
 */
export const CategoryTemplateListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [refreshToken, setRefreshToken] = useState(() => createId());

  const refreshCategoryTemplates = () => {
    setRefreshToken(createId());
  };

  return (
    <CategoryTemplateListContext.Provider value={{ refreshToken, refreshCategoryTemplates }}>
      {children}
    </CategoryTemplateListContext.Provider>
  );
};

/**
 * Hook to access category template list refresh functionality
 */
export const useCategoryTemplateList = () => useContext(CategoryTemplateListContext);
