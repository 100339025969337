import React from 'react';
import {
  Books as BooksIcon,
  FileMagnifyingGlass as FileMagnifyingGlassIcon,
  Asterisk as AsteriskIcon,
} from '@phosphor-icons/react';

import { CardButton } from '@/components/card/CardOld';
import { WorkspaceType } from '../../enums';

export interface IWorkspaceTypeSelectionPageProps {
  onSelect: (newType: WorkspaceType) => void;
}

export const WorkspaceTypeSelectionPage: React.FC<IWorkspaceTypeSelectionPageProps> = (props) => {
  const { onSelect } = props;

  return (
    <div className="grid grid-cols-3 gap-4">
      <CardButton
        title="Due diligence"
        icon={<FileMagnifyingGlassIcon className="w-12 h-12" />}
        centered={true}
        onClick={() => onSelect(WorkspaceType.DueDiligence)}
        content={
          <div className="max-w-md">
            Complete legal due diligence environment for analyzing entire data rooms. Upload, organize, and analyze all
            documents in a data room to identify red flags and generate comprehensive client reports.
          </div>
        }
      />
      <CardButton
        title="Playground"
        icon={<AsteriskIcon className="w-12 h-12" />}
        centered={true}
        onClick={() => onSelect(WorkspaceType.Playground)}
        content={
          <div className="max-w-md">
            Flexible analysis tool for various document needs. Use for client file analysis, individual contract review,
            or any scenario where you need to examine specific documents rather than entire data rooms.
          </div>
        }
      />
      <CardButton
        title="Knowledge"
        icon={<BooksIcon className="w-12 h-12" />}
        centered={true}
        onClick={() => onSelect(WorkspaceType.Knowledge)}
        content={
          <div className="max-w-md">
            Custom legal assistant creator. Upload legislation, case law, and legal doctrine to build a specialized AI
            assistant that provides instant answers to your practice-specific legal questions.
          </div>
        }
      />
    </div>
  );
};
