import React, { useEffect } from 'react';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import { MagnifyingGlassMinus, MagnifyingGlassPlus } from '@phosphor-icons/react';
import {
  Root,
  Pages,
  Page,
  CanvasLayer,
  TextLayer,
  ZoomOut,
  CurrentZoom,
  ZoomIn,
  CurrentPage,
  TotalPages,
  HighlightLayer,
  usePdfJump,
} from '@unriddle-ai/lector';

import { Spinner } from './Spinner';

// Set up the worker
GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.mjs',
  // @ts-ignore
  import.meta.url,
).toString();

interface IPDFPreviewImplProps {
  title: string;
  page?: number | null;
  highlights?: IHighlight[];
}

const PDFPreviewImpl: React.FC<IPDFPreviewImplProps> = (props) => {
  const { title, page, highlights } = props;
  const { jumpToHighlightRects } = usePdfJump();

  useEffect(() => {
    if (highlights && highlights.length > 0) {
      jumpToHighlightRects(
        highlights.map((v) => {
          return {
            ...v,
            type: 'percent',
          };
        }),
        'percent',
        'start',
      );
    }
  }, [highlights, jumpToHighlightRects]);

  return (
    <>
      <div className="bg-gray-200 px-1 flex justify-between items-center">
        <div className="flex-1">
          <div className="flex items-center gap-2 p-2">
            <CurrentPage className="rounded px-2 py-1 w-12" /> of <TotalPages />
          </div>
        </div>

        <div className="flex-2">{title}</div>

        <div className="flex gap-1 p-1 flex-1 justify-end">
          <ZoomOut className="hover:bg-gray-100 rounded p-1">
            <MagnifyingGlassMinus className="h-6 w-6" />
          </ZoomOut>
          <CurrentZoom className="rounded px-2 py-1 w-16" />
          <ZoomIn className="hover:bg-gray-100 rounded p-1">
            <MagnifyingGlassPlus className="h-6 w-6" />
          </ZoomIn>
        </div>
      </div>

      <Pages className="p-4">
        <Page pageNumber={page ?? undefined}>
          <CanvasLayer />
          <TextLayer />
          <HighlightLayer className="bg-yellow-200/40" />
        </Page>
      </Pages>
    </>
  );
};

export interface IHighlight {
  pageNumber: number;
  /** Top in percentage */
  top: number;
  /** Left in percentage */
  left: number;
  /** Height in percentage */
  height: number;
  /** Width in percentage */
  width: number;
}

export interface IPdfPreviewProps {
  fileUrl: string;
  title: string;
  page?: number | null;
  highlights?: IHighlight[];
  customHeight?: string;
}

export const PdfPreview: React.FC<IPdfPreviewProps> = (props) => {
  const { title, fileUrl, page, highlights, customHeight } = props;

  return (
    <Root
      source={fileUrl}
      className="w-full border border-gray-300 rounded overflow-hidden"
      style={{
        height: customHeight ?? '100vh',
      }}
      loader={
        <div className="w-full h-full flex items-center justify-center">
          <Spinner size={12} />
        </div>
      }
    >
      <PDFPreviewImpl title={title} page={page} highlights={highlights} />
    </Root>
  );
};
