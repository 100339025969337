import { createId } from '@paralleldrive/cuid2';
import toast from 'react-hot-toast';

import { getClient } from '@/contexts/websocket-context';
import { Emitter } from '@/utils/emitter';
import { DisposableStore } from '@/utils/disposable';
import { captureException } from '@sentry/react';

export class PresetRunState {
  private websocket = getClient();
  public isFetching: boolean = false;

  private subscribeDisposable = new DisposableStore();

  public updatedHash = Date.now().toString(16);
  private updateEmitter = new Emitter<string>();
  public onUpdate = this.updateEmitter.event;

  constructor(
    public presetRunId: string,
    public teamId: string,
  ) {
    this.websocket.onConnect(() => {
      this.subscribe().catch((err) => {
        captureException(err);
        toast.error(err.message);
      });
      this.updateHash();
    });

    this.subscribe().catch((err) => {
      captureException(err);
      toast.error(err.message);
    });
    this.updateHash();
  }

  private updateHash(): void {
    this.updatedHash = Date.now().toString(16);
    this.updateEmitter.fire(this.updatedHash);
  }

  private cleanupSubscription() {
    this.subscribeDisposable.dispose();
    this.subscribeDisposable = new DisposableStore();
  }

  private subscribe(): Promise<void> {
    this.cleanupSubscription();

    return new Promise<void>((resolve, reject) => {
      const msgRef = createId();
      let isResolved = false;

      this.subscribeDisposable.add(
        this.websocket.onMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved && message.method === 'workspace-preset-run/subscribe-ack') {
              resolve();
              this.updateHash();
              isResolved = true;
            }

            switch (message.method) {
              case 'workspace-preset-run/document-answer-generated': {
                console.log(message);
                this.updateHash();
                break;
              }
              case 'workspace-preset-run/general-answer-generated': {
                console.log(message);
                this.updateHash();
                break;
              }
              case 'workspace-preset-run/status-update': {
                console.log(message);
                this.updateHash();
                break;
              }
            }
          }
        }),
      );
      this.subscribeDisposable.add(
        this.websocket.onErrorMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved) {
              reject(new Error(message.error.message));
              isResolved = true;
            }
            this.cleanupSubscription();
          }
        }),
      );
      this.websocket.send({
        ref: msgRef,
        method: 'workspace-preset-run/subscribe',
        data: {
          presetRunId: this.presetRunId,
        },
      });
    });
  }
}

export class PresetRunStatesStore {
  presetRuns = new Map<string, PresetRunState>();

  getState(presetRunId: string, teamId: string): PresetRunState {
    let presetRun = this.presetRuns.get(presetRunId);
    if (!presetRun) {
      presetRun = new PresetRunState(presetRunId, teamId);
      this.presetRuns.set(presetRunId, presetRun);
    }
    return presetRun;
  }
}

let _presetRunStates: PresetRunStatesStore | null = null;
export function getPresetRunStates(): PresetRunStatesStore {
  if (!_presetRunStates) {
    _presetRunStates = new PresetRunStatesStore();
  }
  return _presetRunStates;
}
