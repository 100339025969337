import * as React from 'react';
import classNames from '@/utils/classnames';

export interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'default' | 'inverted';
  size?: 8 | 10;
}

const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => {
  const { className, variant = 'default', size = 10, ...otherProps } = props;

  return (
    <input
      className={classNames(
        'flex w-full rounded-md text-sm border-neutral-300 border focus:outline-none disabled:cursor-not-allowed disabled:opacity-500 focus:border-neutral-400 bg-white text-neutral-800',
        className,
        {
          'h-10 py-2 px-3': size === 10,
          'h-8 py-1 px-2': size === 8,
        },
      )}
      ref={ref}
      {...otherProps}
    />
  );
});
BaseInput.displayName = 'BaseInput';

export { BaseInput };
