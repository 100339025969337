import { WorkspacePresetRun } from '../../types';

export interface IDocumentData {
  id: string;
  name: string;
  folderId: string | null;
}

export interface IQuestionData {
  id: string;
  idx: number;
  name: string;
  question: string;
  category: string | null;
}

export interface IAnswerData {
  idx: number;
  questionId: string;
  documentId: string;
  documentAnswerId: string;
  isRelevant: boolean;
  isLoading: boolean;
  full: string;
  minified: string;
  minifiedTranslated: string;
  jobIds: string[];
}

export function groupAnswers(presetRun: WorkspacePresetRun) {
  const documents = new Map<string, IDocumentData>();
  const questions = new Map<string, IQuestionData>();

  const groupedPerDocument = new Map<string, IAnswerData[]>();
  const groupedPerQuestion = new Map<string, IAnswerData[]>();
  const groupedPerAnswerId = new Map<string, IAnswerData>();

  for (const question of presetRun.questions) {
    for (const docAnswer of question.documentAnswers) {
      documents.set(docAnswer.document.id, {
        id: docAnswer.document.id,
        name: docAnswer.document.name,
        folderId: docAnswer.document.folderId ?? null,
      });

      questions.set(question.id, {
        id: question.id,
        idx: question.idx,
        name: question.name,
        question: question.question,
        category: question.category,
      });

      const docAnswers = groupedPerDocument.get(docAnswer.document.id) ?? [];
      const questionAnswers = groupedPerQuestion.get(question.id) ?? [];

      const isLoading = docAnswer.isRelevant === null;

      let answer = '';
      if (docAnswer.isRelevant === true) {
        answer = docAnswer.answer ?? '';
      } else if (docAnswer.isRelevant === false) {
        answer = '-';
      }

      const answerEntry = {
        idx: question.idx,
        questionId: question.id,
        documentId: docAnswer.document.id,
        documentAnswerId: docAnswer.id,
        isRelevant: docAnswer.isRelevant ?? false,
        isLoading: isLoading,
        full: answer,
        minified: docAnswer.minifiedAnswer || '-',
        minifiedTranslated: (docAnswer.translatedMinifiedAnswer ?? docAnswer.minifiedAnswer) || '-',
        jobIds: docAnswer.jobIds,
      };

      docAnswers.push(answerEntry);
      questionAnswers.push(answerEntry);

      groupedPerDocument.set(docAnswer.document.id, docAnswers);
      groupedPerQuestion.set(question.id, questionAnswers);
      groupedPerAnswerId.set(docAnswer.id, answerEntry);
    }
  }

  return {
    documents,
    questions,
    groupedPerDocument,
    groupedPerQuestion,
    groupedPerAnswerId,
  };
}

export type GroupedAnswers = ReturnType<typeof groupAnswers>;
