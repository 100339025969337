import { useEffect } from 'react';
import { KeyBindingMap, KeyBindingOptions, tinykeys } from 'tinykeys';

const isEditableElement = (evt: KeyboardEvent) => {
  const activeElement = document.activeElement;

  if (!activeElement) {
    return false;
  }

  // Check if active element or any of it's parent elements is editable
  let element: HTMLElement | null = activeElement as HTMLElement;
  while (element) {
    if (
      element.isContentEditable ||
      element.tagName === 'INPUT' ||
      element.tagName === 'TEXTAREA' ||
      element.tagName === 'SELECT'
    ) {
      return true;
    }

    element = element.parentElement;
  }

  return false;
};

export function useKeybindings(map: KeyBindingMap, options?: KeyBindingOptions) {
  useEffect(() => {
    const newMap: KeyBindingMap = {};
    for (const key in map) {
      newMap[key] = (evt) => {
        if (isEditableElement(evt)) {
          return;
        }

        map[key]!(evt);
      };
    }

    let unsubscribe = tinykeys(window, newMap, options);

    return () => {
      unsubscribe();
    };
  });
}
