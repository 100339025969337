import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { BodyType as UpdateWorkspacePayload } from '@/app/workspace/endpoints/UpdateWorkspaceEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { useTeam } from '@/app/team/context/TeamContext';
import { getDisplayError } from '@/utils/get-display-error';
import { WorkspacePermissionWrapper } from '@/app/workspace/components/WorkspacePermissionWrapper';
import { PageHeader } from '@/components/PageHeader';
import { InputField } from '@/components/input/InputField';
import { LanguageSelectField } from '@/components/LanguageSelect';
import { Button } from '@/components/button/Button';
import { ArrowLeft as ArrowLeftIcon } from '@phosphor-icons/react';
import { LanguageEnum } from '@/app/workspaceDocument/enums';

// Validation schema for both regular and due diligence workspaces
const workspaceDetailsSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Workspace name is required').max(200, 'Name cannot exceed 200 characters'),
  language: Yup.string().oneOf(Object.values(LanguageEnum)).required('Language is required'),
  // Due diligence specific fields
  targetParty: Yup.string().max(200, 'Target party cannot exceed 200 characters').optional(),
  companyNumber: Yup.string().max(200, 'Company number cannot exceed 200 characters').optional(),
  industry: Yup.string().max(200, 'Industry cannot exceed 200 characters').optional(),
  coreActivities: Yup.string().max(5000, 'Core activities cannot exceed 5000 characters').optional(),
  transactionStructure: Yup.string().max(200, 'Transaction structure cannot exceed 200 characters').optional(),
  dealValue: Yup.number().max(1_000_000_000_000, 'Deal value is too large').nullable().optional(),
  criticalElements: Yup.string().max(5000, 'Critical elements cannot exceed 5000 characters').optional(),
  knownIssues: Yup.string().max(5000, 'Known issues cannot exceed 5000 characters').optional(),
});

// Form values interface matching the validation schema and API payload
interface FormValues {
  name: string;
  language: LanguageEnum;
  targetParty: string;
  companyNumber: string;
  industry: string;
  coreActivities: string;
  transactionStructure: string;
  dealValue: number | null;
  criticalElements: string;
  knownIssues: string;
}

// Additional form fields specific to due diligence workspaces
const DueDiligenceFormFields = () => (
  <>
    <div className="flex gap-1 items-center heading-three mb-2">
      <span>Seller information</span>
    </div>
    <div className="grid grid-cols-2 gap-4">
      <InputField type="text" name="targetParty" labelText="Official Name" placeholder="Acme NV" />
      <InputField
        type="text"
        name="industry"
        labelText="Industry"
        placeholder="Software"
        helperText="Main industry the seller is active in."
      />
    </div>
    <InputField type="text" name="companyNumber" labelText="Company number" placeholder="0000.000.000" />
    <InputField
      type="text"
      name="coreActivities"
      labelText="Core activities"
      helperText="Short description of the seller's professional activities."
    />

    <div className="flex gap-1 items-center heading-three mb-2">
      <span>Transaction information</span>
    </div>
    <InputField
      type="text"
      name="transactionStructure"
      labelText="Transaction structure"
      placeholder="Asset Purchase"
    />
    <InputField type="number" name="dealValue" labelText="Estimated Deal Value" placeholder="10000000" />
    <InputField
      type="text"
      name="criticalElements"
      labelText="Critical elements"
      helperText="Elements we should pay extra attention to during analysis (e.g. IP with a software company)."
    />
    <InputField
      type="text"
      name="knownIssues"
      labelText="Known issues"
      helperText="Certain legal issues with the asset that are already known pre-analysis."
    />
  </>
);

export const WorkspaceDetailsPage = () => {
  const { workspace, refreshWorkspace } = useWorkspace();
  const { team } = useTeam();
  const navigate = useNavigate();

  // Determine if we need to show additional due diligence fields
  const isDueDiligenceWorkspace = workspace.type === 'due-diligence';

  // Pre-populate form with existing workspace values
  const initialValues: FormValues = {
    name: workspace.name || '',
    language: workspace.language || LanguageEnum.EN,
    targetParty: workspace.targetParty ?? '',
    companyNumber: workspace.companyNumber ?? '',
    industry: workspace.industry ?? '',
    coreActivities: workspace.coreActivities ?? '',
    transactionStructure: workspace.transactionStructure ?? '',
    dealValue: workspace.dealValue ?? null,
    criticalElements: workspace.criticalElements ?? '',
    knownIssues: workspace.knownIssues ?? '',
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      // Only include due diligence fields in payload if it's a due diligence workspace
      const apiPayload = {
        name: values.name,
        language: values.language,
        ...(isDueDiligenceWorkspace && {
          targetParty: values.targetParty,
          companyNumber: values.companyNumber,
          industry: values.industry,
          coreActivities: values.coreActivities,
          transactionStructure: values.transactionStructure,
          dealValue: values.dealValue || undefined,
          criticalElements: values.criticalElements,
          knownIssues: values.knownIssues,
        }),
      };

      const payload: UpdateWorkspacePayload = {
        workspaceId: workspace.id,
        data: apiPayload,
      };

      await fetchEndpointData('/api/v1/workspace/update', {
        method: 'POST',
        body: payload,
      });

      await refreshWorkspace();
      toast.success('Workspace details have been updated');
    } catch (err) {
      captureException(err);
      toast.error('Could not update workspace: ' + getDisplayError(err));
    }
  };

  return (
    <div className="page-content">
      <PageHeader title="Workspace Details" />

      <div className="h-full">
        <div className="flex justify-between items-center mb-4">
          <Button
            variant="ghost"
            onTrigger={() => navigate(`/app/t/${team.id}/workspace/${workspace.id}/settings`)}
            iconLeft={<ArrowLeftIcon className="button-icon" />}
          >
            Go back
          </Button>
        </div>

        {/* Only workspace owners can modify workspace details */}
        <WorkspacePermissionWrapper allowedPermissions={[]} isOwner={true}>
          <div className="card">
            <div className="heading-two mb-4">
              {workspace.type === 'due-diligence'
                ? 'Due Diligence details'
                : workspace.type === 'knowledge'
                  ? 'Knowledge details'
                  : 'Playground details'}
            </div>

            <Formik initialValues={initialValues} validationSchema={workspaceDetailsSchema} onSubmit={handleSubmit}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputField type="text" name="name" labelText="Workspace name" placeholder={workspace.name} />
                    <LanguageSelectField name="language" labelText="Language" />
                  </div>

                  {/* Conditionally render due diligence specific fields */}
                  {isDueDiligenceWorkspace && <DueDiligenceFormFields />}

                  <div className="flex justify-end mt-6">
                    <Button type="submit" variant="primary">
                      Save changes
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </WorkspacePermissionWrapper>
      </div>
    </div>
  );
};
