import * as React from 'react';
import useSWR from 'swr';

import createContext from '../../../contexts/create-context';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { ResponseType as CategoryResult } from '../endpoints/ListInternalCategoriesEndpoint';
import { SpinnerBlock } from '@/components/Spinner';

export type Category = CategoryResult['items'][0];

export interface ICategoryNode {
  id: number;
  name: string;
  description: string;
  parentCategoryId: number | null;
  children: ICategoryNode[];
}

interface ProviderValue {
  categories: Category[];
  categoryMap: Map<number, Category>;
  categoryTree: ICategoryNode[];
  refreshCategories: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface InternalCategoriesProviderProps {
  children?: React.ReactNode;
}

export const InternalCategoriesProvider: React.FC<InternalCategoriesProviderProps> = (props) => {
  const { children } = props;
  const { data, isLoading, mutate } = useSWR<CategoryResult>('/api/v1/internal/category/list', fetchEndpointData);

  const refreshCategories = React.useCallback(() => {
    mutate();
  }, [mutate]);

  const rawCategories = data?.items ?? [];
  const { categories, categoryMap, categoryTree } = React.useMemo(() => {
    const categories = [...rawCategories];
    const categoryMap = new Map(categories.map((v) => [v.id, v]));

    const nodeMap: Map<number, ICategoryNode> = new Map();
    const categoryTree = [] as ICategoryNode[];

    let pendingItems = [...categories];
    let iterCount = 0;
    while (pendingItems.length > 0 && iterCount < 5000) {
      iterCount++;

      for (const pendingItem of pendingItems) {
        if (!pendingItem.parentCategoryId) {
          const node: ICategoryNode = {
            id: pendingItem.id,
            name: pendingItem.name,
            description: pendingItem.description,
            parentCategoryId: null,
            children: [],
          };
          categoryTree.push(node);
          nodeMap.set(pendingItem.id, node);
          pendingItems = pendingItems.filter((v) => v.id !== pendingItem.id);
        } else {
          const parentNode = nodeMap.get(pendingItem.parentCategoryId);
          if (parentNode) {
            const node: ICategoryNode = {
              id: pendingItem.id,
              name: pendingItem.name,
              description: pendingItem.description,
              parentCategoryId: pendingItem.parentCategoryId,
              children: [],
            };
            parentNode.children.push(node);
            nodeMap.set(pendingItem.id, node);
            pendingItems = pendingItems.filter((v) => v.id !== pendingItem.id);
          }
        }
      }
    }

    return {
      categories,
      categoryMap,
      categoryTree,
    };
  }, [data]);

  if (isLoading) {
    return <SpinnerBlock message="Loading categories..." className="h-screen" />;
  }

  return <ReactProvider value={{ categories, categoryMap, categoryTree, refreshCategories }}>{children}</ReactProvider>;
};

export const useInternalCategories = useContext;
export const InternalCategoriesConsumer = ReactConsumer;
