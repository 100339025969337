import { QuestionsPresetType } from '@/app/questionsPreset/enums';
import { OneByOneAnalysisResult } from './one-by-one/OneByOneAnalysisResult';
import { CrossDocumentAnalysisResultPage } from './cross-document/CrossDocumentAnalysisResult';
import { PresetRun } from '../WorkspaceAnalysisStatePage';

export interface IAnalysisResultsProps {
  presetRun: PresetRun;
}

export const AnalysisResults: React.FC<IAnalysisResultsProps> = (props) => {
  const { presetRun } = props;

  switch (presetRun.type) {
    case QuestionsPresetType.OneByOne:
      return <OneByOneAnalysisResult presetRun={presetRun} />;
    case QuestionsPresetType.CrossDocument:
      return <CrossDocumentAnalysisResultPage presetRun={presetRun} />;
    default:
      return <div>Not implemented yet...</div>;
  }
};
