import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { X as XIcon, TrashSimple as TrashIcon } from '@phosphor-icons/react';
import { captureException } from '@sentry/react';
import toast from 'react-hot-toast';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Button } from '@/components/button/Button';
import { DialogContent, DialogRoot } from '@/components/dialog/Dialog';
import { useTeam } from '@/app/team/context/TeamContext';
import { BodyType as DeleteDocumentsPayload } from '../endpoints/DeleteWorkspaceDocumentsEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { getDisplayError } from '@/utils/get-display-error';

export const InvalidDocumentsCountDialog = () => {
  const navigate = useNavigate();
  const { team } = useTeam();
  const { tree } = useWorkspace();
  const { workspace } = useWorkspace();
  const [open, setOpen] = useState(false);
  const [deletingDocs, setDeletingDocs] = useState<string[]>([]);

  const invalidDocuments = Array.from(tree.invalidDocumentIds).map((v) => {
    return tree.entries.get(v)!;
  });

  if (!invalidDocuments.length) {
    return null;
  }

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <Button
        size={6}
        variant="ghost"
        onTrigger={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();

          setOpen(true);
        }}
      >
        <div className="flex gap-1 items-center text-danger-color">
          <div>{invalidDocuments.length}</div>
          <XIcon className="button-icon" />
        </div>
      </Button>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Invalid documents</h1>

        {invalidDocuments.length > 0 ? (
          <div className="grid gap-2">
            {invalidDocuments.map((v) => {
              const documentId = v.document?.id;
              const isDeleting = documentId ? deletingDocs.includes(documentId) : false;

              return (
                <div
                  key={v.id}
                  className="flex flex-wrap justify-between px-2 gap-2 card-no-padding cursor-pointer"
                  data-clickable="true"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();

                    navigate(`/app/t/${team.id}/workspace/${workspace.id}/documents/redirect-to/${v.document?.id}`);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <div>
                      <XIcon className="button-icon" />
                    </div>
                    <div className="w-full">{v.name}</div>
                  </div>
                  <div className="flex items-center gap-2 py-1">
                    <div>{v.document?.failureReason || 'Unknown'}</div>
                    {documentId && (
                      <Button
                        variant="destructive"
                        size={6}
                        isLoading={isDeleting}
                        onTrigger={async (evt) => {
                          evt.stopPropagation();
                          evt.preventDefault();

                          if (isDeleting) {
                            return;
                          }

                          setDeletingDocs((prev) => [...prev, documentId]);
                          try {
                            const payload: DeleteDocumentsPayload = {
                              documentIds: [documentId],
                              workspaceId: workspace.id,
                            };
                            await fetchEndpointData('/api/v1/workspace/document/delete', {
                              method: 'DELETE',
                              body: payload,
                            });
                            toast.success('Document has been deleted');
                          } catch (err) {
                            captureException(err);
                            toast.error('Could not delete document: ' + getDisplayError(err));
                          }
                          setDeletingDocs((prev) => prev.filter((v) => v !== documentId));
                        }}
                      >
                        <TrashIcon />
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          'All documents are valid.'
        )}
      </DialogContent>
    </DialogRoot>
  );
};
