import {
  PencilSimple as PencilIcon,
  Plus as PlusIcon,
  Trash as TrashIcon,
  CaretDown as CaretDownIcon,
  CaretRight as CaretRightIcon,
  Upload as UploadIcon,
} from '@phosphor-icons/react';
import toast from 'react-hot-toast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { captureException } from '@sentry/react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import {
  ICategoryNode,
  InternalCategoriesProvider,
  useInternalCategories,
} from '../contexts/InternalCategoriesContext';
import { CategoryFormDialog } from './CategoryFormDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { Button } from '../../../components/button/Button';
import { CategoryDeleteDialog } from './CategoryDeleteDialog';
import {
  BodyType as CreateCategoryPayload,
  ResponseType as CreateCategoryResponseType,
} from '../endpoints/CreateInternalCategoryEndpoint';
import { BodyType as UpdateCategoryPayload } from '../endpoints/UpdateInternalCategoryEndpoint';
import { BodyType as DeleteCategoryPayload } from '../endpoints/DeleteInternalCategoryEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { useCategoryTreeReducer } from '../hooks/useCategoryTreeReducer';

export interface ICategoryNodeProps {
  idx: string;
  parentIdx: string;
  node: ICategoryNode;
  depth: number;
  openCategories: Set<number>;
  isOpen: boolean;
  toggleOpen: (categoryId: number) => void;
}

const CategoryNode: React.FC<ICategoryNodeProps> = (props) => {
  const { idx, parentIdx, node, depth, isOpen, openCategories, toggleOpen } = props;

  const { refreshCategories } = useInternalCategories();
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const excludedCategoryIds = useMemo(() => {
    return [node.id];
  }, [node.id]);

  const fullIdx = parentIdx ? `${parentIdx}.${idx}` : idx;
  return (
    <div>
      <div
        className="flex gap-2 justify-between items-center border-b border-gray-200 py-1 hover:bg-gray-100 cursor-pointer"
        style={{
          paddingLeft: `${depth * 1}rem`,
        }}
        onClick={() => {
          toggleOpen(node.id);
        }}
      >
        <div>{isOpen ? <CaretDownIcon className="w-4 h-4" /> : <CaretRightIcon className="w-4 h-4" />}</div>
        <div className="flex flex-1 items-center">
          <div>{`${fullIdx} ${node.name}`}</div>
        </div>
        <div
          className="flex gap-1"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowEditDialog(true);
            }}
          >
            <PencilIcon className="w-4 h-4" />
          </Button>
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowDeleteDialog(true);
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
          <CategoryFormDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-edit`}
            excludedCategoryIds={excludedCategoryIds}
            initialValues={{
              name: node.name,
              description: node.description,
              parentCategoryId: node.parentCategoryId,
            }}
            isOpen={showEditDialog}
            setIsOpen={setShowEditDialog}
            title="Edit category"
            submitText="Save"
            onSubmit={async (values) => {
              try {
                const payload: UpdateCategoryPayload = {
                  categoryId: node.id,
                  data: {
                    name: values.name,
                    description: values.description,
                    parentCategoryId: values.parentCategoryId,
                  },
                };
                await fetchEndpointData('/api/v1/internal/category/update', {
                  method: 'POST',
                  body: payload,
                });
                refreshCategories();
                toast.success('Category updated');
              } catch (err) {
                captureException(err);
                toast.error(`Failed to update category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
          <CategoryDeleteDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-delete`}
            excludedCategoryIds={excludedCategoryIds}
            initialValues={{
              newCategoryId: null,
            }}
            isOpen={showDeleteDialog}
            setIsOpen={setShowDeleteDialog}
            categoryName={node.name}
            onSubmit={async (values) => {
              try {
                const payload: DeleteCategoryPayload = {
                  categoryId: node.id,
                  newCategoryId: values.newCategoryId,
                };
                await fetchEndpointData('/api/v1/internal/category/delete', {
                  method: 'DELETE',
                  body: payload,
                });
                refreshCategories();
                toast.success('Category removed');
              } catch (err) {
                captureException(err);
                toast.error(`Failed to delete category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
        </div>
      </div>

      {isOpen && (
        <div>
          {node.children.map((v, idx) => {
            return (
              <CategoryNode
                key={v.id}
                node={v}
                depth={depth + 1}
                parentIdx={fullIdx}
                idx={`${idx + 1}`}
                openCategories={openCategories}
                toggleOpen={toggleOpen}
                isOpen={openCategories.has(v.id)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export interface IImportNode {
  name: string;
  children: IImportNode[];
}

const createCategory = async (data: {
  name: string;
  description: string;
  parentCategoryId: number | null;
}): Promise<{ id: number }> => {
  const payload: CreateCategoryPayload = {
    data: {
      name: data.name,
      description: data.description,
      parentCategoryId: data.parentCategoryId,
    },
  };
  const res = await fetchEndpointData<CreateCategoryResponseType>('/api/v1/internal/category/create', {
    method: 'POST',
    body: payload,
  });
  return res.category;
};

const _InternalCategoriesPage = () => {
  const { categories, categoryTree: _categoryTree, refreshCategories } = useInternalCategories();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [createKey, setCreateKey] = React.useState(0);
  const [{ categoryTree, openCategories }, dispatch] = useCategoryTreeReducer(_categoryTree);
  const [isImporting, setIsImporting] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'set_category_tree',
      categoryTree: _categoryTree,
    });
  }, [_categoryTree]);

  const toggleOpen = useCallback(
    (categoryId: number) => {
      dispatch({
        type: 'toggle',
        categoryId,
      });
    },
    [dispatch],
  );

  const handleImport = async (
    categories: Array<IImportNode>,
    tree: typeof categoryTree,
    parentCategoryId: number | null,
  ) => {
    for (const category of categories) {
      const existing = tree.find((v) => v.name === category.name);
      if (existing) {
        await handleImport(category.children, existing.children, existing.id);
      } else {
        const res = await createCategory({
          name: category.name,
          description: '',
          parentCategoryId,
        });
        await handleImport(category.children, [], res.id);
      }
    }
  };

  return (
    <div className="page-content">
      <PageHeader title="Global Categories" />

      <div>
        <div className="mb-4 flex justify-between">
          <Breadcrumb
            items={[
              {
                name: 'Global Categories',
              },
            ]}
          />

          <Button
            isLoading={isImporting}
            onTrigger={() => {
              // Create an input element
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = '.json';

              // Handle file selection
              input.onchange = (event) => {
                setIsImporting(true);

                // @ts-ignore
                const file = event.target.files[0];
                if (!file) {
                  setIsImporting(false);
                  return;
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                  try {
                    // @ts-ignore
                    const jsonData = JSON.parse(e.target.result);
                    handleImport(jsonData, categoryTree, null)
                      .then(() => {
                        setIsImporting(false);
                        setCreateKey(Date.now());
                        refreshCategories();
                        toast.success('Data imported');
                      })
                      .catch((err) => {
                        setIsImporting(false);
                        toast.error(`Failed to import data: ${getDisplayError(err)}`);
                      });
                  } catch (error) {
                    setIsImporting(false);
                    toast.error('Invalid file');
                  }
                };
                reader.readAsText(file);

                input.remove();
              };

              // Trigger file picker
              input.click();
            }}
          >
            <UploadIcon className="button-icon" />
          </Button>
        </div>

        <div>
          <div
            className="flex justify-between gap-2 items-center border-b border-gray-200 py-1 cursor-pointer"
            onClick={() => {
              dispatch({
                type: 'toggle_all',
              });
            }}
          >
            <div>{openCategories.size > 0 ? <CaretDownIcon /> : <CaretRightIcon />}</div>
            <div className="font-medium flex-1">Name</div>
            <div
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
              }}
            >
              <Button
                size={6}
                shape="square"
                onTrigger={() => {
                  setShowCreateDialog(true);
                }}
              >
                <PlusIcon className="w-4 h-4" />
              </Button>
              <CategoryFormDialog
                key={`category-create-${createKey}`}
                isOpen={showCreateDialog}
                setIsOpen={setShowCreateDialog}
                title="Create category"
                submitText="Create"
                onSubmit={async (values, helpers) => {
                  try {
                    const payload: CreateCategoryPayload = {
                      data: {
                        name: values.name,
                        description: values.description,
                        parentCategoryId: values.parentCategoryId,
                      },
                    };
                    await fetchEndpointData('/api/v1/internal/category/create', {
                      method: 'POST',
                      body: payload,
                    });
                    setCreateKey(Date.now());
                    refreshCategories();
                    helpers.resetForm();
                    toast.success('Category created');
                  } catch (err) {
                    captureException(err);
                    toast.error(`Failed to create category: ${getDisplayError(err)}`);
                    throw err;
                  }
                }}
              />
            </div>
          </div>

          <div>
            {!categories.length && <div className="my-2">No categories found</div>}
            <div>
              {categoryTree.map((v, idx) => {
                return (
                  <CategoryNode
                    key={v.id}
                    node={v}
                    depth={0}
                    parentIdx=""
                    idx={`${idx + 1}`}
                    openCategories={openCategories}
                    isOpen={openCategories.has(v.id)}
                    toggleOpen={toggleOpen}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InternalCategoriesPage = () => {
  return (
    <InternalCategoriesProvider>
      <_InternalCategoriesPage />
    </InternalCategoriesProvider>
  );
};
