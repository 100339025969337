import { Link, useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import {
  Prohibit as BanIcon,
  Trash as TrashIcon,
  PencilSimple as PencilIcon,
  Info as InfoIcon,
} from '@phosphor-icons/react';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';
import { z } from 'zod';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { IPaginationVariables, usePagination } from '@/hooks/usePagination';
import { Table } from '@/components/table/Table';
import { formatDateTime } from '@/utils/date';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { getDisplayError } from '@/utils/get-display-error';
import { StatusDot } from '@/components/StatusDot';
import { Spinner, SpinnerBlock } from '@/components/Spinner';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as WorkspacePresetRunsResponseType } from '../endpoints/WorkspacePresetRunsEndpoint';
import type { BodyType as DeletePresetRunPayload } from '../endpoints/DeletePresetRunEndpoint';
import type { BodyType as CancelPresetRunPayload } from '../endpoints/CancelPresetRunEndpoint';
import type { BodyType as UpdateAnalysisPayload } from '../endpoints/UpdateWorkspaceAnalysisEndpoint';
import { PresetRunStatus } from '../enums';
import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { WorkspacePermissionWrapper } from '@/app/workspace/components/WorkspacePermissionWrapper';
import { WorkspacePermissionEnum } from '@/app/auth/enum';
import { Pagination } from '@/components/Pagination';
import { LinkButton } from '@/components/button/Button';
import { InputWrapper } from '@/components/InputWrapper';
import { Input } from '@/components/input/Input';
import { Tooltip } from '@/components/tooltip/Tooltip';

const PRESET_RUNS_TABLE_HEADERS = [
  {
    id: 'id',
    name: 'Name',
  },
  {
    id: 'targetParty',
    name: 'Target Party',
  },
  {
    id: 'status',
    name: 'Status',
  },
  {
    id: 'createdAt',
    name: 'Started at',
  },
  {
    id: 'delete',
    name: 'Delete',
  },
];

const analysisNameSchema = z.string().min(1, 'Analysis name is required').max(200, 'Name cannot exceed 200 characters');

const SUBSTRING_MAX_LENGTH = 50;

export const WorkspacePresetRunsPage = () => {
  const { workspace, processingState } = useWorkspace();
  const [searchParams] = useSearchParams();
  const [actionId, setActionId] = useState(0);
  const [newAnalysisName, setNewAnalysisName] = useState('');

  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const query = new URLSearchParams();
      query.set('workspaceId', workspace.id);
      query.set('page', variables.page.toString());
      query.set('take', variables.take.toString());

      const result = await fetchEndpointData<WorkspacePresetRunsResponseType>(
        `/api/v1/workspace/preset-run/list?${query.toString()}`,
      );
      return {
        data: result.docs ?? [],
        pages: result.pagination.pages,
      };
    },
    [workspace.id],
  );
  const pagination = usePagination({
    pageSize: 40,
    page: +(searchParams.get('page') ?? 0),
    fetcher: pageFetcher,
    refreshToken: actionId,
  });

  const documentsProcessing = processingState.processedCount < processingState.totalCount;
  const presetRuns = pagination.data ?? [];
  return (
    <div className="page-content">
      <PageHeader title="Analysis results" />

      <div className="mb-2 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Analysis results',
            },
          ]}
        />

        <div className="flex justify-between items-center mb-4">
          <div>
            {documentsProcessing && (
              <div className="flex gap-1 items-center mb-2">
                <Spinner size={4} />
                Some documents are still being processed...
              </div>
            )}
          </div>

          <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.RunPresets]}>
            <LinkButton to="start" variant="primary">
              Start analysis
            </LinkButton>
          </WorkspacePermissionWrapper>
        </div>
      </div>

      {!!presetRuns.length && (
        <Table
          idKey="id"
          headers={PRESET_RUNS_TABLE_HEADERS}
          data={presetRuns}
          mapData={(entry) => {
            return [
              <Link to={entry.id} className="w-full hover:text-link-color flex gap-3">
                <ConfirmDialog
                  triggerSize={6}
                  triggerVariant="ghost"
                  triggerText={<PencilIcon className="button-icon" />}
                  title="Rename analysis"
                  submitText="Rename"
                  description={
                    <InputWrapper labelText="New analysis name">
                      <Input
                        value={newAnalysisName}
                        type="text"
                        placeholder={entry.name}
                        onChange={(value) => setNewAnalysisName(value)}
                      />
                    </InputWrapper>
                  }
                  onSubmit={async () => {
                    try {
                      analysisNameSchema.parse(newAnalysisName.trim());
                      if (newAnalysisName === entry.name) {
                        throw new Error('New analysis name is the same as the current name');
                      }
                      const payload: UpdateAnalysisPayload = {
                        analysisId: entry.id,
                        name: newAnalysisName,
                      };
                      await fetchEndpointData('/api/v1/workspace/analysis/update', {
                        method: 'POST',
                        body: payload,
                      });
                      setNewAnalysisName('');
                      setActionId(Date.now());
                      toast.success('Analysis has been renamed');
                    } catch (err) {
                      captureException(err);
                      toast.error('Could not rename analysis: ' + getDisplayError(err));
                    }
                  }}
                />
                {entry.name && entry.name.length > SUBSTRING_MAX_LENGTH ? (
                  <div className="flex gap-1 items-center">
                    <div>{entry.name.substring(0, SUBSTRING_MAX_LENGTH)}...</div>
                    <div>
                      <Tooltip text={entry.name}>
                        <InfoIcon className="w-4 h-4" />
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  entry.name
                )}
              </Link>,
              <div>
                {entry.targetParty && entry.targetParty.length > SUBSTRING_MAX_LENGTH ? (
                  <div className="flex gap-1 items-center">
                    <div>{entry.targetParty.substring(0, SUBSTRING_MAX_LENGTH)}...</div>
                    <div>
                      <Tooltip text={entry.targetParty}>
                        <InfoIcon className="w-4 h-4" />
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  entry.targetParty
                )}
              </div>,
              <div className="flex items-center gap-1">
                <StatusDot
                  size={3}
                  color={
                    entry.status === PresetRunStatus.Running
                      ? 'blue'
                      : entry.status === PresetRunStatus.Canceled
                        ? 'red'
                        : 'green'
                  }
                  pulse={entry.status === PresetRunStatus.Running}
                />
                {entry.status}
              </div>,
              formatDateTime(entry.createdAt),
              entry.status === PresetRunStatus.Running ? (
                <ConfirmDialog
                  triggerSize={6}
                  triggerText={<BanIcon className="button-icon" />}
                  title="Cancel analysis result"
                  submitText="Cancel"
                  triggerVariant="destructive"
                  submitVariant="destructive"
                  description={`Are you sure you want to cancel this analysis result?`}
                  onSubmit={async () => {
                    try {
                      const payload: CancelPresetRunPayload = {
                        presetRunId: entry.id,
                      };
                      await fetchEndpointData(`/api/v1/workspace/preset-run/cancel`, {
                        method: 'DELETE',
                        body: payload,
                      });
                      setActionId(Date.now());
                      toast.success('Analysis result has been canceled');
                    } catch (err) {
                      captureException(err);
                      toast.error('Could not cancel analysis result: ' + getDisplayError(err));
                    }
                  }}
                />
              ) : (
                <ConfirmDialog
                  triggerSize={6}
                  triggerText={<TrashIcon className="button-icon" />}
                  title="Delete analysis result"
                  submitText="Delete"
                  confirmationText={'Delete'}
                  triggerVariant="destructive"
                  submitVariant="destructive"
                  description={`Are you sure you want to delete this analysis result?`}
                  onSubmit={async () => {
                    try {
                      const payload: DeletePresetRunPayload = {
                        presetRunId: entry.id,
                      };
                      await fetchEndpointData(`/api/v1/workspace/preset-run/delete`, {
                        method: 'DELETE',
                        body: payload,
                      });
                      setActionId(Date.now());
                      toast.success('Analysis result has been deleted');
                    } catch (err) {
                      captureException(err);
                      toast.error('Could not delete analysis result: ' + getDisplayError(err));
                    }
                  }}
                />
              ),
            ];
          }}
        />
      )}

      {!presetRuns.length && !pagination.isFetching && (
        <div className="card">
          <div>No analysis results found.</div>
        </div>
      )}

      {!presetRuns.length && pagination.isFetching && (
        <div>
          <SpinnerBlock message="Loading analysis results..." />
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          page={pagination.variables.page}
          totalPages={pagination.pages}
        />
      </div>
    </div>
  );
};
