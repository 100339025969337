import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { captureException } from '@sentry/react';
import React from 'react';

import { Button } from './button/Button';

export const ErrorFallback: React.FC<FallbackProps> = (props: FallbackProps) => {
  const { error, resetErrorBoundary } = props;

  return (
    <div className="fixed h-screen w-screen top-0 left-0 p-16 z-error-boundary bg-white">
      <h1 className="heading-one mb-2">Oops, something went wrong</h1>

      <p>Message: {error.message}</p>

      <div className="flex gap-2 my-6">
        <Button variant="primary" onTrigger={() => resetErrorBoundary()}>
          Reset application
        </Button>
        <Button
          onTrigger={() => {
            window.location.href = '/';
          }}
        >
          Go to home page
        </Button>
      </div>
    </div>
  );
};

export const DefaultErrorBoundary: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(err) => {
        captureException(err);
      }}
      onReset={() => {
        window.location.reload();
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
