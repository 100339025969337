import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Formik } from 'formik';

import { SpinnerBlock } from '../../../components/Spinner';
import Logo from '../../../components/Logo';
import { Button } from '../../../components/button/Button';
import type { ResponseType as MeResponse } from '../../user/endpoints/MeEndpoint';
import type { BodyType as RequestCodeBody } from '../magic-auth/RequestLoginCodeEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { getDisplayError } from '@/utils/get-display-error';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
});

export const MagicLoginPage = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useSWR<MeResponse>('/api/v1/user/me', fetchEndpointData, {
    shouldRetryOnError: false,
    errorRetryCount: 0,
  });

  useEffect(() => {
    if (data?.me) {
      navigate('/app/t', { replace: true });
    }
  }, [data?.me, navigate]);

  if (isLoading) {
    return <SpinnerBlock message="Loading..." className="h-screen" />;
  }

  const handleSubmitEmail = async (
    values: { email: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      if (values.email) {
        const payload: RequestCodeBody = {
          email: values.email,
        };
        await fetchEndpointData('/api/v1/auth/request-login-code', {
          method: 'POST',
          body: payload,
        });
        navigate(`/validate-login-token?email=${encodeURIComponent(values.email)}`);
        toast.success('Login code sent!');
      }
    } catch (err) {
      console.error('Email login error:', err);
      toast.error(`Login failed: ${getDisplayError(err)}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="w-[360px] flex flex-col items-center gap-8">
        <Logo width={28} height={24} />

        <h1 className="text-center text-2xl font-bold text-[#262626] leading-9">Login with Code</h1>

        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={loginSchema}
          onSubmit={handleSubmitEmail}
        >
          {({ values, handleChange, handleSubmit, isSubmitting, errors, touched }) => (
            <form onSubmit={handleSubmit} className="w-full flex flex-col gap-8">
              <div className="w-full flex flex-col gap-1">
                <div className="flex items-start gap-1">
                  <label className="text-[#525760] text-sm font-semibold leading-[22px]">Email</label>
                </div>
                <div className="w-full px-3 py-1.5 bg-white rounded-lg border border-[#C0C4CC] flex items-center">
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="name@work-email.com"
                    className="w-full border-none outline-none text-base bg-transparent placeholder:text-[#A3A3A3] placeholder:font-[450] leading-[25.6px]"
                  />
                </div>
                {errors.email && touched.email && <div className="text-red-500 text-xs mt-1">{errors.email}</div>}
              </div>

              <div className="w-full">
                <div className="w-full">
                  <Button
                    type="submit"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    variant="primary"
                    size={12}
                    width="full"
                  >
                    {isSubmitting ? 'Sending code...' : 'Request login code'}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

      <footer className="absolute bottom-8 flex justify-center items-center gap-8">
        <a
          href="https://docs.google.com/document/d/1VfBoGVguSoj5-r8NOcq72qZdGD0yoRx5E04drLrPgiM/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Terms and conditions
        </a>
        <a
          href="https://docs.google.com/document/d/1yZ_9gworSTk6LNkXqVTYscd_9HoSYaL0g5w9HEpdTh4/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Acceptable Use Policy
        </a>
        <a
          href="https://docs.google.com/document/d/1T33zx7ocKqyOQfEk_VS2I2o-y_qd3Y54lHKPK7SYvcg/edit?usp=sharing"
          className="text-center text-[#737373] text-xs font-medium underline leading-[18px]"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};
