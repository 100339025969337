import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Warning as WarningIcon } from '@phosphor-icons/react';

import { useTeam } from '@/app/team/context/TeamContext';
import { useAuth } from '@/contexts/auth-context';
import { DialogContent, DialogRoot, DialogTrigger } from '../dialog/Dialog';
import { BaseButton } from '../button/BaseButton';
import { LinkButton } from '../button/Button';
import { getTeamStates } from '@/app/team/context/TeamStates';
import { Tooltip } from '../tooltip/Tooltip';

const getShortTeamName = (name: string) => {
  if (name.length > 16) {
    return `${name.substring(0, 16)}...`;
  } else {
    return name;
  }
};

const TeamSelectorDialog = () => {
  const { myTeams } = useAuth();
  const navigate = useNavigate();
  const { team: selectedTeam } = useTeam();
  const [open, setOpen] = useState(false);

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <BaseButton variant="ghost" onClick={() => setOpen(true)}>
          {getShortTeamName(selectedTeam.name)}
        </BaseButton>
      </DialogTrigger>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Select a team to switch</h1>

        <div className="grid gap-2 overflow-y-auto" style={{ maxHeight: '50vh' }}>
          {myTeams.map((t) => {
            return (
              <button
                key={`${t.team.id}`}
                className="bg-gray-200 hover:bg-gray-300 rounded p-1 cursor-pointer text-left"
                onClick={() => {
                  navigate(`/app/t/${t.team.id}`);
                  setOpen(false);
                }}
              >
                {t.team.name}
              </button>
            );
          })}
        </div>
      </DialogContent>
    </DialogRoot>
  );
};

export interface ITeamSelectorProps {
  statusIndicatorOverwrite?: React.ReactNode;
}

export const TeamSelector: React.FC<ITeamSelectorProps> = (props) => {
  const { statusIndicatorOverwrite } = props;

  const { myTeams } = useAuth();
  const { team: selectedTeam } = useTeam();

  const teamState = getTeamStates().getState(selectedTeam.id);

  const [remainingCredits, setRemainingCredits] = useState(teamState.remainingCredits);
  useEffect(() => {
    teamState.onRemainingCreditsChange((newRemainingCredits) => {
      setRemainingCredits(newRemainingCredits);
    });
  }, [teamState]);

  return (
    <div className="flex p-2">
      <div className="flex-1">
        {myTeams.length > 1 ? (
          <TeamSelectorDialog />
        ) : (
          <LinkButton to={`/app/t/${selectedTeam.id}`} variant="ghost">
            {getShortTeamName(selectedTeam.name)}
          </LinkButton>
        )}
      </div>

      <div className="flex items-center px-2">
        {statusIndicatorOverwrite
          ? statusIndicatorOverwrite
          : remainingCredits < selectedTeam.lowCreditsWarningTreshold && (
              <Tooltip text={`Low on credits, ${remainingCredits} credits remaining.`}>
                <WarningIcon className="w-4 h-4 text-danger-color" />
              </Tooltip>
            )}
      </div>
    </div>
  );
};
