import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as UpdateRiskResponse, BodyType as UpdateRiskBody } from './endpoints/UpdateRiskEndpoint';
import { getWorkspaceStates } from '../workspace/context/WorkspaceStates';

export async function updateRisk(opts: {
  workspaceId: string;
  riskId: string;
  data: UpdateRiskBody['data'];
}): Promise<UpdateRiskResponse> {
  const { workspaceId, riskId, data } = opts;
  const body: UpdateRiskBody = {
    riskId,
    data,
  };
  const res = await fetchEndpointData<UpdateRiskResponse>('/api/v1/workspace/risk/update', {
    method: 'POST',
    body,
  });
  const syncState = getWorkspaceStates().getExistingState(workspaceId);
  if (syncState) {
    syncState.risksState.updateRisk(riskId, {
      title: data.title ?? undefined,
      description: data.description ?? undefined,
      isPartOfTheReport: data.isPartOfTheReport ?? undefined,
      isArchived: data.isArchived ?? undefined,
      assigneeId: data.assigneeId ?? undefined,
      overallScoreOverwrite: data.overallScoreOverwrite ?? undefined,
    });
  }
  return res;
}
