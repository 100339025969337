import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button } from '@/components/button/Button';
import { Trash as TrashIcon, Pencil as PencilIcon, Plus as PlusIcon } from '@phosphor-icons/react';
import { FormDialog } from '@/components/dialog/FormDialog';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import toast from 'react-hot-toast';
import { InputField } from '@/components/input/InputField';

interface CategoryManagerProps {
  categories: string[];
  onAddCategory: (name: string) => void;
  onRemoveCategory: (category: string) => void;
}

interface PresetQuestion {
  index: string;
  name: string;
  question: string;
  category: string | null;
  additionalContext: string;
  answerInstructions: string;
}

interface CategoryDeletionWarningProps {
  category: string;
  questions: PresetQuestion[];
  onCancel: () => void;
  onConfirm: () => void;
}

export const CategoryManager: React.FC<CategoryManagerProps> = ({ categories, onAddCategory, onRemoveCategory }) => {
  const categorySchema = Yup.object().shape({
    categoryName: Yup.string()
      .required('Category name is required')
      .min(1, 'Category name cannot be empty')
      .test('unique-category', 'Category already exists', (value, context) => {
        return !categories.map((c: string) => c.toLowerCase()).includes(value.toLowerCase());
      }),
  });

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between mb-2">
        <div className="font-medium">Categories (Optional)</div>
        <FormDialog
          triggerText="Add Category"
          triggerIconLeft={<PlusIcon className="w-4 h-4" />}
          title="Add New Category"
          submitText="Add Category"
          initialValues={{ categoryName: '' }}
          validationSchema={categorySchema}
          onSubmit={(values) => {
            onAddCategory(values.categoryName.trim());
            toast.success(`Category "${values.categoryName.trim()}" added`);
          }}
        >
          <InputField labelText="Category Name" name="categoryName" type="text" placeholder="Enter category name" />
        </FormDialog>
      </div>

      {categories.length > 0 ? (
        <div className="flex flex-wrap gap-2 mb-4">
          {categories.map((category: string) => (
            <div key={category} className="flex items-center gap-2 border border-dark-08 rounded px-3 py-1">
              <span>{category}</span>
              <div className="flex items-center">
                <Button type="button" variant="ghost" size={6} onTrigger={() => onRemoveCategory(category)}>
                  <TrashIcon className="w-4 h-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-gray-500 mb-4">No categories added yet</div>
      )}
    </div>
  );
};

export const CategoryDeletionWarning: React.FC<CategoryDeletionWarningProps> = ({
  category,
  questions,
  onCancel,
  onConfirm,
}) => {
  const affectedQuestions = questions.filter((q) => q.category === category);

  return (
    <ConfirmDialog
      isOpen={true}
      onCancel={onCancel}
      triggerText=""
      title="Warning: Category Removal"
      description={
        <>
          <p>
            The category "{category}" is used by {affectedQuestions.length} question
            {affectedQuestions.length !== 1 ? 's' : ''}. Removing it will uncategorize these questions.
          </p>
          <p className="mt-2">Are you sure you want to continue?</p>
        </>
      }
      submitText="Remove Category"
      submitVariant="destructive"
      onSubmit={onConfirm}
    />
  );
};
