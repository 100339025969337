import { useEffect, useMemo, useState } from 'react';
import { Archive as ArchiveIcon, Check as CheckIcon } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { IRisk } from '@/app/workspace/context/WorkspaceRisks';
import { RiskIndicator } from '../components/RiskIndicator';
import { Tooltip } from '@/components/tooltip/Tooltip';
import { Tag } from '@/components/Tag';
import { DocumentRisksAnalysedDialog } from './RisksAnalysedDialog';
import { IgnoredRiskDocsDialog } from './IgnoredRiskDocsDialog';
import { MultiToggle } from '@/components/MultiToggle';

interface IRisksListProps {
  risks: IRisk[];
}

const RisksList: React.FC<IRisksListProps> = (props) => {
  const { risks } = props;
  const { tree } = useWorkspace();

  return (
    <div>
      {risks.length === 0 && <div>No risks found.</div>}

      {risks.length > 0 && (
        <div className="grid grid-cols-1 gap-1 py-2">
          {risks.map((risk) => {
            const node = tree.getNode(risk.documentId);
            const doc = node?.document;

            return (
              <Link
                key={`r-${risk.id}`}
                className="card-no-padding px-2 py-1 flex justify-between items-center"
                data-clickable="true"
                to={risk.id}
              >
                <div className="flex items-center gap-2">
                  <div>
                    <RiskIndicator
                      hasBeenScored={risk.hasBeenScored}
                      score={risk.overallScoreOverwrite ?? risk.overallScore}
                    />
                  </div>
                  <div>{risk.title}</div>
                </div>
                <div>
                  <Tooltip text={node?.getFullpath() ?? ''}>
                    <div>
                      <Tag color="blue">{doc?.generalCategory ?? 'other'}</Tag>
                    </div>
                  </Tooltip>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

type ActiveTab = 'active' | 'archived';

export const WorkspaceRisksPage = () => {
  const { syncState, treeKey } = useWorkspace();
  const [riskKey, setRiskKey] = useState(syncState.risksState.risksUpdateKey);
  const [activeTab, setActiveTab] = useState<ActiveTab>('active');

  const activeRisks = useMemo(() => {
    return syncState.risksState.getActiveRisks();
  }, [riskKey, treeKey]);

  const archivedRisks = useMemo(() => {
    return syncState.risksState.getArchivedRisks();
  }, [riskKey, treeKey]);

  useEffect(() => {
    const disposable = syncState.risksState.onRisksUpdate((k) => {
      setRiskKey(k);
    });
    setRiskKey(syncState.risksState.risksUpdateKey);
    return () => disposable.dispose();
  }, [syncState.risksState]);

  const toggleOptions = useMemo(() => {
    return [
      {
        value: 'active' as ActiveTab,
        icon: <CheckIcon className="w-4 h-4" />,
        title: 'Show active risks',
      },
      {
        value: 'archived' as ActiveTab,
        icon: <ArchiveIcon className="w-4 h-4" />,
        title: 'Show archived risks',
      },
    ];
  }, []);

  return (
    <div className="flex">
      <div className="flex-1 p-8 overflow-y-auto h-without-topbar">
        <div className="flex justify-between mb-4">
          <div className="flex justify-center">
            <div className="heading-one">Risks</div>
          </div>
        </div>

        <div className="my-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex gap-2">
              <DocumentRisksAnalysedDialog />
              <IgnoredRiskDocsDialog />
            </div>

            <div className="flex gap-2">
              <MultiToggle options={toggleOptions} value={activeTab} onChange={(v) => setActiveTab(v as ActiveTab)} />
            </div>
          </div>

          <RisksList risks={activeTab === 'active' ? activeRisks : archivedRisks} />
        </div>
      </div>
    </div>
  );
};
