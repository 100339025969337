import { useField } from 'formik';

import { InputWrapper } from './InputWrapper';
import { ISimpleSelectItem, SimpleSelect } from './select/SimpleSelect';
import { LanguageEnum } from '../app/workspaceDocument/enums';

export const LANG_OPTIONS: ISimpleSelectItem[] = [
  {
    key: '' + LanguageEnum.NL,
    name: 'Nederlands',
  },
  {
    key: '' + LanguageEnum.EN,
    name: 'English',
  },
  {
    key: '' + LanguageEnum.FR,
    name: 'Français',
  },
  {
    key: '' + LanguageEnum.DE,
    name: 'Deutsch',
  },
];

export interface ILanguageSelectProps {
  value: LanguageEnum | null;
  onSelect: (val: LanguageEnum | null) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
}

export const LanguageSelect: React.FC<ILanguageSelectProps> = (props) => {
  const { value, onSelect, isDisabled, onBlur, isInvalid } = props;

  return (
    <SimpleSelect
      items={LANG_OPTIONS}
      selectedItem={LANG_OPTIONS.find((v) => v.key === value) ?? null}
      onSelect={(val) => {
        if (val?.key) {
          onSelect(val?.key as LanguageEnum);
        } else {
          onSelect(null);
        }
      }}
      onBlur={onBlur}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    />
  );
};

export interface ILanguageSelectFieldProps {
  name: string;
  labelText: string;
  helperText?: string;
  isDisabled?: boolean;
}

export const LanguageSelectField: React.FC<ILanguageSelectFieldProps> = (props) => {
  const { labelText, helperText, isDisabled, name } = props;
  const [field, meta, helpers] = useField({ name });

  return (
    <InputWrapper
      labelText={labelText}
      invalidText={meta.touched ? meta.error : undefined}
      helperText={helperText}
      noLabel
    >
      <LanguageSelect
        value={field.value}
        onSelect={helpers.setValue}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
      />
    </InputWrapper>
  );
};
