import { useCallback, useEffect, useState } from 'react';
import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { SpinnerBlock } from '@/components/Spinner';
import { ITableHeader, Table } from '@/components/table/Table';
import { fetchEndpointData } from '@/utils/fetch.client';
import { Button } from '@/components/button/Button';

const TABLE_HEADERS_QUEUE_STATS: ITableHeader[] = [
  {
    id: 'queue',
    name: 'Queue',
  },
  {
    id: 'waiting',
    name: 'Waiting',
  },
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'completed',
    name: 'Completed',
  },
  {
    id: 'failed',
    name: 'Failed',
  },
  {
    id: 'delayed',
    name: 'Delayed',
  },
];

// Type definition based on the updated endpoint response
interface QueueStats {
  stats: {
    [key: string]: number;
    wait: number;
    active: number;
    completed: number;
    failed: number;
    delayed: number;
  };
}

interface DocumentIndexingStats {
  stats: {
    [key: string]: number;
    created: number;
    pending: number;
    indexing: number;
    indexed: number;
  };
}

interface QueueStatsResponse {
  [key: string]: QueueStats | DocumentIndexingStats | number;

  documentIndexingStatus: DocumentIndexingStats;
  pendingEmbeddings: number;
  documentChunkCount: number;
}

export const InternalQueueMonitoringPage = () => {
  const [queueStats, setQueueStats] = useState<QueueStatsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchQueueStats = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await fetchEndpointData<QueueStatsResponse>('/api/v1/queue/stats');
      setQueueStats(result);
    } catch (err) {
      setError('Failed to fetch queue statistics');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchQueueStats();
  }, [fetchQueueStats]);

  const handleRefresh = () => {
    fetchQueueStats();
  };

  const queueStatsTableData = queueStats
    ? Object.entries(queueStats)
        .filter(([key, value]) => typeof value === 'object' && 'stats' in value)
        .filter(([key, _]) => key !== 'documentIndexingStatus') // Handle document indexing status separately
        .map(([key, value]) => {
          const queueValue = value as QueueStats;

          return {
            queue: key,
            waiting: queueValue.stats.wait || 0,
            active: queueValue.stats.active || 0,
            completed: queueValue.stats.completed || 0,
            failed: queueValue.stats.failed || 0,
            delayed: queueValue.stats.delayed || 0,
          };
        })
    : [];

  const documentIndexingStatusData = queueStats?.documentIndexingStatus?.stats
    ? Object.entries(queueStats.documentIndexingStatus.stats).map(([status, count]) => ({
        status: status.charAt(0).toUpperCase() + status.slice(1),
        count: count as number,
      }))
    : [];

  const title = 'Queue Monitor';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Backoffice',
              to: '/app/internal',
            },
            {
              name: title,
            },
          ]}
        />
      </div>

      <div className="mb-4">
        <Button onTrigger={handleRefresh} isDisabled={isLoading}>
          <p className="w-36">{isLoading ? 'Refreshing...' : 'Refresh Queue Status'}</p>
        </Button>
      </div>

      {isLoading && !queueStats && (
        <div>
          <SpinnerBlock message="Loading queue statistics..." className="h-64" />
        </div>
      )}

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          <p>{error}</p>
        </div>
      )}

      {queueStats && (
        <div className="space-y-8">
          <div>
            <h2 className="text-xl font-semibold mb-4">Queue Statistics</h2>
            <Table
              idKey="queue"
              headers={TABLE_HEADERS_QUEUE_STATS}
              data={queueStatsTableData}
              mapData={(entry) => {
                return [
                  <span className="whitespace-nowrap font-medium">{entry.queue}</span>,
                  <span className="whitespace-nowrap">{entry.waiting}</span>,
                  <span className="whitespace-nowrap">{entry.active}</span>,
                  <span className="whitespace-nowrap">{entry.completed}</span>,
                  <span className="whitespace-nowrap">{entry.failed}</span>,
                  <span className="whitespace-nowrap">{entry.delayed}</span>,
                ];
              }}
            />
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-4">Document Indexing Status</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {documentIndexingStatusData.map((entry) => (
                <div key={entry.status} className="bg-white p-4 rounded shadow">
                  <h3 className="text-lg font-medium mb-2">{entry.status}</h3>
                  <p className="text-2xl font-bold">{entry.count.toLocaleString()}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            {Object.entries(queueStats)
              .filter(([key, value]) => typeof value === 'number' && key !== 'pages')
              .map(([key, value]) => {
                return (
                  <div key={key} className="bg-white p-4 rounded shadow">
                    <h3 className="text-lg font-medium mb-2">{key}</h3>
                    <p className="text-2xl font-bold">
                      {typeof value === 'number' ? (value > 1000 ? value.toLocaleString() : value) : ''}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default InternalQueueMonitoringPage;
