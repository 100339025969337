export enum WorkspaceType {
  Knowledge = 'knowledge',
  DueDiligence = 'due-diligence',
  Playground = 'playground',
}

export function workspaceTypeToText(t: WorkspaceType): string {
  switch (t) {
    case WorkspaceType.Knowledge:
      return 'Knowledge';
    case WorkspaceType.DueDiligence:
      return 'Due Diligence';
    case WorkspaceType.Playground:
      return 'Playground';
  }
}
