import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTeam } from '@/app/team/context/TeamContext';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { SpinnerBlock } from '../../../components/Spinner';
import { Pagination } from '../../../components/Pagination';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as CategoryTemplatesResponseType } from '../endpoints/ListCategoryTemplatesEndpoint';
import { CategoryTemplateFormDialog } from './CategoryTemplateDialog';
import { Button } from '../../../components/button/Button';
import { CategoryTemplateCard } from '../components/CategoryTemplateCard';
import { IPaginationVariables, usePagination } from '@/hooks/usePagination';
import { CategoryTemplateListProvider, useCategoryTemplateList } from '../context/CategoryTemplateListProvider';

const CategoryTemplatesContent = () => {
  const { team } = useTeam();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const { refreshToken } = useCategoryTemplateList();

  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const query = new URLSearchParams();
      query.set('teamId', team.id);
      query.set('page', variables.page.toString());
      query.set('take', variables.take.toString());
      const result = await fetchEndpointData<CategoryTemplatesResponseType>(
        `/api/v1/category-template/list?${query.toString()}`,
      );
      return {
        data: result.items,
        pages: Math.ceil(result.items.length / variables.take),
      };
    },
    [team.id],
  );

  const pagination = usePagination({
    pageSize: 40,
    page: +(searchParams.get('page') ?? 0),
    fetcher: pageFetcher,
    refreshToken,
  });

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('page', pagination.variables.page.toString());
      return prev;
    });
  }, [pagination.variables.page, setSearchParams]);

  const templates = pagination.data ?? [];
  const title = 'Category Templates';

  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />
        <div className="flex gap-2">
          <Button variant="primary" onTrigger={() => setShowCreateDialog(true)}>
            Create Category Template
          </Button>
        </div>
      </div>

      <CategoryTemplateFormDialog
        isOpen={showCreateDialog}
        setIsOpen={setShowCreateDialog}
        mode="create"
        onSuccess={() => {
          setShowCreateDialog(false);
        }}
      />

      {!templates.length && pagination.isFetching ? (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      ) : !templates.length ? (
        <div className="text-center py-12">
          <p className="text-gray-600 text-lg mb-4">No category templates found</p>
          <p className="text-gray-500">
            Click the "Create Category Template" button above to create your first template
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4">
          {templates.map((template) => (
            <CategoryTemplateCard
              key={template.id}
              id={template.id}
              title={template.name}
              createdAt={template.createdAt}
              to={template.id}
            />
          ))}
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          page={pagination.variables.page}
          totalPages={pagination.pages}
        />
      </div>
    </div>
  );
};

export const CategoryTemplatesPage = () => {
  return (
    <CategoryTemplateListProvider>
      <CategoryTemplatesContent />
    </CategoryTemplateListProvider>
  );
};
