import { FieldArray, FormikProvider, useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  CaretDown as CaretDownIcon,
  CaretUp as CaretUpIcon,
  DownloadSimple as DownloadIcon,
  Trash as TrashIcon,
} from '@phosphor-icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import z from 'zod';
import React, { useState } from 'react';

import { InputField } from '@/components/input/InputField';
import { Button } from '@/components/button/Button';
import { getDisplayError } from '@/utils/get-display-error';
import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { nullthrows } from '@/utils/invariant';
import classNames from '@/utils/classnames';
import { TextAreaField } from '@/components/textarea/TextAreaField';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { SpinnerBlock } from '@/components/Spinner';
import { SimpleSelect } from '@/components/select/SimpleSelect';
import { CategoryManager, CategoryDeletionWarning } from '../components/CategoryManagement';
import type { ResponseType as QuestionsPresetResponseType } from '../endpoints/QuestionsPresetEndpoint';
import type { BodyType as DeleteQuestionsPresetsPayload } from '../endpoints/DeleteQuestionsPresetEndpoint';
import type {
  ResponseType as UpdateQuestionsPresetResponseType,
  BodyType as UpdateQuestionsPresetPayload,
} from '../endpoints/UpdateQuestionsPresetEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { useTeam } from '../../team/context/TeamContext';
import { useAuth } from '@/contexts/auth-context';
import { captureException } from '@sentry/react';
import { PresetTypeSelector } from '../components/PresetTypeSelector';
import { QuestionsPresetType } from '../enums';

export const presetExportSchema = z.object({
  name: z.string(),
  type: z.string(),
  categories: z.array(z.string()).optional(),
  questions: z.array(
    z.object({
      name: z.string(),
      question: z.string(),
      category: z.string().nullable().optional(),
      additionalContext: z.string(),
      answerInstructions: z.string(),
    }),
  ),
});
export type PresetExportSchemaType = z.infer<typeof presetExportSchema>;

const updatePresetQuestionSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
  question: Yup.string().min(1, 'Required').required('Required'),
  index: Yup.string(),
  category: Yup.string().nullable(),
  additionalContext: Yup.string(),
  answerInstructions: Yup.string(),
});

const updatePresetSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
  type: Yup.mixed().required('Required'),
  categories: Yup.array().of(Yup.string().required()),
  questions: Yup.array().of(updatePresetQuestionSchema).required('Required'),
});

interface IUpdatePresetQuestionProps {
  index: number;
  onDelete: () => void;
  isLast?: boolean;
  onMove: (offset: number) => void;
  categories: string[];
}

interface Question {
  index?: number;
  name: string;
  question: string;
  category: string | null;
  additionalContext: string;
  answerInstructions: string;
}

const UpdatePresetQuestion = (props: IUpdatePresetQuestionProps) => {
  const { index, onDelete, isLast, onMove, categories } = props;
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <div
      className={classNames('w-full flex gap-4', {
        'border-b border-dark-08 mb-8': !isLast,
      })}
    >
      <div className="flex-1">
        <InputField labelText="Display name" type="text" name={`questions.${index}.name`} />
        <InputField labelText="Question" type="text" name={`questions.${index}.question`} />

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Category (Optional)</label>
          <SimpleSelect
            placeholder="Select a category"
            items={[{ key: 'no-cat', name: 'No category' }, ...categories.map((c) => ({ key: c, name: c }))]}
            selectedItem={
              values.questions[index].category
                ? {
                    key: values.questions[index].category,
                    name: values.questions[index].category,
                  }
                : {
                    key: 'no-cat',
                    name: 'No category',
                  }
            }
            onSelect={(item) => {
              setFieldValue(`questions.${index}.category`, item?.key === 'no-cat' ? null : item?.key);
            }}
          />
        </div>

        <TextAreaField labelText="Additional context" name={`questions.${index}.additionalContext`} />
        <TextAreaField labelText="Additional answer instructions" name={`questions.${index}.answerInstructions`} />
      </div>

      <div className="pt-6 flex flex-col gap-2">
        <ConfirmDialog
          triggerText={<TrashIcon className="button-icon" />}
          aria-label="Delete question"
          title="Delete question"
          submitText="Delete"
          triggerVariant="destructive"
          submitVariant="destructive"
          description="Are you sure you want to delete this question?"
          onSubmit={onDelete}
        />
        <Button
          type="button"
          onTrigger={() => {
            onMove(-1);
          }}
          isDisabled={index === 0}
        >
          <CaretUpIcon className="button-icon" />
        </Button>
        <Button
          type="button"
          onTrigger={() => {
            onMove(1);
          }}
          isDisabled={isLast}
        >
          <CaretDownIcon className="button-icon" />
        </Button>
      </div>
    </div>
  );
};

interface UpdatePresetPageProps {
  preset: QuestionsPresetResponseType['preset'];
  mutate: () => void;
}

interface CategoryManagerProps {
  categories: string[];
  onAddCategory: (name: string) => void;
  onRemoveCategory: (category: string) => void;
}

const UpdatePresetPageImpl: React.FC<UpdatePresetPageProps> = (props) => {
  const { preset, mutate } = props;
  const navigate = useNavigate();
  const { me } = useAuth();
  const { team } = useTeam();

  const [showCategoryWarning, setShowCategoryWarning] = useState(false);
  const [categoryToRemove, setCategoryToRemove] = useState<string | null>(null);

  const extractCategories = (questions: Question[]): string[] => {
    return Array.from(
      new Set(questions.map((q) => q.category).filter((category): category is string => Boolean(category))),
    );
  };

  const formikbag = useFormik({
    initialValues: {
      name: preset.name,
      type: preset.type as QuestionsPresetType,
      categories: extractCategories(preset.questions),
      questions: preset.questions.map((q) => {
        return {
          index: q.index.toString(10),
          name: q.name,
          question: q.question,
          category: q.category || null,
          additionalContext: q.additionalContext,
          answerInstructions: q.answerInstructions,
        };
      }),
    },
    validationSchema: updatePresetSchema,
    onSubmit: async (values) => {
      try {
        const payload: UpdateQuestionsPresetPayload = {
          teamId: team.id,
          presetId: preset.id,
          data: {
            name: values.name,
            type: values.type,
            questions: values.questions.map((v, idx) => {
              return {
                ...v,
                index: idx,
                category: v.category || '',
                additionalContext: v.additionalContext,
                answerInstructions: v.answerInstructions,
              };
            }),
          },
        };
        await fetchEndpointData<UpdateQuestionsPresetResponseType>('/api/v1/questions-preset/update', {
          method: 'POST',
          body: payload,
        });
        mutate();
        toast.success('Preset updated');
      } catch (err: any) {
        captureException(err);
        toast.error('Could not update preset: ' + getDisplayError(err));
      }
    },
  });

  const { isSubmitting, values, handleSubmit, setFieldValue } = formikbag;
  return (
    <div className="page-content">
      <PageHeader title="Update preset" />

      <div className="mb-4 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Presets',
              to: '..',
            },
            {
              name: preset.name,
            },
          ]}
        />

        <div className="flex gap-2">
          {me.isSuperUser && (
            <Button
              iconLeft={<DownloadIcon className="button-icon" />}
              onTrigger={() => {
                const nameKey = preset.name
                  .toLowerCase()
                  .replace(/\s+/, '_')
                  .replace(/[^a-z_]/g, '');
                const json = JSON.stringify({
                  name: preset.name,
                  type: preset.type,
                  categories: values.categories,
                  questions: preset.questions.map((q) => {
                    return {
                      name: q.name,
                      question: q.question,
                      category: q.category || null,
                      additionalContext: q.additionalContext,
                      answerInstructions: q.answerInstructions,
                    };
                  }),
                });
                var blob = new Blob([json], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${nameKey}_preset_export.json`;
                link.click();
              }}
            >
              Export
            </Button>
          )}

          <ConfirmDialog
            triggerText="Delete"
            title="Delete preset"
            submitText="Delete"
            confirmationText={'Delete'}
            triggerVariant="destructive"
            submitVariant="destructive"
            description={`Are you sure you want to delete the preset ${preset.name}?`}
            onSubmit={async () => {
              try {
                const payload: DeleteQuestionsPresetsPayload = {
                  teamId: team.id,
                  presetIds: [preset.id],
                };
                await fetchEndpointData('/api/v1/questions-preset/delete', {
                  method: 'DELETE',
                  body: payload,
                });
                toast.success('Preset has been deleted');
                navigate('..');
              } catch (err) {
                captureException(err);
                toast.error('Could not delete preset: ' + getDisplayError(err));
              }
            }}
          />
        </div>
      </div>

      <div className="card">
        <FormikProvider value={formikbag}>
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="mb-4">
              <PresetTypeSelector
                onSelect={(v) => {
                  formikbag.setFieldValue('type', v);
                }}
                value={formikbag.values.type}
              />
            </div>

            <InputField labelText="Preset name" type="text" name="name" />

            {showCategoryWarning && categoryToRemove ? (
              <CategoryDeletionWarning
                category={categoryToRemove}
                questions={values.questions}
                onCancel={() => {
                  setShowCategoryWarning(false);
                  setCategoryToRemove(null);
                }}
                onConfirm={() => {
                  setFieldValue(
                    'categories',
                    values.categories.filter((c) => c !== categoryToRemove),
                  );

                  const updatedQuestions = values.questions.map((q) =>
                    q.category === categoryToRemove ? { ...q, category: null } : q,
                  );

                  setFieldValue('questions', updatedQuestions);
                  setShowCategoryWarning(false);
                  setCategoryToRemove(null);
                  toast.success('Category removed');
                }}
              />
            ) : (
              <CategoryManager
                categories={values.categories}
                onAddCategory={(name: string) => {
                  setFieldValue('categories', [...values.categories, name]);
                }}
                onRemoveCategory={(category: string) => {
                  const hasQuestions = values.questions.some((q) => q.category === category);

                  if (hasQuestions) {
                    setCategoryToRemove(category);
                    setShowCategoryWarning(true);
                  } else {
                    setFieldValue(
                      'categories',
                      values.categories.filter((c) => c !== category),
                    );
                    toast.success('Category removed');
                  }
                }}
              />
            )}

            <FieldArray
              name="questions"
              render={(arrayHelpers) => {
                return (
                  <div className="mb-4">
                    <div className="mt-4 font-medium mb-2">Questions</div>

                    {values.questions.length > 0
                      ? values.questions.map((_, index) => (
                          <UpdatePresetQuestion
                            key={index}
                            index={index}
                            categories={values.categories as string[]}
                            onDelete={() => {
                              arrayHelpers.remove(index);
                            }}
                            onMove={(offset) => {
                              const newIndex = index + offset;
                              if (newIndex < 0 || newIndex >= values.questions.length) {
                                return;
                              }
                              arrayHelpers.move(index, newIndex);
                            }}
                            isLast={index === values.questions.length - 1}
                          />
                        ))
                      : 'No questions, create at least one question'}

                    <div className="mt-4">
                      <Button
                        type="button"
                        onTrigger={() => {
                          arrayHelpers.push({
                            name: '',
                            question: '',
                            category: null,
                            additionalContext: '',
                            answerInstructions: '',
                          });
                        }}
                      >
                        Add Question
                      </Button>
                    </div>
                  </div>
                );
              }}
            />

            <div className="flex justify-end">
              <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                Update Preset
              </Button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </div>
  );
};

export const UpdatePresetPage = () => {
  const { presetId: _presetId } = useParams<{ presetId: string }>();
  const presetId = nullthrows(_presetId, 'Preset id not defined');
  const { data, error, isLoading, mutate } = useSWR<QuestionsPresetResponseType>(
    `/api/v1/questions-preset/get/${presetId}`,
    fetchEndpointData,
  );

  if (error) {
    throw error;
  }

  if (isLoading) {
    return <SpinnerBlock message="Loading preset..." />;
  }

  const preset = nullthrows(data?.preset, 'Preset not found');
  return <UpdatePresetPageImpl preset={preset} mutate={mutate} />;
};
