import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { CardLink } from '@/components/card/CardOld';

export const BackofficeRisksPage = () => {
  return (
    <div className="page-content">
      <PageHeader title="INTERNAL Risks" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Backoffice',
              to: '..',
            },
            {
              name: 'Risks',
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-4 gap-4">
        <CardLink to="/app/internal/risks/riskful-clauses" title="Riskful clauses" />
      </div>
    </div>
  );
};
