import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTeam } from '@/app/team/context/TeamContext';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { SpinnerBlock } from '../../../components/Spinner';
import { Pagination } from '../../../components/Pagination';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as WorkspacesResponseType } from '../endpoints/WorkspacesEndpoint';
import { IPaginationVariables, usePagination } from '@/hooks/usePagination';
import { workspaceTypeToText } from '../enums';
import { LinkButton } from '../../../components/button/Button';
import { WorkspaceCard, WorkspaceListProvider, useWorkspaceList } from '../../../components/card/WorkspaceCard';

const WorkspacesContent = () => {
  const { team } = useTeam();
  const [searchParams, setSearchParams] = useSearchParams();
  const { refreshToken } = useWorkspaceList();

  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const query = new URLSearchParams();
      query.set('teamId', team.id);
      query.set('page', variables.page.toString());
      query.set('take', variables.take.toString());
      const result = await fetchEndpointData<WorkspacesResponseType>(`/api/v1/workspace/list?${query.toString()}`);
      return {
        data: result.docs,
        pages: result.pagination.pages,
      };
    },
    [team.id],
  );
  const pagination = usePagination({
    pageSize: 40,
    page: +(searchParams.get('page') ?? 0),
    fetcher: pageFetcher,
    refreshToken: refreshToken,
  });

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('page', pagination.variables.page.toString());
      return prev;
    });
  }, [pagination.variables.page, setSearchParams]);

  const workspaces = pagination.data ?? [];
  const title = 'Workspaces';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />
        <div className="flex gap-2">
          <LinkButton to="new" variant="primary">
            Add new workspace
          </LinkButton>
        </div>
      </div>

      {!workspaces.length && pagination.isFetching ? (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      ) : !workspaces.length ? (
        <div className="text-center py-12">
          <p className="text-gray-600 text-lg mb-4">No workspaces found</p>
          <p className="text-gray-500">Click the "Add new workspace" button above to create your first workspace</p>
        </div>
      ) : (
        <div className="grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4">
          {workspaces.map((workspace) => (
            <WorkspaceCard
              key={workspace.id}
              id={workspace.id}
              title={workspace.name}
              type={workspaceTypeToText(workspace.type)}
              to={workspace.id}
            />
          ))}
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          page={pagination.variables.page}
          totalPages={pagination.pages}
        />
      </div>
    </div>
  );
};

export const WorkspacesPage = () => {
  return (
    <WorkspaceListProvider>
      <WorkspacesContent />
    </WorkspaceListProvider>
  );
};
