import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { useState, useEffect } from 'react';
import { ArrowLeft as ArrowLeftIcon } from '@phosphor-icons/react';

import { useTeam } from '@/app/team/context/TeamContext';
import { PageHeader } from '@/components/PageHeader';
import { getDisplayError } from '@/utils/get-display-error';
import { useAuth } from '@/contexts/auth-context';
import { fetchEndpointData } from '@/utils/fetch.client';
import { LanguageEnum } from '@/app/workspaceDocument/enums';

import type {
  BodyType as CreateWorkspacePayload,
  ResponseType as CreateWorkspaceResponseType,
} from '../../endpoints/CreateWorkspaceEndpoint';
import { CreateWorkspaceUsersPage, WorkspaceUser } from './UsersPage';
import { KnowledgeWorkspaceDetailsPage } from './KnowledgeWorkspaceDetailsPage';
import { DueDiligenceWorkspaceDetailsPage } from './DueDiligenceWorkspaceDetailsPage';
import { PlaygroundWorkspaceDetailsPage } from './PlaygroundWorkspaceDetailsPage';
import { WorkspaceTypeSelectionPage } from './TypeSelectionPage';
import { Button } from '@/components/button/Button';
import { WorkspaceType } from '../../enums';

export interface IWorkspaceFormValues {
  name: string;
  language: LanguageEnum;

  targetParty?: string;
  companyNumber?: string;
  industry?: string;
  coreActivities?: string;

  transactionStructure?: string;
  dealValue?: string;
  criticalElements?: string;
  knownIssues?: string;
}

export const CreateWorkspacePage = () => {
  const { team } = useTeam();
  const navigate = useNavigate();
  const { me } = useAuth();
  const [type, setType] = useState<WorkspaceType>(WorkspaceType.DueDiligence);
  const [users, setUsers] = useState<WorkspaceUser[]>([]);
  const [openStep, setOpenStep] = useState<'type' | 'details' | 'users'>('type');
  const [values, _setValues] = useState<IWorkspaceFormValues>({
    name: '',
    language: me.language as LanguageEnum,
  });
  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false);

  const setValues = (newValues: IWorkspaceFormValues) => {
    _setValues(newValues);
    setOpenStep('users');
  };

  useEffect(() => {
    setUsers([
      {
        id: me.id,
        name: me.name,
        permissions: [],
        isOwner: true,
      },
    ]);
  }, [me, team]);

  const handleCreateWorkspace = async () => {
    setIsCreatingWorkspace(true);
    try {
      const payload: CreateWorkspacePayload = {
        teamId: team.id,
        data: {
          type: type,
          name: values.name,
          language: values.language,
          targetParty: values.targetParty,
          companyNumber: values.companyNumber,
          industry: values.industry,
          coreActivities: values.coreActivities,

          transactionStructure: values.transactionStructure,
          dealValue: values.dealValue ? parseFloat(values.dealValue) : undefined,
          criticalElements: values.criticalElements,
          knownIssues: values.knownIssues,

          users: users.map((user) => ({
            id: user.id,
            name: user.name,
            permissions: user.permissions,
            isOwner: user.isOwner,
          })),
        },
      };

      const result = await fetchEndpointData<CreateWorkspaceResponseType>('/api/v1/workspace/create', {
        method: 'POST',
        body: payload,
      });

      navigate(`../${result.workspace.id}`);
      toast.success('Workspace created successfully');
    } catch (err) {
      captureException(err);
      console.error(err);
      toast.error('Failed to create workspace: ' + getDisplayError(err));
    }
    setIsCreatingWorkspace(false);
  };

  const title = 'New workspace';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="h-full">
        <div className="flex justify-between items-center mb-4">
          <Button
            variant="ghost"
            iconLeft={<ArrowLeftIcon className="button-icon" />}
            onTrigger={() => {
              switch (openStep) {
                case 'type':
                  navigate('../');
                  break;
                case 'details':
                  setOpenStep('type');
                  break;
                case 'users':
                  setOpenStep('details');
                  break;
              }
            }}
          >
            {openStep === 'type' ? 'Workspaces' : 'Go back'}
          </Button>
        </div>

        {openStep === 'type' && (
          <WorkspaceTypeSelectionPage
            onSelect={(newType) => {
              setType(newType);
              setOpenStep('details');
            }}
          />
        )}

        {openStep === 'details' && (
          <>
            {type === 'due-diligence' && (
              <DueDiligenceWorkspaceDetailsPage
                formValues={values}
                setFormValues={setValues}
                goBack={() => {
                  setOpenStep('type');
                }}
              />
            )}
            {type === 'playground' && (
              <PlaygroundWorkspaceDetailsPage
                formValues={values}
                setFormValues={setValues}
                goBack={() => {
                  setOpenStep('type');
                }}
              />
            )}
            {type === 'knowledge' && (
              <KnowledgeWorkspaceDetailsPage
                formValues={values}
                setFormValues={setValues}
                goBack={() => {
                  setOpenStep('type');
                }}
              />
            )}
          </>
        )}

        {openStep === 'users' && (
          <CreateWorkspaceUsersPage
            users={users}
            setUsers={setUsers}
            goBack={() => {
              setOpenStep('details');
            }}
            createWorkspace={handleCreateWorkspace}
            isSubmitting={isCreatingWorkspace}
          />
        )}
      </div>
    </div>
  );
};
