import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { User as UserIcon } from '@phosphor-icons/react';

import { useAuth } from '../contexts/auth-context';

interface IUserMenuItem {
  name: string;
  action: () => void;
}

export interface IUserMenuProps {}

export const UserMenu: React.FC<IUserMenuProps> = (props) => {
  const { me, logout } = useAuth();
  const navigate = useNavigate();

  const userNavigation: Array<IUserMenuItem> = useMemo(() => {
    return [
      {
        name: 'My Profile',
        action: () => navigate('/app/me'),
      },
      {
        name: 'Logout',
        action: () => logout(),
      },
    ];
  }, [logout]);

  const avatarUrl = useMemo(() => {
    if (me.image && me.imageHash) {
      return `/api/user/avatar/${me.id}/${me.imageHash}`;
    }
  }, [me]);

  return (
    <div className="flex items-center">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <span className="sr-only">Open user menu</span>
          {avatarUrl ? (
            <img className="h-8 w-8 rounded-full" src={avatarUrl} alt={me.name} title={me.name} />
          ) : (
            <div className="h-8 w-8 flex justify-center items-center rounded-full bg-gray-200">
              <UserIcon className="h-4 w-4" />
            </div>
          )}
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="z-10 mt-2 mr-2 w-48 origin-top-right rounded bg-white border border-gray-200 py-1"
            side="top"
          >
            {userNavigation.map((item) => (
              <DropdownMenu.Item key={item.name}>
                <button
                  onClick={item.action}
                  className="block w-full px-4 py-2 text-sm text-left text-dark-500 focus:outline-none hover:outline-none hover:bg-gray-200"
                >
                  {item.name}
                </button>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};
