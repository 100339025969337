import { UploadSimple as UploadIcon } from '@phosphor-icons/react';
import React, { useCallback, useRef } from 'react';
import toast from 'react-hot-toast';
import mime from 'mime';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Button, ButtonProps } from '@/components/button/Button';
import { useTeam } from '@/app/team/context/TeamContext';
import { isSkippedFilename } from './FullPageDropUpload';

export interface IUploadButtonProps {
  folderId: string | null;
  allowedTypes: string[];
  renderButton: (onClick: () => void) => React.ReactNode;
}

export const UploadButton: React.FC<IUploadButtonProps> = (props) => {
  const { folderId, allowedTypes, renderButton } = props;
  const { team } = useTeam();
  const { syncState } = useWorkspace();
  const inputEl = useRef<HTMLInputElement | null>(null);

  const onClickButton = () => {
    if (inputEl.current) {
      // @ts-ignore
      inputEl.current.dispatchEvent(new MouseEvent('click'));
    }
  };

  const handleFileUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let inputFiles = evt.target.files;
    if (!inputFiles) {
      return;
    }

    if (inputFiles.length > 0) {
      for (const file of inputFiles) {
        if (isSkippedFilename(file.name)) {
          continue;
        }

        const fileType = file.type || mime.getType(file.name) || 'application/octet-stream';

        const mbSize = file.size / 1024 / 1024;
        if (mbSize > team.uploadLimit) {
          toast.error(`File size ${mbSize}MB exceeds the upload limit ${team.uploadLimit}MB`);
          continue;
        }

        syncState.startUpload({
          folderId,
          file,
          fileType,
          folderPrefix: '',
        });
      }
    }

    evt.target.value = '';
  };

  return (
    <div>
      <input
        type="file"
        multiple={true}
        accept={allowedTypes.join(', ')}
        onChange={handleFileUpload}
        className="hidden"
        ref={inputEl}
      ></input>

      {renderButton(onClickButton)}
    </div>
  );
};

export interface ISimpleUploadButtonProps {
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  folderId: string | null;
  allowedTypes: string[];
}

export const SimpleUploadButton: React.FC<ISimpleUploadButtonProps> = (props) => {
  const { size, variant, folderId, allowedTypes } = props;

  const renderButton = useCallback(
    (onClick: () => void) => {
      return (
        <Button size={size} variant={variant} onTrigger={onClick}>
          <div className="flex items-center gap-1">
            <UploadIcon className="button-icon" />
            Upload Files
          </div>
        </Button>
      );
    },
    [size, variant],
  );

  return <UploadButton folderId={folderId} allowedTypes={allowedTypes} renderButton={renderButton} />;
};
