import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as RiskfulClausesDetailResponseType } from '../endpoints/RiskfulClausesDetailEndpoint';
import { Spinner } from '@/components/Spinner';

export const BackofficeRiskfulClausesDetailPage = () => {
  const { documentCategory: _documentCategory } = useParams<{ documentCategory: string }>();
  const documentCategory = _documentCategory ?? '';
  const { data, isLoading, error } = useSWR<RiskfulClausesDetailResponseType>(
    `/api/v1/internal/risks/riskful-clauses/${documentCategory}`,
    fetchEndpointData,
    {
      shouldRetryOnError: false,
      errorRetryCount: 0,
    },
  );

  const documentTypes = data?.docs ?? [];
  return (
    <div className="page-content">
      <PageHeader title="INTERNAL Risks" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Riskful clauses',
              to: '..',
            },
            {
              name: documentCategory,
            },
          ]}
        />
      </div>

      {error && <div>Error: {error.message}</div>}

      {isLoading && <Spinner size={8} />}

      <div className="grid gap-4">
        {documentTypes.map((v) => {
          return (
            <div key={v.id}>
              <div className="heading-two mb-2">Party name: {v.partyName}</div>

              <div>
                {v.clauses.map((clause, i) => {
                  return (
                    <div key={i} className="border-b border-gray-200 py-1">
                      {clause}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
