import React, { useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';

import toast from 'react-hot-toast';
import {
  Prohibit as BanIcon,
  DownloadSimple as DownloadIcon,
  Question as QuestionIcon,
  File as FileIcon,
  ArrowLeft as ArrowLeftIcon,
  PencilSimple as PencilIcon,
  Info as InfoIcon,
} from '@phosphor-icons/react';

import { WorkspacePresetRun } from '../../types';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { getDisplayError } from '@/utils/get-display-error';
import { DataField } from '@/components/DataField';
import { StatusDot } from '@/components/StatusDot';
import { PresetRunStatus } from '../../../../enums';
import type { BodyType as CancelPresetRunPayload } from '../../../../endpoints/CancelPresetRunEndpoint';
import type { BodyType as UpdateAnalysisPayload } from '../../../../endpoints/UpdateWorkspaceAnalysisEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { PresetRunDocumentsDialog } from './PresetRunDocumentsDialog';
import { captureException } from '@sentry/react';
import { FormDialog } from '@/components/dialog/FormDialog';
import { LabeledCheckbox } from '@/components/checkbox/LabeledCheckbox';
import { useWorkspaceCategories } from '@/app/workspaceCategory/contexts/WorkspaceCategoriesContext';
import { formatDateTime } from '@/utils/date';
import { MultiToggle } from '@/components/MultiToggle';
import { groupAnswers, GroupedAnswers } from './group-answers';
import { DisplayAnswersByQuestion } from './DisplayPerQuestion';
import { DisplayAnswersByDocument } from './DisplayPerDocument';
import { SingleDocReferenceSidecar } from './SingleDocReferenceSidecar';
import { downloadDocAnswersAsDocx } from './download-docx';
import { Button, LinkButton } from '@/components/button/Button';
import { downloadDocAnswersAsExcel } from './download-xlsx';
import { InfoDialog } from '@/components/dialog/InfoDialog';
import { useAppContext } from '@/contexts/app-context';
import { WorkspacePermissionWrapper } from '@/app/workspace/components/WorkspacePermissionWrapper';
import { WorkspacePermissionEnum } from '@/app/auth/enum';
import { InputWrapper } from '@/components/InputWrapper';
import { Input } from '@/components/input/Input';
import { z } from 'zod';
import { Tooltip } from '@/components/tooltip/Tooltip';
import { PageHeader } from '@/components/PageHeader';

interface IDocumentSelectProps {
  items: Array<{ id: string; name: string }>;
}

export const DocumentSelect: React.FC<IDocumentSelectProps> = (props) => {
  const { items } = props;
  const [field, _meta, helpers] = useField<string[]>('documents');
  const selectedItems = field.value;

  useEffect(() => {
    helpers.setTouched(true);
  }, [helpers]);

  return (
    <div>
      {items.map((v) => {
        const id = v.id;
        const name = v.name;
        const isChecked = field.value.includes(id);

        return (
          <div key={name}>
            <LabeledCheckbox
              labelText={name}
              isChecked={!!isChecked}
              onChange={(v) => {
                const value = new Set([...selectedItems]);
                if (v) {
                  value.add(id);
                } else {
                  // eslint-disable-next-line drizzle/enforce-delete-with-where
                  value.delete(id);
                }
                helpers.setValue([...value]);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

interface IDownloadWordDialogProps {
  answers: GroupedAnswers;
}

const DownloadWordDialog: React.FC<IDownloadWordDialogProps> = (props) => {
  const { answers } = props;

  const items = useMemo(() => {
    return [...answers.documents.values()].map((v) => {
      return {
        id: v.id,
        name: v.name,
      };
    });
  }, [answers]);

  return (
    <FormDialog
      showTrigger={true}
      triggerText="Word"
      triggerIconLeft={<DownloadIcon className="button-icon" />}
      title={`Export analysis result as Word`}
      submitText="Export"
      onSubmit={async (values) => {
        downloadDocAnswersAsDocx(answers, values.documents);
      }}
      initialValues={{
        documents: items.map((v) => v.id),
      }}
    >
      <div className="mb-4">
        <div className="label-text mb-2">Documents</div>
        <DocumentSelect items={items} />
      </div>
    </FormDialog>
  );
};

type ViewMode = 'question' | 'document';

const analysisNameSchema = z.string().min(1, 'Analysis name is required').max(200, 'Name cannot exceed 200 characters');

const SUBSTRING_MAX_LENGTH = 30;

export const OneByOneAnalysisResult: React.FC<{ presetRun: WorkspacePresetRun }> = (props) => {
  const { presetRun } = props;
  const { enableDebugMode } = useAppContext();

  const grouped = useMemo(() => groupAnswers(presetRun), [presetRun]);
  const docCount = grouped.documents.size;

  const { categoryMap } = useWorkspaceCategories();
  const [viewMode, setViewMode] = useState<ViewMode>(docCount < 2 ? 'document' : 'question');
  const [showFullVersion, setShowFullVersion] = useState(false);
  const [showTranslation, setShowTranslation] = useState(true);
  const [openRef, setOpenRef] = useState<string>('');
  const [openDebugDialog, setOpenDebugDialog] = useState<string>('');
  const [newAnalysisName, setNewAnalysisName] = useState('');

  const toggleOptions = useMemo(() => {
    return [
      {
        value: 'question',
        icon: <QuestionIcon className="w-4 h-4" />,
        title: 'Grouped per question',
      },
      {
        value: 'document',
        icon: <FileIcon className="w-4 h-4" />,
        title: 'Grouped per document',
      },
    ];
  }, []);

  const title = `Analysis: ${presetRun.name}`;
  const debugAnswer = grouped.groupedPerAnswerId.get(openDebugDialog);
  const categoryNames = presetRun.categories.map((v) => categoryMap.get(v)?.name).filter(Boolean);
  const isPending = presetRun.status === PresetRunStatus.Running;
  return (
    <>
      <PageHeader title={title} />

      <div className="flex">
        <div className="flex-1 p-8 overflow-y-auto h-without-topbar">
          <div className="flex justify-between mb-4">
            <div>
              <LinkButton shape="square" to="..">
                <ArrowLeftIcon className="w-4 h-4" />
              </LinkButton>
            </div>

            <div className="flex gap-2">
              {presetRun.status === PresetRunStatus.Finished && (
                <>
                  <DownloadWordDialog answers={grouped} />
                  <Button
                    iconLeft={<DownloadIcon className="button-icon" />}
                    onTrigger={() => {
                      downloadDocAnswersAsExcel(grouped);
                    }}
                  >
                    Excel
                  </Button>
                </>
              )}
            </div>
          </div>

          <div className="heading-one mb-4 flex items-center gap-2">
            <div>{presetRun.name}</div>
            <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.RunPresets]}>
              <ConfirmDialog
                triggerSize={6}
                triggerVariant="ghost"
                triggerText={<PencilIcon className="button-icon" />}
                title="Rename analysis"
                submitText="Rename"
                description={
                  <InputWrapper labelText="New analysis name">
                    <Input
                      value={newAnalysisName}
                      type="text"
                      placeholder={presetRun.name}
                      onChange={(value) => setNewAnalysisName(value)}
                    />
                  </InputWrapper>
                }
                onSubmit={async () => {
                  try {
                    analysisNameSchema.parse(newAnalysisName.trim());
                    if (newAnalysisName === presetRun.name) {
                      throw new Error('New analysis name is the same as the current name');
                    }
                    const payload: UpdateAnalysisPayload = {
                      analysisId: presetRun.id,
                      name: newAnalysisName,
                    };
                    await fetchEndpointData('/api/v1/workspace/analysis/update', {
                      method: 'POST',
                      body: payload,
                    });
                    setNewAnalysisName('');
                    window.location.reload();
                    toast.success('Analysis has been renamed');
                  } catch (err) {
                    captureException(err);
                    toast.error('Could not rename analysis: ' + getDisplayError(err));
                  }
                }}
              />
            </WorkspacePermissionWrapper>
          </div>

          <div className="mb-2 flex justify-between">
            <div className="flex flex-wrap gap-x-4 gap-y-2 items-center">
              <DataField title="Preset Type">Document</DataField>
              {presetRun.targetParty && (
                <DataField title="Target Party">
                  {presetRun.targetParty && presetRun.targetParty.length > SUBSTRING_MAX_LENGTH ? (
                    <div className="flex gap-1 items-center">
                      <div>{presetRun.targetParty.substring(0, SUBSTRING_MAX_LENGTH)}...</div>
                      <Tooltip text={presetRun.targetParty}>
                        <InfoIcon className="w-4 h-4" />
                      </Tooltip>
                    </div>
                  ) : (
                    presetRun.targetParty
                  )}
                </DataField>
              )}
              <DataField title="Status">
                <div className="flex gap-1 items-center">
                  <StatusDot
                    size={3}
                    color={
                      presetRun.status === PresetRunStatus.Running
                        ? 'blue'
                        : presetRun.status === PresetRunStatus.Canceled || presetRun.status === PresetRunStatus.Failed
                          ? 'red'
                          : 'green'
                    }
                    pulse={presetRun.status === PresetRunStatus.Running}
                  />
                  {presetRun.status}
                </div>
              </DataField>

              <DataField title="Started at">{formatDateTime(presetRun.createdAt)}</DataField>

              {enableDebugMode && presetRun.finishedAt && (
                <DataField title="Finished at">{formatDateTime(presetRun.finishedAt)}</DataField>
              )}

              <DataField title="Language">{presetRun.language.toUpperCase()}</DataField>

              <PresetRunDocumentsDialog presetRun={presetRun} />

              {categoryNames.length > 0 && <DataField title="Categories">{categoryNames.join(',')}</DataField>}
            </div>

            <div className="flex gap-2 items-center">
              {isPending && (
                <div>
                  <ConfirmDialog
                    triggerText={<BanIcon className="button-icon" />}
                    title="Cancel"
                    submitText="Cancel"
                    triggerVariant="destructive"
                    submitVariant="destructive"
                    description="Are you sure you want to cancel this analysis?"
                    onSubmit={async () => {
                      try {
                        const payload: CancelPresetRunPayload = {
                          presetRunId: presetRun.id,
                        };
                        await fetchEndpointData('/api/v1/workspace/preset-run/cancel', {
                          method: 'DELETE',
                          body: payload,
                        });
                        toast.success('Analysis canceled');
                      } catch (err) {
                        captureException(err);
                        toast.error('Could not cancel analysis: ' + getDisplayError(err));
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="pb-4 my-4 flex justify-between">
            <MultiToggle options={toggleOptions} value={viewMode} onChange={(v) => setViewMode(v as ViewMode)} />

            <div className="flex justify-center items-center gap-4">
              <label className="flex items-center gap-2">
                <Checkbox isChecked={showTranslation} onChange={setShowTranslation} />
                <div>Translated</div>
              </label>

              <label className="flex items-center gap-2">
                <Checkbox isChecked={showFullVersion} onChange={setShowFullVersion} />
                <div>Detailed</div>
              </label>
            </div>
          </div>

          {viewMode === 'question' && (
            <DisplayAnswersByQuestion
              answers={grouped}
              showDetailed={showFullVersion}
              showTranslation={showTranslation}
              openRef={openRef}
              onOpenRef={setOpenRef}
              onOpenDebugDialog={setOpenDebugDialog}
            />
          )}

          {viewMode === 'document' && (
            <DisplayAnswersByDocument
              answers={grouped}
              showDetailed={showFullVersion}
              showTranslation={showTranslation}
              openRef={openRef}
              onOpenRef={setOpenRef}
              onOpenDebugDialog={setOpenDebugDialog}
            />
          )}
        </div>

        {openRef && <SingleDocReferenceSidecar answerId={openRef} answers={grouped} openRef={setOpenRef} />}

        {debugAnswer && (
          <InfoDialog
            title={`Debug: ${debugAnswer.documentAnswerId}`}
            isOpen={true}
            setIsOpen={(newIsOpen) => {
              if (!newIsOpen) {
                setOpenDebugDialog('');
              }
            }}
          >
            <div>{`Answer id: ${debugAnswer.documentAnswerId}`}</div>
            <div>{`Document id: ${debugAnswer.documentId}`}</div>
            <div>{`Question name: ${grouped.questions.get(debugAnswer.questionId)?.name}`}</div>
            <div>{`Question: ${grouped.questions.get(debugAnswer.questionId)?.question}`}</div>
            <div>{`is relevant: ${debugAnswer.isRelevant}`}</div>
            <div>{`is loading: ${debugAnswer.isLoading}`}</div>
            <div>{`LLM job ids: ${debugAnswer.jobIds.join(', ')}`}</div>
          </InfoDialog>
        )}
      </div>
    </>
  );
};
