import useSWR from 'swr';

import { DialogContent, DialogRoot } from '@/components/dialog/Dialog';
import { fetchEndpointData } from '@/utils/fetch.client';
import { ResponseType as DocumentMetadataResponseType } from '../endpoints/DocumentMetadataEndpoint';
import { SpinnerBlock } from '@/components/Spinner';
import { FilePreview, IBbox } from './FilePreview';

export interface IVisualDocumentPreviewDialogProps {
  documentId: string;
  isOpen: boolean;
  onClose: () => void;
  bboxes: IBbox[];
}

export const VisualDocumentPreviewDialog: React.FC<IVisualDocumentPreviewDialogProps> = (props) => {
  const { documentId, isOpen, onClose, bboxes } = props;
  const { data: documentData, isLoading } = useSWR<DocumentMetadataResponseType>(
    `/api/v1/workspace/document/metadata/${documentId}`,
    fetchEndpointData,
  );

  const doc = documentData?.document;
  const fileUrl = doc?.hasFile ? `/api/v1/workspace/document/download/${doc.name}?fileId=${doc.id}` : null;

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          onClose();
        }
      }}
    >
      <DialogContent className="dialog-file-preview">
        {isLoading && <SpinnerBlock message="Loading document..." />}
        {!isLoading && !fileUrl && <div>Document has no visual file to show the preview on.</div>}
        {doc && fileUrl && (
          <FilePreview
            title={doc.name}
            mimetype={doc.mimetype ?? ''}
            fileUrl={fileUrl}
            pageNumber={bboxes[0]?.page}
            highlights={bboxes}
            customHeight="90vh"
          />
        )}
      </DialogContent>
    </DialogRoot>
  );
};
