import React, { Fragment, useEffect, useState } from 'react';
import { CaretRight as ChevronRightIcon, Bell as BellIcon } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

import { UserMenu } from '../UserMenu';
import { TeamSelector } from './TeamSelector';
import { Sidebar } from './Sidebar';
import { SidebarNavButton, type SidebarNavButtonProps } from '../button/SidebarNavButton';
import { useNotifications } from '@/app/notifications/context/WorkspaceContext';

export interface IDashboardProps {
  children: React.ReactNode;
  sidebarTopButtons: Array<SidebarNavButtonProps>;
  sidebarBottomButtons: Array<SidebarNavButtonProps>;
  breadcrumbButtons: Array<React.ReactNode>;
  statusIndicatorOverwrite?: React.ReactNode;
  topBarAdditions?: React.ReactNode;
  notificationsLink?: string;
}

export const TeamDashboardTemplate: React.FC<IDashboardProps> = (props) => {
  const {
    children,
    breadcrumbButtons,
    sidebarTopButtons,
    sidebarBottomButtons,
    statusIndicatorOverwrite,
    topBarAdditions,
    notificationsLink,
  } = props;
  const navigate = useNavigate();
  const { state } = useNotifications();
  const [count, setCount] = useState(state.notificationsCount);

  useEffect(() => {
    const disposable = state.onNotificationsCountChange((newCount) => {
      setCount(newCount);
    });
    setCount(state.notificationsCount);
    return () => {
      disposable.dispose();
    };
  }, [state]);

  return (
    <div className="flex w-full min-h-screen">
      <Sidebar>
        <div className="flex h-full flex-col justify-between">
          <div>
            <TeamSelector statusIndicatorOverwrite={statusIndicatorOverwrite} />

            <div className="grid mt-4">
              {sidebarTopButtons.map((v, idx) => {
                return <SidebarNavButton key={idx} icon={v.icon} to={v.to} title={v.title} isExternal={v.isExternal} />;
              })}
            </div>
          </div>

          <div className="grid">
            {sidebarBottomButtons.map((v, idx) => {
              return <SidebarNavButton key={idx} icon={v.icon} to={v.to} title={v.title} isExternal={v.isExternal} />;
            })}
          </div>
        </div>
      </Sidebar>

      <div className="w-full">
        <header className="relative h-14">
          <div className="fixed top-0 flex justify-between border-b border-b-gray-300 select-none p-2 bg-white h-14 w-no-sidebar z-topbar">
            <div className="flex items-center gap-2">
              {breadcrumbButtons.map((v, idx) => {
                return (
                  <Fragment key={idx}>
                    {v}
                    {idx < breadcrumbButtons.length - 1 && (
                      <div>
                        <ChevronRightIcon className="button-icon" />
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>

            {topBarAdditions}

            <div className="flex items-center gap-4">
              {!!notificationsLink && (
                <div
                  className="relative text-neutral-800 hover:text-blue-7 cursor-pointer"
                  onClick={() => {
                    navigate(notificationsLink);
                  }}
                >
                  <BellIcon className="w-6 h-6" />

                  {count > 0 && <div className="absolute top-0 right-0 rounded-full bg-red-7 w-3 h-3"></div>}
                </div>
              )}
              <UserMenu />
            </div>
          </div>
        </header>

        <main>{children}</main>
      </div>
    </div>
  );
};
