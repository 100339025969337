import { useEffect, useMemo, useState } from 'react';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { TreeNode } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { DocumentPickerDialogWithTrigger } from '@/app/workspaceDocument/components/DocumentPicker';

interface IPendingDoc {
  documentId: string;
  name: string;
}

interface IStartAnalysisFilePickerProps {
  selectedFiles: TreeNode[];
  onSelectionChange: (files: TreeNode[]) => void;
}

export const StartAnalysisFilePicker: React.FC<IStartAnalysisFilePickerProps> = (props) => {
  const { selectedFiles, onSelectionChange } = props;
  const [showPicker, setShowPicker] = useState(false);
  const { tree } = useWorkspace();
  const [documentsToEmit, setDocumentsToEmit] = useState<IPendingDoc[]>([]);

  const initialSelection = useMemo(() => {
    return selectedFiles.map((file) => file.id);
  }, [selectedFiles]);

  useEffect(() => {
    if (!documentsToEmit.length) {
      return;
    }

    const uniqueFiles = new Map<string, TreeNode>();
    let newDocumentsToEmit = new Map(documentsToEmit.map((v) => [v.documentId, v]));
    for (const docToEmit of documentsToEmit) {
      const node = tree.getNode(docToEmit.documentId);
      if (node) {
        if (node.type === 'document') {
          uniqueFiles.set(node.id, node);
        }

        // eslint-disable-next-line drizzle/enforce-delete-with-where
        newDocumentsToEmit.delete(docToEmit.documentId);
      }
    }

    for (const selectedFile of selectedFiles) {
      uniqueFiles.set(selectedFile.id, selectedFile);
    }

    setDocumentsToEmit([...newDocumentsToEmit.values()]);
    onSelectionChange([...uniqueFiles.values()]);
  }, [documentsToEmit, selectedFiles]);

  return (
    <>
      <div
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
        }}
      >
        <DocumentPickerDialogWithTrigger
          showRoot={true}
          triggerText="Select files to analyse"
          title="Select a folder"
          open={showPicker}
          onOpenChange={setShowPicker}
          multiSelect={true}
          onlyFolders={false}
          initialSelection={initialSelection}
          onSubmit={(selectedNodes) => {
            const selectedFiles = selectedNodes.filter((node) => node.type === 'document');
            const selectedFolders = selectedNodes.filter((node) => node.type === 'folder');

            const files = new Map<string, TreeNode>();
            for (const file of selectedFiles) {
              files.set(file.id, file);
            }

            for (const folder of selectedFolders) {
              for (const fileId of tree.getChildDocumentIds(folder.id, true)) {
                if (!files.has(fileId)) {
                  const file = tree.getNode(fileId);
                  if (file && file.type === 'document') {
                    files.set(file.id, file);
                  }
                }
              }
            }

            onSelectionChange([...files.values()]);
            setShowPicker(false);
          }}
        />
      </div>
    </>
  );
};
