import {
  ArrowLeft as ArrowLeftIcon,
  Bell as BellIcon,
  Checks as ChecksIcon,
  Check as CheckIcon,
  Trash as TrashIcon,
} from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/components/button/Button';
import { PageHeader } from '@/components/PageHeader';
import { useNotifications } from '../context/WorkspaceContext';
import { IPaginationVariables, usePagination } from '@/hooks/usePagination';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as ListNotificationsResponseType } from '../endpoints/ListNotificationsEndpoint';
import { SpinnerBlock } from '@/components/Spinner';
import { Pagination } from '@/components/Pagination';
import { useAuth } from '@/contexts/auth-context';
import classNames from '@/utils/classnames';

export const NotificationsPage = () => {
  const { state } = useNotifications();
  const [refreshToken, setRefreshToken] = useState(state.notificationsHash);
  const { me } = useAuth();

  useEffect(() => {
    const disposable = state.onNotificationsChange((newHash) => {
      setRefreshToken(newHash);
    });
    return () => disposable.dispose();
  }, [state]);

  const pageFetcher = useCallback(async (variables: IPaginationVariables) => {
    const params = new URLSearchParams();
    params.set('page', variables.page.toString(10));
    params.set('take', variables.take.toString());

    const result = await fetchEndpointData<ListNotificationsResponseType>(
      `/api/v1/notification/list?${params.toString()}`,
    );
    return {
      data: result.docs ?? [],
      pages: result.pagination.pages,
    };
  }, []);
  const pagination = usePagination({
    pageSize: 40,
    refreshToken,
    page: 0,
    fetcher: pageFetcher,
  });

  const notifications = pagination.data ?? [];
  return (
    <div>
      <PageHeader title="Notifications" />

      <div className="p-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-6">
            <Button
              shape="square"
              onTrigger={() => {
                window.history.back();
              }}
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </Button>

            <div className="heading-one">Notifications</div>
          </div>

          <div className="flex gap-4">
            {notifications.length > 0 && (
              <Button
                onTrigger={async () => {
                  try {
                    await fetchEndpointData<ListNotificationsResponseType>(`/api/v1/notification/mark-all-as-read`, {
                      method: 'POST',
                      body: {},
                    });
                  } catch (err) {
                    console.error(err);
                    toast.error('Failed to mark all as read');
                  }
                }}
                iconLeft={<ChecksIcon className="button-icon" />}
              >
                Mark all as read
              </Button>
            )}

            {me.isSuperUser && (
              <Button
                onTrigger={async () => {
                  await fetchEndpointData<ListNotificationsResponseType>(`/api/v1/notification/test`, {
                    method: 'POST',
                    body: {},
                  });
                }}
              >
                Test Notification
              </Button>
            )}
          </div>
        </div>

        {!!notifications.length && (
          <div className="grid gap-2 my-4">
            {notifications.map((v) => {
              return (
                <div
                  key={v.id}
                  className={classNames('card', {
                    'text-neutral-500': v.isRead,
                    'text-neutral-800': !v.isRead,
                  })}
                >
                  <div className="flex justify-between items-center">
                    <div className="font-medium flex items-center gap-2">
                      {v.title}
                      {!v.isRead && <div className="bg-blue-7 w-3 h-3 rounded-full"></div>}
                    </div>
                    <div className="flex gap-1">
                      <Button
                        shape="square"
                        variant="ghost"
                        size={8}
                        onTrigger={async () => {
                          try {
                            await fetchEndpointData(`/api/v1/notification/mark-as-read`, {
                              method: 'POST',
                              body: {
                                notificationId: v.id,
                              },
                            });
                          } catch (err) {
                            console.error(err);
                            toast.error('Failed mark notification as read');
                          }
                        }}
                      >
                        <CheckIcon className="w-4 h-4" />
                      </Button>
                      <Button
                        shape="square"
                        variant="ghost"
                        size={8}
                        onTrigger={async () => {
                          try {
                            await fetchEndpointData(`/api/v1/notification/delete`, {
                              method: 'POST',
                              body: {
                                notificationIds: [v.id],
                              },
                            });
                          } catch (err) {
                            console.error(err);
                            toast.error('Failed to delete notification');
                          }
                        }}
                      >
                        <TrashIcon className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                  <div>{v.description}</div>
                </div>
              );
            })}
          </div>
        )}

        {!notifications.length && !pagination.isFetching && (
          <div className="my-8 text-center">
            <div className="text-neutral-500">
              <BellIcon className="w-12 h-12 mx-auto mb-4" />
              <p className="text-lg font-medium">Your notification inbox is empty</p>
              <p className="mt-1">You'll see notifications here when there's activity in your account.</p>
            </div>
          </div>
        )}

        {!notifications.length && pagination.isFetching && (
          <div>
            <SpinnerBlock message="Loading..." className="h-screen" />
          </div>
        )}

        <div className="py-4">
          <Pagination
            hasPrevious={pagination.hasPrevious}
            previous={pagination.previous}
            hasNext={pagination.hasNext}
            next={pagination.next}
            isFetching={pagination.isFetching}
            page={pagination.variables.page}
            totalPages={pagination.pages}
          />
        </div>
      </div>
    </div>
  );
};
