import React, { useEffect, useMemo, useState } from 'react';
import classNames from '@/utils/classnames';
import { Bug as BugIcon, ArrowSquareOut as ArrowSquareOutIcon } from '@phosphor-icons/react';

import { Button, LinkButton } from '../../../../components/button/Button';
import { IMinimalDocument, IMinimalDocumentSearchMatch } from '../../websocket/types';
import { useAuth } from '../../../../contexts/auth-context';
import { DialogContent, DialogRoot } from '../../../../components/dialog/Dialog';
import { ResponseType as ChatMessagePartsResponseType } from '../../endpoints/WorkspaceChatMessagePartsEndpoint';

import { TREE_ROOT_ID } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { MarkdownText } from '@/components/markdown/Markdown';
import { VisualDocumentPreviewDialog } from '@/app/workspaceDocument/components/VisualDocumentPreviewDialog';
import { PREVIEW_MIMETYPES } from '@/app/workspaceDocument/components/FilePreview';

const renderText = (t: string) => t;

interface IChatReferencePreviewProps {
  document: IMinimalDocument;
  reference: {
    refId: number | null;
    documentId: string;
    matches: IMinimalDocumentSearchMatch[];
  };
  messageParts: ChatMessagePartsResponseType['parts'];
  isOpen: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const ChatReferencePreviewDialog: React.FC<IChatReferencePreviewProps> = (props) => {
  const { reference, document, isOpen, onOpenChange, messageParts } = props;
  const { me } = useAuth();
  const [showHidden, setShowHidden] = useState<string[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const hasPreviews = Boolean(document.hasFile && document.mimetype && PREVIEW_MIMETYPES.has(document.mimetype));

  useEffect(() => {
    if (!isOpen) {
      setShowHidden([]);
    }
  }, [isOpen]);

  const matches = reference.matches;
  const searchParams = new URLSearchParams();
  const mostRelevant = [...matches].sort((a, b) => a.questionDistance - b.questionDistance)[0];
  if (mostRelevant) {
    if (mostRelevant.pageNumber) {
      searchParams.set('pageNumber', mostRelevant.pageNumber.toString());
    }

    if (mostRelevant.pageRef) {
      searchParams.set('pageRef', mostRelevant.pageRef);
    }
  }

  searchParams.set('highlightChunks', [...new Set(matches.map((v) => v.chunkId))].join(';'));

  const maxSimilaritiesPerChunk = useMemo(() => {
    const maxSimilarities = new Map<string, number>();
    for (const msgPart of messageParts) {
      for (const chunk of msgPart.chunks) {
        const newMaxSimilarity = Math.max(maxSimilarities.get(chunk.chunkId) ?? 0, chunk.answerSimilarity);
        maxSimilarities.set(chunk.chunkId, newMaxSimilarity);
      }
    }
    return maxSimilarities;
  }, [messageParts]);

  const chunkIds = useMemo(() => {
    return new Set(matches.map((v) => v.chunkId));
  }, [matches]);

  const [_topChunkId, topSimilarity] = useMemo(() => {
    const filtered = [...maxSimilaritiesPerChunk.entries()].filter(([chunkId, similarity]) => {
      return chunkIds.has(chunkId);
    });
    const sorted = filtered.sort((a, b) => b[1] - a[1]);
    const topValue = sorted[0];
    return [topValue?.[0] ?? '', topValue?.[1] ?? 0];
  }, [maxSimilaritiesPerChunk, document.id]);

  const similarityTreshold = Math.max(topSimilarity - 0.1, 0.35);
  const irrelevanceTreshold = Math.max(topSimilarity - 0.2, 0);

  const [matchBlocks, irrelevanceBlocks] = useMemo(() => {
    let prevBlockWasIrrelevant = false;
    const irrelevanceBlocks: string[][] = [];
    const blocks: typeof matches = [];
    for (const match of matches) {
      const maxSimilarity = maxSimilaritiesPerChunk.get(match.chunkId) ?? 0;
      const isIrrelevantMatch = maxSimilarity < irrelevanceTreshold;

      if (isIrrelevantMatch) {
        if (prevBlockWasIrrelevant) {
          const lastIrrelevanceBlock = irrelevanceBlocks.pop() ?? [];
          lastIrrelevanceBlock.push(match.chunkId);
          irrelevanceBlocks.push(lastIrrelevanceBlock);
        } else {
          irrelevanceBlocks.push([match.chunkId]);
        }
      }

      if (isIrrelevantMatch) {
        if (!prevBlockWasIrrelevant || showHidden.includes(match.chunkId)) {
          blocks.push(match);
        }
      } else {
        blocks.push(match);
      }

      prevBlockWasIrrelevant = isIrrelevantMatch;
    }
    return [blocks, irrelevanceBlocks];
  }, [matches, showHidden, irrelevanceTreshold, maxSimilaritiesPerChunk]);

  const bboxes = useMemo(() => {
    const sortedMatches = matches
      .filter((v) => v.bboxes.length > 0)
      .sort((a, b) => {
        return (maxSimilaritiesPerChunk.get(b.chunkId) ?? 0) - (maxSimilaritiesPerChunk.get(a.chunkId) ?? 0);
      });
    if (sortedMatches.length > 0) {
      const topMatch = sortedMatches[0]!;
      return topMatch.bboxes;
    }
    return [];
  }, [matchBlocks]);

  return (
    <>
      <DialogRoot open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent className="dialog-content flex flex-col">
          <div className="overflow-y-auto">
            <h1 className="heading-one mb-4">{document.name}</h1>

            <div className="mt-8">
              {matchBlocks.map((m) => {
                const showDebugInfo = me.isSuperUser;
                const maxSimilarity = maxSimilaritiesPerChunk.get(m.chunkId) ?? 0;
                const isRelevantMatch = maxSimilarity > similarityTreshold;
                const isIrrelevantMatch = maxSimilarity < irrelevanceTreshold;

                const debugInfo = [];
                if (m.isOriginalMatch) {
                  debugInfo.push('Original match');
                  debugInfo.push(`Question similarity: ${Math.round(Math.max(0, 1 - m.questionDistance) * 100)}%`);
                }
                if (maxSimilarity > 0) {
                  debugInfo.push(`Answer similarity: ${Math.round(maxSimilarity * 100)}%`);
                }

                if (isIrrelevantMatch && !showHidden.includes(m.chunkId)) {
                  return (
                    <div
                      key={m.chunkId}
                      className="relative my-4"
                      onClick={() => {
                        const irrelevanceBlock = irrelevanceBlocks.find((v) => v.includes(m.chunkId)) ?? [];
                        setShowHidden((prev) => {
                          return [...new Set([...prev, ...irrelevanceBlock])];
                        });
                      }}
                    >
                      <div className="absolute rounded-full h-6 px-2 bg-gray-100 right-0 -top-3 cursor-pointer">
                        show
                      </div>
                      <div className="bg-gray-100 my-2 cursor-pointer" style={{ height: 1 }}></div>
                    </div>
                  );
                } else {
                  return (
                    <div key={m.chunkId} id={`dialog-chunk-${m.chunkId}`}>
                      {isIrrelevantMatch && (
                        <div key={m.chunkId} className="relative my-4">
                          <div
                            className="absolute rounded-full h-6 px-2 bg-gray-100 right-0 -top-3 cursor-pointer"
                            onClick={() => {
                              const irrelevanceBlock = new Set(
                                irrelevanceBlocks.find((v) => v.includes(m.chunkId)) ?? [],
                              );
                              setShowHidden((prev) => {
                                return prev.filter((v) => !irrelevanceBlock.has(v));
                              });
                            }}
                          >
                            hide
                          </div>
                          <div className="bg-gray-100 my-2 cursor-pointer" style={{ height: 1 }}></div>
                        </div>
                      )}

                      <div
                        className={classNames('whitespace-pre-line my-2 border-l-2 pl-2 text-dark-500', {
                          'border-transparent': !isRelevantMatch && !m.isOriginalMatch,
                          'border-blue-200': isRelevantMatch,
                          'border-gray-300': !isRelevantMatch && m.isOriginalMatch,
                        })}
                      >
                        <div>
                          <MarkdownText content={m.content} renderText={renderText} />
                        </div>
                        {showDebugInfo && debugInfo.length > 0 && (
                          <div className="flex gap-1 font-medium text-xs text-gray-500 mt-1">
                            <BugIcon className="w-4 h-4" />
                            <div>{debugInfo.join(', ')}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <div className="flex gap-2">
              <LinkButton
                variant="primary"
                to={`../../documents/${document.folderId ?? TREE_ROOT_ID}/${document.id}?${searchParams}`}
                iconLeft={<ArrowSquareOutIcon className="button-icon" />}
              >
                Open Document
              </LinkButton>

              {hasPreviews && (
                <Button
                  onTrigger={() => {
                    setShowPreview(true);
                  }}
                >
                  View Original PDF
                </Button>
              )}
            </div>

            <Button
              onTrigger={() => {
                onOpenChange(false);
              }}
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </DialogRoot>

      {bboxes.length > 0 && (
        <VisualDocumentPreviewDialog
          documentId={document.id}
          isOpen={showPreview}
          onClose={() => {
            setShowPreview(false);
          }}
          bboxes={bboxes}
        />
      )}
    </>
  );
};
