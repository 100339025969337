import { useEffect, useState } from 'react';

import classNames from '../utils/classnames';

export interface IDotLoaderProps {}

export const DotLoader: React.FC<IDotLoaderProps> = () => {
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFrame((prevFrame) => (prevFrame + 1) % 6);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const baseDotClasses = 'rounded-full bg-blue-500 transition-all duration-500 ease-in-out';

  const getSizeClasses = (dotIndex: number): string => {
    const isSmallFrame = frame % 2 === 0;
    const activeDotIndex = Math.floor(frame / 2);

    if (isSmallFrame) {
      return 'w-2 h-2';
    } else {
      return dotIndex === activeDotIndex ? 'w-3 h-3' : 'w-2 h-2';
    }
  };

  return (
    <div className="flex items-center space-x-1.5 h-3">
      {[0, 1, 2].map((index) => (
        <div key={index} className={classNames(baseDotClasses, getSizeClasses(index))}></div>
      ))}
    </div>
  );
};
