import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import {
  Bug as BugIcon,
  DownloadSimple as DownloadIcon,
  Eye as EyeIcon,
  ChatCircle as MessageCircleIcon,
  PencilSimple as PencilIcon,
  Trash as TrashIcon,
  User as UserIcon,
  Building as BuildingIcon,
  TextColumns as TextColumnsIcon,
  FileX as FileXIcon,
} from '@phosphor-icons/react';
import useSWR from 'swr';
import { captureException } from '@sentry/react';

import { PageHeader } from '../../../components/PageHeader';
import { nullthrows } from '../../../utils/invariant';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { FilePreview, PREVIEW_MIMETYPES } from '../components/FilePreview';
import { useAuth } from '../../../contexts/auth-context';
import { Button, LinkButton } from '../../../components/button/Button';
import { Tag } from '../../../components/Tag';
import { formatDate } from '../../../utils/date';
import { SummarizeDialog } from '../components/SummaryDialog';
import { InputDialog } from '../../../components/dialog/InputDialog';
import { StatusDot } from '../../../components/StatusDot';
import { SpinnerBlock } from '../../../components/Spinner';
import { BodyType as DeleteDocumentsPayload } from '../endpoints/DeleteWorkspaceDocumentsEndpoint';
import { BodyType as RenameDocumentPayload } from '../endpoints/RenameDocumentEndpoint';
import { ResponseType as DocumentContentResponseType } from '../endpoints/DocumentContentEndpoint';
import { ResponseType as DocumentMetadataResponseType } from '../endpoints/DocumentMetadataEndpoint';
import {
  ResponseType as DocumentParagraphIndexesResponseType,
  BodyType as DocumentParagraphIndexesPayload,
} from '../endpoints/WorkspaceDocumentParagraphIndexesEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { DocumentIndexingStatus } from '../enums';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { TreeNode } from '../tree/WorkspaceDocumentTree';
import { WorkspacePermissionEnum } from '@/app/auth/enum';
import { WorkspacePermissionWrapper } from '@/app/workspace/components/WorkspacePermissionWrapper';
import { DocumentTreeBreadcrumb } from '../components/DocumentTreeBreadcrumb';
import { useTeam } from '@/app/team/context/TeamContext';
import { InfoDialog } from '@/components/dialog/InfoDialog';
import { DocumentContent } from './content/DocumentContent';
import { DocumentDebugView } from './content/DocumentDebugView';
import { MultiToggle } from '@/components/MultiToggle';

const fetchParagraphIndexes = async ([endpoint, chunkIds]: [string, string[]]) => {
  const payload: DocumentParagraphIndexesPayload = {
    chunkIds,
  };
  const result = await fetchEndpointData<DocumentParagraphIndexesResponseType>(endpoint, {
    method: 'POST',
    body: payload,
  });
  return result;
};

export interface IDocumentComponentProps {
  document: DocumentMetadataResponseType['document'];
  documentContent: string | null;
  folderNode: TreeNode;
}

type ViewMode = 'text' | 'visual';

const DocumentComponent: React.FC<IDocumentComponentProps> = (props) => {
  const { document, documentContent } = props;
  const [searchParams] = useSearchParams();
  const { team } = useTeam();
  const { workspace } = useWorkspace();
  const [debugMode, setDebugMode] = useState(false);
  const { me } = useAuth();
  const navigate = useNavigate();

  const documentId = document.id;
  const pageNumber = searchParams.get('pageNumber');

  const highlightChunks = searchParams.get('highlightChunks');
  const { data: paragraphsToHighlightData } = useSWR(
    [`/api/v1/workspace/document/paragraph-indexes/${document.id}`, highlightChunks ? highlightChunks.split(';') : []],
    fetchParagraphIndexes,
  );
  const paragraphsToHighlight = paragraphsToHighlightData?.parahraphIndexes || [];

  const fileUrl = document.hasFile
    ? `/api/v1/workspace/document/download/${document.name}?fileId=${document.id}`
    : null;
  const hasPreviews = Boolean(document.hasFile && document.mimetype && PREVIEW_MIMETYPES.has(document.mimetype));
  const [shownDialog, setShownDialog] = useState('none');
  const [viewMode, setViewMode] = useState<ViewMode>(hasPreviews ? 'visual' : 'text');

  const isProcessing =
    document.indexingStatus !== DocumentIndexingStatus.Indexed &&
    document.indexingStatus !== DocumentIndexingStatus.Invalid;
  const isInvalidDocument = document.indexingStatus === DocumentIndexingStatus.Invalid;

  const toggleOptions = useMemo(() => {
    return [
      {
        value: 'text' as ViewMode,
        icon: <TextColumnsIcon className="w-4 h-4" />,
        title: 'View text value',
      },
      {
        value: 'visual' as ViewMode,
        icon: <EyeIcon className="w-4 h-4" />,
        title: hasPreviews ? 'View PDF' : 'No visual preview available',
        isDisabled: !hasPreviews,
      },
    ];
  }, [hasPreviews]);

  let contentNode = null;
  if (documentContent) {
    if (viewMode === 'visual' && fileUrl) {
      if (!fileUrl) {
        contentNode = <div className="text-gray-500 p-4">No visual preview available.</div>;
      } else {
        contentNode = (
          <FilePreview
            title={document.name}
            mimetype={document.mimetype ?? ''}
            fileUrl={fileUrl}
            pageNumber={pageNumber ? +pageNumber : null}
          />
        );
      }
    } else {
      contentNode = <DocumentContent content={documentContent} paragraphsToHighlight={paragraphsToHighlight} />;
    }
  } else if (isProcessing) {
    contentNode = <SpinnerBlock message="Processing document..." />;
  } else if (isInvalidDocument) {
    contentNode = (
      <div className="flex flex-col items-center text-danger-color-dark">
        <FileXIcon className="h-8 w-8 mb-4" />
        <div className="font-bold mb-2">Invalid Document</div>
        <div>{`Reason: ${document.failureReason ?? 'Unknown failure reason'}`}</div>
      </div>
    );
  } else {
    contentNode = <div className="text-gray-500 p-4">No content available.</div>;
  }

  return (
    <div className="page-content">
      <PageHeader title={document.name} />

      <div className="flex justify-between items-center max-w-full gap-4 mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Document',
            },
          ]}
        />

        <div className="flex gap-2 items-center">
          <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.Summarize]}>
            <SummarizeDialog title={document.name} documentId={documentId} language={me.language} />
          </WorkspacePermissionWrapper>
          <LinkButton title="Chat with document" to={`../../../chat?documents=${document.id}`}>
            <MessageCircleIcon className="button-icon" />
          </LinkButton>
          {fileUrl && (
            <LinkButton isExternal to={fileUrl} target="_blank">
              <DownloadIcon className="button-icon" />
            </LinkButton>
          )}
          {me.isSuperUser && (
            <Button onTrigger={() => setDebugMode(!debugMode)}>
              <BugIcon className="button-icon" />
            </Button>
          )}
          <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.DeleteDocuments]}>
            <ConfirmDialog
              triggerText={<TrashIcon className="button-icon" />}
              title="Delete document"
              submitText="Delete"
              confirmationText={'Delete'}
              triggerVariant="destructive"
              submitVariant="destructive"
              description={`Are you sure you want to delete ${document.name}?`}
              onSubmit={async () => {
                try {
                  const payload: DeleteDocumentsPayload = {
                    documentIds: [documentId],
                    workspaceId: workspace.id,
                  };
                  await fetchEndpointData('/api/v1/workspace/document/delete', {
                    method: 'DELETE',
                    body: payload,
                  });
                  toast.success('Document has been deleted');
                  navigate('..');
                } catch (err) {
                  captureException(err);
                  toast.error('Could not delete document: ' + getDisplayError(err));
                }
              }}
            />
          </WorkspacePermissionWrapper>
        </div>
      </div>

      <div className="mb-4">
        <DocumentTreeBreadcrumb
          nodeId={document.id}
          onSelect={(nodeId: string) => {
            if (nodeId === document.id) {
              return;
            }

            navigate(`/app/t/${team.id}/workspace/${workspace.id}/documents/${nodeId}`);
          }}
        />
      </div>

      <InfoDialog
        title="Parties"
        hideTrigger={true}
        isOpen={shownDialog === 'parties'}
        setIsOpen={(newOpen) => {
          if (!newOpen) {
            setShownDialog('none');
          } else {
            setShownDialog('parties');
          }
        }}
      >
        <div className="flex flex-col gap-2">
          {document.parties.map((party) => {
            return (
              <div key={party.id} className="flex items-center gap-2">
                {party.isPerson ? <UserIcon className="w-4 h-4" /> : <BuildingIcon className="w-4 h-4" />}
                <div>{party.name}</div>
                <div className="flex flex-wrap gap-2">
                  {party.isPerson && party.companyName && <Tag color="orange">{party.companyName}</Tag>}

                  {party.roles.map((role, roleIdx) => {
                    return (
                      <Tag color="blue" key={`${party.id}-role-${roleIdx}`}>
                        {role}
                      </Tag>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </InfoDialog>

      {debugMode ? (
        <div>
          <DocumentDebugView document={document} />
        </div>
      ) : (
        <div className="card">
          <div className="flex items-start justify-between gap-4 mb-4">
            <div className="flex items-center gap-2">
              <div className="heading-two">{document.name}</div>

              <InputDialog
                triggerText={<PencilIcon className="button-icon" />}
                triggerVariant="ghost"
                title="Rename document"
                description="Enter a new name for the document"
                labelText="New name"
                submitText="Rename"
                initialValue={document.name}
                onSubmit={async (value) => {
                  try {
                    const payload: RenameDocumentPayload = {
                      documentId,
                      newName: value,
                    };
                    await fetchEndpointData('/api/v1/workspace/document/rename', {
                      method: 'POST',
                      body: payload,
                    });
                    toast.success('Document name has been updated');
                  } catch (err) {
                    captureException(err);
                    toast.error('Could not update document name: ' + getDisplayError(err));
                  }
                }}
              />
            </div>

            <MultiToggle options={toggleOptions} value={viewMode} onChange={(v) => setViewMode(v as ViewMode)} />
          </div>

          <div className="flex justify-between items-center flex-wrap mb-4">
            <div className="flex items-center gap-2">
              {isProcessing && (
                <div className="flex items-center gap-1">
                  <StatusDot size={3} color="blue" pulse={true} />
                  Processing
                </div>
              )}
              <Tag color="blue">{formatDate(document.date ?? document.createdAt)}</Tag>
              {document.categories.map((c) => {
                return (
                  <Tag key={c.category.id} color="green">
                    {c.category.name}
                  </Tag>
                );
              })}
              {document.jurisdiction && <Tag color="red">{document.jurisdiction.toUpperCase()}</Tag>}
              {document.language && <Tag color="red">{document.language.toUpperCase()}</Tag>}
              {document.parties.length > 0 && (
                <Tag
                  color="blue"
                  onClick={() => {
                    setShownDialog('parties');
                  }}
                >
                  <UserIcon className="w-4 h-4 mr-1" />
                  {`${document.parties.length}`}
                </Tag>
              )}
            </div>
          </div>

          {contentNode}
        </div>
      )}
    </div>
  );
};

const NotFoundFallback: React.FC<{ text: string }> = (props) => {
  return (
    <div className="page-content">
      <Breadcrumb
        items={[
          {
            name: 'Documents',
            to: '../..',
          },
          {
            name: 'Not Found',
          },
        ]}
      />

      <p className="mt-4">{props.text}</p>
    </div>
  );
};

export const WorkspaceDocumentPage = () => {
  const { documentId: _documentId, folderId: _folderId } = useParams<{
    documentId: string;
    folderId: string;
  }>();
  const documentId = nullthrows(_documentId, 'documentId not defined in params');
  const folderId = nullthrows(_folderId, 'folderId not defined in params');

  const { tree: explorerTree } = useWorkspace();

  const {
    data: documentData,
    isLoading,
    mutate: mutateDocMetadata,
  } = useSWR<DocumentMetadataResponseType>(`/api/v1/workspace/document/metadata/${documentId}`, fetchEndpointData);
  const {
    data: documentContentData,
    isLoading: isLoadingContent,
    mutate: mutateDocContent,
  } = useSWR<DocumentContentResponseType>(`/api/v1/workspace/document/content/${documentId}`, fetchEndpointData);
  const isFetching = isLoading || isLoadingContent;

  const content = documentContentData?.content;
  const refetchDocument = useCallback(() => {
    if (!content) {
      mutateDocContent(undefined, {
        revalidate: true,
      });
    }

    mutateDocMetadata(undefined, {
      revalidate: true,
    });
  }, [mutateDocMetadata, content]);

  useEffect(() => {
    const getUpdatedAt = () => {
      const node = explorerTree.getDocumentNode(documentId);
      if (!node) {
        return 0;
      }

      const nodeUpdatedAt = node.updatedAt.getTime();
      const documentUpdatedAt = node.document?.updatedAt.getTime() ?? 0;
      return Math.max(nodeUpdatedAt, documentUpdatedAt);
    };
    let lastUpdatedAt = getUpdatedAt();
    const disposable = explorerTree.onTreeChange(() => {
      const updatedAt = getUpdatedAt();
      if (updatedAt !== lastUpdatedAt) {
        lastUpdatedAt = updatedAt;
        refetchDocument();
      }
    });
    return () => {
      disposable.dispose();
    };
  }, [explorerTree]);

  const document = documentData?.document;
  const folderNode = explorerTree.getFolderNode(folderId);
  if (!document || !folderNode) {
    if (isFetching || explorerTree.isSyncing) {
      return <SpinnerBlock message="Loading document..." />;
    } else {
      if (!document) {
        return <NotFoundFallback text="Document not found" />;
      }

      if (!folderNode) {
        return <NotFoundFallback text="Folder not found" />;
      }
    }
  }

  return (
    <DocumentComponent
      document={document}
      documentContent={documentContentData?.content || ''}
      folderNode={folderNode}
    />
  );
};
