import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import * as Yup from 'yup';
import useSWR from 'swr';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { getDisplayError } from '../../../utils/get-display-error';
import type {
  BodyType as UpdateWorkspacePermissionsPayload,
  ResponseType as UpdateWorkspacePermissionsResponseType,
} from '../endpoints/UpdateWorkspaceUserEndpoint';
import type { ResponseType as GetUserResponseType } from '../endpoints/WorkspaceUserEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { WorkspacePermissionsSelector } from '../components/WorkspacePermissionsSelector';
import { CheckboxField } from '@/components/checkbox/CheckboxField';
import { nullthrows } from '@/utils/invariant';
import { WorkspacePermissionEnum } from '@/app/auth/enum';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { captureException } from '@sentry/react';

const updateWorkspaceUserSchema = Yup.object().shape({
  permissions: Yup.array(Yup.string()).required('Required'),
  isOwner: Yup.boolean().required('Required'),
});

export const UpdateWorkspaceUserPage = () => {
  const navigate = useNavigate();
  const { workspace } = useWorkspace();
  const { userId: _userId } = useParams<{ userId: string }>();
  const userId = nullthrows(_userId, 'userId is required');

  const usersUrl = useResolvedPath('..');

  const { data, isLoading, mutate } = useSWR<GetUserResponseType>(
    `/api/v1/workspace/user/get?workspaceId=${workspace.id}&userId=${userId}`,
    fetchEndpointData,
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const user = nullthrows(data?.user, 'User not found');
  return (
    <div className="page-content">
      <PageHeader title={`Update workspace user ${user.name}`} />

      <div>
        <div className="mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Users',
                to: usersUrl.pathname,
              },
              {
                name: user.name,
              },
            ]}
          />
        </div>

        <div className="card">
          <Formik
            initialValues={{
              permissions: user.isOwner
                ? [...Object.values(WorkspacePermissionEnum)]
                : (user.permissions as WorkspacePermissionEnum[]),
              isOwner: user.isOwner,
            }}
            validationSchema={updateWorkspaceUserSchema}
            onSubmit={async (values) => {
              try {
                const payload: UpdateWorkspacePermissionsPayload = {
                  workspaceId: workspace.id,
                  userId,
                  newPermissions: values.permissions,
                  isOwner: values.isOwner,
                };
                await fetchEndpointData<UpdateWorkspacePermissionsResponseType>('/api/v1/workspace/user/update', {
                  method: 'POST',
                  body: payload,
                });
                mutate();
                navigate('..');
                toast.success('User has been updated');
              } catch (err: any) {
                captureException(err);
                toast.error('Could not update workspace user: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="heading-two mb-2">Permissions</div>

                  <div className="mb-4">
                    <WorkspacePermissionsSelector
                      value={values['permissions']}
                      onChange={(v) => setFieldValue('permissions', v)}
                    />
                  </div>

                  <CheckboxField labelText="Owner" name="isOwner" />

                  <Button type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                    Update Permissions
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
