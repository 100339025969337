import React, { useCallback, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { File as FileIcon, Scan as ScanIcon } from '@phosphor-icons/react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTeam } from '@/app/team/context/TeamContext';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { Pagination } from '../../../components/Pagination';
import { SpinnerBlock } from '../../../components/Spinner';
import { ITableHeader, Table } from '../../../components/table/Table';
import { formatDateTime } from '../../../utils/date';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { ResponseType as AuditLogsResponseType } from '../endpoints/ListWorkspaceAuditLogsEndpoint';

const TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Action',
  },
  {
    id: 'description',
    name: 'Description',
  },
  {
    id: 'references',
    name: 'References',
  },
  {
    id: 'creator',
    name: 'Creator',
  },
  {
    id: 'createdAt',
    name: 'Created at',
  },
];

interface IReferenceButtonProps {
  to: string;
  icon: React.ReactNode;
}

const ReferenceButton: React.FC<IReferenceButtonProps> = (props) => {
  const { to, icon } = props;

  return <Link to={to}>{icon}</Link>;
};

export const WorkspaceAuditLogsPage = () => {
  const { team } = useTeam();
  const [searchParams, setSearchParams] = useSearchParams();
  const { workspace } = useWorkspace();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const searchParams = new URLSearchParams();
      searchParams.set('workspaceId', workspace.id);
      searchParams.set('page', variables.page.toString());
      searchParams.set('take', variables.take.toString());

      const result = await fetchEndpointData<AuditLogsResponseType>(
        `/api/v1/workspace/audit-log/list?${searchParams.toString()}`,
      );
      return {
        data: result.docs ?? [],
        pages: result.pagination.pages,
      };
    },
    [team.id],
  );
  const pagination = usePagination({
    pageSize: 40,
    page: +(searchParams.get('page') ?? 0),
    fetcher: pageFetcher,
  });

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('page', pagination.variables.page.toString());
      return prev;
    });
  }, [pagination.variables.page]);

  const entries = pagination.data ?? [];
  const title = 'Audit Logs';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />
      </div>

      {(!!entries.length || !pagination.isFetching) && (
        <Table
          idKey="id"
          headers={TABLE_HEADERS}
          data={entries}
          mapData={(entry) => {
            return [
              <span className="whitespace-nowrap">{entry.action}</span>,
              <span className="whitespace-nowrap">{entry.description}</span>,
              <span className="whitespace-nowrap">
                {entry.documentId ? (
                  <ReferenceButton
                    icon={<FileIcon className="audit-log-reference-icon" />}
                    to={`/app/t/${team.id}/workspace/${workspace.id}/documents/redirect-to/${entry.documentId}`}
                  />
                ) : null}
                {entry.presetRunId ? (
                  <ReferenceButton
                    icon={<ScanIcon className="audit-log-reference-icon" />}
                    to={`/app/t/${team.id}/workspace/${workspace.id}/analysis/${entry.presetRunId}`}
                  />
                ) : null}
              </span>,
              <span className="whitespace-nowrap">{entry.user ? entry.user.name : '-'}</span>,
              <span className="whitespace-nowrap">{formatDateTime(entry.createdAt)}</span>,
            ];
          }}
        />
      )}

      {!entries.length && pagination.isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          totalPages={pagination.pages}
          page={pagination.variables.page}
        />
      </div>
    </div>
  );
};
