import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { AuthProvider } from './contexts/auth-context';
import { WebsocketProvider } from './contexts/websocket-context';

import { LoginPage } from './app/auth/pages/Login';
import { CreateTeamPage } from './app/backofficeTeam/pages/CreateTeam';
import { TeamsPage } from './app/team/pages/Teams';
import { TeamWrapper } from './app/team/pages/TeamWrapper';
import { WorkspaceChatPage } from './app/workspaceChat/pages/chat/WorkspaceChatPage';
import { TeamUsersPage } from './app/teamUser/pages/TeamUsers';
import { AddTeamUserPage } from './app/teamUser/pages/AddTeamUser';
import { SettingsPage } from './app/settings/pages/SettingsPage';
import { WorkspaceChatHistoryPage } from './app/workspaceChat/pages/WorkspaceChatHistory';
import { UserNotFoundPage } from './app/auth/pages/UserNotFound';
import { TeamSettingsPage } from './app/team/pages/TeamSettings';
import { MyProfilePage } from './app/user/pages/MyProfile';
import { UsagePage } from './app/usage/pages/Usage';
import { InternalTeamsPage } from './app/backofficeTeam/pages/InternalTeamsPage';
import { InternalTeamPage } from './app/backofficeTeam/pages/InternalTeamPage';
import { UserDashboard } from './components/page-templates/UserDashboard';
import { WorkspacesPage } from './app/workspace/pages/WorkspacesPage';
import { CreateWorkspacePage } from './app/workspace/pages/create/CreateWorkspacePage';
import { WorkspacePage } from './app/workspace/pages/workspace/WorkspacePage';
import { WorkspaceDocumentsPage } from './app/workspaceDocument/pages/WorkspaceDocumentsPage';
import { PresetsPage } from './app/questionsPreset/pages/PresetsPage';
import { CreatePresetPage } from './app/questionsPreset/pages/CreatePresetPage';
import { UpdatePresetPage } from './app/questionsPreset/pages/UpdatePresetPage';
import { WorkspacePresetRunsPage } from './app/workspacePresetRun/pages/WorkspacePresetRunsPage';
import { AnalysisStatePage } from './app/workspacePresetRun/pages/preset-run/WorkspaceAnalysisStatePage';
import { ApiKeysPage } from './app/apiKey/pages/ApiKeysPage';
import { CreateApiKeyPage } from './app/apiKey/pages/CreateApiKeyPage';
import { CategoryTemplatePage } from './app/category/pages/CategoryTemplatePage';
import { ChatRedirectComponent } from './app/workspaceChat/pages/ChatRedirect';
import { WorkspaceInsightsPage } from './app/workspace/pages/workspace/WorkspaceInsightsPage';
import { LoginFailedPage } from './app/auth/pages/LoginFailed';
import { TREE_ROOT_ID } from './app/workspaceDocument/tree/WorkspaceDocumentTree';
import { WorkspaceDocumentPage } from './app/workspaceDocument/pages/WorkspaceDocumentPage';
import { TeamDashboard, TeamDashboardWrapper } from './components/page-templates/TeamDashboard';
import { WorkspaceSettingsPage } from './app/workspaceSettings/pages/WorkspaceSettingsPage';
import { UpdateTeamUserPage } from './app/teamUser/pages/UpdateTeamUserPage';
import { WorkspaceUsersPage } from './app/workspaceUser/pages/WorkspaceUsersPage';
import { AddWorkspaceUserPage } from './app/workspaceUser/pages/AddWorkspaceUserPage';
import { UpdateWorkspaceUserPage } from './app/workspaceUser/pages/UpdateWorkspaceUserPage';
import { WorkspaceAuditLogsPage } from './app/workspaceAuditLog/pages/WorkspaceAuditLogsPage';
import { WorkspaceDocumentRedirectPage } from './app/workspaceDocument/pages/WorkspaceDocumentRedirectPage';
import { WorkspaceCategoriesPage } from './app/workspaceCategory/pages/WorkspaceCategoriesPage';
import { CategoryTemplatesPage } from './app/category/pages/CategoryTemplatesPage';
import { WorkspaceIntegrationsPage } from './app/workspaceIntegration/pages/IntegrationsPage';
import { KnowlexIntegrationPage } from './app/workspaceIntegration/knowlex/pages/KnowlexIntegrationPage';
import { DefaultErrorBoundary } from './components/ErrorFallback';
import { BackofficePage } from './app/backoffice/pages/BackofficePage';
import { InternalAuditLogsPage } from './app/backofficeAuditLog/pages/InternalAuditLogsPage';
import { InternalWorkflowsPage } from './app/backofficeWorkflow/pages/WorkflowsPage';
import { StartAnalysisPage } from './app/workspacePresetRun/pages/start-analysis/StartAnalysisPage';
import { StartDocumentAnalysisPage } from './app/workspacePresetRun/pages/start-analysis/StartDocumentAnalysisPage';
import { StartCrossDocumentAnalysisPage } from './app/workspacePresetRun/pages/start-analysis/StartCrossDocumentAnalysisPage';
import { MagicLoginPage } from './app/auth/pages/MagicAuth';
import { ValidateLoginTokenPage } from './app/auth/pages/ValidateLoginTokenPage';
import { InternalCategoriesPage } from './app/backofficeCategory/pages/CategoriesPage';
import { WorkspaceRisksPage } from './app/workspaceRisk/pages/WorkspaceRisksPage';
import { InternalQueueMonitoringPage } from '@/app/backofficeQueues/pages/QueueMonitoringPage';
import { WorkspaceHomePage } from './app/workspace/pages/WorkspaceHomePage';
import { WorkspaceDetailsPage } from './app/workspaceSettings/pages/WorkspaceDetailsPage';
import { BackofficeRisksPage } from './app/backofficeRisks/pages/InternalRisksPage';
import { BackofficeRiskfulClausesPage } from './app/backofficeRisks/pages/InternalCommonRiskfulClauses';
import { BackofficeRiskfulClausesDetailPage } from './app/backofficeRisks/pages/InternalRiskfulClauseDetails';
import { WorkspaceRiskPage } from './app/workspaceRisk/pages/RiskDetailPage';
import { NotificationsProvider } from './app/notifications/context/WorkspaceContext';
import { NotificationsPage } from './app/notifications/pages/NotificationsPage';

const AppWrapper = () => {
  return (
    <AuthProvider>
      <WebsocketProvider>
        <NotificationsProvider>
          <Outlet />
        </NotificationsProvider>
      </WebsocketProvider>
    </AuthProvider>
  );
};

const UserDashboardRoute = () => {
  return (
    <UserDashboard>
      <Outlet />
    </UserDashboard>
  );
};

const RoutesWrapper = () => {
  return (
    <DefaultErrorBoundary>
      <Outlet />
    </DefaultErrorBoundary>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="*" element={<RoutesWrapper />}>
      <Route path="app" element={<AppWrapper />}>
        <Route path="me" element={<UserDashboardRoute />}>
          <Route index element={<MyProfilePage />} />
        </Route>

        <Route path="internal" element={<UserDashboardRoute />}>
          <Route path="teams">
            <Route index element={<InternalTeamsPage />} />
            <Route path="new" element={<CreateTeamPage />} />
            <Route path=":internalTeamId" element={<InternalTeamPage />} />
          </Route>

          <Route path="categories" element={<InternalCategoriesPage />} />

          <Route path="audit-logs" element={<InternalAuditLogsPage />} />

          <Route path="workflows" element={<InternalWorkflowsPage />} />

          <Route path="queues" element={<InternalQueueMonitoringPage />} />

          <Route path="risks">
            <Route index element={<BackofficeRisksPage />} />
            <Route path="riskful-clauses">
              <Route index element={<BackofficeRiskfulClausesPage />} />
              <Route path=":documentCategory" element={<BackofficeRiskfulClausesDetailPage />} />
            </Route>
          </Route>

          <Route index element={<BackofficePage />} />
        </Route>

        <Route path="t">
          <Route path=":teamId" element={<TeamWrapper />}>
            <Route index element={<Navigate to="workspace" replace={true} />} />
            <Route
              path="usage"
              element={
                <TeamDashboard>
                  <UsagePage />
                </TeamDashboard>
              }
            />
            <Route path="categories">
              <Route
                index
                element={
                  <TeamDashboard>
                    <CategoryTemplatesPage />
                  </TeamDashboard>
                }
              />
              <Route
                path=":categoryTemplateId"
                element={
                  <TeamDashboard>
                    <CategoryTemplatePage />
                  </TeamDashboard>
                }
              />
            </Route>
            <Route path="settings" element={<TeamDashboardWrapper />}>
              <Route path="team" element={<TeamSettingsPage />} />
              <Route path="users">
                <Route index element={<TeamUsersPage />} />
                <Route path="edit/:userId" element={<UpdateTeamUserPage />} />
                <Route path="add">
                  <Route index element={<AddTeamUserPage />} />
                </Route>
              </Route>
              <Route path="api-keys">
                <Route index element={<ApiKeysPage />} />
                <Route path="new" element={<CreateApiKeyPage />} />
              </Route>
              <Route index element={<SettingsPage />} />
            </Route>
            <Route path="questions-preset" element={<TeamDashboardWrapper />}>
              <Route index element={<PresetsPage />} />
              <Route path="new" element={<CreatePresetPage />} />
              <Route path=":presetId" element={<UpdatePresetPage />} />
            </Route>
            <Route
              path="notifications"
              element={
                <TeamDashboard>
                  <NotificationsPage />
                </TeamDashboard>
              }
            />
            <Route path="workspace">
              <Route
                index
                element={
                  <TeamDashboard>
                    <WorkspacesPage />
                  </TeamDashboard>
                }
              />
              <Route
                path="new"
                element={
                  <TeamDashboard>
                    <CreateWorkspacePage />
                  </TeamDashboard>
                }
              />
              <Route path=":workspaceId" element={<WorkspacePage />}>
                <Route index element={<WorkspaceHomePage />} />
                <Route path="notifications" element={<NotificationsPage />} />
                <Route path="insights" element={<WorkspaceInsightsPage />} />
                <Route path="documents">
                  <Route index element={<Navigate to={TREE_ROOT_ID} replace={true} />} />
                  <Route path="redirect-to/:documentId" element={<WorkspaceDocumentRedirectPage />} />
                  <Route path=":folderId">
                    <Route index element={<WorkspaceDocumentsPage />} />
                    <Route path=":documentId" element={<WorkspaceDocumentPage />} />
                  </Route>
                </Route>
                <Route path="chat">
                  <Route index element={<ChatRedirectComponent />} />
                  <Route path="new" element={<ChatRedirectComponent prefix="../" />} />
                  <Route path="history" element={<WorkspaceChatHistoryPage />} />
                  <Route path=":chatId" element={<WorkspaceChatPage />} />
                </Route>
                <Route path="questions-preset">
                  <Route index element={<PresetsPage />} />
                  <Route path="new" element={<CreatePresetPage />} />
                  <Route path=":presetId" element={<UpdatePresetPage />} />
                </Route>
                <Route path="analysis">
                  <Route index element={<WorkspacePresetRunsPage />} />
                  <Route path="start">
                    <Route index element={<StartAnalysisPage />} />
                    <Route path="one-by-one" element={<StartDocumentAnalysisPage />} />
                    <Route path="cross-document" element={<StartCrossDocumentAnalysisPage />} />
                  </Route>
                  <Route path=":analysisId" element={<AnalysisStatePage />} />
                </Route>
                <Route path="categories" element={<WorkspaceCategoriesPage />} />
                <Route path="risks">
                  <Route index element={<WorkspaceRisksPage />} />
                  <Route path=":riskId" element={<WorkspaceRiskPage />} />
                </Route>
                <Route path="audit-logs">
                  <Route index element={<WorkspaceAuditLogsPage />} />
                </Route>
                <Route path="settings">
                  <Route index element={<WorkspaceSettingsPage />} />
                  <Route path="details" element={<WorkspaceDetailsPage />} />
                  <Route path="users">
                    <Route index element={<WorkspaceUsersPage />} />
                    <Route path="edit/:userId" element={<UpdateWorkspaceUserPage />} />
                    <Route path="add" element={<AddWorkspaceUserPage />} />
                  </Route>
                  <Route path="integrations">
                    <Route index element={<WorkspaceIntegrationsPage />} />
                    <Route path="knowlex" element={<KnowlexIntegrationPage />} />
                  </Route>
                </Route>
                <Route index path="*" element={<Navigate to="." replace={true} />} />
              </Route>
            </Route>
            <Route index path="*" element={<Navigate to="documents" replace={true} />} />
          </Route>
          <Route index path="*" element={<TeamsPage />} />
        </Route>
        <Route index path="*" element={<Navigate to="/app/t" replace={true} />} />
      </Route>
      ,
      <Route path="login" element={<LoginPage />} />,
      <Route path="magic-auth" element={<MagicLoginPage />} />,
      <Route path="validate-login-token" element={<ValidateLoginTokenPage />} />,
      <Route path="user-not-found" element={<UserNotFoundPage />} />,
      <Route path="auth-failed" element={<LoginFailedPage />} />,
      <Route index element={<Navigate to="/app/t" replace={true} />} />,
      <Route
        path="*"
        // TODO: Add some nicer looking 404 page
        element={
          <main className="p-2">
            <p>Page not found</p>
          </main>
        }
      />
    </Route>,
  ]),
);

export const AppRoutes = () => {
  return <RouterProvider router={router} />;
};
