// !!! This file is used both client and server side !!!

import { CustomError } from '../error/CustomError';
import { ErrorCode } from '../error/ErrorCodes';

export enum QuestionsPresetType {
  OneByOne = 'one-by-one',
  CrossDocument = 'cross-document',
}

export function getQuestionsPresetType(type: string): QuestionsPresetType {
  switch (type.toLowerCase().trim()) {
    case 'one-by-one':
      return QuestionsPresetType.OneByOne;
    case 'cross-document':
      return QuestionsPresetType.CrossDocument;
    default:
      throw new CustomError('Invalid QuestionsPresetType', ErrorCode.VALIDATION_ERROR);
  }
}
