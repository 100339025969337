import * as React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { nullthrows } from '@/utils/invariant';
import createContext from '@/contexts/create-context';
import { getWorkspaceStates, WorkspaceState } from './WorkspaceStates';
import { useTeam } from '@/app/team/context/TeamContext';
import { SpinnerBlock } from '@/components/Spinner';
import { getDisplayError } from '@/utils/get-display-error';
import type { ResponseType as WorkspaceResponseType } from '../endpoints/WorkspaceEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { TreeNode, WorkspaceDocumentTree } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { useAppContext } from '@/contexts/app-context';
import { useCallback } from 'react';

export type Workspace = WorkspaceResponseType['workspace'];

interface ProviderValue {
  workspace: Workspace;
  syncState: WorkspaceState;
  isFetching: boolean;
  isSyncingTree: boolean;
  tree: WorkspaceDocumentTree;
  treeKey: number;
  documents: TreeNode[];
  processingState: {
    processedCount: number;
    totalCount: number;
  };
  refreshWorkspace: () => Promise<void>;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface WorkspaceProviderProps {
  children?: React.ReactNode;
}

const _WorkspaceProvider: React.FC<{
  workspace: Workspace;
  refreshWorkspace: () => Promise<void>;
  children?: React.ReactNode;
}> = (props) => {
  const { workspace, refreshWorkspace, children } = props;
  const { team } = useTeam();
  const { versions } = useAppContext();
  const { workspaceId: _workspaceId } = useParams<{ workspaceId: string }>();
  const workspaceId = nullthrows(_workspaceId, 'document scan id not defined');

  const syncState = getWorkspaceStates().getState({
    workspaceId,
    teamId: team.id,
    fileTreeVersion: versions.fileTree,
  });
  const documentState = syncState.treeState;

  const [treeKey, setTreeKey] = React.useState(0);
  const [isSyncing, setIsSyncing] = React.useState(documentState.isSyncing);

  React.useEffect(() => {
    const disposable = documentState.onTreeChange(() => {
      setTreeKey((prev) => prev + 1);
    });
    const disposableTwo = documentState.onSyncStateChange((_newIsSyncing) => {
      setIsSyncing(_newIsSyncing);
    });
    setIsSyncing(documentState.isSyncing);
    setTreeKey((prev) => prev + 1);
    return () => {
      disposable.dispose();
      disposableTwo.dispose();
    };
  }, [documentState]);

  const documents = React.useMemo(() => {
    return [...documentState.entries.values()].filter((v) => !!v.document);
  }, [documentState, treeKey]);

  return (
    <ReactProvider
      value={{
        workspace,
        syncState,
        isFetching: isSyncing,
        tree: documentState,
        treeKey: treeKey,
        isSyncingTree: isSyncing,
        documents,
        processingState: {
          processedCount: 0,
          totalCount: 0,
        },
        refreshWorkspace,
      }}
    >
      {children}
    </ReactProvider>
  );
};

export const WorkspaceProvider: React.FC<WorkspaceProviderProps> = (props) => {
  const { children } = props;
  const { workspaceId: _workspaceId } = useParams<{ workspaceId: string }>();
  const workspaceId = nullthrows(_workspaceId, 'document scan id not defined');
  const { data, isLoading, error, mutate } = useSWR<WorkspaceResponseType>(
    `/api/v1/workspace/get/${workspaceId}`,
    fetchEndpointData,
  );

  const refreshWorkspace = useCallback(async () => {
    try {
      await mutate();
    } catch (error) {
      console.error('Failed to refresh workspace data:', error);
    }
  }, [mutate]);

  const workspace = data?.workspace;

  if (!workspace && isLoading) {
    return <SpinnerBlock message="Loading workspace..." />;
  }

  if (!workspace && error) {
    return <div>{getDisplayError(error)}</div>;
  }

  if (!workspace) {
    return <div>Workspace not found</div>;
  }

  return (
    <_WorkspaceProvider workspace={workspace} refreshWorkspace={refreshWorkspace}>
      {children}
    </_WorkspaceProvider>
  );
};

export const useWorkspace = useContext;
export const WorkspaceConsumer = ReactConsumer;
