import { Files as FilesIcon, Graph as GraphIcon } from '@phosphor-icons/react';

import { PageHeader } from '@/components/PageHeader';
import { Breadcrumb } from '@/components/Breadcrumb';
import { CardLink } from '@/components/card/CardOld';

export const StartAnalysisPage = () => {
  return (
    <div className="page-content">
      <PageHeader title="Start analysis" />

      <div className="mb-2 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Start analysis',
            },
          ]}
        />
      </div>

      <div className="mb-4">
        Quickly scan through documents using the analyse feature, select which type of analysis you want to get started.
      </div>

      <div className="grid grid-cols-2 gap-4">
        <CardLink
          title="One by one"
          to="one-by-one"
          icon={<FilesIcon className="w-12 h-12" />}
          centered={true}
          content={
            <div className="max-w-md">
              Individually analyze one or more documents of the same type (e.g., management agreement(s)) and receive a
              structured overview of the results for each document. Mixing different document types is not supported.
            </div>
          }
        />
        <CardLink
          title="Cross document (experimental)"
          to="cross-document"
          icon={<GraphIcon className="w-12 h-12" />}
          centered={true}
          content={
            <div className="max-w-md">
              Perform a combined analyses on multiple documents of different types (e.g., deed of incorporation and
              articles of association), to generate a single response.
            </div>
          }
        />
      </div>
    </div>
  );
};
