import React from 'react';

import classNames from '@/utils/classnames';

export interface ICheckboxProps {
  id?: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  tabIndex?: number;
  size?: 4 | 6;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const { id, onChange, isChecked, isDisabled, tabIndex, size = 4 } = props;

  return (
    <div
      className="cursor-pointer"
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        onChange(!isChecked);
      }}
    >
      <input
        id={id}
        type="checkbox"
        className="visually-hidden"
        onChange={(evt) => onChange(evt.target.checked)}
        checked={isChecked}
        disabled={isDisabled}
        tabIndex={tabIndex}
      ></input>

      <div
        className={classNames(
          'rounded text-white flex justify-center items-center p-1 focus:outline-primary-500 border border-gray-400',
          {
            'bg-gray-200': !isChecked,
            'bg-primary-500': isChecked,
            'w-4 h-4': size === 4,
            'w-6 h-6': size === 6,
          },
        )}
      >
        {isChecked ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            viewBox="0 0 256 256"
            className="w-full h-full"
          >
            <path
              d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"
              strokeWidth={16}
              stroke="currentColor"
            ></path>
          </svg>
        ) : null}
      </div>
    </div>
  );
};
