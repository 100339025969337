import * as React from 'react';
import toast from 'react-hot-toast';

import createContext from '@/contexts/create-context';
import { NotificationState, getNotificationState } from './NotificationState';
import { useAuth } from '@/contexts/auth-context';

interface ProviderValue {
  state: NotificationState;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface NotificationsProviderProps {
  children?: React.ReactNode;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = (props) => {
  const { children } = props;
  const { me } = useAuth();

  const state = getNotificationState(me.id);

  React.useEffect(() => {
    const disposable = state.onNotification((notification) => {
      toast(`New notification: ${notification.title}`);
    });
    return () => disposable.dispose();
  }, [state]);

  return (
    <ReactProvider
      value={{
        state,
      }}
    >
      {children}
    </ReactProvider>
  );
};

export const useNotifications = useContext;
export const NotificationsConsumer = ReactConsumer;
