// !!! This file is used both client and server side !!!

import { CustomError } from '../error/CustomError';
import { ErrorCode } from '../error/ErrorCodes';

export enum PresetRunStatus {
  Running = 'Running',
  Finished = 'Finished',
  Canceled = 'Canceled',
  Failed = 'Failed',
}

export function getPresetRunStatus(status: string): PresetRunStatus {
  switch (status.toLowerCase().trim()) {
    case 'running':
      return PresetRunStatus.Running;
    case 'finished':
      return PresetRunStatus.Finished;
    case 'canceled':
      return PresetRunStatus.Canceled;
    default:
      throw new CustomError(`Unknown analysis result status: ${status}`, ErrorCode.VALIDATION_ERROR);
  }
}
