import * as Yup from 'yup';
import { Formik } from 'formik';
import React from 'react';

import { Button } from '@/components/button/Button';
import { InputField } from '@/components/input/InputField';
import { LanguageSelectField } from '@/components/LanguageSelect';
import { IWorkspaceFormValues } from './CreateWorkspacePage';
import { TextAreaField } from '@/components/textarea/TextAreaField';
import { InfoDialog } from '@/components/dialog/InfoDialog';
import { LanguageEnum } from '@/app/workspaceDocument/enums';

const dueDiligenceInformationSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
  language: Yup.string().oneOf(Object.values(LanguageEnum)).required('Required'),

  targetParty: Yup.string().min(1, 'Required').required('Required'),
  industry: Yup.string().optional(),
  coreActivities: Yup.string().optional(),

  transactionStructure: Yup.string().optional(),
  dealValue: Yup.string().matches(/\d+/, 'Should be a number').optional(),
  criticalElements: Yup.string().optional(),
  knownIssues: Yup.string().optional(),
});

export interface IDueDiligenceWorkspaceDetailsPageProps {
  formValues: IWorkspaceFormValues;
  setFormValues: (values: IWorkspaceFormValues) => void;
  goBack: () => void;
}

export const DueDiligenceWorkspaceDetailsPage: React.FC<IDueDiligenceWorkspaceDetailsPageProps> = (props) => {
  const { formValues, setFormValues, goBack } = props;

  return (
    <div className="card">
      <div className="heading-two mb-4">Due diligence dataroom details</div>

      <Formik
        validationSchema={dueDiligenceInformationSchema}
        initialValues={{
          ...formValues,
          targetParty: formValues.targetParty ?? '',
          companyNumber: formValues.companyNumber ?? '',
          industry: formValues.industry ?? '',
          coreActivities: formValues.coreActivities ?? '',
          transactionStructure: formValues.transactionStructure ?? '',
          dealValue: formValues.dealValue ?? '',
          criticalElements: formValues.criticalElements ?? '',
          knownIssues: formValues.knownIssues ?? '',
        }}
        onSubmit={setFormValues}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <InputField type="text" name="name" labelText="Transaction name" placeholder="Project Gemma" />
              <LanguageSelectField name="language" labelText="Output language" />
            </div>

            <div className="flex gap-1 items-center heading-three mb-2">
              <span>Seller information</span>
              <InfoDialog title="Seller information">
                <div>
                  Enter information about the target (the seller). This information allows our models to perform more
                  accurate analysis.
                </div>
              </InfoDialog>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <InputField type="text" name="targetParty" labelText="Official Name" placeholder="Acme NV" />
              <InputField
                type="text"
                name="industry"
                labelText="Industry"
                placeholder="Software"
                helperText="Main industry the seller is active in."
              />
            </div>
            <InputField type="text" name="companyNumber" labelText="Company number" placeholder="0000.000.000" />
            <TextAreaField
              name="coreActivities"
              labelText="Core activities"
              helperText="Short description of the seller's professional activities."
            />

            <div className="flex gap-1 items-center heading-three mb-2">
              <span>Transaction information</span>
              <InfoDialog title="Transaction information">
                <div>
                  To put risks and analysis results in the context of the potential deal we need to have an idea about
                  it's size and any existing points of concern.
                </div>
              </InfoDialog>
            </div>
            <InputField
              type="text"
              name="transactionStructure"
              labelText="Transaction structure"
              placeholder="Asset Purchase"
            />
            <InputField type="number" name="dealValue" labelText="Estimated Deal Value" placeholder="10000000" />
            <TextAreaField
              name="criticalElements"
              labelText="Critical elements"
              helperText="Elements we should pay extra attention to during analysis (e.g. IP with a software company)."
            />
            <TextAreaField
              name="knownIssues"
              labelText="Known issues"
              helperText="Certain legal issues with the asset that are already known pre-analysis."
            />

            <div className="flex justify-between mt-4">
              <Button variant="default" onTrigger={goBack}>
                Previous
              </Button>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
