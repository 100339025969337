import React from 'react';

type LogoProps = {
  width?: number;
  height?: number;
  className?: string;
};

export const Logo: React.FC<LogoProps> = ({ width = 24, height = 24, className = '' }) => {
  // Calculate the aspect ratio to maintain proportions
  const aspectRatio = 190 / 159;
  const adjustedHeight = height;
  const adjustedWidth = width || Math.round(adjustedHeight * aspectRatio);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g fill="#303132" clipPath="url(#clip0_6_245)">
        <path d="M9.343 23.988a.24.24 0 0 1-.242-.326 6.7 6.7 0 0 0 .499-2.54c0-.792-.137-1.55-.391-2.256l-.646-1.324L.168.346A.24.24 0 0 1 .384 0h5.724c.182 0 .35.103.43.269l7.59 15.4c1.837 3.723-.731 8.055-4.787 8.319zM23.76 0h-5.28a.24.24 0 0 0-.24.24v23.04c0 .133.107.24.24.24h5.28a.24.24 0 0 0 .24-.24V.24a.24.24 0 0 0-.24-.24M2.88 24a2.88 2.88 0 1 0 0-5.76 2.88 2.88 0 0 0 0 5.76"></path>
      </g>
      <defs>
        <clipPath id="clip0_6_245">
          <path fill="#fff" d="M0 0h24v24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
