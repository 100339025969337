import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from '@/utils/classnames';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import {
  PaperPlaneTilt as SendIcon,
  NotePencil as NotePencilIcon,
  ListMagnifyingGlass,
  Paperclip as PaperclipIcon,
  Export as ExportIcon,
  File as FileIcon,
  Files as FilesIcon,
} from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { useChat } from '../useChat';
import { Button } from '../../../../components/button/Button';
import { nullthrows } from '../../../../utils/invariant';
import { BaseTextArea } from '../../../../components/textarea/BaseTextArea';
import { SpinnerBlock } from '../../../../components/Spinner';
import { DocumentPickerDialog } from '../../../workspaceDocument/components/DocumentPicker';
import { WorkspaceChatMessageComponent } from './WorkspaceChatMessageComponent';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { WorkspaceDocumentTree, TreeNode } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';

const TITLE_REPLACE_DELAY = 50;
const MAX_INPUT_HEIGHT = 250;
const MIN_INPUT_HEIGHT = 40;

const setDynamicInputHeight = (element: HTMLTextAreaElement) => {
  element.style.height = 'auto';

  const scrollHeight: number = element.scrollHeight;
  if (scrollHeight <= MAX_INPUT_HEIGHT) {
    element.style.height = Math.max(scrollHeight, MIN_INPUT_HEIGHT) + 'px';
  } else {
    element.style.height = MAX_INPUT_HEIGHT + 'px';
    element.style.overflow = 'auto';
  }
};

// Helper function to calculate selection info
const calculateSelectionInfo = (
  documentIds: string[],
  folderIds: string[],
  tree: WorkspaceDocumentTree | undefined,
): { singleDocName: string | null; docCount: number } => {
  if (!tree) {
    // Return count based on IDs if tree is not ready
    const count = documentIds.length;
    return { singleDocName: null, docCount: count };
  }

  // If exactly one document is selected and no folders
  if (documentIds.length === 1 && folderIds.length === 0) {
    const docId = documentIds[0]!;
    const node = tree.getDocumentNode(docId);
    if (node?.document) {
      return { singleDocName: node.name, docCount: 0 };
    }
    // If node not found, treat as count 1
    return { singleDocName: null, docCount: 1 };
  }

  // Otherwise, calculate total unique documents
  const uniqueDocIds = new Set<string>(documentIds);
  if (folderIds.length > 0) {
    for (const folderId of folderIds) {
      if (folderId) {
        const docsInFolder = tree.getChildDocumentIds(folderId, true);
        for (const docId of docsInFolder) {
          uniqueDocIds.add(docId);
        }
      }
    }
  }

  const totalDocuments = uniqueDocIds.size;
  return { singleDocName: null, docCount: totalDocuments };
};

export interface IChatPageProps {
  chatId: string;
  isNew: boolean;
  initialFolderFilters: string[];
  initialDocumentFilters: string[];
}

const ChatPage: React.FC<IChatPageProps> = (props) => {
  const { isNew, chatId, initialFolderFilters, initialDocumentFilters } = props;
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { tree } = useWorkspace();
  const [singleSelectedDocName, setSingleSelectedDocName] = useState<string | null>(null);
  const [documentsCount, setDocumentsCount] = useState<number>(0);
  const {
    state: chatState,
    sendMessage,
    setFilters,
    filterNodeIds,
  } = useChat(
    useMemo(() => {
      return {
        chatId: nullthrows(chatId, 'chatId not defined'),
        isNewChat: isNew,
        filters: {
          selectedFolderIds: initialFolderFilters,
          selectedDocumentIds: initialDocumentFilters,
        },
      };
    }, [chatId, isNew, initialFolderFilters, initialDocumentFilters]),
  );
  const [openRef, setOpenRef] = useState<string | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [pendingMessage, setPendingMessage] = useState('');
  const [title, setTitle] = useState(chatState.title);

  // Animated title transition with race condition handling
  useEffect(() => {
    const timeoutRefs = [] as NodeJS.Timeout[];

    // Store the original title and the new title
    const oldTitle = title;
    const newTitle = chatState.title;

    // First, remove the old title character by character
    let currentDisplayTitle = oldTitle;
    let charIndex = oldTitle.length;

    // Function to remove characters one by one
    const removeChar = () => {
      if (charIndex > 0) {
        charIndex--;
        currentDisplayTitle = oldTitle.substring(0, charIndex);
        setTitle(currentDisplayTitle);
        const timeoutId = setTimeout(removeChar, TITLE_REPLACE_DELAY);
        timeoutRefs.push(timeoutId);
      } else {
        // When removal is complete, start adding new title
        charIndex = 0;
        addChar();
      }
    };

    // Function to add characters one by one
    const addChar = () => {
      if (charIndex < newTitle.length) {
        charIndex++;
        currentDisplayTitle = newTitle.substring(0, charIndex);
        setTitle(currentDisplayTitle);
        const timeoutId = setTimeout(addChar, TITLE_REPLACE_DELAY);
        timeoutRefs.push(timeoutId);
      }
    };

    // Start the animation if there's a title to animate
    if (oldTitle) {
      removeChar();
    } else {
      // If there was no previous title, just add the new one
      charIndex = 0;
      addChar();
    }

    // Cleanup function to clear any remaining timeouts when component unmounts
    // or when a new effect run replaces this one
    return () => {
      timeoutRefs.forEach((id) => clearTimeout(id));
    };
  }, [chatState.title]);

  // Effect to update selection info based on initial filters and tree availability
  useEffect(() => {
    const { singleDocName, docCount } = calculateSelectionInfo(initialDocumentFilters, initialFolderFilters, tree);
    setSingleSelectedDocName(singleDocName);
    setDocumentsCount(docCount);
  }, [initialDocumentFilters, initialFolderFilters, tree]);

  const messages = chatState.messages;
  useEffect(() => {
    if (messages.length) {
      setSearchParams(
        (prev) => {
          // eslint-disable-next-line drizzle/enforce-delete-with-where
          prev.delete('new');
          return prev;
        },
        { replace: true },
      );
    }
  }, [messages.length]);

  // Get the last message and its finished status outside the effect
  const lastMessage = messages[messages.length - 1];
  const lastMessageIsFinished = lastMessage?.isFinished ?? false;

  // Scroll effect - scrolls the window
  useEffect(() => {
    // Check if the last message is finished - no need to check ref anymore
    if (lastMessageIsFinished) {
      const animationFrameId = requestAnimationFrame(() => {
        // Scroll window to the bottom
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      });

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
    // Trigger only when the last message finishes (length change covered by subsequent finish)
  }, [lastMessageIsFinished]);

  const firstUserMessageContent = useMemo(() => {
    const firstUserMsg = messages.find((msg) => msg.userId !== 'system');
    return firstUserMsg?.content;
  }, [messages]);

  const handleMessageSendAction = () => {
    const lastMessage = chatState.messages[chatState.messages.length - 1];
    if (lastMessage && !lastMessage.isFinished) {
      toast.error('Already generating a message');
      return;
    }

    const trimmedPendingMessage = pendingMessage.trim();
    if (trimmedPendingMessage.length > 0) {
      sendMessage(trimmedPendingMessage);
    }
    setPendingMessage('');
  };

  const handleShareChat = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success('Link to the chat copied to clipboard. You can now share it with your colleagues.');
      })
      .catch((error) => {
        console.error('Failed to copy link:', error);
        toast.error('Failed to copy link to clipboard.');
      });
  };

  // Selection info display - simplified to just show document count
  const renderSelectionInfo = () => {
    if (singleSelectedDocName) {
      return (
        <div className="px-3 py-1 text-xs text-neutral-500 flex items-center gap-1 overflow-hidden">
          <FileIcon className="w-3.5 h-3.5 flex-shrink-0 text-neutral-400" />
          <span className="truncate" title={singleSelectedDocName}>
            {singleSelectedDocName}
          </span>
        </div>
      );
    }

    if (documentsCount > 0) {
      return (
        <div className="px-3 py-1 text-xs text-neutral-500 flex items-center gap-1 overflow-hidden">
          <FilesIcon className="w-3.5 h-3.5 flex-shrink-0 text-neutral-400" />
          <span>
            {documentsCount} document{documentsCount !== 1 ? 's' : ''} selected
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col h-without-topbar bg-white">
      <div className="fixed w-no-sidebar z-topbar flex items-center justify-center py-4 bg-white">
        <div className="absolute left-4 flex gap-2">
          <Link
            to="../history"
            className="flex items-center justify-center w-8 h-8 rounded-md hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-dark-07 focus:ring-offset-2 transition-colors duration-200"
            title="Chat history"
          >
            <ListMagnifyingGlass size={20} className="text-neutral-800" />
          </Link>
          <Link
            to="../"
            className="flex items-center justify-center w-8 h-8 rounded-md hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-dark-07 focus:ring-offset-2 transition-colors duration-200"
            title="Back to chat"
          >
            <NotePencilIcon size={20} className="text-neutral-800" />
          </Link>
        </div>
        <h1
          className="font-inter font-bold text-base leading-[160%] text-neutral-800 truncate max-w-[calc(100%-150px)] px-2"
          title={title}
        >
          {title || '\u00A0'}
        </h1>
        <div className="absolute right-4">
          <button
            onClick={handleShareChat}
            className="flex items-center justify-center w-8 h-8 rounded-md hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-dark-07 focus:ring-offset-2 transition-colors duration-200"
            title="Share chat"
          >
            <ExportIcon size={20} className="text-neutral-800" />
          </button>
        </div>
      </div>

      <div className="flex-grow flex justify-center">
        <div className="w-chat max-w-no-sidebar flex flex-col">
          <DocumentPickerDialog
            initialSelection={filterNodeIds}
            open={showFilterDialog}
            onOpenChange={setShowFilterDialog}
            multiSelect={true}
            showRoot={true}
            onSubmit={(selectedNodes: TreeNode[]) => {
              const selectedFolderIds = selectedNodes.map((v) => v.folder?.id).filter(Boolean) as string[];
              const selectedDocumentIds = selectedNodes.map((v) => v.document?.id).filter(Boolean) as string[];

              // Use the helper function to update state
              const { singleDocName, docCount } = calculateSelectionInfo(selectedDocumentIds, selectedFolderIds, tree);
              setSingleSelectedDocName(singleDocName);
              setDocumentsCount(docCount);

              setFilters({
                selectedFolderIds,
                selectedDocumentIds,
              });
              setShowFilterDialog(false);
            }}
          />

          <div ref={chatContainerRef} className="flex-grow flex flex-col pt-14">
            {messages.length > 0 ? (
              <div className="flex flex-col gap-4 p-4">
                {messages.map((msg) => {
                  return (
                    <WorkspaceChatMessageComponent
                      key={msg.messageId}
                      msg={msg}
                      openRef={openRef}
                      setOpenRef={setOpenRef}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center text-center px-4 h-full">
                {chatState.isFetching ? (
                  <SpinnerBlock message="Loading chat session..." />
                ) : (
                  <div className="flex flex-col gap-2 items-center w-fit h-fit mb-8">
                    <h1 className="font-inter text-3xl font-medium text-neutral-800">Welcome to Jurimesh</h1>
                    <h2 className="font-inter text-xl font-normal text-neutral-500">How can I help you today?</h2>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="p-4 w-full sticky bottom-0 bg-white">
            <div className="relative p-1 pt-1 bg-white rounded-2xl border border-gray-200 shadow-md flex flex-col gap-2">
              {renderSelectionInfo()}
              <BaseTextArea
                placeholder={
                  chatState.isFetching
                    ? 'Loading...'
                    : messages.length > 1
                      ? 'Ask follow up...'
                      : 'Ask a question to start a new conversation...'
                }
                autoFocus={true}
                rows={1}
                className={classNames(
                  'w-full overflow-hidden resize-none bg-transparent focus:outline-none placeholder:text-neutral-400 placeholder:leading-relaxed text-neutral-800 border-none',
                  {
                    'animate-pulse': chatState.isFetching,
                  },
                )}
                style={{
                  height: MIN_INPUT_HEIGHT,
                }}
                autoComplete="off"
                disabled={chatState.isFetching}
                value={pendingMessage}
                onChange={(evt) => {
                  setPendingMessage(evt.currentTarget.value);
                }}
                onInput={(evt) => {
                  setDynamicInputHeight(evt.target as HTMLTextAreaElement);
                }}
                onBlur={(evt) => {
                  evt.target.style.height = `${MIN_INPUT_HEIGHT}px`;
                }}
                onFocus={(evt) => {
                  setDynamicInputHeight(evt.target as HTMLTextAreaElement);
                }}
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter' && evt.shiftKey === false) {
                    evt.preventDefault();
                    evt.stopPropagation();

                    handleMessageSendAction();
                  }
                }}
              />
              <div className="w-full flex justify-between items-center px-3 pb-3">
                <Button onTrigger={() => setShowFilterDialog(true)} variant="default" shape="square">
                  <div className="relative">
                    <PaperclipIcon className="w-5 h-5" />
                    {filterNodeIds.length > 0 && (
                      <div className="absolute -top-1.5 -right-1.5 w-2 h-2 bg-blue-500 rounded-full" />
                    )}
                  </div>
                </Button>
                <Button onTrigger={handleMessageSendAction} variant="primary" shape={'square'}>
                  <SendIcon className="w-5 h-5" />
                </Button>
              </div>
            </div>
            <div className="text-xs text-neutral-500 text-center mt-3">
              The chat can make mistakes, make sure to verify answers.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WorkspaceChatPage = () => {
  const { chatId: _chatId } = useParams<{ chatId: string }>();
  const [searchParams] = useSearchParams();
  const folderParam = searchParams.get('folders');
  const documentParam = searchParams.get('documents');

  const initialFolderFilters = useMemo(() => {
    return folderParam?.split(';') ?? [];
  }, [folderParam]);
  const initialDocumentFilters = useMemo(() => {
    return documentParam?.split(';') ?? [];
  }, [documentParam]);

  const isNewChat = searchParams.get('new') === 'true';
  const chatId = nullthrows(_chatId, 'chatId not defined');
  return (
    <ChatPage
      chatId={chatId}
      isNew={isNewChat}
      initialFolderFilters={initialFolderFilters}
      initialDocumentFilters={initialDocumentFilters}
    />
  );
};
