import React, { useState, ReactNode } from 'react';
import { CaretDown, CaretRight } from '@phosphor-icons/react';

interface ExpandableProps {
  // Required props
  title: ReactNode;
  children: ReactNode;

  isExpanded?: boolean;
  onExpandToggle?: (expanded: boolean) => void;
  itemCount?: number;
  itemLabel?: string;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;

  caretSize?: number;
  caretClassName?: string;

  rightElement?: ReactNode;
  titlePrefix?: ReactNode;

  withHeaderBg?: boolean;
}

export const Expandable: React.FC<ExpandableProps> = ({
  title,
  children,
  isExpanded: isExpandedProp,
  onExpandToggle,
  itemCount,
  itemLabel = 'items',
  className = 'border border-gray-200 rounded-md overflow-hidden mb-3',
  headerClassName = 'font-medium p-2 cursor-pointer hover:bg-gray-200 flex items-center gap-1',
  contentClassName = 'p-3',
  caretSize = 4,
  caretClassName = 'text-gray-500',
  rightElement,
  titlePrefix,
  withHeaderBg = true,
}) => {
  const [internalExpanded, setInternalExpanded] = useState(true);
  const isExpanded = isExpandedProp !== undefined ? isExpandedProp : internalExpanded;

  const handleToggle = () => {
    const newState = !isExpanded;
    if (onExpandToggle) {
      onExpandToggle(newState);
    } else {
      setInternalExpanded(newState);
    }
  };

  return (
    <div className={className}>
      <div className={withHeaderBg ? 'bg-gray-100 border-b border-gray-200' : ''}>
        <div className={headerClassName} onClick={handleToggle}>
          {titlePrefix}

          {isExpanded ? (
            <CaretDown className={`w-${caretSize} h-${caretSize} ${caretClassName}`} />
          ) : (
            <CaretRight className={`w-${caretSize} h-${caretSize} ${caretClassName}`} />
          )}

          <div className="flex flex-wrap gap-1 items-center">
            <div className="text-wrap hyphens-auto">{title}</div>

            {itemCount !== undefined && (
              <div className="text-gray-500 text-sm ml-2">
                ({itemCount} {itemLabel})
              </div>
            )}
          </div>

          {rightElement && <div className="ml-auto">{rightElement}</div>}
        </div>
      </div>

      {isExpanded && <div className={contentClassName}>{children}</div>}
    </div>
  );
};

export function useExpandableState(initialIds?: string[]) {
  const [expandedIds, setExpandedIds] = useState<Set<string>>(initialIds ? new Set(initialIds) : new Set());

  const isExpanded = (id: string) => expandedIds.has(id);

  const toggleExpanded = (id: string) => {
    setExpandedIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        // eslint-disable-next-line drizzle/enforce-delete-with-where
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const expandAll = (ids: string[]) => {
    setExpandedIds(new Set(ids));
  };

  const collapseAll = () => {
    setExpandedIds(new Set());
  };

  const toggleAll = (ids: string[]) => {
    if (ids.every((id) => expandedIds.has(id))) {
      collapseAll();
    } else {
      expandAll(ids);
    }
  };

  return {
    expandedIds,
    isExpanded,
    toggleExpanded,
    expandAll,
    collapseAll,
    toggleAll,
  };
}

export const ExpandAllButton: React.FC<{
  allIds: string[];
  expandedIds: Set<string>;
  onToggle: (ids: string[]) => void;
  label?: string;
  collapsedLabel?: string;
  className?: string;
}> = ({
  allIds,
  expandedIds,
  onToggle,
  label = 'Expand All',
  collapsedLabel = 'Collapse All',
  className = 'px-3 py-1 bg-gray-100 hover:bg-gray-200 rounded-md text-sm font-medium flex items-center gap-1',
}) => {
  const allExpanded = allIds.length > 0 && allIds.every((id) => expandedIds.has(id));

  return (
    <button className={className} onClick={() => onToggle(allIds)}>
      {allExpanded ? (
        <>
          <CaretDown className="w-4 h-4" />
          {collapsedLabel}
        </>
      ) : (
        <>
          <CaretRight className="w-4 h-4" />
          {label}
        </>
      )}
    </button>
  );
};
