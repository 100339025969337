import { Navigate } from 'react-router-dom';

import { useAuth } from '../../../contexts/auth-context';
import { SELECTED_TEAM_KEY } from '../../../constants.client';

export const TeamsPage = () => {
  const { myTeams } = useAuth();

  const lastSelectedTeamId = localStorage.getItem(SELECTED_TEAM_KEY);
  const firstTeam = lastSelectedTeamId
    ? (myTeams.find((v) => v.team.id === lastSelectedTeamId) ?? myTeams[0])
    : myTeams[0];
  if (!firstTeam) {
    return <div>Not part of any team.</div>;
  } else {
    return <Navigate to={`/app/t/${firstTeam.team.id}`} replace={true} />;
  }
};
