import React, { useState } from 'react';
import {
  Prohibit as BanIcon,
  DownloadSimple as DownloadIcon,
  Warning as WarningIcon,
  ArrowLeft as ArrowLeftIcon,
} from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { Checkbox } from '@/components/checkbox/Checkbox';
import { Button, LinkButton } from '@/components/button/Button';
import { WorkspacePresetRun } from '../../types';
import { DataField } from '@/components/DataField';
import { StatusDot } from '@/components/StatusDot';
import { getDisplayError } from '@/utils/get-display-error';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import {
  AggregatedAnswersResults,
  downloadAnswersAsDocx,
  IAggregatedAnswer,
  IDoc,
  useGroupedAnswers,
} from './AggregatedAnswerResults';
import { PresetRunStatus } from '../../../../enums';
import type { BodyType as CancelPresetRunPayload } from '../../../../endpoints/CancelPresetRunEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { captureException } from '@sentry/react';
import { useWorkspaceCategories } from '@/app/workspaceCategory/contexts/WorkspaceCategoriesContext';
import { formatDateTime } from '@/utils/date';
import { CrossDocumentReferenceSidecar } from './CrossDocumentReferenceSidecar';
import { Tooltip } from '@/components/tooltip/Tooltip';
import { Info as InfoIcon } from '@phosphor-icons/react/dist/csr/Info';
import { useAppContext } from '@/contexts/app-context';
import { z } from 'zod';
import { WorkspacePermissionWrapper } from '@/app/workspace/components/WorkspacePermissionWrapper';
import { WorkspacePermissionEnum } from '@/app/auth/enum';
import { PencilSimple as PencilIcon } from '@phosphor-icons/react/dist/csr/PencilSimple';
import { InputWrapper } from '@/components/InputWrapper';
import { Input } from '@/components/input/Input';
import type { BodyType as UpdateAnalysisPayload } from '@/app/workspacePresetRun/endpoints/UpdateWorkspaceAnalysisEndpoint';
import { InfoDialog } from '@/components/dialog/InfoDialog';
import { PageHeader } from '@/components/PageHeader';

const analysisNameSchema = z.string().min(1, 'Analysis name is required').max(200, 'Name cannot exceed 200 characters');

const SUBSTRING_MAX_LENGTH = 30;

export const CrossDocumentAnalysisResultPage: React.FC<{ presetRun: WorkspacePresetRun }> = (props) => {
  const { presetRun } = props;
  const { enableDebugMode } = useAppContext();
  const { categoryMap } = useWorkspaceCategories();
  const grouped = useGroupedAnswers(presetRun);
  const [openDoc, setOpenDoc] = useState<IDoc | null>(null);
  const [debugAnswer, setDebugAnswer] = useState<IAggregatedAnswer | null>(null);
  const [showMinified, setShowMinified] = useState(false);
  const [showTranslated, setShowTranslated] = useState(true);
  const [newAnalysisName, setNewAnalysisName] = useState('');

  const title = `Analysis: ${presetRun.name}`;
  const categoryNames = presetRun.categories.map((v) => categoryMap.get(v)?.name).filter(Boolean);
  const answerCount = Object.keys(grouped).length;
  const isPending = presetRun.status === PresetRunStatus.Running;
  return (
    <>
      <PageHeader title={title} />

      <div className="flex">
        <div className="flex-1 p-8 overflow-y-auto h-without-topbar">
          <div className="flex justify-between mb-4">
            <div>
              <LinkButton shape="square" to="..">
                <ArrowLeftIcon className="w-4 h-4" />
              </LinkButton>
            </div>

            <div className="flex gap-2">
              {presetRun.status === PresetRunStatus.Finished && answerCount > 0 && (
                <>
                  <Button
                    iconLeft={<DownloadIcon className="button-icon" />}
                    onTrigger={() => {
                      downloadAnswersAsDocx(grouped);
                    }}
                  >
                    Word
                  </Button>
                </>
              )}
            </div>
          </div>

          <div className="heading-one mb-4 flex items-center gap-2">
            <div>{presetRun.name}</div>
            <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.RunPresets]}>
              <ConfirmDialog
                triggerSize={6}
                triggerVariant="ghost"
                triggerText={<PencilIcon className="button-icon" />}
                title="Rename analysis"
                submitText="Rename"
                description={
                  <InputWrapper labelText="New analysis name">
                    <Input
                      value={newAnalysisName}
                      type="text"
                      placeholder={presetRun.name}
                      onChange={(value) => setNewAnalysisName(value)}
                    />
                  </InputWrapper>
                }
                onSubmit={async () => {
                  try {
                    analysisNameSchema.parse(newAnalysisName.trim());
                    if (newAnalysisName === presetRun.name) {
                      throw new Error('New analysis name is the same as the current name');
                    }
                    const payload: UpdateAnalysisPayload = {
                      analysisId: presetRun.id,
                      name: newAnalysisName,
                    };
                    await fetchEndpointData('/api/v1/workspace/analysis/update', {
                      method: 'POST',
                      body: payload,
                    });
                    setNewAnalysisName('');
                    window.location.reload();
                    toast.success('Analysis has been renamed');
                  } catch (err) {
                    captureException(err);
                    toast.error('Could not rename analysis: ' + getDisplayError(err));
                  }
                }}
              />
            </WorkspacePermissionWrapper>
          </div>

          <div className="mb-2 flex justify-between">
            <div className="flex flex-wrap gap-x-4 gap-y-2 items-center">
              <DataField title="Preset Type">Cross Document</DataField>
              <DataField title="Target Party">
                {presetRun.targetParty && presetRun.targetParty.length > SUBSTRING_MAX_LENGTH ? (
                  <div className="flex gap-1 items-center">
                    <div>{presetRun.targetParty.substring(0, SUBSTRING_MAX_LENGTH)}...</div>
                    <Tooltip text={presetRun.targetParty}>
                      <InfoIcon className="w-4 h-4" />
                    </Tooltip>
                  </div>
                ) : (
                  presetRun.targetParty
                )}
              </DataField>
              <DataField title="Status">
                <div className="flex gap-1 items-center">
                  <StatusDot
                    size={3}
                    color={
                      presetRun.status === PresetRunStatus.Running
                        ? 'blue'
                        : presetRun.status === PresetRunStatus.Canceled || presetRun.status === PresetRunStatus.Failed
                          ? 'red'
                          : 'green'
                    }
                    pulse={presetRun.status === PresetRunStatus.Running}
                  />
                  {presetRun.status}
                </div>
              </DataField>

              <DataField title="Started at">{formatDateTime(presetRun.createdAt)}</DataField>

              {enableDebugMode && presetRun.finishedAt && (
                <DataField title="Finished at">{formatDateTime(presetRun.finishedAt)}</DataField>
              )}

              <DataField title="Language">{presetRun.language.toUpperCase()}</DataField>

              {categoryNames.length > 0 && <DataField title="Categories">{categoryNames.join(',')}</DataField>}
            </div>

            <div className="flex gap-2 items-center">
              {isPending && (
                <div>
                  <ConfirmDialog
                    triggerText={<BanIcon className="button-icon" />}
                    title="Cancel"
                    submitText="Cancel"
                    triggerVariant="destructive"
                    submitVariant="destructive"
                    description="Are you sure you want to cancel this analysis?"
                    onSubmit={async () => {
                      try {
                        const payload: CancelPresetRunPayload = {
                          presetRunId: presetRun.id,
                        };
                        await fetchEndpointData('/api/v1/workspace/preset-run/cancel', {
                          method: 'DELETE',
                          body: payload,
                        });
                        toast.success('Analysis canceled');
                      } catch (err) {
                        captureException(err);
                        toast.error('Could not cancel analysis: ' + getDisplayError(err));
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-2 items-center font-medium">
            <WarningIcon className="button-icon text-danger-color" />
            Cross Document Analysis is still an experimental feature, be sure to always double-check the results.
          </div>

          <div className="flex justify-end my-4">
            <div className="flex items-center justify-center gap-4">
              <label className="flex items-center gap-2">
                <Checkbox isChecked={showTranslated} onChange={setShowTranslated} />
                <div>Translated</div>
              </label>
              <label className="flex items-center gap-2">
                <Checkbox isChecked={showMinified} onChange={setShowMinified} />
                <div>Minified</div>
              </label>
            </div>
          </div>

          <AggregatedAnswersResults
            presetRun={presetRun}
            showTranslated={showTranslated}
            showMinified={showMinified}
            openDoc={openDoc}
            setOpenDoc={setOpenDoc}
            onOpenDebugAnswer={setDebugAnswer}
          />
        </div>

        {openDoc && <CrossDocumentReferenceSidecar doc={openDoc} onClose={() => setOpenDoc(null)} />}

        {debugAnswer && (
          <InfoDialog
            title={`Debug: ${debugAnswer.questionId}`}
            isOpen={true}
            setIsOpen={(newIsOpen) => {
              if (!newIsOpen) {
                setDebugAnswer(null);
              }
            }}
          >
            <div>{`Question: ${debugAnswer.question}`}</div>
            <div>{`Question id: ${debugAnswer.questionId}`}</div>
            <div>{`LLM Job ids: ${debugAnswer.jobIds.join(', ')}`}</div>
            <div>{`is generating: ${debugAnswer.isGenerating}`}</div>

            <div>
              <div className="mt-2">Documents</div>
              <div>
                {debugAnswer.documents.map((v) => {
                  return (
                    <div key={v.id} className="my-2">
                      <div className="font-medium">{`Document: ${v.doc.id}`}</div>
                      <div>{`name: ${v.doc.name}`}</div>
                      <div>{`Answer: ${v.answer || '-'}`}</div>
                      <div>{`LLM Job ids: ${v.jobIds.join(', ')}`}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </InfoDialog>
        )}
      </div>
    </>
  );
};
