import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Trash, PencilSimple } from '@phosphor-icons/react';
import { captureException } from '@sentry/react';
import toast from 'react-hot-toast';

import { Card, CardLink, ActionMenuItem } from '../../../components/card/Card';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { fetchEndpointData } from '@/utils/fetch.client';
import { getDisplayError } from '@/utils/get-display-error';
import { formatDate } from '@/utils/date';
import { useTeam } from '@/app/team/context/TeamContext';
import type { BodyType as DeleteCategoryTemplateBody } from '@/app/category/endpoints/DeleteCategoryTemplateEndpoint';
import type { BodyType as UpdateCategoryTemplateBody } from '@/app/category/endpoints/UpdateCategoryTemplateEndpoint';
import { useCategoryTemplateList } from '@/app/category/context/CategoryTemplateListProvider';
import { CategoryTemplateFormDialog } from '../pages/CategoryTemplateDialog';

export interface CategoryTemplateCardProps {
  id: string;
  title: string;
  createdAt: string;
  to?: string;
  className?: string;
  onDeleteSuccess?: () => void;
}

export const CategoryTemplateCard: React.FC<CategoryTemplateCardProps> = ({
  id,
  title,
  createdAt,
  to,
  className,
  onDeleteSuccess,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { team } = useTeam();
  const { refreshCategoryTemplates } = useCategoryTemplateList();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (isDeleting) return;

    setIsDeleting(true);
    try {
      const payload: DeleteCategoryTemplateBody = {
        teamId: team.id,
        templateId: id,
      };
      await fetchEndpointData('/api/v1/category-template/delete', {
        method: 'DELETE',
        body: payload,
      });

      refreshCategoryTemplates();

      toast.success('Category template deleted');

      if (onDeleteSuccess) {
        onDeleteSuccess();
      } else if (location.pathname.includes(`/${id}/`)) {
        // If we're on a nested route within the template, navigate to the templates list
        navigate(`/app/t/${team.id}/category-templates`);
      }
    } catch (err) {
      captureException(err);
      toast.error('Could not delete category template: ' + getDisplayError(err));
    } finally {
      setIsDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  const menuItems: ActionMenuItem[] = [
    {
      icon: <PencilSimple size={20} className="text-gray-500" />,
      label: 'Rename Template',
      onClick: () => setShowEditDialog(true),
    },
    {
      icon: <Trash size={20} className="text-red-500" />,
      label: 'Delete Template',
      variant: 'destructive',
      onClick: () => setShowDeleteDialog(true),
    },
  ];

  const cardProps = {
    id,
    heading: title,
    subtitle: formatDate(createdAt),
    showAvatar: true,
    className,
    menuItems,
  };

  return (
    <>
      {to ? <CardLink to={to} {...cardProps} /> : <Card {...cardProps} />}

      <CategoryTemplateFormDialog
        isOpen={showEditDialog}
        setIsOpen={setShowEditDialog}
        mode="edit"
        templateId={id}
        initialName={title}
      />

      <ConfirmDialog
        isOpen={showDeleteDialog}
        setIsOpen={setShowDeleteDialog}
        triggerText=""
        title="Delete category template"
        submitText="Delete"
        confirmationText="Delete"
        submitVariant="destructive"
        description={`Are you sure you want to delete ${title}?`}
        onSubmit={handleDelete}
        isDisabled={isDeleting}
      />
    </>
  );
};

export default CategoryTemplateCard;
