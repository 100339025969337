import React, { useMemo } from 'react';

export interface IFilepathProps {
  children: string;
}

export const Filepath: React.FC<IFilepathProps> = (props) => {
  const { children } = props;

  const [folder, filename] = useMemo(() => {
    const parts = children.split('/');
    const filename = parts.pop();
    const folder = parts.join('/') + '/';

    return [folder, filename];
  }, [children]);

  return (
    <div>
      <span className="text-gray-800">{folder}</span>
      <span className="font-medium">{filename}</span>
    </div>
  );
};
