import { useMemo } from 'react';
import { PdfPreview } from '../../../components/PdfPreview';

export const PREVIEW_MIMETYPES = new Set(['application/pdf']);

export interface IBbox {
  page: number;
  // number between 1-1000
  top: number;
  // number between 1-1000
  left: number;
  // number between 1-1000
  width: number;
  // number between 1-1000
  height: number;
}

export interface IFilePreviewProps {
  title: string;
  mimetype: string;
  fileUrl: string;
  pageNumber?: number | null;
  highlights?: IBbox[];
  customHeight?: string;
}

export const FilePreview: React.FC<IFilePreviewProps> = (props) => {
  const { title, mimetype, fileUrl, pageNumber, highlights: _highlights, customHeight } = props;

  const highlights = useMemo(() => {
    return _highlights
      ? _highlights.map((v) => {
          return {
            pageNumber: v.page,
            top: v.top / 10,
            left: v.left / 10,
            height: v.height / 10,
            width: v.width / 10,
          };
        })
      : [];
  }, [_highlights]);

  if (mimetype === 'application/pdf') {
    return (
      <PdfPreview
        fileUrl={fileUrl}
        title={title}
        page={pageNumber}
        highlights={highlights}
        customHeight={customHeight}
      />
    );
  }

  return <div>File not supported.</div>;
};
