import { useEffect, useState } from 'react';
import { Bug as BugIcon } from '@phosphor-icons/react';

import classNames from '@/utils/classnames';
import { IHighlightPiece } from './highlight-match';
import { MarkdownText } from '@/components/markdown/Markdown';
import { IBbox } from '@/app/workspaceDocument/components/FilePreview';
import type { ResponseType as DocumentMatchesResponseType } from '../../../endpoints/WorkspacePresetRunDocumentMatchesEndpoint';

const renderText = (t: string) => t;
const renderHighlightedText = (t: string) => {
  return <span className="bg-yellow-300">{t}</span>;
};

const IGNORED_MD_TOKENS = ['strong', 'em', 'del', 'escape', 'codespan', 'def'];

export interface IMatchTextProps {
  id: string;
  pieces: Array<IHighlightPiece>;
}

const MatchText: React.FC<IMatchTextProps> = (props) => {
  const { id, pieces } = props;

  return (
    <div>
      {pieces.map((v, idx) => {
        return (
          <MarkdownText
            key={`${id}-${idx}`}
            content={v.value}
            renderText={v.isHighlighted ? renderHighlightedText : renderText}
            ignoredTokens={IGNORED_MD_TOKENS}
          />
        );
      })}
    </div>
  );
};

interface IReferenceDialogContentProps {
  setSearchParams: (newParams: string) => void;
  showDebugInfo: boolean;
  matches: Array<
    DocumentMatchesResponseType['documentMatches'][0] & {
      pieces: IHighlightPiece[];
      highlightedLength: number;
    }
  >;
}

export const ReferenceDialogContent: React.FC<IReferenceDialogContentProps> = (props) => {
  const { setSearchParams, showDebugInfo, matches } = props;
  const [bboxesToShow, setBboxesToShow] = useState<IBbox[]>([]);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    const mostRelevant = [...matches].sort(
      (a, b) => (b.highlightedLength ?? b.highlightedLength) - (a.highlightedLength ?? a.highlightedLength),
    )[0];
    if (mostRelevant) {
      if (mostRelevant.documentChunk.page) {
        searchParams.set('pageNumber', mostRelevant.documentChunk.page.toString());
      }

      if (mostRelevant.documentChunk.pageRef) {
        searchParams.set('pageRef', mostRelevant.documentChunk.pageRef);
      }
    }
    setSearchParams(searchParams.toString());
  }, [matches]);

  useEffect(() => {
    const sorted = matches.sort((a, b) => {
      return (b.highlightedLength || b.answerSimilarity || 0) - (a.highlightedLength || a.answerSimilarity || 0);
    });
    const topChunk = sorted[0];
    if (topChunk) {
      setTimeout(() => {
        const element = window.document.getElementById(`dialog-chunk-${topChunk.id}`);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 50);
    }
  }, [matches]);

  return (
    <>
      <div
        className={classNames('flex flex-col', {
          'gap-2': showDebugInfo,
        })}
      >
        {matches
          .sort((a, b) => a.documentChunk.chunkIdx - b.documentChunk.chunkIdx)
          .map((m) => {
            return (
              <div
                key={m.id}
                id={`dialog-chunk-${m.id}`}
                className={classNames('whitespace-pre-line p-2', {
                  'border-gray-200 border-b': showDebugInfo,
                })}
              >
                {showDebugInfo && (
                  <div className="font-medium text-xs text-gray-600 mb-1">
                    {`Chunk ${m.documentChunk.chunkIdx}, page ${m.documentChunk.page || '-'}`}
                  </div>
                )}
                <MatchText id={m.id} pieces={m.pieces} />
                {showDebugInfo && (
                  <div className="flex gap-1 font-medium text-xs text-gray-600 mt-1">
                    <BugIcon className="w-4 h-4" />
                    <div>{`Original match, question similarity: ${Math.round(m.questionSimilarity * 100)}%, answer similarity: ${Math.round((m.answerSimilarity ?? 0) * 100)}%`}</div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};
