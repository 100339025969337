import { z } from 'zod';

export function getDisplayError(err: any): string {
  if (typeof err !== 'object') {
    return `${err}`;
  }

  if (err instanceof z.ZodError) {
    return err.errors.map((e) => e.message).join(', ');
  }

  if (Array.isArray(err.errors)) {
    if (typeof err.errors[0] === 'string') {
      return err.errors.join(', ');
    }
  }

  if (err.message) {
    return err.message;
  }

  return 'Unknown error';
}
