import React, { useState, memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { DotsThreeVertical } from '@phosphor-icons/react';
import classNames from '@/utils/classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { GradientAvatar, AvatarId } from '@/components/avatar/GradientAvatar';

// Types
type CardId = AvatarId;

interface CardBaseProps {
  className?: string;
  id: CardId;
  heading: string;
  subtitle?: string;
  showAvatar?: boolean;
}

interface CardProps extends CardBaseProps {
  children?: React.ReactNode;
  menuItems?: ActionMenuItem[];
  onMenuClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

// Exclude properties that conflict with CardBaseProps
type CardLinkBaseProps = Omit<LinkProps, 'className' | 'id' | 'heading' | 'subtitle'>;

interface CardLinkProps extends CardLinkBaseProps, CardBaseProps {
  children?: React.ReactNode;
  menuItems?: ActionMenuItem[];
  onMenuClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface CardHeaderProps {
  id: CardId;
  showAvatar?: boolean;
  menuItems?: ActionMenuItem[];
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface CardContentProps {
  heading: string;
  subtitle?: string;
  children?: React.ReactNode;
}

// Action Menu Types
export interface ActionMenuItem {
  icon: React.ReactNode;
  label: string;
  to?: string;
  variant?: 'default' | 'destructive';
  onClick?: (e: React.MouseEvent) => void;
}

interface ActionMenuProps {
  itemId: string;
  menuItems: ActionMenuItem[];
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

// Styles
const styles = {
  card: `
    relative
    w-full
    min-h-[164px]
    bg-white 
    rounded-2xl
    border
    border-neutral-200
    p-4
    flex
    flex-col
    transition-shadow
    duration-200
    hover:shadow-md
  `,
  header: `
    w-full
    h-9
    flex
    justify-between
    items-center
  `,
  content: `
    w-full
    mt-auto
    flex
    flex-col
    gap-2
  `,
  heading: `
    w-full
    font-inter
    font-bold
    text-lg
    leading-[160%]
    tracking-normal
    text-neutral-800
    line-clamp-2
  `,
  subtitle: `
    w-full
    font-inter
    font-medium
    text-sm
    leading-[160%]
    tracking-normal
    text-neutral-500
  `,
};

// Generic ActionMenu component
const ActionMenu = memo<ActionMenuProps>(({ itemId, menuItems, onClick }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <DropdownMenu.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenu.Trigger asChild>
        <button
          type="button"
          className="w-9 h-9 min-w-[36px] max-w-[36px] min-h-[36px] max-h-[36px] rounded-[6px] flex items-center justify-center hover:bg-neutral-100 transition-colors"
          onClick={(e) => {
            e.stopPropagation();
            onClick?.(e);
          }}
        >
          <DotsThreeVertical size={20} weight="bold" className="text-neutral-800" />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 min-w-[197px] bg-white rounded-lg border border-[#E5E5E5] py-2 shadow-[0px_4px_12px_0px_#00000014]"
          sideOffset={8}
          align="start"
        >
          {menuItems.map((item, index) => (
            <DropdownMenu.Item
              key={`${itemId}-menu-item-${index}`}
              className={classNames(
                'outline-none cursor-pointer h-[42px] px-4 flex items-center gap-[10px] text-sm hover:bg-neutral-50',
                item.variant === 'destructive' ? 'text-red-7' : 'text-neutral-800',
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDropdownOpen(false);

                if (item.onClick) {
                  setTimeout(() => {
                    item.onClick?.(e);
                  }, 100);
                }
              }}
            >
              {item.to ? (
                <Link
                  to={item.to}
                  className="flex items-center gap-[10px] w-full h-full"
                  onClick={(e) => e.stopPropagation()}
                >
                  {item.icon}
                  {item.label}
                </Link>
              ) : (
                <>
                  {item.icon}
                  {item.label}
                </>
              )}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
});

ActionMenu.displayName = 'ActionMenu';

const CardHeader = memo<CardHeaderProps>(({ id, showAvatar, menuItems, onClick }) => {
  return (
    <div className={styles.header}>
      {showAvatar && <GradientAvatar id={id} />}
      {menuItems && <ActionMenu itemId={id} menuItems={menuItems} onClick={onClick} />}
    </div>
  );
});

CardHeader.displayName = 'CardHeader';

const CardContent = memo<CardContentProps>(({ heading, subtitle, children }) => (
  <div className={styles.content}>
    <h3 className={styles.heading}>{heading}</h3>
    {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    {children}
  </div>
));

CardContent.displayName = 'CardContent';

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, id, heading, subtitle, showAvatar, children, menuItems, onMenuClick, ...props }, ref) => (
    <div ref={ref} className={classNames(styles.card, className)} {...props}>
      <CardHeader id={id} showAvatar={showAvatar} menuItems={menuItems} onClick={onMenuClick} />
      <CardContent heading={heading} subtitle={subtitle}>
        {children}
      </CardContent>
    </div>
  ),
);

Card.displayName = 'Card';

export const CardLink = React.forwardRef<HTMLAnchorElement, CardLinkProps>(
  ({ to, id, heading, subtitle, showAvatar, children, className, menuItems, onMenuClick, ...props }, ref) => (
    <Link ref={ref} to={to} className={classNames(styles.card, className)} data-clickable="true" {...props}>
      <CardHeader id={id} showAvatar={showAvatar} menuItems={menuItems} onClick={onMenuClick} />
      <CardContent heading={heading} subtitle={subtitle}>
        {children}
      </CardContent>
    </Link>
  ),
);

CardLink.displayName = 'CardLink';
