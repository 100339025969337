import './styles/globals.css';
import './utils/custom-validations';
import './utils/sentry.client';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { cleanupExpiredFetchCache } from './utils/fetch.client';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
} else {
  console.error('Root node for React is not found');
}

setTimeout(() => {
  console.log('Starting fetch cleanup');
  cleanupExpiredFetchCache().catch(console.error);
}, 90_000);
