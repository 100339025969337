import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Check as CheckIcon,
  CaretDown as CaretDownIcon,
  CaretRight as CaretRightIcon,
  File as FileIcon,
  Folder as FolderIcon,
  Plus as PlusIcon,
  Trash as TrashIcon,
} from '@phosphor-icons/react';

import { DialogRoot, DialogContent } from '../../../../components/dialog/Dialog';
import classNames from '../../../../utils/classnames';
import { Button, ButtonProps } from '../../../../components/button/Button';
import { nullthrows } from '@/utils/invariant';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { TREE_ROOT_ID, TreeNode } from '../../tree/WorkspaceDocumentTree';
import { numberAwareTextSort } from '@/utils/text';

interface IFolderNodeProps {
  folder: TreeNode;
  openFolderId: string | null;
  onOpen: (v: TreeNode, isOpened: boolean) => void;
  selectedNodeIds: string[];
  onSelect: (v: TreeNode) => void;
  isSelectable: boolean;
  expandedFolders: string[];
}

export const FolderNode: React.FC<IFolderNodeProps> = (props) => {
  const { folder, openFolderId, onOpen, selectedNodeIds, onSelect, isSelectable, expandedFolders } = props;

  const [isHoveringTitle, setIsHoveringTitle] = useState(false);
  const [isHoveringOpen, setIsHoveringOpen] = useState(false);
  const [isHoveringSelect, setIsHoveringSelect] = useState(false);

  const isExpanded = expandedFolders.includes(folder.id);
  const isSelected = selectedNodeIds.includes(folder.id);
  const childfolders = folder.getChildren().filter((node) => node.type === 'folder');

  const openHoverState = isHoveringOpen || (!isSelectable && isHoveringTitle);

  return (
    <div
      className={classNames('grid gap-1', {
        'mb-1': !isExpanded,
      })}
    >
      <div className="card-no-padding flex justify-between overflow-hidden">
        <div
          className={classNames('flex items-center px-1 cursor-pointer', {
            'bg-gray-600 text-white': openHoverState,
            'bg-gray-500 text-white': openFolderId === folder.id && !openHoverState,
          })}
          onClick={() => {
            onOpen(folder, openFolderId === folder.id);
          }}
          onMouseEnter={() => {
            setIsHoveringOpen(true);
          }}
          onMouseLeave={() => {
            setIsHoveringOpen(false);
          }}
        >
          {isExpanded ? <CaretDownIcon className="w-4 h-4" /> : <CaretRightIcon className="w-4 h-4" />}
        </div>
        <div
          className={classNames('flex gap-1 items-center w-full pl-1 cursor-pointer', {
            'bg-gray-600': isHoveringSelect || isHoveringTitle,
            'text-white': (isHoveringSelect || isHoveringTitle) && openFolderId !== folder.id,
            'bg-gray-500 text-white': openFolderId === folder.id,
          })}
          onMouseEnter={() => {
            setIsHoveringTitle(true);
          }}
          onMouseLeave={() => {
            setIsHoveringTitle(false);
          }}
          onClick={() => {
            onOpen(folder, openFolderId === folder.id);
          }}
        >
          <div>{folder.name}</div>
        </div>
        {isSelectable && (
          <div
            className={classNames('flex items-center px-1 border-l border-gray-200 text-gray-600 cursor-pointer', {
              'text-white bg-gray-600': isHoveringSelect || isHoveringTitle,
              'bg-gray-500 text-white': isSelected && !(isHoveringSelect || isHoveringTitle),
            })}
            onMouseEnter={() => {
              setIsHoveringSelect(true);
            }}
            onMouseLeave={() => {
              setIsHoveringSelect(false);
            }}
            onClick={() => {
              onSelect(folder);
            }}
          >
            {isSelected ? <CheckIcon className="w-4 h-4" /> : <PlusIcon className="w-4 h-4" />}
          </div>
        )}
      </div>
      {isExpanded && (
        <div className="ml-2">
          {childfolders
            .sort((a, b) => numberAwareTextSort(a.name, b.name))
            .map((v) => {
              return (
                <FolderNode
                  key={v.id}
                  folder={v}
                  openFolderId={openFolderId}
                  onOpen={onOpen}
                  onSelect={onSelect}
                  selectedNodeIds={selectedNodeIds}
                  isSelectable={isSelectable}
                  expandedFolders={expandedFolders}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

interface IDocumentListProps {
  folder: TreeNode;
  selectedNodeIds: string[];
  onSelectNode: (v: TreeNode) => void;
}

export const DocumentList: React.FC<IDocumentListProps> = (props) => {
  const { folder, selectedNodeIds, onSelectNode } = props;
  const [hoveredDoc, setHoveredDoc] = useState<string | null>(null);

  const documents = folder.getChildren().filter((node) => node.type === 'document');

  if (!documents.length) {
    return <div>{`No documents found in ${folder.name}`}</div>;
  }

  return (
    <div className="grid gap-1">
      {documents
        .sort((a, b) => numberAwareTextSort(a.name, b.name))
        .map((v) => {
          const isHovered = v.id === hoveredDoc;
          const isSelected = selectedNodeIds.includes(v.id);

          return (
            <div
              key={v.id}
              className={classNames('rounded-md flex justify-between overflow-hidden', {
                'bg-gray-600 text-white': isHovered,
                'bg-gray-500 text-white': isSelected && !isHovered,
                'bg-gray-100': !isHovered && !isSelected,
              })}
              onMouseEnter={() => {
                setHoveredDoc(v.id);
              }}
              onMouseLeave={() => {
                setHoveredDoc((prev) => {
                  if (prev === v.id) {
                    return null;
                  }
                  return prev;
                });
              }}
              onClick={() => {
                onSelectNode(v);
              }}
            >
              <div className="w-full pl-1 cursor-pointer">{v.name}</div>
              <div
                className={classNames('flex items-center px-1 border-l border-gray-200 cursor-pointer', {
                  'text-gray-600': !isHovered && !isSelected,
                  'text-white': isHovered || isSelected,
                })}
              >
                {isSelected ? <CheckIcon className="w-4 h-4" /> : <PlusIcon className="w-4 h-4" />}
              </div>
            </div>
          );
        })}
    </div>
  );
};

interface ISelectionListProps {
  selectedNodeIds: string[];
  onDeselectNode: (v: TreeNode) => void;
}

export const SelectionList: React.FC<ISelectionListProps> = (props) => {
  const { selectedNodeIds, onDeselectNode } = props;
  const { tree, treeKey } = useWorkspace();

  const selectedNodes: TreeNode[] = useMemo(() => {
    return selectedNodeIds
      .map((v) => {
        return tree.entries.get(v);
      })
      .filter(Boolean) as TreeNode[];
  }, [selectedNodeIds, treeKey]);

  if (!selectedNodes.length) {
    return <div>No selection</div>;
  }

  return (
    <div className="grid gap-1">
      {selectedNodes
        .sort((a, b) => numberAwareTextSort(a.name, b.name))
        .map((v) => {
          return (
            <div key={v.id} className="bg-gray-100 rounded-md flex justify-between overflow-hidden">
              <div className="w-full pl-1 flex items-center gap-1">
                {v.type === 'folder' ? (
                  <FolderIcon className="folder-icon-style w-4 h-4" />
                ) : (
                  <FileIcon className="file-icon-style w-4 h-4" />
                )}
                <div>{v.name}</div>
              </div>
              <div
                className="flex items-center px-1 border-l border-gray-200 cursor-pointer hover:bg-danger-color hover:text-white"
                onClick={() => {
                  onDeselectNode(v);
                }}
              >
                <TrashIcon className="w-4 h-4" />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export interface IDocumentPickerProps {
  rootExplorerId?: string;
  showRoot?: boolean;
  title?: string;
  onlyFolders?: boolean;
  onlyDocuments?: boolean;
  multiSelect?: boolean;
  initialSelection?: string[];
  onSubmit: (selectedNodes: TreeNode[]) => void;
}

export const DocumentPicker: React.FC<IDocumentPickerProps> = (props) => {
  const {
    rootExplorerId = TREE_ROOT_ID,
    title,
    initialSelection = [],
    onSubmit,
    onlyFolders,
    onlyDocuments,
    multiSelect,
    showRoot,
  } = props;
  const { tree } = useWorkspace();
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const [openedFolder, setOpenedFolder] = useState<null | TreeNode>(null);
  const [expandedFolders, setExpandedFolders] = useState<string[]>([rootExplorerId]);

  useEffect(() => {
    setSelectedNodeIds(initialSelection);
  }, [initialSelection.sort().join(',')]);

  const handleSelect = useCallback(
    (v: TreeNode) => {
      if (!multiSelect) {
        setSelectedNodeIds((prev) => {
          const setValue = new Set(prev);
          if (setValue.has(v.id)) {
            return [];
          } else {
            return [v.id];
          }
        });
      } else {
        setSelectedNodeIds((prev) => {
          const setValue = new Set(prev);
          if (setValue.has(v.id)) {
            // eslint-disable-next-line drizzle/enforce-delete-with-where
            setValue.delete(v.id);
          } else {
            setValue.add(v.id);
          }
          return [...setValue];
        });
      }
    },
    [setSelectedNodeIds, multiSelect],
  );

  const handleFolderOpen = useCallback(
    (v: TreeNode, isCurrentActive: boolean) => {
      if (v.id !== rootExplorerId) {
        setExpandedFolders((prev) => {
          const setValue = new Set(prev);
          if (setValue.has(v.id)) {
            if (!onlyFolders && !isCurrentActive) {
              return prev;
            }

            // eslint-disable-next-line drizzle/enforce-delete-with-where
            setValue.delete(v.id);
          } else {
            setValue.add(v.id);
          }
          return [...setValue];
        });
      }

      if (onlyFolders) {
        return;
      }

      setOpenedFolder(v);
    },
    [setOpenedFolder, setExpandedFolders, onlyFolders, rootExplorerId],
  );

  const handleSelectFolder = useCallback(
    (v: TreeNode) => {
      if (onlyDocuments) {
        return;
      }

      handleSelect(v);
    },
    [handleSelect, onlyFolders],
  );

  let gridTemplateColumns = '';
  if (multiSelect) {
    if (onlyFolders) {
      gridTemplateColumns = '1fr 1fr';
    } else {
      gridTemplateColumns = '1fr 2fr 1fr';
    }
  } else {
    if (onlyFolders) {
      gridTemplateColumns = '1fr';
    } else {
      gridTemplateColumns = '1fr 2fr';
    }
  }

  const root = nullthrows(tree.entries.get(rootExplorerId), 'Root folder not found');
  const selectedNode = selectedNodeIds.length ? tree.entries.get(selectedNodeIds[0]!) : null;
  const rootFolders = root.getChildren().filter((node) => node.type === 'folder');
  return (
    <div className="flex flex-col gap-4" style={{ height: '80vh' }}>
      <div className="heading-two">{title ?? 'Select documents / folders'}</div>
      <div className="grid gap-4" style={{ gridTemplateColumns }}>
        <div>
          <div className="font-medium mb-1">Folders</div>
          <div className="overflow-y-auto" style={{ height: 'calc(80vh - 8rem)' }}>
            {showRoot ? (
              <FolderNode
                key={root.id}
                folder={root}
                openFolderId={openedFolder?.id || null}
                onOpen={handleFolderOpen}
                onSelect={handleSelectFolder}
                selectedNodeIds={selectedNodeIds}
                isSelectable={!onlyDocuments}
                expandedFolders={expandedFolders}
              />
            ) : (
              rootFolders
                .sort((a, b) => numberAwareTextSort(a.name, b.name))
                .map((folder) => {
                  return (
                    <FolderNode
                      key={folder.id}
                      folder={folder}
                      openFolderId={openedFolder?.id || null}
                      onOpen={handleFolderOpen}
                      onSelect={handleSelectFolder}
                      selectedNodeIds={selectedNodeIds}
                      isSelectable={!onlyDocuments}
                      expandedFolders={expandedFolders}
                    />
                  );
                })
            )}
          </div>
        </div>
        {!onlyFolders && (
          <div>
            <div className="font-medium mb-1">Documents</div>
            <div className="overflow-y-auto" style={{ height: 'calc(80vh - 8rem)' }}>
              {!openedFolder && <div>Select a folder to view documents</div>}
              {openedFolder && (
                <DocumentList folder={openedFolder} selectedNodeIds={selectedNodeIds} onSelectNode={handleSelect} />
              )}
            </div>
          </div>
        )}
        {multiSelect && (
          <div>
            <div className="font-medium mb-1">Selection</div>
            <div className="overflow-y-auto" style={{ height: 'calc(80vh - 8rem)' }}>
              {<SelectionList selectedNodeIds={selectedNodeIds} onDeselectNode={handleSelect} />}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <div>{!multiSelect && selectedNode && `Selected: ${selectedNode.name}`}</div>

        <div className="flex gap-2">
          <Button
            onTrigger={() => {
              setSelectedNodeIds([]);
            }}
          >
            Clear selection
          </Button>
          <Button
            variant="primary"
            onTrigger={() => {
              const selectedNodes = selectedNodeIds
                .map((v) => {
                  return tree.entries.get(v);
                })
                .filter(Boolean) as TreeNode[];

              onSubmit(selectedNodes);
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export interface IDocumentPickerDialogProps extends IDocumentPickerProps {
  className?: string;
  open: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const DocumentPickerDialog: React.FC<IDocumentPickerDialogProps> = (props) => {
  const { open, onOpenChange, ...otherProps } = props;

  const isMinimal = Boolean((otherProps.onlyDocuments || otherProps.onlyFolders) && !otherProps.multiSelect);
  return (
    <DialogRoot open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className={classNames('document-selection-dialog', {
          'w-minimal-filepicker': isMinimal,
          'w-full-filepicker': !isMinimal,
        })}
      >
        <DocumentPicker {...otherProps} />
      </DialogContent>
    </DialogRoot>
  );
};

export interface IDocumentPickerDialogWithTriggerProps extends IDocumentPickerDialogProps {
  showTrigger?: boolean;
  triggerSize?: ButtonProps['size'];
  triggerText: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  open: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const DocumentPickerDialogWithTrigger: React.FC<IDocumentPickerDialogWithTriggerProps> = (props) => {
  const { triggerText, triggerSize, isLoading, isDisabled, ...otherProps } = props;

  return (
    <div>
      <Button
        size={triggerSize}
        onTrigger={() => {
          otherProps.onOpenChange(true);
        }}
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        {triggerText}
      </Button>

      <DocumentPickerDialog {...otherProps} />
    </div>
  );
};
