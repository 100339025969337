import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';
import { CardLink } from '@/components/card/CardOld';

export const BackofficePage = () => {
  return (
    <div className="page-content">
      <PageHeader title="INTERNAL Backoffice" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Backoffice',
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-4 gap-4">
        <CardLink to="/app/internal/teams" title="Teams" />
        <CardLink to="/app/internal/categories" title="Default Categories" />
        <CardLink to="/app/internal/audit-logs" title="Audit logs" />
        <CardLink to="/app/internal/workflows" title="Workflows" />
        <CardLink to="/app/internal/queues" title="Queues" />
        <CardLink to="/app/internal/risks" title="Risks" />
      </div>
    </div>
  );
};
