import * as XLSX from 'forked-xlsx';

import { numberAwareTextSort } from '@/utils/text';
import dayjs from 'dayjs';
import { GroupedAnswers } from './group-answers';

export function downloadDocAnswersAsExcel(groupedAnswers: GroupedAnswers) {
  const rows: any[] = [];
  const docs = [...groupedAnswers.documents.values()].sort((a, b) => numberAwareTextSort(a.name, b.name));
  const sortedQuestions = [...groupedAnswers.questions.values()].sort((a, b) => a.idx - b.idx);
  const titles = ['Document', ...sortedQuestions.map((v) => v.name)];

  const questionNameToId = new Map<string, string>();
  for (const question of sortedQuestions) {
    questionNameToId.set(question.name, question.id);
  }

  rows.push(titles);

  for (const doc of docs) {
    const row = [];
    const docAnswers = groupedAnswers.groupedPerDocument.get(doc.id) ?? [];
    for (const key of titles) {
      if (key === 'Document') {
        row.push(doc.name);
      } else {
        const questionId = questionNameToId.get(key);
        if (!questionId) {
          row.push('-');
        } else {
          const answer = docAnswers.find((v) => v.questionId === questionId);
          row.push(answer?.minified ?? '-');
        }
      }
    }
    rows.push(row);
  }

  const ws = XLSX.utils.aoa_to_sheet(rows);

  if (!ws['!cols']) {
    ws['!cols'] = [];
  }

  for (let i = 0; i < titles.length; i++) {
    if (!ws['!cols'][i]) {
      ws['!cols'][i] = {};
    }

    ws['!cols']![i]!.wch = 50;
  }

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Analysis answers');
  const res = XLSX.write(wb, {
    type: 'base64',
    cellStyles: true,
  });

  const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
  const linkTag = document.createElement('a');
  linkTag.href = url;
  linkTag.download = `preset-run-results-${dayjs().format('DD-MM-YYYY-HH-mm')}.xlsx`;
  linkTag.click();
}
