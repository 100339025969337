import { ExclamationMark as ExclamationMarkIcon } from '@phosphor-icons/react';

import { Tooltip } from '@/components/tooltip/Tooltip';
import classNames from '@/utils/classnames';
import { riskScoreToSeverity } from '../utils';

interface IRiskIndicatorProps {
  hasBeenScored: boolean;
  score: number;
}

export const RiskIndicationDot: React.FC<IRiskIndicatorProps> = (props) => {
  const { hasBeenScored, score } = props;

  const severity = riskScoreToSeverity(score);
  return (
    <div
      className={classNames('w-4 h-4 rounded-full flex items-center justify-center', {
        'bg-red-8': hasBeenScored && severity === 'critical',
        'bg-red-7': hasBeenScored && severity === 'high',
        'bg-yellow-500': hasBeenScored && severity === 'medium',
        'bg-green-500': hasBeenScored && severity === 'low',
        'bg-gray-400': !hasBeenScored,
      })}
    >
      {severity === 'critical' && <ExclamationMarkIcon className="w-3 h-3 text-white" />}
    </div>
  );
};

export const RiskIndicator: React.FC<IRiskIndicatorProps> = (props) => {
  const { hasBeenScored, score } = props;

  const severity = riskScoreToSeverity(score);
  return (
    <Tooltip text={severity}>
      <RiskIndicationDot hasBeenScored={hasBeenScored} score={score} />
    </Tooltip>
  );
};
