import React, { useMemo } from 'react';
import * as docx from 'docx';
import dayjs from 'dayjs';
import { Question as QuestionIcon, Bug as BugIcon } from '@phosphor-icons/react';

import { ChatMessageText, IDocumentDict } from '@/app/workspaceChat/pages/chat/ChatMessage';
import { WorkspacePresetRun } from '../../types';
import { SpinnerBlock } from '@/components/Spinner';
import { PresetRunStatus } from '../../../../enums';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { DOCX_STYLES } from '@/utils/docx/write-docx';
import { Tooltip } from '@/components/tooltip/Tooltip';
import { AnswerLoadingBar } from '../AnswerLoadingBar';
import classNames from '@/utils/classnames';
import { useAppContext } from '@/contexts/app-context';
import { Expandable, useExpandableState, ExpandAllButton } from '@/components/expandable/Expandable';

export interface IDoc {
  id: string;
  answer: string;
  questionId: string;
  jobIds: string[];
  doc: {
    id: string;
    name: string;
    folderId?: string | null;
  };
}

export interface IAggregatedAnswer {
  idx: number;
  question: string;
  questionId: string;
  answer: string;
  translatedAnswer: string;
  minifiedAnswer: string;
  translatedMinifiedAnswer: string;
  jobIds: string[];
  isGenerating: boolean;
  documents: IDoc[];
  category: string | null;
  name?: string;
}

export type GroupedAnswersType = Record<string, IAggregatedAnswer>;

export function downloadAnswersAsDocx(groupedAnswers: GroupedAnswersType) {
  const children: any[] = [];

  children.push(
    new docx.Paragraph({
      heading: docx.HeadingLevel.HEADING_1,
      text: 'Run Export - ' + dayjs().format('DD-MM-YYYY HH:mm'),
    }),
  );

  const table = new docx.Table({
    rows: [...Object.entries(groupedAnswers)].map(([question, answer]) => {
      return new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: [
                  new docx.TextRun({
                    text: question,
                    bold: true,
                  }),
                ],
              }),
            ],
            width: { size: 35, type: docx.WidthType.PERCENTAGE },
            margins: {
              top: 64,
              bottom: 64,
              left: 64,
              right: 64,
            },
          }),
          new docx.TableCell({
            children: answer.minifiedAnswer.split('\n').map((v) => {
              return new docx.Paragraph({ text: v });
            }),
            width: { size: 65, type: docx.WidthType.PERCENTAGE },
            margins: {
              top: 64,
              bottom: 64,
              left: 64,
              right: 64,
            },
          }),
        ],
      });
    }),
    width: { size: 100, type: docx.WidthType.PERCENTAGE },
    columnWidths: [35, 65],
    layout: docx.TableLayoutType.FIXED,
  });

  children.push(table);

  const doc = new docx.Document({
    creator: 'Jurimesh',
    description: 'Analysis export',
    styles: DOCX_STYLES,
    sections: [
      {
        children,
      },
    ],
  });

  docx.Packer.toBlob(doc).then((blob) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `global-analysis-result-${dayjs().format('DD-MM-YYYY-HH-mm')}.docx`;
    link.click();
  });
}

interface IRemappedRef {
  refId: number;
  documentId: string;
}

function processSummary(summary: string, references: IRemappedRef[]): string {
  let result = '' + summary;
  for (const ref of references) {
    result = result.replace(`[ref#${ref.documentId}]`, `[ref#${ref.refId}]`);
  }
  return result;
}

export interface IAggregatedAnswersResultsProps {
  presetRun: WorkspacePresetRun;
  showTranslated: boolean;
  showMinified: boolean;
  openDoc: IDoc | null;
  setOpenDoc: (doc: IDoc | null) => void;
  onOpenDebugAnswer: (answer: IAggregatedAnswer) => void;
}

export function useGroupedAnswers(presetRun: WorkspacePresetRun) {
  const grouped = useMemo(() => {
    const answers: GroupedAnswersType = {};
    for (const question of presetRun.questions) {
      for (const answer of question.answers) {
        answers[question.name] = {
          idx: question.idx,
          question: question.question,
          questionId: question.id,
          answer: answer.summary || '-',
          translatedAnswer: answer.translatedSummary || answer.summary || '-',
          minifiedAnswer: answer.minifiedSummary || '-',
          translatedMinifiedAnswer: answer.translatedMinifiedSummary || answer.minifiedSummary || '-',
          isGenerating: false,
          jobIds: answer.jobIds,
          documents: question.documentAnswers.map((d) => {
            return {
              id: d.id,
              questionId: question.id,
              answer: d.answer ?? '',
              doc: d.document,
              jobIds: d.jobIds,
            };
          }),
          category: question.category,
          name: question.name,
        };
      }

      if (!question.answers.length) {
        answers[question.name] = {
          idx: question.idx,
          question: question.question,
          questionId: question.id,
          answer: '',
          translatedAnswer: '',
          minifiedAnswer: '',
          translatedMinifiedAnswer: '',
          isGenerating: true,
          jobIds: [],
          documents: question.documentAnswers.map((d) => {
            return {
              id: d.id,
              questionId: question.id,
              answer: d.answer ?? '',
              doc: d.document,
              jobIds: d.jobIds,
            };
          }),
          category: question.category,
          name: question.name,
        };
      }
    }
    return answers;
  }, [presetRun]);

  return grouped;
}

export const AggregatedAnswersResults: React.FC<IAggregatedAnswersResultsProps> = (props) => {
  const { presetRun, showTranslated, showMinified, openDoc, setOpenDoc, onOpenDebugAnswer } = props;
  const grouped = useGroupedAnswers(presetRun);
  const { tree, treeKey } = useWorkspace();
  const { enableDebugMode } = useAppContext();

  // Initialize categorizedAnswers, sortedCategories, and allQuestionsUncategorized
  const { categorizedAnswers, sortedCategories, allQuestionsUncategorized } = useMemo(() => {
    const result = new Map<string, IAggregatedAnswer[]>();
    let hasCategory = false;

    const answersArray = Object.entries(grouped).map(([name, answer]) => {
      if (answer.category) {
        hasCategory = true;
      }
      return { ...answer, name };
    });

    const sortedAnswers = answersArray.sort((a, b) => a.idx - b.idx);

    sortedAnswers.forEach((answer) => {
      const category = answer.category || 'Uncategorized';

      if (!result.has(category)) {
        result.set(category, []);
      }

      result.get(category)?.push(answer);
    });

    return {
      categorizedAnswers: result,
      sortedCategories: [...result.keys()].sort(),
      allQuestionsUncategorized: !hasCategory,
    };
  }, [grouped]);

  const allQuestionIds = useMemo(() => Object.values(grouped).map((answer) => answer.questionId), [grouped]);

  const {
    expandedIds: expandedCategories,
    isExpanded: isCategoryExpanded,
    toggleExpanded: toggleCategory,
    toggleAll: toggleAllCategories,
  } = useExpandableState(sortedCategories);

  const {
    expandedIds: expandedQuestions,
    isExpanded: isQuestionExpanded,
    toggleExpanded: toggleQuestion,
    toggleAll: toggleAllQuestions,
  } = useExpandableState(allQuestionIds);

  const toggleAllQuestionsInCategory = (category: string) => {
    const questionsInCategory = categorizedAnswers.get(category) || [];
    const questionIds = questionsInCategory.map((answer) => answer.questionId);

    if (questionIds.every((id) => expandedQuestions.has(id))) {
      const newExpandedQuestions = new Set(expandedQuestions);
      questionIds.forEach((id) => {
        // eslint-disable-next-line drizzle/enforce-delete-with-where
        newExpandedQuestions.delete(id);
      });
      toggleAllQuestions([...newExpandedQuestions]);
    } else {
      const newExpandedQuestions = new Set(expandedQuestions);
      questionIds.forEach((id) => newExpandedQuestions.add(id));
      toggleAllQuestions([...newExpandedQuestions]);
    }
  };

  const totalDocuments = useMemo(() => {
    return tree.getDocumentIdsFromFoldersAndDocuments(presetRun.folderIds, presetRun.documentIds, true).size;
  }, [tree, treeKey, presetRun.folderIds, presetRun.documentIds]);

  const { totalQuestionCount, answeredQuestionCount, pendingDocumentCount } = useMemo(() => {
    let pendingDocuments = new Set<string>();
    let totalQuestionCount = 0;
    let answeredQuestionCount = 0;
    for (const question of presetRun.questions) {
      for (const answer of question.documentAnswers) {
        totalQuestionCount++;
        if (answer.isRelevant != null) {
          answeredQuestionCount++;
        } else {
          pendingDocuments.add(answer.document.id);
        }
      }
    }
    return {
      totalQuestionCount,
      answeredQuestionCount,
      pendingDocumentCount: pendingDocuments.size,
    };
  }, [presetRun]);

  const answerCount = Object.keys(grouped).length;
  const isPending = presetRun.status === PresetRunStatus.Running;
  const isCanceled = presetRun.status === PresetRunStatus.Canceled;

  if ((isPending && answerCount === 0) || (!isPending && answerCount === 0)) {
    return (
      <div className="grid gap-4">
        {isPending && answerCount === 0 && (
          <SpinnerBlock
            message={
              answeredQuestionCount === totalQuestionCount
                ? 'Aggregating answers...'
                : `Analysing documents ${totalDocuments - pendingDocumentCount}/${totalDocuments}...`
            }
          />
        )}

        {!isPending && answerCount === 0 && (
          <div className="text-center text-gray-600">
            {isCanceled
              ? 'Analysis canceled.'
              : 'Could not find data to analyse, documents have probably been removed.'}
          </div>
        )}
      </div>
    );
  }

  if (allQuestionsUncategorized) {
    return (
      <div className="grid gap-4">
        <div className="flex justify-end mb-2">
          <ExpandAllButton
            allIds={allQuestionIds}
            expandedIds={expandedQuestions}
            onToggle={toggleAllQuestions}
            label="Expand All Questions"
            collapsedLabel="Collapse All Questions"
          />
        </div>

        <div className="flex flex-col gap-4">
          {Object.entries(grouped)
            .sort(([, a], [, b]) => a.idx - b.idx)
            .map(([questionName, value]) => {
              const documentRefs: IDocumentDict = {};
              const references = value.documents.map((doc, docIdx) => {
                documentRefs[doc.doc.id] = {
                  name: doc.doc.name,
                };

                return {
                  refId: docIdx,
                  documentId: doc.doc.id,
                };
              });

              let answerToDisplay = showMinified ? value.minifiedAnswer : value.answer;
              if (showTranslated) {
                answerToDisplay = showMinified ? value.translatedMinifiedAnswer : value.translatedAnswer;
              }

              // Ensure there's at least a dash if no answer content
              if (!answerToDisplay || answerToDisplay.trim() === '') {
                answerToDisplay = '-';
              }

              return (
                <Expandable
                  title={questionName}
                  isExpanded={isQuestionExpanded(value.questionId)}
                  onExpandToggle={() => toggleQuestion(value.questionId)}
                  headerClassName="font-medium p-2 bg-gray-50 cursor-pointer hover:bg-gray-100 flex items-center gap-1 border-b border-gray-200"
                  caretSize={4}
                  caretClassName="text-gray-500"
                  rightElement={
                    <Tooltip text={value.question}>
                      <QuestionIcon className="w-4 h-4" />
                    </Tooltip>
                  }
                >
                  <div
                    className={classNames({
                      'text-gray-900': !openDoc || openDoc.questionId === value.questionId,
                      'text-gray-600': openDoc && openDoc.questionId !== value.questionId,
                    })}
                  >
                    {isPending && value.isGenerating ? (
                      <AnswerLoadingBar seed={value.question} />
                    ) : (
                      <ChatMessageText
                        content={processSummary(answerToDisplay, references)}
                        references={references}
                        documents={documentRefs}
                        openRef={(refId) => {
                          const ref = references.find((v) => v.refId === refId);
                          if (!ref) {
                            return;
                          }

                          const foundDoc = value.documents.find((v) => v.doc.id === ref.documentId);
                          if (foundDoc) {
                            setOpenDoc(foundDoc);
                          }
                        }}
                      />
                    )}
                  </div>
                  {enableDebugMode && (
                    <div
                      className="mt-1 hover:text-link-color cursor-pointer inline-flex items-center"
                      onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        onOpenDebugAnswer(value);
                      }}
                    >
                      <BugIcon className="w-4 h-4 mr-1" />
                      <span>Debug</span>
                    </div>
                  )}
                </Expandable>
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-4">
      <div className="flex justify-end gap-2 mb-2">
        <ExpandAllButton
          allIds={sortedCategories}
          expandedIds={expandedCategories}
          onToggle={toggleAllCategories}
          label="Expand All Categories"
          collapsedLabel="Collapse All Categories"
        />
      </div>

      <div className="flex flex-col gap-4">
        {sortedCategories.map((category) => {
          const answersInCategory = categorizedAnswers.get(category) || [];
          const categoryQuestionIds = answersInCategory.map((answer) => answer.questionId);
          const areAllQuestionsExpanded =
            categoryQuestionIds.length > 0 && categoryQuestionIds.every((id) => expandedQuestions.has(id));

          return (
            <Expandable
              title={category}
              isExpanded={isCategoryExpanded(category)}
              onExpandToggle={() => toggleCategory(category)}
              itemCount={answersInCategory.length}
              itemLabel="questions"
              headerClassName="font-semibold gap-1 flex items-center p-3 bg-gray-100 cursor-pointer hover:bg-gray-200 border-b border-gray-200"
              caretSize={5}
              caretClassName="text-gray-600"
              contentClassName="p-2"
            >
              <div className="flex justify-end mb-2">
                <ExpandAllButton
                  allIds={categoryQuestionIds}
                  expandedIds={expandedQuestions}
                  onToggle={() => toggleAllQuestionsInCategory(category)}
                  label="Expand All Questions"
                  collapsedLabel="Collapse All Questions"
                />
              </div>

              <div className="flex flex-col gap-2">
                {answersInCategory.map((value) => {
                  const documentRefs: IDocumentDict = {};
                  const references = value.documents.map((doc, docIdx) => {
                    documentRefs[doc.doc.id] = {
                      name: doc.doc.name,
                    };

                    return {
                      refId: docIdx,
                      documentId: doc.doc.id,
                    };
                  });

                  let answerToDisplay = showMinified ? value.minifiedAnswer : value.answer;
                  if (showTranslated) {
                    answerToDisplay = showMinified ? value.translatedMinifiedAnswer : value.translatedAnswer;
                  }

                  // Ensure there's at least a dash if no answer content
                  if (!answerToDisplay || answerToDisplay.trim() === '') {
                    answerToDisplay = '-';
                  }

                  const questionTooltip = (
                    <Tooltip text={value.question}>
                      <QuestionIcon className="w-4 h-4" />
                    </Tooltip>
                  );

                  return (
                    <Expandable
                      title={
                        <div className="flex flex-wrap gap-1 items-center">
                          <div className="text-wrap hyphens-auto">{value.name}</div>
                        </div>
                      }
                      isExpanded={isQuestionExpanded(value.questionId)}
                      onExpandToggle={() => toggleQuestion(value.questionId)}
                      headerClassName="font-medium p-2 bg-gray-50 cursor-pointer hover:bg-gray-100 flex items-center gap-1 border-b border-gray-200"
                      caretSize={4}
                      caretClassName="text-gray-500"
                      rightElement={questionTooltip}
                    >
                      <div
                        className={classNames({
                          'text-gray-900': !openDoc || openDoc.questionId === value.questionId,
                          'text-gray-600': openDoc && openDoc.questionId !== value.questionId,
                        })}
                      >
                        {isPending && value.isGenerating ? (
                          <AnswerLoadingBar seed={value.question} />
                        ) : (
                          <ChatMessageText
                            content={processSummary(answerToDisplay, references)}
                            references={references}
                            documents={documentRefs}
                            openRef={(refId) => {
                              const ref = references.find((v) => v.refId === refId);
                              if (!ref) {
                                return;
                              }

                              const foundDoc = value.documents.find((v) => v.doc.id === ref.documentId);
                              if (foundDoc) {
                                setOpenDoc(foundDoc);
                              }
                            }}
                          />
                        )}
                      </div>
                      {enableDebugMode && (
                        <div
                          className="mt-1 hover:text-link-color cursor-pointer inline-flex items-center"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();

                            onOpenDebugAnswer(value);
                          }}
                        >
                          <BugIcon className="w-4 h-4 mr-1" />
                          <span>Debug</span>
                        </div>
                      )}
                    </Expandable>
                  );
                })}
              </div>
            </Expandable>
          );
        })}
      </div>
    </div>
  );
};
