type Severity = 'low' | 'medium' | 'high' | 'critical';

export function riskScoreToSeverity(score: number): Severity {
  if (score < 35) {
    return 'low';
  } else if (score < 70) {
    return 'medium';
  } else if (score < 85) {
    return 'high';
  } else {
    return 'critical';
  }
}
