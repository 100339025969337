import React from 'react';

import { getRandomValue } from '@/utils/number';

export interface IAnswerLoadingBarProps {
  seed: string;
}

export const AnswerLoadingBar: React.FC<IAnswerLoadingBarProps> = ({ seed }) => {
  return (
    <div className="h-full flex items-center">
      <div
        className="bg-gray-300 rounded animate-pulse"
        style={{
          height: 12,
          width: Math.round(50 + getRandomValue(seed) * 100),
        }}
      >
        &nbsp;
      </div>
    </div>
  );
};
