import { Emitter } from '@/utils/emitter';
import { WorkspaceState } from './WorkspaceStates';
import { IWorkspaceRisksDetectedMessage, IWorkspaceRisksUpdatedMessage } from '../websocket/workspace/message-types';
import { sleep } from '@/utils/sleep';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as IListWorkspaceRisksResponse } from '@/app/workspaceRisk/endpoints/ListWorkspaceRisks';
import { SinglePromiseRunner } from '@/utils/promise';

export interface IRisk {
  id: string;
  title: string;
  description: string;
  clause: string;
  documentId: string;
  chunkId: string | null;
  isArchived: boolean;
  isPartOfTheReport: boolean;
  assigneeId: string | null;
  hasBeenScored: boolean;
  overallScore: number;
  overallScoreOverwrite: number | null;
}

function sortRisks(a: IRisk, b: IRisk): number {
  const diff = (b.overallScoreOverwrite ?? b.overallScore) - (a.overallScoreOverwrite ?? a.overallScore);
  if (diff !== 0) {
    return diff;
  }
  return +a.id - +b.id;
}

export class WorkspaceRisks {
  riskMap = new Map<string, IRisk>();

  fetchRunner = new SinglePromiseRunner();

  public risksUpdateKey = Date.now().toString(16);
  private risksUpdateEmitter = new Emitter<string>();
  public onRisksUpdate = this.risksUpdateEmitter.event;

  constructor(private workspaceState: WorkspaceState) {
    workspaceState.treeState.onTreeChange(() => {
      let hasDeleted = false;
      for (const risk of this.riskMap.values()) {
        if (!workspaceState.treeState.entries.has(risk.documentId)) {
          // eslint-disable-next-line drizzle/enforce-delete-with-where
          this.riskMap.delete(risk.id);
          hasDeleted = true;
        }
      }

      if (hasDeleted) {
        this._updateRisksChanges();
      }
    });
  }

  private _updateRisksChanges() {
    this.risksUpdateKey = Date.now().toString(16);
    this.risksUpdateEmitter.fire(this.risksUpdateKey);
  }

  getRisk(riskId: string): IRisk | null {
    return this.riskMap.get(riskId) || null;
  }

  getActiveRisks() {
    return [...this.riskMap.values()].filter((v) => !v.isArchived).sort(sortRisks);
  }

  getArchivedRisks() {
    return [...this.riskMap.values()].filter((v) => !!v.isArchived).sort(sortRisks);
  }

  handleConnect() {
    this.fetchRunner.run(() => this.fetchInitialRisks());
  }

  private async _fetchInitialRisks(): Promise<void> {
    const result = await fetchEndpointData<IListWorkspaceRisksResponse>(
      `/api/v1/workspace/${this.workspaceState.workspaceId}/risk?take=500`,
      {
        method: 'GET',
      },
    );
    for (const risk of result.docs) {
      this.riskMap.set(risk.id, risk);
    }
    this._updateRisksChanges();
  }

  private async fetchInitialRisks(): Promise<void> {
    try {
      await this._fetchInitialRisks();
    } catch (err) {
      console.error(err);
      await sleep(5000);
      return this.fetchInitialRisks();
    }
  }

  handleRisksDetectedEvent(evt: IWorkspaceRisksDetectedMessage['data']) {
    if (evt.workspaceId !== this.workspaceState.workspaceId) {
      return;
    }

    const documentId = evt.documentId;
    const risks = evt.risks;
    for (const r of risks) {
      const risk: IRisk = this.riskMap.get(r.id) ?? {
        ...r,
        documentId,
        hasBeenScored: false,
        isArchived: false,
        isPartOfTheReport: false,
        assigneeId: null,
        overallScore: 0,
        overallScoreOverwrite: null,
      };
      this.riskMap.set(risk.id, { ...risk });
    }

    this._updateRisksChanges();
  }

  handleRiskUpdatedEvent(evt: IWorkspaceRisksUpdatedMessage['data']) {
    if (evt.workspaceId !== this.workspaceState.workspaceId) {
      return;
    }

    const riskId = evt.risk.id;
    const risk = this.riskMap.get(riskId);
    if (!risk) {
      return;
    }

    risk.overallScore = evt.risk.overallScore;
    risk.overallScoreOverwrite = evt.risk.overallScoreOverwrite;
    risk.title = evt.risk.title ?? risk.title;
    risk.description = evt.risk.description ?? risk.description;
    risk.isArchived = evt.risk.isArchived ?? risk.isArchived;
    risk.isPartOfTheReport = evt.risk.isPartOfTheReport ?? risk.isPartOfTheReport;
    if (evt.risk.assigneeId !== undefined) {
      risk.assigneeId = evt.risk.assigneeId;
    }
    risk.hasBeenScored = true;

    this.riskMap.set(riskId, { ...risk });

    this._updateRisksChanges();
  }

  updateRisk(
    riskId: string,
    changes: {
      title?: string;
      description?: string;
      isArchived?: boolean;
      isPartOfTheReport?: boolean;
      assigneeId?: string;
      overallScore?: number;
      overallScoreOverwrite?: number | null;
    },
  ) {
    const risk = this.riskMap.get(riskId);
    if (!risk) {
      return;
    }

    if (changes.title) {
      risk.title = changes.title;
    }

    if (changes.description) {
      risk.description = changes.description;
    }

    if (changes.overallScore != null) {
      risk.overallScore = changes.overallScore;
    }

    if (changes.overallScoreOverwrite !== undefined) {
      risk.overallScoreOverwrite = changes.overallScoreOverwrite;
    }

    if (changes.isPartOfTheReport != null) {
      risk.isPartOfTheReport = changes.isPartOfTheReport;
    }

    if (changes.isArchived != null) {
      risk.isArchived = changes.isArchived;

      if (changes.isArchived) {
        risk.isPartOfTheReport = false;

        // TODO: Split the risks into 2 lists? One for archived and one for active?
      }
    }

    if (changes.assigneeId !== undefined) {
      risk.assigneeId = changes.assigneeId;
    }

    this.riskMap.set(riskId, { ...risk });

    this._updateRisksChanges();
  }
}
