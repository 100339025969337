import React, { useState } from 'react';
import { Question as QuestionIcon } from '@phosphor-icons/react';

import { PresetRun } from '../WorkspaceAnalysisStatePage';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { Button } from '@/components/button/Button';
import type {
  BodyType as StartAnalysisPayload,
  ResponseType as StartAnalysisResponse,
} from '@/app/workspacePresetRun/endpoints/StartAnalysisEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';
import { getDisplayError } from '@/utils/get-display-error';
import { Tooltip } from '@/components/tooltip/Tooltip';
import { SpinnerBlock } from '@/components/Spinner';
import { Expandable, useExpandableState, ExpandAllButton } from '@/components/expandable/Expandable';

export interface IAnalysisQuestionaireProps {
  presetRun: PresetRun;
}

export const AnalysisQuestionaire: React.FC<IAnalysisQuestionaireProps> = (props) => {
  const { presetRun } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>(presetRun.questions.map((v) => v.id));
  const questionsHash = presetRun.questions.map((v) => v.id).join(',');

  React.useEffect(() => {
    setSelectedQuestions(presetRun.questions.map((v) => v.id));
  }, [questionsHash]);

  // Create categorized questions object
  const categorizedQuestions = React.useMemo(() => {
    return presetRun.questions.reduce(
      (acc, question) => {
        const category = question.category || 'Uncategorized';
        if (!acc[category]) acc[category] = [];
        acc[category].push(question);
        return acc;
      },
      {} as Record<string, typeof presetRun.questions>,
    );
  }, [presetRun.questions]);

  // Get list of categories for expand/collapse all functionality
  const categoryKeys = Object.keys(categorizedQuestions);

  // Use expandable states for categories
  const {
    expandedIds: expandedCategories,
    isExpanded: isCategoryExpanded,
    toggleExpanded: toggleCategory,
    toggleAll: toggleAllCategories,
  } = useExpandableState(categoryKeys);

  const toggleCategorySelection = (category: string, event?: React.MouseEvent) => {
    // Prevent expanding/collapsing when clicking the checkbox
    if (event) {
      event.stopPropagation();
    }

    const questions = categorizedQuestions[category] || [];
    const allSelected = questions.length > 0 && questions.every((q) => selectedQuestions.includes(q.id));

    setSelectedQuestions((prev) => {
      const newSelection = new Set(prev);
      if (!questions.length) return prev;

      questions.forEach((q) => {
        if (allSelected) {
          // eslint-disable-next-line drizzle/enforce-delete-with-where
          newSelection.delete(q.id);
        } else {
          newSelection.add(q.id);
        }
      });
      return Array.from(newSelection);
    });
  };

  const handleCheck = (checkedId: string, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setSelectedQuestions((prev) => {
      const newSelection = new Set(prev);
      if (newSelection.has(checkedId)) {
        // eslint-disable-next-line drizzle/enforce-delete-with-where
        newSelection.delete(checkedId);
      } else {
        newSelection.add(checkedId);
      }
      return Array.from(newSelection);
    });
  };

  const startAnalysis = async () => {
    setIsLoading(true);
    try {
      const payload: StartAnalysisPayload = {
        analysisId: presetRun.id,
        questionIds: selectedQuestions,
      };
      await fetchEndpointData<StartAnalysisResponse>(`/api/v1/workspace/analysis/start`, {
        method: 'POST',
        body: payload,
      });
      toast.success('Analysis has been started');
    } catch (err) {
      captureException(err);
      toast.error('Could not start analysis: ' + getDisplayError(err));
    }
    setIsLoading(false);
  };

  const isAllSelected = selectedQuestions.length === presetRun.questions.length;
  return (
    <div>
      <div className="mb-4">Select the questions to run in this analysis and start the analysis.</div>
      {presetRun.questions.length > 0 && (
        <div className="flex justify-start items-center gap-2 mb-4">
          <Checkbox
            size={6}
            onChange={() => setSelectedQuestions(isAllSelected ? [] : presetRun.questions.map((v) => v.id))}
            isChecked={isAllSelected}
          />

          <ExpandAllButton
            allIds={categoryKeys}
            expandedIds={expandedCategories}
            onToggle={toggleAllCategories}
            label="Expand All Categories"
            collapsedLabel="Collapse All Categories"
            className="px-3 py-1 bg-gray-100 hover:bg-gray-200 rounded-md text-sm font-medium flex items-center gap-1 mr-2"
          />
        </div>
      )}

      {!presetRun.questions.length ? (
        presetRun.isAuto ? (
          <SpinnerBlock message="Extracting relevant questions..." />
        ) : (
          <div>No questions defined.</div>
        )
      ) : (
        <div className="flex flex-col gap-4">
          {Object.entries(categorizedQuestions).map(([category, questions]) => {
            const isCategoryChecked = questions.length > 0 && questions.every((q) => selectedQuestions.includes(q.id));

            return (
              <Expandable
                title={category}
                isExpanded={isCategoryExpanded(category)}
                onExpandToggle={() => toggleCategory(category)}
                itemCount={questions.length}
                itemLabel="questions"
                className="border border-gray-200 rounded-md overflow-hidden"
                headerClassName="font-semibold gap-1 flex items-center p-3 cursor-pointer hover:bg-gray-200"
                contentClassName="pl-4 pr-4 pt-2 pb-2"
                caretSize={5}
                caretClassName="text-gray-600"
                withHeaderBg={true}
                titlePrefix={
                  <div className="w-6 flex justify-center mr-2" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      size={6}
                      onChange={() => toggleCategorySelection(category)}
                      isChecked={isCategoryChecked}
                    />
                  </div>
                }
              >
                <div className="mt-2 mb-2">
                  {questions.map((question) => (
                    <div
                      key={question.id}
                      className="border border-gray-200 rounded-md overflow-hidden mb-3 bg-gray-50"
                      onClick={(e) => handleCheck(question.id, e)}
                    >
                      <div className="font-medium p-2 flex items-center cursor-pointer hover:bg-gray-100">
                        <div className="w-6 flex justify-center mr-2" onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            size={6}
                            onChange={() => handleCheck(question.id)}
                            isChecked={selectedQuestions.includes(question.id)}
                          />
                        </div>
                        <span className="flex-1">{question.name}</span>
                        <Tooltip text={question.question}>
                          <QuestionIcon className="w-4 h-4 ml-2 text-gray-500" />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </Expandable>
            );
          })}
        </div>
      )}

      <div className="flex justify-end my-7">
        <Button
          onTrigger={startAnalysis}
          isDisabled={!selectedQuestions.length}
          isLoading={isLoading}
          variant="primary"
        >
          Start Analysis
        </Button>
      </div>
    </div>
  );
};
