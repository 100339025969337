import { Formik } from 'formik';
import React from 'react';

import { Button } from '@/components/button/Button';
import { InputField } from '@/components/input/InputField';
import { LanguageSelectField } from '@/components/LanguageSelect';
import { IWorkspaceFormValues } from './CreateWorkspacePage';

export interface IKnowledgeWorkspaceDetailsPageProps {
  formValues: IWorkspaceFormValues;
  setFormValues: (values: IWorkspaceFormValues) => void;
  goBack: () => void;
}

export const KnowledgeWorkspaceDetailsPage: React.FC<IKnowledgeWorkspaceDetailsPageProps> = (props) => {
  const { formValues, setFormValues, goBack } = props;

  return (
    <div className="card">
      <div className="heading-two mb-4">Knowledge base details</div>

      <Formik
        initialValues={formValues}
        onSubmit={(values) => {
          setFormValues({
            name: values.name,
            language: values.language,
          });
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputField type="text" name="name" labelText="Knowledge base name" />
              <LanguageSelectField name="language" labelText="Output language" />
            </div>

            <div className="flex justify-between mt-4">
              <Button variant="default" onTrigger={goBack}>
                Previous
              </Button>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
