import React from 'react';

import { GroupedAnswers } from './group-answers';
import { ReferenceSidecar } from '../ReferenceSidecar';

export interface ISingleDocReferenceSidecarProps {
  answerId: string;
  answers: GroupedAnswers;
  openRef: (answerId: string) => void;
}

export const SingleDocReferenceSidecar: React.FC<ISingleDocReferenceSidecarProps> = (props) => {
  const { answerId, answers, openRef } = props;

  const answer = answers.groupedPerAnswerId.get(answerId);
  if (!answer) {
    return null;
  }

  const doc = answers.documents.get(answer.documentId);
  if (!doc) {
    return null;
  }

  return (
    <div className="flex w-[calc(45vw-2.5rem)]">
      <div className="h-full w-8 bg-gray-100 border-l border-r border-gray-200"></div>

      <div className="relative flex-1">
        <div className="fixed bg-white overflow-y-auto bottom-0 h-without-topbar w-[calc(45vw-4.5rem)]">
          <ReferenceSidecar
            documentAnswer={`${answer?.full ?? ''}\n${answer?.minified}`}
            documentAnswerId={answer?.documentAnswerId ?? ''}
            document={{
              id: doc.id,
              name: doc.name,
              folderId: doc.folderId!,
            }}
            onOpenChange={(newOpen) => {
              if (!newOpen) {
                openRef('');
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
