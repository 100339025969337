export interface IDeferedPromise<T> {
  promise: Promise<T>;
  resolve: (value: T) => void;
  reject: (reason: any) => void;
}

export function createDeferedPromise<T>(): IDeferedPromise<T> {
  let resolve: (value: T) => void;
  let reject: (reason: any) => void;
  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  // @ts-ignore
  return { promise, resolve, reject };
}

/**
 * SinglePromiseRunner - A utility to ensure only one promise executes at a time
 * If a promise is already running when a new one is attempted, the new one is ignored
 */
export class SinglePromiseRunner<T = any> {
  isPending: boolean;
  currentPromise: Promise<T> | null = null;

  constructor() {
    this.isPending = false;
    this.currentPromise = null;
  }

  run(factory: () => Promise<T>): Promise<T> | null {
    // If a promise is already running, return null
    if (this.isPending) {
      return null;
    }

    // Mark as pending
    this.isPending = true;

    // Execute the promise
    this.currentPromise = factory().finally(() => {
      // Reset pending state when done (whether resolved or rejected)
      this.isPending = false;
      this.currentPromise = null;
    });

    return this.currentPromise;
  }

  isRunning() {
    return this.isPending;
  }
}
