import { useEffect, useState, useRef } from 'react';
import { createId } from '@paralleldrive/cuid2';

import { BaseButton } from '../../../components/button/BaseButton';
import { useWebsocket } from '../../../contexts/websocket-context';
import { LanguageEnum } from '../enums';
import { DisposableStore } from '../../../utils/disposable';
import { ChatMessageText } from '../../workspaceChat/pages/chat/ChatMessage';
import { SpinnerBlock } from '../../../components/Spinner';
import { DialogContent, DialogRoot, DialogTrigger } from '../../../components/dialog/Dialog';

export interface ISummarizeDialogProps {
  title: string;
  documentId: string;
  language: string;
}

export function SummarizeDialog(props: ISummarizeDialogProps) {
  const { title, documentId, language } = props;
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { websocket: wsClient } = useWebsocket();
  const [msgRef] = useState(createId());
  const [content, setContent] = useState('');
  const [displayedContent, setDisplayedContent] = useState('');

  // For tracking the simulated streaming
  const fullContentRef = useRef('');
  const streamingTimerRef = useRef<number | null>(null);
  const currentCharIndexRef = useRef(0);

  // Simulate streaming text effect
  const simulateStreaming = (fullText: string) => {
    // Clear any existing timer
    if (streamingTimerRef.current) {
      window.clearInterval(streamingTimerRef.current);
    }

    // Store the full content
    fullContentRef.current = fullText;
    currentCharIndexRef.current = 0;
    setDisplayedContent('');

    // Start streaming at random intervals (15-35ms per character)
    streamingTimerRef.current = window.setInterval(
      () => {
        if (currentCharIndexRef.current < fullContentRef.current.length) {
          // Add 1-3 characters at a time for more realistic streaming
          const charsToAdd = Math.floor(Math.random() * 3) + 1;
          const nextIndex = Math.min(currentCharIndexRef.current + charsToAdd, fullContentRef.current.length);
          const nextChunk = fullContentRef.current.substring(currentCharIndexRef.current, nextIndex);

          setDisplayedContent((prev) => prev + nextChunk);
          currentCharIndexRef.current = nextIndex;
        } else {
          // Stop streaming when finished
          if (streamingTimerRef.current) {
            window.clearInterval(streamingTimerRef.current);
            streamingTimerRef.current = null;
          }
        }
      },
      Math.floor(Math.random() * 15) + 10,
    );
  };

  useEffect(() => {
    const disposableStore = new DisposableStore();

    disposableStore.add(
      wsClient.onMessage((message) => {
        if (message.ref === msgRef) {
          if (message.method === 'workspace/document/summarize-response') {
            setContent(message.data.content);

            // Start streaming the content instead of showing it all at once
            simulateStreaming(message.data.content);

            if (message.data.isFinished) {
              setIsFetching(false);
              disposableStore.dispose();
            }
          }
        }
      }),
    );

    disposableStore.add(
      wsClient.onErrorMessage((message) => {
        const errorContent = `Error: ${message.error.message}`;
        setContent(errorContent);
        // We can still stream error messages
        simulateStreaming(errorContent);

        if (message.ref === msgRef) {
          setIsFetching(false);
          disposableStore.dispose();
        }
      }),
    );

    return () => {
      // Clear any streaming timers on unmount
      if (streamingTimerRef.current) {
        window.clearInterval(streamingTimerRef.current);
      }
      disposableStore.dispose();
    };
  }, [msgRef, wsClient]);

  useEffect(() => {
    if (open && !isFetching && !content) {
      setIsFetching(true);

      wsClient.send({
        ref: msgRef,
        method: 'workspace/document/summarize-request',
        data: {
          documentId,
          language: language.toLowerCase() as LanguageEnum,
        },
      });
    }
  }, [open, isFetching, content, documentId, language, msgRef, wsClient]);

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <BaseButton
          isLoading={isFetching}
          onClick={() => {
            setOpen(true);
          }}
        >
          Summarize
        </BaseButton>
      </DialogTrigger>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Summary of {title}</h1>

        <div className="overflow-y-auto" style={{ height: '50vh' }}>
          {Boolean(!displayedContent && isFetching) ? (
            <SpinnerBlock message="Generating summary..." />
          ) : (
            <ChatMessageText content={displayedContent} references={[]} documents={{}} openRef={() => {}} />
          )}
        </div>
      </DialogContent>
    </DialogRoot>
  );
}
