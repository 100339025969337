import toast from 'react-hot-toast';
import { Trash as TrashIcon, PencilSimple as EditIcon, Plus as PlusIcon } from '@phosphor-icons/react';
import { useState, useEffect } from 'react';

import { useTeam } from '@/app/team/context/TeamContext';
import { Button } from '@/components/button/Button';
import { Table } from '@/components/table/Table';
import { useAuth } from '@/contexts/auth-context';
import { UserFormModal, UserFormValues } from '../components/AddTeamUserModal';
import { Tooltip } from '@/components/tooltip/Tooltip';

export interface ICreateWorkspaceUsersPageProps {
  users: WorkspaceUser[];
  setUsers: (users: WorkspaceUser[]) => void;

  isSubmitting: boolean;
  createWorkspace: () => void;
  goBack: () => void;
}

export interface WorkspaceUser {
  id: string;
  name: string;
  permissions: string[];
  isOwner: boolean;
}

interface UserToEdit {
  assignedUser: {
    id: string;
    name: string;
  };
  permissions: string[];
  isOwner: boolean;
}

const USERS_HEADER = [
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'permissions',
    name: 'Permissions',
  },
  {
    id: 'isOwner',
    name: 'Owner',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

export const CreateWorkspaceUsersPage: React.FC<ICreateWorkspaceUsersPageProps> = (props) => {
  const { users, setUsers, isSubmitting, createWorkspace, goBack } = props;
  const { team } = useTeam();
  const { me } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [userToEdit, setUserToEdit] = useState<UserToEdit | null>(null);

  useEffect(() => {
    setUsers([
      {
        id: me.id,
        name: me.name,
        permissions: [],
        isOwner: true,
      },
    ]);
  }, [me, team]);

  const handleAddUser = (values: UserFormValues): void => {
    if (!values.assignedUser) return;

    const newUser: WorkspaceUser = {
      id: values.assignedUser.id,
      name: values.assignedUser.name,
      permissions: values.permissions,
      isOwner: values.isOwner,
    };

    setUsers([...users, newUser]);
    toast.success(`${values.assignedUser.name} added to users list`);
  };

  const handleUpdateUser = (values: UserFormValues): void => {
    if (!values.assignedUser) return;

    setUsers(
      users.map((user) =>
        user.id === values.assignedUser?.id
          ? {
              id: values.assignedUser.id,
              name: values.assignedUser.name,
              permissions: values.permissions,
              isOwner: values.isOwner,
            }
          : user,
      ),
    );

    toast.success(`${values.assignedUser.name} updated successfully`);
  };

  const handleEditUser = (userId: string) => {
    const userToEdit = users.find((user) => user.id === userId);
    if (userToEdit) {
      const formattedUser: UserToEdit = {
        assignedUser: {
          id: userToEdit.id,
          name: userToEdit.name,
        },
        permissions: userToEdit.permissions,
        isOwner: userToEdit.isOwner,
      };

      setUserToEdit(formattedUser);
      setIsEditModalOpen(true);
    }
  };

  const handleRemoveUser = (userId: string) => {
    setUsers(users.filter((user) => user.id !== userId));
  };

  return (
    <div className="card">
      <div className="flex justify-between items-center mb-4">
        <h3 className="heading-two">Add users to the workspace</h3>

        <Button variant="ghost" iconLeft={<PlusIcon className="button-icon" />} onTrigger={() => setIsModalOpen(true)}>
          Add User
        </Button>
      </div>

      <UserFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddUser}
        mode="add"
        existingUsers={users}
      />

      {userToEdit && (
        <UserFormModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setUserToEdit(null);
          }}
          onSubmit={handleUpdateUser}
          initialValues={userToEdit}
          mode="edit"
          existingUsers={users}
        />
      )}

      <div>
        <Table
          idKey="id"
          headers={USERS_HEADER}
          data={users}
          mapData={(user) => [
            user.name,
            Array.isArray(user.permissions) ? user.permissions.join(', ') : '',
            user.isOwner ? 'Yes' : 'No',
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={() => handleEditUser(user.id)}
                className="text-blue-500 hover:text-blue-700"
                title="Edit user"
                disabled={me.id === user.id}
              >
                {me.id === user.id ? (
                  <Tooltip text="Can't edit your own user permissions">
                    <EditIcon className="w-5 h-5" />
                  </Tooltip>
                ) : (
                  <EditIcon className="w-5 h-5" />
                )}
              </button>
              <button
                type="button"
                onClick={() => handleRemoveUser(user.id)}
                className="text-red-500 hover:text-red-700"
                title="Remove user"
                disabled={me.id === user.id}
              >
                {me.id === user.id ? (
                  <Tooltip text="You can't delete your own user">
                    <TrashIcon className="w-5 h-5" />
                  </Tooltip>
                ) : (
                  <TrashIcon className="w-5 h-5" />
                )}
              </button>
            </div>,
          ]}
        />
      </div>

      <div className="flex justify-between mt-4">
        <Button variant="default" onTrigger={goBack} isLoading={isSubmitting}>
          Previous
        </Button>
        <Button variant="primary" onTrigger={createWorkspace} isLoading={isSubmitting}>
          Create workspace
        </Button>
      </div>
    </div>
  );
};
