import React, { useMemo } from 'react';
import { File as FileIcon, Globe as GlobeIcon } from '@phosphor-icons/react';

import { ISelectionCardOption, SelectionCards } from '@/components/SelectionCards';
import { QuestionsPresetType } from '../enums';

export interface IPresetTypeSelectorProps {
  value: QuestionsPresetType;
  onSelect: (value: QuestionsPresetType) => void;
}

export const PresetTypeSelector: React.FC<IPresetTypeSelectorProps> = (props) => {
  const { value, onSelect } = props;

  const cards: ISelectionCardOption<QuestionsPresetType>[] = useMemo(() => {
    return [
      {
        icon: <FileIcon className="selection-card-icon" />,
        title: 'One by One',
        description: `Analyse on a document per document basis, this ensures the highest level of precision and control.`,
        value: QuestionsPresetType.OneByOne,
      },
      {
        icon: <GlobeIcon className="selection-card-icon" />,
        title: 'Cross Document (experimental)',
        description:
          'Combine insights from multiple documents into one result (per question), finding connections and discrepencies between documents.',
        value: QuestionsPresetType.CrossDocument,
      },
    ];
  }, []);

  return <SelectionCards cols={2} cards={cards} onSelect={onSelect} value={value} />;
};
