import { useMemo, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { DialogContent, DialogRoot } from '@/components/dialog/Dialog';
import { useTeam } from '@/app/team/context/TeamContext';
import { Tag } from '@/components/Tag';
import { Button } from '@/components/button/Button';
import { fetchEndpointData } from '@/utils/fetch.client';
import {
  BodyType as MarkDocsBodyType,
  ResponseType as MarkDocsResponseType,
} from '../endpoints/MarkDocumentsForRiskAssessmentEndpoint';

export const IgnoredRiskDocsDialog = () => {
  const { team } = useTeam();
  const { workspace, tree, treeKey } = useWorkspace();
  const [open, setOpen] = useState(false);

  const markFileToAssess = async (fileId: string) => {
    try {
      const body: MarkDocsBodyType = {
        workspaceId: workspace.id,
        documentIds: [fileId],
      };
      await fetchEndpointData<MarkDocsResponseType>(`/api/v1/workspace/risk/mark-documents`, {
        method: 'POST',
        body,
      });
      toast.success('File has been marked for risk assessment');
    } catch (err) {
      console.error(err);
      toast.error('Failed to mark file for risk assessment');
    }
  };

  const state = useMemo(() => {
    const docs = [];
    for (const [id, entry] of tree.entries.entries()) {
      const doc = entry.document;
      if (doc) {
        if (!doc.shouldSpotRisks) {
          docs.push({
            id,
            path: entry.getFullpath(),
          });
        }
      }
    }
    return {
      docs,
    };
  }, [treeKey]);

  if (!state.docs.length) {
    return null;
  }

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <Tag
        color="blue"
        onClick={() => {
          setOpen(true);
        }}
      >{`${state.docs.length} ignored`}</Tag>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Ignored documents</h1>

        <div className="grid gap-2">
          {state.docs.map((v) => {
            return (
              <div className="flex justify-between items-center gap-2 card-no-padding" key={v.id}>
                <ReactLink
                  className="flex-1 justify-between items-center px-2 py-1 gap-2"
                  to={`/app/t/${team.id}/workspace/${workspace.id}/documents/redirect-to/${v.id}`}
                >
                  {v.path}
                </ReactLink>
                <div className="p-1">
                  <Button
                    size={6}
                    onTrigger={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();

                      markFileToAssess(v.id).catch((err) => {
                        console.error(err);
                      });
                    }}
                  >
                    <PlusIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
