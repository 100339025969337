export function parseNumberInput(value: string, precision = 0): number {
  const strValue = `${value}`.replace(/,+/g, '.');
  const parts = strValue.split('.');
  let inputValue = strValue;
  if (parts.length > 1) {
    const pointValue = parts.pop();
    inputValue = parts.join('') + '.' + pointValue;
  }
  const parsed = parseFloat(inputValue);
  // Faster than log?
  const precisionMultiplier = +('1' + '0'.repeat(precision));
  const multiplied = Math.round(parsed * precisionMultiplier);
  if (isNaN(multiplied)) {
    throw new Error('String is not a number');
  }
  return multiplied;
}

export function formatNumber(value: number, precision = 0, decimalSeperator = '.'): string {
  const isNegative = value < 0;
  const asString = `${Math.abs(Math.round(value))}`;
  const firstNumbers = asString.slice(0, -precision);
  const lastNumber = asString.slice(-precision);
  return `${isNegative ? '-' : ''}${firstNumbers || '0'}${decimalSeperator}${lastNumber.padStart(2, '0')}`;
}

export function NaNToZero(value: number): number {
  if (isNaN(value)) {
    return 0;
  } else {
    return value;
  }
}

export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * A deterministic random number generator that produces consistent values based on string input.
 *
 * Given the same string key, this function will always return the same "random" value,
 * making it useful for scenarios where you need reproducible randomness without
 * maintaining state between function calls.
 *
 * Uses a combination of string hashing and a linear congruential generator
 * to produce well-distributed values between 0 and 1.
 */
export function getRandomValue(key: string) {
  // Create a numeric hash from the string key using djb2 algorithm
  // This is both fast and has good distribution properties
  let hash = 5381;
  for (let i = 0; i < key.length; i++) {
    hash = (hash << 5) + hash + key.charCodeAt(i);
  }
  hash = hash >>> 0; // Convert to unsigned 32-bit integer

  // Use a simple linear congruential generator with the hash as seed
  // These values are from Numerical Recipes and provide good distribution
  const a = 1664525;
  const c = 1013904223;
  const m = 0xffffffff; // 2^32 - 1

  // Generate a value between 0 and 1
  const randomVal = ((a * hash + c) & m) / m;

  return randomVal;
}

export function tryParseFloat(value: any): number | null {
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    return null;
  } else {
    return parsed;
  }
}
