import React, { useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { captureException } from '@sentry/react';
import toast from 'react-hot-toast';
import { mutate } from 'swr';
import { createId } from '@paralleldrive/cuid2';

import { Card, CardLink, ActionMenuItem } from './Card';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { fetchEndpointData } from '@/utils/fetch.client';
import { getDisplayError } from '@/utils/get-display-error';
import { useTeam } from '@/app/team/context/TeamContext';
import type { BodyType as DeleteWorkspacePayload } from '@/app/workspace/endpoints/DeleteWorkspaceEndpoint';

// Create a context for workspace list updates
interface WorkspaceListContextValue {
  refreshToken: string;
  refreshWorkspaces: () => Promise<void>;
}

const WorkspaceListContext = createContext<WorkspaceListContextValue>({
  refreshToken: '',
  refreshWorkspaces: async () => {},
});

/**
 * Provider component that manages workspace list refresh state
 */
export const WorkspaceListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [refreshToken, setRefreshToken] = useState(() => createId());

  const refreshWorkspaces = async () => {
    setRefreshToken(createId());
    await mutate((key) => typeof key === 'string' && key.includes('/api/v1/workspace/list'));
  };

  return (
    <WorkspaceListContext.Provider value={{ refreshToken, refreshWorkspaces }}>
      {children}
    </WorkspaceListContext.Provider>
  );
};

/**
 * Hook to access workspace list refresh functionality
 */
export const useWorkspaceList = () => useContext(WorkspaceListContext);

export interface WorkspaceCardProps {
  id: string;
  title: string;
  type: string;
  to?: string;
  className?: string;
  children?: React.ReactNode;
  onDeleteSuccess?: () => void;
}

export const WorkspaceCard: React.FC<WorkspaceCardProps> = ({
  id,
  title,
  type,
  to,
  className,
  children,
  onDeleteSuccess,
}) => {
  const navigate = useNavigate();
  const { team } = useTeam();
  const { refreshWorkspaces } = useWorkspaceList();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (isDeleting) return;

    setIsDeleting(true);
    try {
      await fetchEndpointData('/api/v1/workspace/delete', {
        method: 'DELETE',
        body: { workspaceId: id } as DeleteWorkspacePayload,
      });

      await refreshWorkspaces();

      toast.success('Workspace has been deleted');

      if (onDeleteSuccess) {
        onDeleteSuccess();
      } else {
        navigate(`/app/t/${team.id}/workspace`);
      }
    } catch (err) {
      captureException(err);
      toast.error(`Could not delete workspace: ${getDisplayError(err)}`);
    } finally {
      setIsDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  const workspaceMenuItems: ActionMenuItem[] = [
    {
      icon: <PencilSimple size={20} className="text-neutral-500" />,
      label: 'Edit',
      to: to ? `${to}/settings/details` : `${id}/settings/details`,
      variant: 'default',
    },
    {
      icon: <Trash size={20} className="text-red-5" />,
      label: 'Delete Workspace',
      variant: 'destructive',
      onClick: () => setShowDeleteDialog(true),
    },
  ];

  const cardProps = {
    id,
    heading: title,
    subtitle: type,
    showAvatar: true,
    className,
    menuItems: workspaceMenuItems,
  };

  return (
    <>
      {to ? (
        <CardLink to={to} {...cardProps}>
          {children}
        </CardLink>
      ) : (
        <Card {...cardProps}>{children}</Card>
      )}

      <ConfirmDialog
        isOpen={showDeleteDialog}
        setIsOpen={setShowDeleteDialog}
        triggerText=""
        title="Delete workspace"
        submitText="Delete"
        confirmationText="Delete"
        submitVariant="destructive"
        description={`Are you sure you want to delete ${title}?`}
        onSubmit={handleDelete}
        isDisabled={isDeleting}
      />
    </>
  );
};

export default WorkspaceCard;
