import { classNames } from '@/utils/classnames';
import { Checkbox } from './Checkbox';

export interface ILabeledCheckboxProps {
  id?: string;
  isChecked: boolean;
  onChange: (val: boolean) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  labelText: string;
  tabIndex?: number;
}

export const LabeledCheckbox: React.FC<ILabeledCheckboxProps> = (props) => {
  const { id, isChecked, onChange, isInvalid, isDisabled, labelText, tabIndex } = props;

  return (
    <div
      className={classNames('flex items-center select-none', {
        'cursor-pointer': !isDisabled,
      })}
      onClick={() => {
        if (isDisabled) return;
        onChange(!isChecked);
      }}
    >
      <Checkbox
        isChecked={isChecked}
        onChange={onChange}
        id={id}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        tabIndex={tabIndex}
      />
      <div className="ml-2 text-dark-500">{labelText}</div>
    </div>
  );
};
