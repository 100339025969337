import { useMemo } from 'react';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { ProgressBar } from '../../../../components/ProgressBar';
import { Spinner, SpinnerBlock } from '../../../../components/Spinner';
import { PageHeader } from '@/components/PageHeader';
import { Breadcrumb } from '@/components/Breadcrumb';
import { CardDataField } from '@/components/DataField';
import { formatBytes } from '@/utils/text';

export interface ICountsComponentProps {
  title: string;
  counts: Map<string, number>;
}

export const CountsComponent: React.FC<ICountsComponentProps> = (props) => {
  const { title, counts } = props;

  const sorted = useMemo(() => {
    return Array.from(counts.entries()).sort((a, b) => b[1] - a[1]);
  }, [counts]);

  const highest = sorted[0]?.[1] ?? 0;
  return (
    <CardDataField title={title}>
      {sorted.length > 0 ? (
        <div className="grid gap-2 mt-2">
          {sorted.map(([key, value]) => {
            return (
              <div key={key}>
                <div className="flex justify-between">
                  <div>{key}</div>
                  <div>{value}</div>
                </div>

                <ProgressBar height={2} color="blue" progress={value} total={highest} pulse={false} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-2">No data</div>
      )}
    </CardDataField>
  );
};

export const WorkspaceInsightsPage = () => {
  const { processingState, isFetching, tree, treeKey } = useWorkspace();

  const documents = useMemo(() => {
    return [...tree.entries.values()].filter((v) => !!v.document);
  }, [tree, treeKey]);

  const totalPageCount = documents.reduce((acc, v) => acc + (v.document?.pageCount ?? 0), 0);
  const totalStorage = documents.reduce((acc, v) => acc + (v.document?.fileSize ?? 0), 0);
  const counts = useMemo(() => {
    const countPerCategory = new Map<string, number>();
    for (const doc of documents) {
      for (const category of doc?.document?.categories ?? []) {
        const categoryName = category.name;
        const newCount = (countPerCategory.get(categoryName) ?? 0) + 1;
        countPerCategory.set(categoryName, newCount);
      }
    }

    const countPerJurisdiction = new Map<string, number>();
    for (const doc of documents) {
      const jurisdiction = doc?.document?.jurisdiction?.toUpperCase() ?? 'Unknown';
      const newCount = (countPerJurisdiction.get(jurisdiction) ?? 0) + 1;
      countPerJurisdiction.set(jurisdiction, newCount);
    }

    const countPerLanguage = new Map<string, number>();
    for (const doc of documents) {
      const lang = doc?.document?.language?.toUpperCase() ?? 'Unknown';
      const newCount = (countPerLanguage.get(lang) ?? 0) + 1;
      countPerLanguage.set(lang, newCount);
    }

    return {
      totalCount: documents.length,
      countPerCategory,
      countPerJurisdiction,
      countPerLanguage,
    };
  }, [documents]);

  return (
    <div className="page-content">
      <PageHeader title="Insights" />

      <div className="mb-4 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Insights',
            },
          ]}
        />
      </div>

      <div>
        {processingState.processedCount < processingState.totalCount && (
          <div className="flex gap-1 items-center mb-4">
            <Spinner size={4} />
            Some documents are still being processed...
          </div>
        )}

        {!documents.length && isFetching && (
          <div>
            <SpinnerBlock message="Loading..." className="h-screen" />
          </div>
        )}

        <div className="grid grid-cols-3 gap-4 mb-4">
          <CardDataField title="Total documents">{documents.length}</CardDataField>
          <CardDataField title="Total pages">{totalPageCount}</CardDataField>
          <CardDataField title="Total storage">{formatBytes(totalStorage)}</CardDataField>
        </div>

        <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
          <CountsComponent title="Documents per category" counts={counts.countPerCategory} />
          <CountsComponent title="Documents per jurisdiction" counts={counts.countPerJurisdiction} />
          <CountsComponent title="Documents per language" counts={counts.countPerLanguage} />
        </div>
      </div>
    </div>
  );
};
