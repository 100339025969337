import { useMemo, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Check as CheckIcon } from '@phosphor-icons/react';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { DialogContent, DialogRoot } from '@/components/dialog/Dialog';
import { Spinner } from '@/components/Spinner';
import { useTeam } from '@/app/team/context/TeamContext';
import { Tag } from '@/components/Tag';

export const DocumentRisksAnalysedDialog = () => {
  const { team } = useTeam();
  const { workspace, tree, treeKey } = useWorkspace();
  const [open, setOpen] = useState(false);

  const state = useMemo(() => {
    const docs = [];
    let docsAnalysed = 0;
    for (const [id, entry] of tree.entries.entries()) {
      const doc = entry.document;
      if (doc) {
        if (doc.shouldSpotRisks) {
          if (doc.hasSpottedRisks) {
            docsAnalysed += 1;
          }

          docs.push({
            id,
            path: entry.getFullpath(),
            isAnalysed: doc.hasSpottedRisks,
          });
        }
      }
    }
    return {
      docs: docs.sort((a, b) => {
        if (a.isAnalysed && !b.isAnalysed) {
          return -1;
        }

        if (!a.isAnalysed && b.isAnalysed) {
          return 1;
        }

        return 0;
      }),
      docsAnalysed,
    };
  }, [treeKey]);

  if (!state.docs.length) {
    return null;
  }

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <Tag
        color="green"
        onClick={() => {
          setOpen(true);
        }}
      >{`${state.docsAnalysed} of ${state.docs.length} analysed`}</Tag>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Analysed documents</h1>

        <div className="grid gap-2">
          {state.docs.map((v) => {
            return (
              <ReactLink
                key={v.id}
                className="flex items-center px-2 gap-2 card-no-padding cursor-pointer"
                to={`/app/t/${team.id}/workspace/${workspace.id}/documents/redirect-to/${v.id}`}
              >
                <div>{v.isAnalysed ? <CheckIcon className="button-icon text-green-600" /> : <Spinner size={4} />}</div>
                <div className="w-full">{v.path}</div>
              </ReactLink>
            );
          })}
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
