import { useMemo, useState } from 'react';
import {
  ArrowSquareOut as ExternalLinkIcon,
  User as UserIcon,
  Building as BuildingIcon,
  X as XIcon,
  Eye as EyeIcon,
} from '@phosphor-icons/react';
import useSWR from 'swr';

import { Button, LinkButton } from '@/components/button/Button';
import { useTeam } from '@/app/team/context/TeamContext';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Tag } from '@/components/Tag';
import { InfoDialog } from '@/components/dialog/InfoDialog';
import { useAppContext } from '@/contexts/app-context';
import { TREE_ROOT_ID } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { useSize } from '@/hooks/useSize';
import { ReferenceDialogContent } from './ReferenceContent';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as DocumentMatchesResponseType } from '../../../endpoints/WorkspacePresetRunDocumentMatchesEndpoint';
import { getHighlightedText } from './highlight-match';
import { getDisplayError } from '@/utils/get-display-error';
import { VisualDocumentPreviewDialog } from '@/app/workspaceDocument/components/VisualDocumentPreviewDialog';

export interface IReferenceSidecarProps {
  documentAnswer: string;
  documentAnswerId: string;
  document: {
    id: string;
    name: string;
    folderId: string;
  };
  onOpenChange: (newOpen: boolean) => void;
}

export const ReferenceSidecar: React.FC<IReferenceSidecarProps> = (props) => {
  const { documentAnswer, documentAnswerId, document, onOpenChange } = props;
  const [searchParams, setSearchParams] = useState('');
  const { enableDebugMode } = useAppContext();
  const { team } = useTeam();
  const { tree, workspace } = useWorkspace();
  const [shownDialog, setShownDialog] = useState<'parties' | 'table-of-contents' | null>(null);
  const [containerRef, dimensions] = useSize<HTMLDivElement>();
  const [showPreview, setShowPreview] = useState(false);

  const node = tree.getDocumentNode(document.id);
  const nodeDocument = node?.document;

  const { data, isLoading, error } = useSWR<DocumentMatchesResponseType>(
    `/api/v1/workspace/preset-run-document-matches/${documentAnswerId}?v=2`,
    fetchEndpointData,
  );

  const matches = useMemo(() => {
    const rawMatches = data?.documentMatches ?? [];
    const parsedMatches = rawMatches.map((m) => {
      const pieces = getHighlightedText(m.documentChunk.content, documentAnswer);
      let highlightedLength = 0;
      for (const piece of pieces) {
        if (piece.isHighlighted) {
          highlightedLength += piece.value.length;
        }
      }

      return {
        ...m,
        pieces,
        highlightedLength,
      };
    });
    return parsedMatches;
  }, [data]);

  const { bboxes, hasBboxes } = useMemo(() => {
    let hasBboxes = false;
    const res = [];
    for (const match of matches) {
      if (match.highlightedLength > 0) {
        res.push(...match.documentChunk.bboxes);
      }

      if (match.documentChunk.bboxes.length > 0) {
        hasBboxes = true;
      }
    }
    return {
      bboxes: res,
      hasBboxes,
    };
  }, [matches]);

  return (
    <div className="relative" ref={containerRef}>
      <div className="relative h-14 z-topbar">
        <div
          className="fixed p-2 flex justify-between items-center border-b border-gray-200 bg-white"
          style={{
            width: dimensions.width,
          }}
        >
          <div className="flex gap-2 items-center">
            <Button
              shape="square"
              variant="ghost"
              onTrigger={() => {
                onOpenChange(false);
              }}
            >
              <XIcon className="w-4 h-4" />
            </Button>

            <h1 className="heading-three">{document.name}</h1>
          </div>

          <div className="flex gap-2 items-center">
            {!!nodeDocument && (
              <div className="flex gap-2">
                {nodeDocument.parties.length > 0 && (
                  <Tag
                    color="blue"
                    onClick={() => {
                      setShownDialog('parties');
                    }}
                  >
                    <UserIcon className="w-4 h-4 mr-1" />
                    {`${nodeDocument.parties.length} Parties involved`}
                  </Tag>
                )}

                <InfoDialog
                  title="Parties"
                  hideTrigger={true}
                  isOpen={shownDialog === 'parties'}
                  setIsOpen={(newOpen) => {
                    if (!newOpen) {
                      setShownDialog(null);
                    } else {
                      setShownDialog('parties');
                    }
                  }}
                >
                  <div className="flex flex-col">
                    {nodeDocument.parties.map((party) => {
                      return (
                        <div key={party.id} className="flex items-center gap-2">
                          {party.isPerson ? <UserIcon className="w-4 h-4" /> : <BuildingIcon className="w-4 h-4" />}
                          <div>{party.name}</div>
                          <div className="flex flex-wrap gap-2">
                            {party.isPerson && party.companyName && <Tag color="orange">{party.companyName}</Tag>}

                            {party.roles.map((role, roleIdx) => {
                              return (
                                <Tag color="blue" key={`${party.id}-role-${roleIdx}`}>
                                  {role}
                                </Tag>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </InfoDialog>
              </div>
            )}

            {hasBboxes && (
              <div>
                <Button
                  shape="square"
                  variant="ghost"
                  onTrigger={() => {
                    setShowPreview(true);
                  }}
                >
                  <EyeIcon className="w-4 h-4" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="overflow-y-auto">
        {isLoading && <div className="text-gray-400 p-2">Loading...</div>}

        {!isLoading && error && (
          <div className="text-danger-color p-2">Error loading data: {getDisplayError(error)}</div>
        )}

        {!isLoading && matches.length > 0 && (
          <ReferenceDialogContent matches={matches} setSearchParams={setSearchParams} showDebugInfo={enableDebugMode} />
        )}

        {!isLoading && matches.length === 0 && <div className="p-2">No relevant data found in this document.</div>}
      </div>

      <div className="flex justify-between flex-shrink-0 p-2">
        <LinkButton
          variant="primary"
          to={`/app/t/${team.id}/workspace/${workspace.id}/documents/${document.folderId ?? TREE_ROOT_ID}/${document.id}?${searchParams}`}
          iconLeft={<ExternalLinkIcon className="button-icon" />}
        >
          Open Document
        </LinkButton>
      </div>

      <VisualDocumentPreviewDialog
        documentId={document.id}
        isOpen={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
        bboxes={bboxes}
      />
    </div>
  );
};
