import { Formik, FormikHelpers } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '@/components/Breadcrumb';
import { Button } from '@/components/button/Button';
import { PageHeader } from '@/components/PageHeader';
import { getDisplayError } from '@/utils/get-display-error';
import type {
  BodyType as AddUserPayload,
  ResponseType as AddUserResponseType,
} from '../endpoints/AddWorkspaceUserEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { CheckboxField } from '@/components/checkbox/CheckboxField';
import { WorkspacePermissionsSelector } from '../components/WorkspacePermissionsSelector';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { TeamUserSelectField } from '@/app/teamUser/components/TeamUserSelector';
import { captureException } from '@sentry/react';
import { WorkspacePermissionEnum } from '@/app/auth/enum';

const addWorkspaceSchema = Yup.object().shape({
  user: Yup.object({
    id: Yup.string().required('User is required'),
    name: Yup.string().required(),
  })
    .nullable()
    .required('User is required'),
  permissions: Yup.array(Yup.string()).required('Required'),
  isOwner: Yup.boolean().required('Required'),
});

interface WorkspaceUser {
  id: string;
  name: string;
}

interface UserFormValues {
  user: WorkspaceUser | null;
  permissions: string[];
  isOwner: boolean;
}

export const AddWorkspaceUserPage = () => {
  const navigate = useNavigate();
  const { workspace } = useWorkspace();

  const onSubmit = async (values: UserFormValues) => {
    try {
      const userId = values.user?.id;
      if (!userId) {
        throw new Error('userId is required');
      }

      const payload: AddUserPayload = {
        workspaceId: workspace.id,
        data: {
          userId,
          permissions: values.permissions,
          isOwner: values.isOwner,
        },
      };
      await fetchEndpointData<AddUserResponseType>('/api/v1/workspace/user/add', {
        method: 'POST',
        body: payload,
      });
      navigate('..');
      toast.success('User has been added to the workspace');
    } catch (err: any) {
      captureException(err);
      toast.error('Could not add user to the workspace: ' + getDisplayError(err));
    }
  };

  const usersUrl = useResolvedPath('..');
  return (
    <div className="page-content">
      <PageHeader title="Add a user" />

      <div>
        <div className="mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Users',
                to: usersUrl.pathname,
              },
              {
                name: 'Add a user',
              },
            ]}
          />
        </div>

        <div className="card">
          <Formik
            initialValues={{
              user: {
                id: '',
                name: '',
              },
              permissions: [],
              isOwner: false,
            }}
            validationSchema={addWorkspaceSchema}
            onSubmit={async (values: UserFormValues, { resetForm }) => {
              await onSubmit(values);
              resetForm();
            }}
          >
            {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <TeamUserSelectField labelText="User" name="user" />

                  <div className="heading-two mb-2">Permissions</div>

                  <div className="mb-4">
                    <WorkspacePermissionsSelector
                      value={values['permissions'] as WorkspacePermissionEnum[]}
                      onChange={(v) => setFieldValue('permissions', v)}
                    />
                  </div>

                  <CheckboxField labelText="Owner" name="isOwner" />

                  <Button type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                    Add user to the workspace
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
