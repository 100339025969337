import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { VariantProps, cva } from 'class-variance-authority';

import classNames from '@/utils/classnames';

const sidebarNavButtonVariants = cva(
  'flex items-center text-sm font-medium transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-dark-07 focus:ring-offset-2 select-none disabled:opacity-50 disabled:pointer-events-none bg-transparent hover:bg-gray-300',
  {
    variants: {
      width: {
        auto: '',
        full: 'w-full',
      },
    },
    defaultVariants: {
      width: 'full',
    },
  },
);

export interface SidebarNavButtonProps
  extends Omit<LinkProps, 'className'>,
    VariantProps<typeof sidebarNavButtonVariants> {
  icon?: React.ReactNode;
  title: string;
  isExternal?: boolean;
}

const SidebarNavButton = React.forwardRef<HTMLAnchorElement, SidebarNavButtonProps>(
  ({ icon, title, to, isExternal, width, ...otherProps }, ref) => {
    const commonProps = {
      className: classNames(sidebarNavButtonVariants({ width })),
      title,
      ref,
      ...otherProps,
    };

    const content = (
      <div className="flex-1 flex whitespace-nowrap h-10">
        {icon && <div className="py-4 pl-4 flex items-center justify-center flex-shrink-0">{icon}</div>}
        <div className="px-4 flex items-center truncate">{title}</div>
      </div>
    );

    if (isExternal) {
      return (
        <a href={to.toString()} target="_blank" rel="noopener noreferrer" {...commonProps}>
          {content}
        </a>
      );
    }

    return (
      <Link to={to} {...commonProps}>
        {content}
      </Link>
    );
  },
);

SidebarNavButton.displayName = 'SidebarNavButton';

export { SidebarNavButton, sidebarNavButtonVariants };
