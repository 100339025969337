import React from 'react';
import toast from 'react-hot-toast';
import { CopySimple as CopySimpleIcon } from '@phosphor-icons/react';

import { FlaggingDialog } from '../../../workspaceDocument/components/FlaggingDialog';
import { BaseButton } from '../../../../components/button/BaseButton';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { removeMarkdownFromText } from '@/utils/markdown';

export interface IChatMessageActionsProps {
  messageId: string;
  messageContent: string;
}

export const ChatMessageActions: React.FC<IChatMessageActionsProps> = (props) => {
  const { messageId, messageContent } = props;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(removeMarkdownFromText(messageContent));
      toast.success('Message copied to clipboard');
    } catch (err) {
      console.error('Failed to copy message: ', err);
      toast.error('Failed to copy message');
    }
  };

  return (
    <div className="flex items-center gap-2">
      <FlaggingDialog messageId={messageId} />
      <Tooltip text="Copy">
        <BaseButton
          variant="ghost"
          onClick={copyToClipboard}
          className="w-7 h-7 min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px] rounded-md py-1 px-0 flex items-center justify-center"
        >
          <CopySimpleIcon size={16} />
        </BaseButton>
      </Tooltip>
    </div>
  );
};
