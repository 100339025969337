import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { nullthrows } from '@/utils/invariant';
import { getPresetRunStates } from './PresetRunStates';
import { useTeam } from '@/app/team/context/TeamContext';
import { useThrottle } from '@/hooks/useThrottle';
import { SpinnerBlock } from '@/components/Spinner';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as WorkspacePresetRunResponseType } from '../../endpoints/WorkspacePresetRunEndpoint';
import { PageHeader } from '@/components/PageHeader';
import { Breadcrumb } from '@/components/Breadcrumb';
import { AnalysisResults } from './results/AnalysisResults';
import { AnalysisQuestionaire } from './question-selection/QuestionOverview';

export type PresetRun = WorkspacePresetRunResponseType['presetRun'];

export const AnalysisStatePage = () => {
  const { analysisId: _analysisId } = useParams<{ analysisId: string }>();
  const analysisId = nullthrows(_analysisId, 'Analysis result id not defined');

  const { team } = useTeam();
  const state = getPresetRunStates().getState(analysisId, team.id);
  const [fetchKey, setFetchKey] = useState(state.updatedHash);
  useEffect(() => {
    const changeDisposable = state.onUpdate((newHash) => {
      setFetchKey(newHash);
    });
    return () => {
      changeDisposable.dispose();
    };
  }, [state]);

  const {
    data,
    isLoading,
    error,
    mutate: refetchPresetData,
  } = useSWR<WorkspacePresetRunResponseType>(`/api/v1/workspace/preset-run/${analysisId}`, fetchEndpointData);
  useThrottle(refetchPresetData, fetchKey, 1000);

  const presetRun = data?.presetRun;

  let title = 'Analysis';
  let content: React.ReactNode = '';
  if (!presetRun) {
    if (isLoading) {
      content = <SpinnerBlock message="Loading Analysis result..." />;
    } else if (error) {
      content = <div>Error: {error.message}</div>;
    } else {
      content = <div>Analysis not found</div>;
    }
  } else {
    title = `Analysis: ${presetRun.name}`;

    if (presetRun.status === 'Created') {
      content = <AnalysisQuestionaire presetRun={presetRun} />;
    } else {
      return <AnalysisResults presetRun={presetRun} />;
    }
  }

  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="mb-2 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Analyses',
              to: '..',
            },
            {
              name: title,
            },
          ]}
        />
      </div>

      <div>{content}</div>
    </div>
  );
};
