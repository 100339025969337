import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/button/Button';
import { useTeam } from '@/app/team/context/TeamContext';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { BodyType as CreateApiKeyBody } from '../endpoints/CreateApiKeyEndpoint';
import { captureException } from '@sentry/react';

const createApiKeySchema = Yup.object().shape({
  description: Yup.string().min(1, 'Required').required('Required'),
});

export const CreateApiKeyPage = () => {
  const navigate = useNavigate();
  const { team } = useTeam();

  return (
    <div className="page-content">
      <PageHeader title="Create API Key" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'API Keys',
              to: '..',
            },
            {
              name: 'New API Key',
            },
          ]}
        />
      </div>

      <div className="card">
        <Formik
          initialValues={{
            description: '',
          }}
          validationSchema={createApiKeySchema}
          onSubmit={async (values) => {
            try {
              const payload: CreateApiKeyBody = {
                teamId: team.id,
                description: values.description,
              };
              await fetchEndpointData('/api/v1/api-key/create', {
                method: 'POST',
                body: payload,
              });
              navigate('..');
              toast.success('Api key created');
            } catch (err: any) {
              captureException(err);
              toast.error('Could not create api key: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Description" type="text" name="description" />

              <Button variant="primary" type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                Create API Key
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
