import { Trash as TrashIcon } from '@phosphor-icons/react';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { BodyType as DeleteWorkspacePayload } from '@/app/workspace/endpoints/DeleteWorkspaceEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { useTeam } from '@/app/team/context/TeamContext';
import { getDisplayError } from '@/utils/get-display-error';
import { WorkspacePermissionWrapper } from '@/app/workspace/components/WorkspacePermissionWrapper';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Breadcrumb } from '@/components/Breadcrumb';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { PageHeader } from '@/components/PageHeader';

export const WorkspaceSettingsPage = () => {
  const { team } = useTeam();
  const { workspace } = useWorkspace();
  const navigate = useNavigate();

  const sections = useMemo(() => {
    const res = [
      {
        title: 'Details',
        to: 'details',
      },
      {
        title: 'Users',
        to: 'users',
      },
    ];

    if (workspace.isOwner) {
      res.push({
        title: 'Integrations',
        to: 'integrations',
      });
    }

    return res;
  }, [workspace]);

  return (
    <div className="page-content">
      <PageHeader title="Workspace Settings" />

      <div className="mb-4 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Workspace Settings',
            },
          ]}
        />

        <div className="flex gap-2">
          <WorkspacePermissionWrapper allowedPermissions={[]} isOwner={true}>
            <ConfirmDialog
              triggerText={
                <div className="flex items-center gap-2">
                  <TrashIcon className="button-icon" />
                  Delete workspace
                </div>
              }
              title="Delete workspace"
              submitText="Delete"
              confirmationText={'Delete'}
              triggerVariant="destructive"
              submitVariant="destructive"
              description={`Are you sure you want to delete ${workspace.name}?`}
              onSubmit={async () => {
                try {
                  const payload: DeleteWorkspacePayload = {
                    workspaceId: workspace.id,
                  };
                  await fetchEndpointData('/api/v1/workspace/delete', {
                    method: 'DELETE',
                    body: payload,
                  });
                  toast.success('Workspace has been deleted');
                  navigate(`/app/t/${team.id}/workspace`);
                } catch (err) {
                  captureException(err);
                  toast.error('Could not delete workspace: ' + getDisplayError(err));
                }
              }}
            />
          </WorkspacePermissionWrapper>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4">
        {sections.map((v) => {
          return (
            <Link to={v.to} key={v.to} className="block card cursor-pointer" data-clickable="true">
              <div className="card-heading">{v.title}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
