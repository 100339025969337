import { useCallback, useId, useMemo } from 'react';

import { IChatMessage } from '../useChat';
import { DotLoader } from '../../../../components/DotLoader';
import { REF_RE, UNKNOWN_TAGS_RE, getReferenceIdsFromMatch } from '../../../workspaceDocument/constants';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { MarkdownText } from '@/components/markdown/Markdown';

export type IDocumentDict = Record<string, { name: string }>;
export type IDocumentRef = { refId: number | null; documentId: string };

const ChatTextNode: React.FC<IChatMessageTextProps> = (props) => {
  const { references, documents, openRef } = props;
  const id = useId();

  const content = props.content.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
  const nodes = useMemo(() => {
    const matches = content.matchAll(REF_RE);
    const result = [];
    let key = 0;
    let lastIndex = 0;
    for (const match of matches) {
      if (match.index == null) continue;

      if (lastIndex !== match.index) {
        result.push(content.slice(lastIndex, match.index));
      }

      const refIds = getReferenceIdsFromMatch(match).map((v) => +v);
      for (const refId of refIds) {
        if (isNaN(refId)) {
          continue;
        }

        const ref = references.find((v) => v.refId === refId);
        if (ref) {
          const document = documents[ref.documentId];
          if (document) {
            result.push(
              <Tooltip text={document.name} delayDuration={100} key={`${id}-${result.length}`}>
                <span
                  className="inline-flex items-center justify-center font-medium select-none cursor-pointer rounded-full text-xs w-5 h-5 mx-1 bg-indigo-100 text-indigo-700 hover:bg-indigo-200"
                  key={key}
                  onClick={() => {
                    openRef(refId);
                  }}
                >
                  {refId}
                </span>
              </Tooltip>,
            );
          }
        }
      }
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex !== content.length) {
      result.push(content.slice(lastIndex));
    }

    return result.map((r) => {
      if (typeof r === 'string') {
        return r.replace(UNKNOWN_TAGS_RE, '');
      } else {
        return r;
      }
    });
  }, [content]);

  return <>{nodes}</>;
};

export interface IChatMessageTextProps {
  content: string;
  references: IDocumentRef[];
  documents: IDocumentDict;
  openRef: (refId: number | string) => void;
}

export const ChatMessageText: React.FC<IChatMessageTextProps> = (props) => {
  const { content, references, documents, openRef } = props;

  const renderText = useCallback(
    (content: string) => {
      return <ChatTextNode content={content} references={references} documents={documents} openRef={openRef} />;
    },
    [references, documents, openRef],
  );

  return <MarkdownText content={content} renderText={renderText} />;
};

export interface IChatMessageContentProps {
  message: IChatMessage;
  references: IChatMessage['references'];
  documents: IChatMessage['documents'];
  openRef: (refId: number | string) => void;
}

export const ChatMessageContent: React.FC<IChatMessageContentProps> = (props) => {
  const { message, references, documents, openRef } = props;

  const content = message.content;
  if (!content) {
    return (
      <div className="flex gap-1 items-center">
        <div className="self-end mb-1">
          <DotLoader />
        </div>
      </div>
    );
  } else {
    return <ChatMessageText content={content} references={references} documents={documents} openRef={openRef} />;
  }
};
