import React from 'react';

import { ReferenceSidecar } from '../ReferenceSidecar';
import { IDoc } from './AggregatedAnswerResults';

export interface ICrossDocumentReferenceSidecarProps {
  doc: IDoc;
  onClose: () => void;
}

export const CrossDocumentReferenceSidecar: React.FC<ICrossDocumentReferenceSidecarProps> = (props) => {
  const { doc, onClose } = props;

  return (
    <div className="flex w-[calc(45vw-2.5rem)]">
      <div className="h-full w-8 bg-gray-100 border-l border-r border-gray-200"></div>

      <div className="relative flex-1">
        <div className="fixed bg-white overflow-y-auto bottom-0 h-without-topbar w-[calc(45vw-4.5rem)]">
          <ReferenceSidecar
            documentAnswer={doc.answer}
            documentAnswerId={doc.id}
            document={{
              id: doc.doc.id,
              name: doc.doc.name,
              folderId: doc.doc.folderId ?? 'ROOT',
            }}
            onOpenChange={(isOpen) => {
              if (!isOpen) {
                onClose();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
