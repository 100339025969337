export interface FetchEndpointOpts {
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  body?: any;
}

export async function fetchEndpointData<T>(url: string | URL, opts: FetchEndpointOpts = {}): Promise<T> {
  const method = (opts.method ?? 'GET').toUpperCase();
  const urlString = url.toString();
  const shouldCache = method === 'GET';

  // We manually cache as the browser is very aggressive with removing cache entries
  if (shouldCache) {
    try {
      const cachedData = await getCachedData<T>(urlString);

      if (cachedData) {
        console.log('Cache hit', urlString);
        return cachedData;
      }
    } catch (err) {
      console.warn('Error accessing IndexedDB cache:', err);
      // Continue with fetch if cache access fails
    }
  }

  const response = await fetch(url, {
    method: opts.method ?? 'GET',
    body: opts.body ? JSON.stringify(opts.body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  const json: any = await response.json();
  if (!response.ok) {
    if (json.error) {
      if (typeof json.error === 'object' && typeof json.error.message === 'string') {
        throw new Error(json.error.message);
      }

      if (typeof json.error === 'string') {
        throw new Error(json.error);
      }
    }

    console.error(json);

    throw new Error(`Request failed: ${response.statusText}`);
  }

  // Cache the successful response
  if (shouldCache) {
    try {
      const maxAge = getMaxAge(response.headers.get('Cache-Control'));

      if (maxAge) {
        await cacheData(urlString, json, maxAge);
      }
    } catch (err) {
      console.warn('Error saving to IndexedDB cache:', err);
      // Continue anyway as the fetch was successful
    }
  }

  return json as T;
}

function getMaxAge(cacheControl: string | null): number | undefined {
  if (!cacheControl) {
    return undefined;
  }

  // Don't cache if no-store is specified
  if (cacheControl.includes('no-store')) {
    return undefined;
  }

  // Don't cache if no-cache is specified
  if (cacheControl.includes('no-cache')) {
    return undefined;
  }

  const maxAgeMatch = cacheControl.match(/max-age=(\d+)/);
  if (maxAgeMatch && maxAgeMatch[1]) {
    return parseInt(maxAgeMatch[1], 10);
  }

  return undefined;
}

// IndexedDB helper functions
const DB_NAME = 'apiCache';
const STORE_NAME = 'responses';
const DB_VERSION = 1;

interface CacheEntry<T> {
  data: T;
  timestamp: number;
  expires: number;
}

function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = (event) => {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'url' });
      }
    };
  });
}

async function getCachedData<T>(url: string): Promise<T | null> {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(url);

    request.onerror = () => reject(request.error);

    request.onsuccess = () => {
      const entry = request.result as CacheEntry<T> | undefined;

      if (!entry) {
        resolve(null);
        return;
      }

      // If we have an explicit expiration time, use that
      if (entry.expires != null && Date.now() > entry.expires) {
        resolve(null);
        return;
      }

      resolve(entry.data);
    };
  });
}

async function cacheData<T>(url: string, data: T, maxAge: number): Promise<void> {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    const entry: CacheEntry<T> & { url: string } = {
      url,
      data,
      timestamp: Date.now(),
      expires: Date.now() + maxAge * 1000,
    };

    const request = store.put(entry);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve();
  });
}

export async function cleanupExpiredFetchCache(): Promise<void> {
  const db = await openDatabase();
  const now = Date.now();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.openCursor();

    request.onerror = () => reject(request.error);

    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest).result as IDBCursorWithValue | null;

      if (cursor) {
        const entry = cursor.value as CacheEntry<unknown> & { url: string };

        if (entry.expires != null && entry.expires < now) {
          // eslint-disable-next-line drizzle/enforce-delete-with-where
          cursor.delete();
        }

        cursor.continue();
      } else {
        resolve();
      }
    };
  });
}
