import React, { useMemo, useState } from 'react';

import { DataField } from '@/components/DataField';
import { WorkspacePresetRun } from '../../types';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { TreeNode } from '@/app/workspaceDocument/tree/WorkspaceDocumentTree';
import { DialogContent, DialogRoot } from '@/components/dialog/Dialog';

export interface IPresetRunDocumentsDialogProps {
  presetRun: WorkspacePresetRun;
}

export const PresetRunDocumentsDialog: React.FC<IPresetRunDocumentsDialogProps> = (props) => {
  const { presetRun } = props;
  const { tree, treeKey } = useWorkspace();
  const [showDialog, setShowDialog] = useState(false);

  const documents: TreeNode[] = useMemo(() => {
    const documentIds = new Set<string>([...presetRun.documentIds]);
    const documents = new Set<TreeNode>();
    for (const documentId of documentIds) {
      const document = tree.getDocumentNode(documentId);
      if (document) {
        documents.add(document);
      }
    }
    return [...documents];
  }, [presetRun, treeKey]);

  return (
    <>
      <DataField title="Document analysed">
        <div
          className="hover:text-link-color cursor-pointer"
          onClick={() => {
            setShowDialog(true);
          }}
        >
          {presetRun.documentIds.length} documents
        </div>

        <DialogRoot open={showDialog} onOpenChange={setShowDialog}>
          <DialogContent className="dialog-content flex flex-col">
            <div className="heading-two mb-4">Documents analysed</div>
            <div className="grid gap-1">
              {documents.map((v) => {
                const node = tree.getNode(v.id);
                if (!node) {
                  return null;
                }

                return (
                  <div className="card-no-padding px-2" key={v.id}>
                    {node.getFullpath()}
                  </div>
                );
              })}
            </div>
          </DialogContent>
        </DialogRoot>
      </DataField>
    </>
  );
};
