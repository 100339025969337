import React from 'react';
import { FormikHelpers } from 'formik';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { InputField } from '../../../components/input/InputField';
import { useTeam } from '@/app/team/context/TeamContext';
import { fetchEndpointData } from '@/utils/fetch.client';
import { getDisplayError } from '@/utils/get-display-error';
import type { BodyType as CreateCategoryTemplateBody } from '../endpoints/CreateCategoryTemplateEndpoint';
import type { BodyType as UpdateCategoryTemplateBody } from '../endpoints/UpdateCategoryTemplateEndpoint';
import { useCategoryTemplateList } from '../context/CategoryTemplateListProvider';

interface ICategoryTemplateFormValues {
  name: string;
}

interface ICategoryTemplateFormProps {
  isOpen?: boolean;
  setIsOpen?: (newIsOpen: boolean) => void;
  mode: 'create' | 'edit';
  templateId?: string;
  initialName?: string;
  onSuccess?: (values: ICategoryTemplateFormValues) => void;
}

export const CategoryTemplateFormDialog: React.FC<ICategoryTemplateFormProps> = (props) => {
  const { isOpen, setIsOpen, mode, templateId, initialName, onSuccess } = props;
  const { team } = useTeam();
  const { refreshCategoryTemplates } = useCategoryTemplateList();

  const handleSubmit = async (
    values: ICategoryTemplateFormValues,
    helpers: FormikHelpers<ICategoryTemplateFormValues>,
  ) => {
    try {
      if (mode === 'create') {
        const payload: CreateCategoryTemplateBody = {
          teamId: team.id,
          data: {
            name: values.name,
          },
        };
        await fetchEndpointData('/api/v1/category-template/create', {
          method: 'POST',
          body: payload,
        });
        toast.success('Category template created');
      } else {
        const payload: UpdateCategoryTemplateBody = {
          teamId: team.id,
          templateId: templateId!,
          data: {
            name: values.name,
          },
        };
        await fetchEndpointData('/api/v1/category-template/update', {
          method: 'POST',
          body: payload,
        });
        toast.success('Category template updated');
      }

      refreshCategoryTemplates();

      helpers.resetForm();
      if (setIsOpen) {
        setIsOpen(false);
      }
      onSuccess?.(values);
    } catch (err) {
      captureException(err);
      toast.error(`Failed to ${mode} category template: ${getDisplayError(err)}`);
      throw err;
    }
  };

  return (
    <FormDialog
      open={isOpen}
      setOpen={setIsOpen}
      showTrigger={false}
      triggerText=""
      title={mode === 'create' ? 'Create category template' : 'Edit category template'}
      submitText={mode === 'create' ? 'Create' : 'Save changes'}
      onSubmit={handleSubmit}
      initialValues={{
        name: initialName ?? '',
      }}
    >
      <InputField type="text" labelText="Name" name="name" />
    </FormDialog>
  );
};
