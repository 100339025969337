import { useEffect, useState } from 'react';
import { UploadSimple as UploadIcon, X as XIcon, ArrowClockwise as ArrowClockwiseIcon } from '@phosphor-icons/react';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Button } from '@/components/button/Button';
import { DialogContent, DialogRoot } from '@/components/dialog/Dialog';
import { Spinner } from '@/components/Spinner';
import classNames from '@/utils/classnames';
import { Tooltip } from '@/components/tooltip/Tooltip';

export const PendingDocumentUploadsDialog = () => {
  const { syncState } = useWorkspace();
  const [pendingUploads, setPendingUploads] = useState(() => {
    return [...syncState.pendingUploads.entries()];
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const disposable = syncState.onUploadChange(() => {
      setPendingUploads([...syncState.pendingUploads.entries()]);
    });
    return () => disposable.dispose();
  }, [syncState]);

  if (!pendingUploads.length) {
    return null;
  }

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <Button
        size={6}
        variant="ghost"
        onTrigger={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();

          setOpen(true);
        }}
      >
        <div className="flex gap-1 items-center">
          <div>{pendingUploads.length}</div>
          <UploadIcon className="button-icon" />
        </div>
      </Button>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Pending uploads</h1>

        {pendingUploads.length > 0 ? (
          <div className="grid gap-2">
            {pendingUploads.map(([id, v]) => {
              return (
                <div className="card-no-padding overflow-hidden" key={id}>
                  <div
                    className={classNames('bg-blue-200 h-1', {
                      'animate-pulse': !v.error,
                    })}
                    style={{ width: `${v.progress}%` }}
                  ></div>
                  <div className="flex justify-between">
                    <div
                      className={classNames('flex items-center px-2 gap-2', {
                        'text-danger-color-dark': v.error,
                      })}
                    >
                      <div>
                        {v.error ? (
                          <Tooltip text={v.error}>
                            <XIcon className="w-4 h-4" />
                          </Tooltip>
                        ) : (
                          <Spinner size={4} />
                        )}
                      </div>
                      <div className="w-full">{v.file.name}</div>
                    </div>

                    {!!v.error && (
                      <div>
                        <Button
                          size={6}
                          onTrigger={() => {
                            syncState.retryUpload(id);
                          }}
                        >
                          <ArrowClockwiseIcon className="button-icon" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          'All documents have been uploaded.'
        )}
      </DialogContent>
    </DialogRoot>
  );
};
