import React, { useState, useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { Clipboard as ClipboardIcon, Gps as GpsIcon } from '@phosphor-icons/react';
import useSWR from 'swr';

import { Button } from '@/components/button/Button';
import { Tag } from '@/components/Tag';
import { formatDateTime } from '@/utils/date';
import { SpinnerBlock } from '@/components/Spinner';
import { ResponseType as DocumentMetadataResponseType } from '../../endpoints/DocumentMetadataEndpoint';
import { ResponseType as DocumentChunksResponseType } from '../../endpoints/DocumentChunksEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';
import { MarkdownText } from '@/components/markdown/Markdown';
import { Pagination, IPaginationProps } from '@/components/Pagination';

const CHUNKS_PER_PAGE = 50;

const renderText = (t: string) => t;

export const DocumentDebugView: React.FC<{ document: DocumentMetadataResponseType['document'] }> = (props) => {
  const { document } = props;
  const [currentPage, setCurrentPage] = useState(0); // 0-indexed to match Pagination component
  const [isLoading, setIsLoading] = useState(false);

  const { data: chunksData, isLoading: isDataLoading } = useSWR<DocumentChunksResponseType>(
    `/api/v1/document/chunks/${document.id}`,
    fetchEndpointData,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    },
  );

  // Define chunks outside of the conditional rendering
  const chunks = chunksData?.chunks ?? [];

  // Memoize sorted chunks to avoid re-sorting on every render
  const sortedChunks = useMemo(() => {
    return [...chunks].sort((a, b) => a.chunkIdx - b.chunkIdx);
  }, [chunks]);

  // Memoize pagination calculations
  const { totalPages, indexOfFirstChunk, currentChunks } = useMemo(() => {
    const totalPages = Math.max(1, Math.ceil(sortedChunks.length / CHUNKS_PER_PAGE));
    const indexOfFirstChunk = currentPage * CHUNKS_PER_PAGE;
    const currentChunks = sortedChunks.slice(indexOfFirstChunk, indexOfFirstChunk + CHUNKS_PER_PAGE);

    return { totalPages, indexOfFirstChunk, currentChunks };
  }, [sortedChunks, currentPage]);

  // Handle page navigation with useCallback
  const nextPage = useCallback(() => {
    if (currentPage < totalPages - 1) {
      setIsLoading(true);
      // Simulate a brief loading state for better UX feedback
      setTimeout(() => {
        setCurrentPage(currentPage + 1);
        window.scrollTo(0, 0);
        setIsLoading(false);
      }, 100);
    }
  }, [currentPage, totalPages]);

  const prevPage = useCallback(() => {
    if (currentPage > 0) {
      setIsLoading(true);
      // Simulate a brief loading state for better UX feedback
      setTimeout(() => {
        setCurrentPage(currentPage - 1);
        window.scrollTo(0, 0);
        setIsLoading(false);
      }, 100);
    }
  }, [currentPage]);

  // Memoize pagination props object
  const paginationProps = useMemo<IPaginationProps>(
    () => ({
      hasPrevious: currentPage > 0,
      previous: prevPage,
      hasNext: currentPage < totalPages - 1,
      next: nextPage,
      isFetching: isLoading,
      page: currentPage,
      totalPages: totalPages,
    }),
    [currentPage, totalPages, prevPage, nextPage, isLoading],
  );

  // Memoize the clipboard copy function
  const copyToClipboard = useCallback((content: string) => {
    navigator.clipboard.writeText(content);
    toast.success('Copied to clipboard');
  }, []);

  // Handle loading state
  if (isDataLoading) {
    return <SpinnerBlock message="Loading debug data..." />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="card flex gap-2 flex-wrap">
        <Tag color="blue">{`Confidence ${Math.round((document.ocrConfidence ?? 1) * 100)}%`}</Tag>
        <Tag color="blue">{`Created at ${formatDateTime(document.createdAt)}`}</Tag>
        <Tag color="blue">{`Indexed at ${document.indexedAt ? formatDateTime(document.indexedAt) : 'unknown'}`}</Tag>
        <Tag color="blue">{`Total chunks: ${chunks.length}`}</Tag>
      </div>

      {currentChunks.map((c) => {
        return (
          <div key={c.id} className="card">
            <div className="flex justify-between items-center mb-4">
              <div className="font-bold">
                {`Chunk #${c.chunkIdx} - Group ${c.groupIdx ?? '-'}${c.title ? ` - Title: ${c.title}` : ''}`}{' '}
              </div>
              <div>
                <Button onTrigger={() => copyToClipboard(c.content)}>
                  <ClipboardIcon className="button-icon" />
                </Button>
              </div>
            </div>
            <div className="whitespace-pre-line">
              <MarkdownText content={c.content} renderText={renderText} />
            </div>
            {c.bboxes.length > 0 && (
              <div className="flex gap-2 mt-2 border-t pt-2 border-gray-200 text-gray-800">
                <GpsIcon className="button-icon mt-1" />
                <div>
                  {c.bboxes.map((bbox) => {
                    return (
                      <div>{`Page: ${bbox.page}, Top: ${bbox.top}, Height: ${bbox.height}, Left: ${bbox.left}, Width: ${bbox.width}`}</div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      })}

      {/* Pagination Controls */}
      {sortedChunks.length > 0 && (
        <div className="mt-6 pb-4">
          <Pagination {...paginationProps} />
        </div>
      )}
    </div>
  );
};
