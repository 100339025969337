import {
  Gear as SettingsIcon,
  Briefcase as BriefcaseIcon,
  Sliders as SlidersIcon,
  Tag as TagIcon,
  Coins as CoinsIcon,
  SquaresFour as Grid2X2Icon,
  Headset as HeadsetIcon,
} from '@phosphor-icons/react';
import React, { useMemo, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '@/contexts/auth-context';
import { useTeam } from '@/app/team/context/TeamContext';
import { TeamDashboardTemplate } from './TeamDashboardTemplate';
import { InfoDialog } from '@/components/dialog/InfoDialog';
import { formatDate } from '@/utils/date';
import { type SidebarNavButtonProps } from '../button/SidebarNavButton';

export interface IDashboardProps {
  children: React.ReactNode;
}

export const TeamDashboard: React.FC<IDashboardProps> = (props) => {
  const { children } = props;
  const { me, myTeams } = useAuth();
  const { team: selectedTeam } = useTeam();
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);

  const currentTeam = myTeams.find((t) => t.team.id === selectedTeam?.id);

  // Check if the current team is scheduled for deletion
  useEffect(() => {
    if (selectedTeam?.scheduledForDeletion) {
      // Check if this warning has been shown before
      const dismissedWarnings = localStorage.getItem('dismissed_deletion_warnings') || '{}';
      const dismissedWarningsObj = JSON.parse(dismissedWarnings);

      // Only show the dialog if it hasn't been dismissed for this team
      if (!dismissedWarningsObj[selectedTeam.id]) {
        setShowDeletionDialog(true);
      }
    }
  }, [selectedTeam]);

  // Handle dialog dismissal
  const handleDialogClose = () => {
    setShowDeletionDialog(false);

    // Mark this team's warning as dismissed in localStorage
    const dismissedWarnings = localStorage.getItem('dismissed_deletion_warnings') || '{}';
    const dismissedWarningsObj = JSON.parse(dismissedWarnings);
    dismissedWarningsObj[selectedTeam.id] = true;
    localStorage.setItem('dismissed_deletion_warnings', JSON.stringify(dismissedWarningsObj));
  };

  const sidebarTopButtons = useMemo(() => {
    const buttons: SidebarNavButtonProps[] = [
      {
        icon: <Grid2X2Icon className="button-icon" />,
        title: 'Workspaces',
        to: `/app/t/${selectedTeam.id}/workspace`,
      },
      {
        icon: <SlidersIcon className="button-icon" />,
        title: 'Presets',
        to: `/app/t/${selectedTeam.id}/questions-preset`,
      },
      {
        icon: <TagIcon className="button-icon" />,
        title: 'Category Templates',
        to: `/app/t/${selectedTeam.id}/categories`,
      },
    ];

    return buttons;
  }, [currentTeam, me.isSuperUser]);

  const sidebarBottomButtons = useMemo(() => {
    const buttons: SidebarNavButtonProps[] = [];

    if (selectedTeam.teamsUrl) {
      buttons.push({
        icon: <HeadsetIcon className="button-icon" />,
        title: 'Support',
        to: selectedTeam.teamsUrl,
        isExternal: true,
      });
    }

    buttons.push({
      icon: <CoinsIcon className="button-icon" />,
      title: 'Usage',
      to: `/app/t/${selectedTeam.id}/usage`,
    });

    if (currentTeam?.isOwner) {
      buttons.push({
        icon: <SettingsIcon className="button-icon" />,
        title: 'Settings',
        to: `/app/t/${selectedTeam.id}/settings`,
      });
    }

    if (me.isSuperUser) {
      buttons.push({
        icon: <BriefcaseIcon className="button-icon" />,
        title: 'Backoffice',
        to: '/app/internal',
      });
    }

    return buttons;
  }, [currentTeam, me.isSuperUser]);

  const formattedDeletionDate = selectedTeam?.scheduledDeletionDate
    ? formatDate(selectedTeam.scheduledDeletionDate)
    : '';

  return (
    <>
      <TeamDashboardTemplate
        breadcrumbButtons={[]}
        sidebarTopButtons={sidebarTopButtons}
        sidebarBottomButtons={sidebarBottomButtons}
        notificationsLink={`/app/t/${selectedTeam.id}/notifications`}
      >
        {children}
      </TeamDashboardTemplate>

      {/* Deletion warning dialog */}
      <InfoDialog
        hideTrigger
        title="Team Scheduled for Deletion"
        submitText="Acknowledge"
        isOpen={showDeletionDialog}
        setIsOpen={handleDialogClose}
      >
        <p className="text-gray-700">
          Your team is scheduled for deletion on <span className="font-semibold">{formattedDeletionDate}</span>.
        </p>
        <p className="text-gray-700 mt-2">Please contact sales for renewing credits to keep your team active.</p>
      </InfoDialog>
    </>
  );
};

export const TeamDashboardWrapper = () => {
  return (
    <TeamDashboard>
      <Outlet />
    </TeamDashboard>
  );
};
