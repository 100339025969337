import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Bookmark as BookmarkIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Archive as ArchiveIcon,
} from '@phosphor-icons/react';
import { captureException } from '@sentry/react';
import toast from 'react-hot-toast';

import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { IRisk } from '@/app/workspace/context/WorkspaceRisks';
import { RiskIndicator } from '../components/RiskIndicator';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { nullthrows } from '@/utils/invariant';
import { UpdateRiskScoreDialog } from '../components/UpdateRiskScoreDialog';
import { riskScoreToSeverity } from '../utils';
import { MarkdownText } from '@/components/markdown/Markdown';
import { useTeam } from '@/app/team/context/TeamContext';
import { Button, LinkButton } from '@/components/button/Button';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { updateRisk } from '../models';
import { getDisplayError } from '@/utils/get-display-error';
import { useKeybindings } from '@/hooks/useKeybindings';

const renderText = (content: string) => content;

export interface IRiskViewProps {
  risk: IRisk;
}

const RiskView: React.FC<IRiskViewProps> = (props) => {
  const { risk } = props;
  const { team } = useTeam();
  const { workspace, tree } = useWorkspace();
  const [isEditScoreOpen, setIsEditScoreOpen] = useState(false);
  const [initialTitle, setInitialTitle] = useState(risk.title);
  const [initialDescription, setInitialDescription] = useState(risk.description);
  const [description, setDescription] = useState(risk.description);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Function to adjust textarea height based on content
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  };

  // Adjust height when description changes
  useEffect(() => {
    adjustTextareaHeight();
  }, [description]);

  // Adjust height on initial render
  useEffect(() => {
    adjustTextareaHeight();
  }, []);

  const fullpath = tree.getNode(risk.documentId)?.getFullpath() ?? 'unknown file';
  const riskScore = risk.overallScoreOverwrite ?? risk.overallScore;
  const severity = riskScoreToSeverity(riskScore);
  return (
    <div>
      <div className="flex justify-between items-center mb-3">
        <div
          className="heading-two focus:outline-none cursor-text flex-1"
          contentEditable={true}
          onBlur={async (evt) => {
            const newTitle = evt.target.innerText.replace(/\s+/g, ' ').trim();
            if (newTitle === initialTitle) {
              return;
            }

            try {
              await updateRisk({
                workspaceId: workspace.id,
                riskId: risk.id,
                data: {
                  title: newTitle,
                },
              });
              setInitialTitle(newTitle);
              toast.success('Risk title has been updated');
            } catch (err) {
              captureException(err);
              toast.error('Could not update risk title: ' + getDisplayError(err));
            }
          }}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter' || evt.key === 'Escape') {
              evt.preventDefault();
              evt.currentTarget.blur();
            }
          }}
        >
          {risk.title}
        </div>

        <div className="flex self-start gap-2">
          {!risk.isArchived && (
            <ConfirmDialog
              triggerText={risk.isPartOfTheReport ? 'Delete from report' : 'Add to report'}
              title={risk.isPartOfTheReport ? 'Delete from report' : 'Add to report'}
              submitText={risk.isPartOfTheReport ? 'Delete from report' : 'Add to report'}
              description={
                risk.isPartOfTheReport
                  ? `Do you want to remove this risk from the report?`
                  : `Do you want to add this risk to the report?`
              }
              onSubmit={async () => {
                try {
                  await updateRisk({
                    riskId: risk.id,
                    workspaceId: workspace.id,
                    data: {
                      isPartOfTheReport: !risk.isPartOfTheReport,
                    },
                  });
                  toast.success('Risk has been updated');
                } catch (err) {
                  captureException(err);
                  toast.error('Could not update risk: ' + getDisplayError(err));
                }
              }}
            />
          )}
          <ConfirmDialog
            triggerIconLeft={<ArchiveIcon className="w-4 h-4" />}
            triggerText={risk.isArchived ? 'Unarchive' : 'Archive'}
            title={risk.isArchived ? 'Unarchive' : 'Archive'}
            submitText={risk.isArchived ? 'Unarchive' : 'Archive'}
            description={risk.isArchived ? `Do you want to unarchive this risk?` : `Do you want to archive this risk?`}
            triggerVariant={risk.isArchived ? 'default' : 'destructive'}
            submitVariant={risk.isArchived ? 'primary' : 'destructive'}
            onSubmit={async () => {
              try {
                await updateRisk({
                  riskId: risk.id,
                  workspaceId: workspace.id,
                  data: {
                    isArchived: !risk.isArchived,
                    isPartOfTheReport: !risk.isArchived ? false : undefined,
                  },
                });
                toast.success('Risk has been updated');
              } catch (err) {
                captureException(err);
                toast.error('Could not update risk: ' + getDisplayError(err));
              }
            }}
          />
        </div>
      </div>

      <div className="pb-2 border-b border-gray-300">
        <div
          className="cursor-pointer inline-flex items-center gap-2 mb-2 hover:bg-neutral-200 rounded px-2 py-1"
          onClick={() => {
            setIsEditScoreOpen(true);
          }}
        >
          <RiskIndicator hasBeenScored={risk.hasBeenScored} score={riskScore} />
          <div className="capitalize select-none">{severity}</div>
        </div>
        <UpdateRiskScoreDialog riskId={risk.id} isOpen={isEditScoreOpen} setIsOpen={setIsEditScoreOpen} />
      </div>

      <div className="grid grid-cols-2 gap-8 pt-4">
        <div>
          <div className="heading-three mb-2">Risk description</div>
          <textarea
            ref={textareaRef}
            className="pb-4 whitespace-pre-wrap break-words overflow-hidden focus:outline-none w-full border-none resize-none bg-transparent"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onBlur={async () => {
              const newDescription = description.trim();
              if (newDescription === initialDescription) {
                return;
              }

              try {
                await updateRisk({
                  workspaceId: workspace.id,
                  riskId: risk.id,
                  data: {
                    description: newDescription,
                  },
                });
                setInitialDescription(newDescription);
                toast.success('Risk description has been updated');
              } catch (err) {
                captureException(err);
                toast.error('Could not update risk description: ' + getDisplayError(err));
              }
            }}
            onKeyDown={(evt) => {
              if (evt.key === 'Escape' || (evt.key === 'Enter' && (evt.ctrlKey || evt.metaKey))) {
                evt.preventDefault();
                evt.currentTarget.blur();
              }
            }}
          />
        </div>

        <div>
          <div className="flex justify-between mb-2">
            <div className="heading-three">Clause</div>
            <div>
              <Link
                to={`/app/t/${team.id}/workspace/${workspace.id}/documents/redirect-to/${risk.documentId}`}
                target="_blank"
              >
                <BookmarkIcon className="w-4 h-4 hover:text-blue-600" />
              </Link>
            </div>
          </div>
          <div className="whitespace-prewrap">
            <MarkdownText content={risk.clause} renderText={renderText} />
          </div>

          <div className="mt-2 text-gray-800">{fullpath}</div>
        </div>
      </div>
    </div>
  );
};

export const WorkspaceRiskPage = () => {
  const { riskId: _riskId } = useParams<{ riskId: string }>();
  const riskId = nullthrows(_riskId, 'Risk ID is required');

  const navigate = useNavigate();
  const { syncState, treeKey } = useWorkspace();
  const [risk, setRisk] = useState<IRisk | null>(syncState.risksState.getRisk(riskId));
  const [nextRiskId, setNextRiskId] = useState<string | null>(null);
  const [prevRiskId, setPrevRiskId] = useState<string | null>(null);

  const prev = useCallback(() => {
    if (!prevRiskId) {
      return;
    }

    navigate(`../${prevRiskId}`, {
      replace: true,
    });
  }, [prevRiskId]);
  const next = useCallback(() => {
    if (!nextRiskId) {
      return;
    }

    navigate(`../${nextRiskId}`, {
      replace: true,
    });
  }, [nextRiskId]);

  useKeybindings(
    useMemo(() => {
      return {
        ArrowLeft: prev,
        ArrowRight: next,
      };
    }, [prev, next]),
    {
      event: 'keyup',
      capture: true,
    },
  );

  useEffect(() => {
    const disposable = syncState.risksState.onRisksUpdate(() => {
      setRisk(syncState.risksState.getRisk(riskId));
    });
    setRisk(syncState.risksState.getRisk(riskId));
    return () => disposable.dispose();
  }, [syncState.risksState, treeKey, riskId]);

  useEffect(() => {
    if (!risk) {
      setNextRiskId(null);
      setPrevRiskId(null);
      return;
    }

    const risks = risk.isArchived ? syncState.risksState.getArchivedRisks() : syncState.risksState.getActiveRisks();
    const riskIdx = risks.findIndex((r) => r.id === risk.id);
    if (riskIdx === -1) {
      setNextRiskId(null);
      setPrevRiskId(null);
      return;
    }

    if (riskIdx > 0) {
      setPrevRiskId(risks[riskIdx - 1]!.id);
    } else {
      setPrevRiskId(null);
    }

    const nextRisk = risks[riskIdx + 1];
    if (nextRisk) {
      setNextRiskId(nextRisk.id);
    } else {
      setNextRiskId(null);
    }
  }, [risk?.id]);

  return (
    <div className="flex">
      <div className="flex-1 p-8 overflow-y-auto h-without-topbar">
        <div className="flex justify-between mb-4">
          <LinkButton to=".." iconLeft={<ArrowLeftIcon className="w-4 h-4" />}>
            Back to Risks
          </LinkButton>

          <div className="flex gap-2">
            <Button shape="square" onTrigger={prev} isDisabled={!prevRiskId}>
              <ArrowLeftIcon className="w-4 h-4" />
            </Button>
            <Button shape="square" onTrigger={next} isDisabled={!nextRiskId}>
              <ArrowRightIcon className="w-4 h-4" />
            </Button>
          </div>
        </div>

        <div className="my-4">
          {!risk && <div>Risk not found.</div>}

          {!!risk && <RiskView risk={risk} key={risk.id} />}
        </div>
      </div>
    </div>
  );
};
