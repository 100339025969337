import React, { useState, useEffect, useMemo, useCallback, useId } from 'react';

import { Pagination, IPaginationProps } from '@/components/Pagination';
import { generateDocumentContentTree } from '../utils/content-tree.util';
import { DocumentNode } from './DocumentNode';

const PARAGRAPHS_PER_PAGE = 100;

const contentTreeCache = new Map<string, ReturnType<typeof generateDocumentContentTree>>();
function contentTreeCached(content: string) {
  const tree = contentTreeCache.get(content) ?? generateDocumentContentTree(content);
  contentTreeCache.set(content, tree);
  return tree;
}

export interface IDocumentContentProps {
  content: string;
  paragraphsToHighlight?: number[];
}

export const DocumentContent: React.FC<IDocumentContentProps> = (props) => {
  const { content, paragraphsToHighlight = [] } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const id = useId();

  // Memoize parsed paragraphs to avoid unnecessary recalculations
  const nodes = useMemo(() => contentTreeCached(content), [content]);

  // Memoize total pages calculation
  const totalPages = useMemo(() => Math.max(1, Math.ceil(nodes.length / PARAGRAPHS_PER_PAGE)), [nodes.length]);

  // Memoize current paragraphs to display
  const currentParagraphs = useMemo(() => {
    const indexOfFirstParagraph = currentPage * PARAGRAPHS_PER_PAGE;
    return nodes.slice(indexOfFirstParagraph, indexOfFirstParagraph + PARAGRAPHS_PER_PAGE);
  }, [nodes, currentPage]);

  // Reset to first page when content changes if no highlights
  useEffect(() => {
    if (paragraphsToHighlight.length === 0) {
      setCurrentPage(0);
    }
  }, [content, paragraphsToHighlight.length]);

  // Jump to the page containing the first highlighted paragraph
  useEffect(() => {
    if (nodes.length > 0 && paragraphsToHighlight.length > 0) {
      // Find the lowest index among paragraphs to highlight
      const firstHighlightIndex = Math.min(...paragraphsToHighlight);

      // Calculate which page contains this paragraph
      const targetPage = Math.floor(firstHighlightIndex / PARAGRAPHS_PER_PAGE);

      // Set the page
      setCurrentPage(targetPage);
    }
  }, [nodes.length, paragraphsToHighlight]);

  // Memoize pagination navigation handlers
  const nextPage = useCallback(() => {
    if (currentPage < totalPages - 1) {
      setIsLoading(true);
      // Simulate a brief loading state for better UX feedback
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
        window.scrollTo(0, 0);
        setIsLoading(false);
      }, 100);
    }
  }, [currentPage, totalPages]);

  const prevPage = useCallback(() => {
    if (currentPage > 0) {
      setIsLoading(true);
      // Simulate a brief loading state for better UX feedback
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage - 1);
        window.scrollTo(0, 0);
        setIsLoading(false);
      }, 100);
    }
  }, [currentPage]);

  // Memoize pagination props to prevent unnecessary re-renders of Pagination component
  const paginationProps = useMemo<IPaginationProps>(
    () => ({
      hasPrevious: currentPage > 0,
      previous: prevPage,
      hasNext: currentPage < totalPages - 1,
      next: nextPage,
      isFetching: isLoading,
      page: currentPage,
      totalPages: totalPages,
    }),
    [currentPage, totalPages, nextPage, prevPage, isLoading],
  );

  // If there's no content, show a message
  if (nodes.length === 0) {
    return <div className="text-gray-500 p-4">No content available.</div>;
  }

  const indexOfFirstParagraph = currentPage * PARAGRAPHS_PER_PAGE;
  const highlightSet = new Set(paragraphsToHighlight);
  return (
    <div>
      {/* Document Content */}
      {currentParagraphs.map((node, index) => {
        const absoluteIndex = indexOfFirstParagraph + index;
        const isHighlighted = highlightSet.has(absoluteIndex);

        const baseKey = `${id}-${absoluteIndex}`;
        if (isHighlighted) {
          return (
            <div key={baseKey} id={baseKey}>
              <DocumentNode node={node} baseKey={baseKey} isHighlighted={true} />
            </div>
          );
        }

        return <DocumentNode node={node} baseKey={baseKey} key={baseKey} isHighlighted={false} />;
      })}

      {/* Pagination Controls */}
      <div className="mt-6 pb-4">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};
