import React from 'react';
import { IChildNode } from '../utils/content-tree.util';
import classNames from '@/utils/classnames';

function headingLevelToTag(level: number) {
  switch (level) {
    case 1:
      return 'h1';
    case 2:
      return 'h2';
    case 3:
      return 'h3';
    case 4:
      return 'h4';
    case 5:
      return 'h5';
    default:
      return 'h6';
  }
}

const _DocumentNode: React.FC<{ node: IChildNode; baseKey: string; isHighlighted: boolean }> = (props) => {
  const { node, baseKey, isHighlighted } = props;

  if (node.type === 'paragraph') {
    return (
      <p className="my-1 whitespace-pre-line">
        {node.children.map((c, i) => (
          <DocumentNode node={c} baseKey={`${baseKey}-${i}`} key={`${baseKey}-${i}`} isHighlighted={isHighlighted} />
        ))}
      </p>
    );
  } else if (node.type === 'heading') {
    return React.createElement(
      headingLevelToTag(node.level),
      {
        className: 'doc-heading',
      },
      node.children.map((c, i) => (
        <DocumentNode node={c} baseKey={`${baseKey}-${i}`} key={`${baseKey}-${i}`} isHighlighted={isHighlighted} />
      )),
    );
  } else if (node.type === 'table') {
    return (
      <table className="table-auto my-4 rounded-md overflow-hidden">
        {node.rows.map((row, rowIdx) => {
          return (
            <tr
              className={classNames('border-b', {
                'bg-dark-06': row.columns.find((col) => col.type === 'th'),
              })}
              key={`${baseKey}-${rowIdx}`}
            >
              {row.columns.map((col, colIdx) => {
                const colKey = `${baseKey}-${rowIdx}-${colIdx}`;

                if (col.type === 'td') {
                  return (
                    <td
                      key={colKey}
                      colSpan={col.colspan}
                      className={classNames('p-2 border-b', {
                        'border-r': colIdx !== row.columns.length - 1,
                      })}
                    >
                      {col.children.map((c, i) => {
                        return (
                          <DocumentNode
                            node={c}
                            baseKey={`${baseKey}-${i}`}
                            key={`${colKey}-${i}`}
                            isHighlighted={isHighlighted}
                          />
                        );
                      })}
                    </td>
                  );
                } else {
                  return (
                    <th
                      key={colKey}
                      colSpan={col.colspan}
                      className={classNames('p-2 border-b', {
                        'border-r': colIdx !== row.columns.length - 1,
                      })}
                    >
                      {col.children.map((c, i) => {
                        return (
                          <DocumentNode
                            node={c}
                            baseKey={`${baseKey}-${i}`}
                            key={`${colKey}-${i}`}
                            isHighlighted={isHighlighted}
                          />
                        );
                      })}
                    </th>
                  );
                }
              })}
            </tr>
          );
        })}
      </table>
    );
  } else if (node.type === 'list') {
    const TagType = node.isOrdered ? 'ol' : 'ul';
    return (
      <TagType className="my-4" start={node.start}>
        {node.items.map((item, itemIdx) => {
          const liKey = `${baseKey}-${itemIdx}`;
          return (
            <li
              className={classNames('ml-4', {
                'list-decimal': node.isOrdered,
                'list-disc': !node.isOrdered,
              })}
              key={liKey}
            >
              {item.children.map((c, i) => (
                <DocumentNode
                  node={c}
                  baseKey={`${baseKey}-${i}`}
                  key={`${liKey}-${i}`}
                  isHighlighted={isHighlighted}
                />
              ))}
            </li>
          );
        })}
      </TagType>
    );
  } else if (node.type === 'text') {
    return (
      <span
        className={classNames({
          'font-bold': node.isBold,
          'font-italic': node.isItalic,
          'bg-yellow-fluo': isHighlighted,
        })}
      >
        {node.content}
      </span>
    );
  } else {
    console.error('Unhandled node type', node);
    return null;
  }
};

export const DocumentNode = React.memo(_DocumentNode);

DocumentNode.displayName = 'DocumentNode';
