import React, { useState } from 'react';

import { getDisplayError } from '../../utils/get-display-error';
import { BaseButton } from '../button/BaseButton';
import { Button, ButtonProps } from '../button/Button';
import { DialogContent, DialogRoot } from './Dialog';
import { Input } from '../input/Input';
import { InputWrapper } from '../InputWrapper';

export interface IConfirmDialogProps {
  triggerSize?: ButtonProps['size'];
  triggerText: React.ReactNode;
  triggerVariant?: ButtonProps['variant'];
  triggerIconLeft?: ButtonProps['iconLeft'];
  triggerIconRight?: ButtonProps['iconRight'];
  title: string;
  description: React.ReactNode;
  cancelText?: string;
  submitText: string;
  submitVariant?: ButtonProps['variant'];
  isDisabled?: boolean;
  isOpen?: boolean;
  confirmationText?: string | null;
  setIsOpen?: (isOpen: boolean) => void;
  onSubmit: () => void | Promise<void>;
  onCancel?: () => void;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props) => {
  const {
    triggerSize,
    triggerText,
    triggerVariant,
    triggerIconLeft,
    triggerIconRight,
    title,
    cancelText = 'Cancel',
    submitText,
    submitVariant,
    description,
    isOpen,
    confirmationText,
    setIsOpen,
    onSubmit,
    onCancel,
    isDisabled,
  } = props;
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = async () => {
    if (confirmationText && inputValue.trim().toLowerCase() !== confirmationText.trim().toLowerCase()) {
      setError('Confirmation text does not match');
      return;
    }

    setError('');
    setIsLoading(true);
    try {
      await onSubmit();
      setOpen(false);
      setInputValue('');
    } catch (err) {
      setError(getDisplayError(err));
    }
    setIsLoading(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (setIsOpen) {
      setIsOpen(newOpen);
    } else {
      setOpen(newOpen);
    }

    if (!newOpen && onCancel) {
      onCancel();
    }
  };

  return (
    <DialogRoot open={isOpen ?? open} onOpenChange={handleOpenChange}>
      {!setIsOpen && (
        <Button
          size={triggerSize}
          isLoading={isLoading}
          variant={triggerVariant}
          iconLeft={triggerIconLeft}
          iconRight={triggerIconRight}
          title={title}
          isDisabled={isDisabled}
          onTrigger={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();

            setOpen(true);
          }}
        >
          {triggerText}
        </Button>
      )}

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">{title}</h1>

        <div className="mb-4">{description}</div>

        {!!confirmationText && (
          <InputWrapper labelText={`Type "${confirmationText}" to confirm`}>
            <Input
              type="text"
              onChange={setInputValue}
              value={inputValue}
              onKeyDown={(evt) => {
                if (evt.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
          </InputWrapper>
        )}

        <div className="text-sm mb-4 w-full text-danger-color-dark">{error}</div>

        <div className="flex justify-between">
          <div>
            <BaseButton
              isLoading={isLoading}
              disabled={isLoading}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (onCancel) {
                  onCancel();
                }

                if (setIsOpen) {
                  setIsOpen(false);
                } else {
                  setOpen(false);
                }
              }}
            >
              {cancelText}
            </BaseButton>
          </div>
          <div>
            <BaseButton
              autoFocus={true}
              isLoading={isLoading}
              disabled={isLoading}
              variant={submitVariant ?? 'primary'}
              onClick={(event) => {
                handleSubmit();

                event.preventDefault();
                event.stopPropagation();
              }}
            >
              {submitText}
            </BaseButton>
          </div>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
