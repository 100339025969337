import { useMemo } from 'react';
import useSWR from 'swr';

import { PageHeader } from '@/components/PageHeader';
import type { ResponseType as QuestionsPresetsResponseType } from '@/app/questionsPreset/endpoints/QuestionsPresetsEndpoint';
import { useTeam } from '@/app/team/context/TeamContext';
import { fetchEndpointData } from '@/utils/fetch.client';
import { Spinner } from '@/components/Spinner';
import { StartAnalysisFlow } from './StartFlow';

export const StartCrossDocumentAnalysisPage = () => {
  const { team } = useTeam();

  const { data, isLoading } = useSWR<QuestionsPresetsResponseType>(
    `/api/v1/questions-preset/list?teamId=${team.id}&page=0&take=100`,
    fetchEndpointData,
  );

  const presets = useMemo(() => {
    return (data?.docs ?? []).filter((v) => v.type === 'cross-document');
  }, [data]);

  return (
    <div className="page-content">
      <PageHeader title="Analyse documents one by one" />

      {isLoading ? <Spinner size={12} /> : <StartAnalysisFlow presets={presets} presetType="cross-document" />}
    </div>
  );
};
